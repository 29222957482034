import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  OnChanges,
  OnDestroy,
  ViewChild,
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import {
  AdditionalFieldSpec,
  AdditionalFieldValue,
  AddressFieldDefinition,
  AddressViewModel,
  BooleanFieldDefinition,
  ContactFormGroup,
  CurrencyFieldDefinition,
  CurrencyValue,
  DateFieldDefinition,
  FieldCategory,
  NumberFieldDefinition,
  ProfileListReference,
  CombinedFilingData,
  DocumentInfo,
  RequestContact,
  SearchResultItem,
  TextFieldDefinition,
  RequestDocumentParticipant,
} from '@fsx/fsx-shared';
import {
  asyncScheduler,
  debounceTime,
  distinctUntilChanged,
  skip,
  startWith,
  Subject,
  take,
  takeUntil,
  tap,
} from 'rxjs';
import {
  FormArrayWithModel,
  FormControlWithModel,
} from '../../models/form-control.model';
import {
  AddressComponentFieldDefinition,
  AddressFormGroup,
  ReferenceResolver,
  SelectionFieldDefinition,
  SelectionFieldType,
} from '../../types';
import { IUploadedFile } from '../file-upload/base-file-upload.component';
import { FsxAddressComponent } from '../address/address.component';
import { FsxTextComponent } from '../text/text.component';
import { FsxNumberComponent } from '../number/number.component';
import { FsxDateComponent } from '../date/date.component';
import { FsxCurrencyComponent } from '../currency/currency.component';
import { FsxBooleanComponent } from '../boolean/boolean.component';
import {
  FsxContactComponent,
  FsxEfmSearchComponent,
  FsxFileUploadWrapperComponent,
  FsxParticipantComponent,
  FsxProfileSingleSelectionComponent,
} from '../../../public-api';

export interface FilesUploadedEventParams {
  uploadedFiles: IUploadedFile[];
  additionalFieldName: string;
}

@Component({
  selector: 'fsx-additional-fields-component',
  templateUrl: './additional.component.html',
  styleUrls: ['./additional.component.scss'],
})
export class FsxAdditionalFieldsComponent
  implements OnInit, OnChanges, OnDestroy
{
  @Input() additionalFieldSpec!: AdditionalFieldSpec;
  @Input() resolver!: ReferenceResolver;
  @Input() initialValues!: AdditionalFieldValue | null | undefined;

  /**
   * The array of existing AdditionalFieldValue objects to pass onto nested instances of this
   * AdditionalFieldsComponent.
   *
   * All values coming from the same AdditionalFieldCompoent hierarchy are stored in the same
   * additionalFieldValues array. Since the AdditionalFieldComponent can appear almost anywhere,
   * it is the responsibility of the parent container component to pass in the correct instance.
   *
   * For example:
   *
   * All AdditionalFieldValue objects for all document-level additional-fields are stored in
   * the RequestDocumentCase.additionalFieldValues array.
   *
   * And...
   *
   * All AdditionalFieldValue objects for all "filed by" (associated-party) additional-fields
   * are stored in RequestDocumentCase.filedBy[0].additionalFieldValues.
   *
   */
  @Input() additionalFieldValues: AdditionalFieldValue[] | null = null;

  @Input() combinedFilingData!: CombinedFilingData | null;
  @Input() fileUploadDocumentInfos: DocumentInfo[] = [];
  @Input() id: string | number | null = null;
  @Output() filesUploadedFromAdditionalFieldEvent =
    new EventEmitter<FilesUploadedEventParams>();
  @Output() fileRemovedFromAdditionalFieldEvent =
    new EventEmitter<DocumentInfo>();
  @Output() additionalFieldValueEvent =
    new EventEmitter<AdditionalFieldValue>();

  @ViewChild('addressField') addressField!: FsxAddressComponent;
  @ViewChild('textField') textField!: FsxTextComponent;
  @ViewChild('numberField') numberField!: FsxNumberComponent;
  @ViewChild('dateField') dateField!: FsxDateComponent;
  @ViewChild('currencyField') currencyField!: FsxCurrencyComponent;
  @ViewChild('booleanField') booleanField!: FsxBooleanComponent;
  @ViewChild('singleSelectionField')
  singleSelectionField!: FsxProfileSingleSelectionComponent;
  @ViewChild('searchField') searchField!: FsxEfmSearchComponent;
  @ViewChild('fileUploadField') fileUploadField!: FsxFileUploadWrapperComponent;
  @ViewChild('contactField') contactField!: FsxContactComponent;
  @ViewChild('participantField') participantField!: FsxParticipantComponent;

  public fieldCategory = FieldCategory;
  public textFormControl!: FormControlWithModel<TextFieldDefinition>;
  public numberFormControl!: FormControlWithModel<NumberFieldDefinition>;
  public dateFormControl!: FormControlWithModel<DateFieldDefinition>;
  public currencyFormControl!: FormControlWithModel<CurrencyFieldDefinition>;
  public booleanFormControl!: FormControlWithModel<BooleanFieldDefinition>;
  public addressFormArray!: FormArrayWithModel<FormGroup<AddressFormGroup>>;
  public contactFormArray!: FormArrayWithModel<FormGroup<ContactFormGroup>>;
  public selectionFormControl!: FormControlWithModel<SelectionFieldDefinition>;

  /**
   * The empty AdditionalFieldValue object, which is emitted through the
   * additionalFieldValueEvent evemt emitter.
   */
  public additionalFieldValue = {} as AdditionalFieldValue;

  public selectionType = SelectionFieldType;
  private destroy$: Subject<void> = new Subject<void>();

  public documentInfos!: DocumentInfo[];
  public fileValues!: string[];

  ngOnInit(): void {
    // We always set the additionalFieldName first. This ensures that container components
    // know which additional field the emitted values came from.
    this.additionalFieldValue.additionalFieldName =
      this.additionalFieldSpec.name ?? '';
  }

  ngOnChanges(): void {
    // TODO - this is a little hacky, to cater for dependent file upload fields
    if (this.additionalFieldSpec.fieldType === FieldCategory.File) {
      // we're a file category
      if (this.initialValues && this.initialValues.fileValues) {
        if (this.initialValues.fileValues.length > 0) {
          // get the document infos from the IDs passed in
          this.fileUploadDocumentInfos = this.resolver.getDocumentInfos(
            this.initialValues.fileValues,
          );
        } else {
          this.fileUploadDocumentInfos = [];
        }
      }
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  public getAddressFieldDefinition(
    addrFieldDefinition: AddressFieldDefinition,
  ): AddressComponentFieldDefinition | null {
    let addressFieldDefinition: AddressComponentFieldDefinition;
    const addressCategoriesDefinition = this.resolver.getAddressProfile(
      addrFieldDefinition.addressProfileName,
    );
    if (addressCategoriesDefinition) {
      addressFieldDefinition = {
        minRequired: addrFieldDefinition.minRequired,
        maxAllowed: addrFieldDefinition.maxAllowed,
        addressProfileName: addrFieldDefinition.addressProfileName,
        country: addrFieldDefinition.country,
        category: addrFieldDefinition.category,
        description: addrFieldDefinition.description,
        additionalFields: addrFieldDefinition.additionalFields,
        ...addressCategoriesDefinition.spec,
      };
    } else {
      return null;
    }

    return addressFieldDefinition;
  }

  public setAddressFormArray(
    formArray: FormArrayWithModel<FormGroup<AddressFormGroup>>,
  ): void {
    this.addressFormArray = formArray;
    let addressValues: AddressViewModel[] = [];
    this.addressFormArray.valueChanges
      .pipe(
        debounceTime(700),
        skip(1),
        take(1),
        tap(() => {
          this.additionalFieldValue.addressValues = [];
          this.addressFormArray.value.forEach(
            (address: AddressViewModel, index: number) => {
              const listReference =
                this.additionalFieldSpec.addressFieldDefinition?.category
                  ?.listReference ??
                ({
                  list: 'AddressCategories',
                  additionalListName: '',
                } as ProfileListReference);
              let dropdownCategory = this.resolver
                .getAddressCategoryDropdownOptions(listReference)
                .find((element) => element.category?.name === address.category);
              if (dropdownCategory?.category) {
                address.category = {
                  caption: dropdownCategory.category.caption,
                  name: dropdownCategory.category.name,
                  commonCategory: dropdownCategory.category.commonCategory,
                };
              }
              let newAddress: AddressViewModel = {
                ...address,
                caption: '',
              } as AddressViewModel;
              newAddress.caption = this.setAddressCaption(newAddress);
              addressValues[index] = newAddress;
            },
          );
        }),
        distinctUntilChanged((prev, cur) => {
          // Prevent continuing to issue an update if there is nothing new to update.
          const areEqual = JSON.stringify(prev) === JSON.stringify(cur);
          return areEqual;
        }),
        tap(() => {
          // Issue the update
          this.additionalFieldValue.addressValues = addressValues;
          this.additionalFieldValueEvent.emit(this.additionalFieldValue);
        }),
      )
      .subscribe();
  }

  public setContactFormArray(
    formArray: FormArrayWithModel<FormGroup<ContactFormGroup>>,
  ): void {
    this.contactFormArray = formArray;
    let contactValues: RequestContact[] = [];
    this.contactFormArray.valueChanges
      .pipe(
        take(1),
        tap(() => {
          this.additionalFieldValue.contactValues = [];
          this.contactFormArray.value.forEach(
            (contact: RequestContact, index: number) => {
              // Set Category for contact addresses
              this._setContactCategories(contact);
              // Set category for phones
              this._setPhoneCategories(contact);
              // Set category for emails
              this._setEmailCategories(contact);
              // Finish Building new contact object
              let newContact: RequestContact = {
                ...contact,
              } as RequestContact;
              // Set new contact to appropriate index
              contactValues[index] = newContact;
            },
          );

          this.additionalFieldValue.contactValues = contactValues;
          if (this.contactFormArray.touched) {
            this.additionalFieldValueEvent.emit(this.additionalFieldValue);
          }
        }),
      )
      .subscribe();
  }

  public setAddressCaption(newAddress: AddressViewModel): string {
    let caption = '';
    if (newAddress.addressLine1) {
      caption = `${newAddress.addressLine1}`;
    }

    if (newAddress.addressLine2) {
      caption += `, ${newAddress.addressLine2}`;
    }

    if (newAddress.locality) {
      caption += `, ${newAddress.locality}`;
    }

    if (newAddress.administrativeArea) {
      caption += `, ${newAddress.administrativeArea}`;
    }

    if (newAddress.postalCode) {
      caption += `, ${newAddress.postalCode}`;
    }

    if (newAddress.country) {
      caption += `, ${newAddress.country}`;
    }

    return caption;
  }

  public setTextFormControl(
    formControl: FormControlWithModel<TextFieldDefinition>,
  ) {
    this.textFormControl = formControl;
    this.textFormControl.valueChanges
      .pipe(
        debounceTime(500),
        startWith(formControl.value),
        takeUntil(this.destroy$),
        tap(() => {
          this.additionalFieldValue.textValue = this.textFormControl.value;
          this.additionalFieldValueEvent.emit(this.additionalFieldValue);
        }),
      )
      .subscribe();
  }

  public setNumberFormControl(
    formControl: FormControlWithModel<NumberFieldDefinition>,
  ) {
    this.numberFormControl = formControl;
    this.numberFormControl.valueChanges
      .pipe(
        debounceTime(500),
        startWith(formControl.value),
        takeUntil(this.destroy$),
        tap(() => {
          asyncScheduler.schedule(() => {
            this.additionalFieldValue.numberValue =
              this.numberFormControl.value;
            this.additionalFieldValueEvent.emit(this.additionalFieldValue);
          });
        }),
      )
      .subscribe();
  }

  public setDateFormControl(
    formControl: FormControlWithModel<DateFieldDefinition>,
  ) {
    this.dateFormControl = formControl;
    this.dateFormControl.valueChanges
      .pipe(
        debounceTime(500),
        startWith(formControl.value),
        takeUntil(this.destroy$),
        tap(() => {
          this.additionalFieldValue.dateValue = this.dateFormControl.value;
          this.additionalFieldValueEvent.emit(this.additionalFieldValue);
        }),
      )
      .subscribe();
  }

  public setCurrencyFormControl(
    formControl: FormControlWithModel<CurrencyFieldDefinition>,
  ) {
    this.currencyFormControl = formControl;
    this.currencyFormControl.valueChanges
      .pipe(
        debounceTime(500),
        startWith(formControl.value),
        takeUntil(this.destroy$),
        tap(() => {
          asyncScheduler.schedule(() => {
            const currValue: CurrencyValue = {
              currencyKey: null, // ToDo: Hardcoded 'usd' was failing validation. BE expecting null.
              amount: this.currencyFormControl.value,
            };
            this.additionalFieldValue.currencyValue = currValue;
            this.additionalFieldValueEvent.emit(this.additionalFieldValue);
          });
        }),
      )
      .subscribe();
  }

  public setBooleanFormControl(
    formControl: FormControlWithModel<BooleanFieldDefinition>,
  ) {
    this.booleanFormControl = formControl;
    this.booleanFormControl.valueChanges
      .pipe(
        debounceTime(500),
        startWith(formControl.value),
        takeUntil(this.destroy$),
        tap(() => {
          this.additionalFieldValue.booleanValue =
            this.booleanFormControl.value;
          this.additionalFieldValueEvent.emit(this.additionalFieldValue);
        }),
      )
      .subscribe();
  }

  public updateNestedAdditionalField(addlFieldValue: AdditionalFieldValue) {
    this.additionalFieldValueEvent.emit(addlFieldValue);
  }

  public setSelectionFormControl(
    formControl: FormControlWithModel<SelectionFieldDefinition>,
  ) {
    this.selectionFormControl = formControl;
    this.selectionFormControl.valueChanges
      .pipe(
        debounceTime(500),
        startWith(formControl.value),
        takeUntil(this.destroy$),
        tap(() => {
          this.additionalFieldValue.selectionValue = [];
          if (this.selectionFormControl.value) {
            this.additionalFieldValue.selectionValue.push(
              this.selectionFormControl.value,
            );
          }
          this.additionalFieldValueEvent.emit(this.additionalFieldValue);
        }),
      )
      .subscribe();
  }

  public setSearchFormControl(option: SearchResultItem) {
    this.additionalFieldValue.searchResultItem = option;
    this.additionalFieldValueEvent.emit(this.additionalFieldValue);
  }

  public setParticipantSelectedValue(option: RequestDocumentParticipant[]) {
    // ToDo: Talk to dave about this workaround (NOT A PERM FIX)
    this.additionalFieldValue.participantValues = option.filter(
      (o) => !!o.participantName,
    );
    this.additionalFieldValueEvent.emit(this.additionalFieldValue);
  }

  public onFilesUploadedEvent(uploadedFiles: IUploadedFile[]) {
    const fileNames: string[] = uploadedFiles.map(
      (uploadedFile) => uploadedFile.file.name,
    );
    this.additionalFieldValue.fileValues = fileNames;

    const params: FilesUploadedEventParams = {
      uploadedFiles: uploadedFiles,
      additionalFieldName: this.additionalFieldSpec.name,
    };

    this.filesUploadedFromAdditionalFieldEvent.emit(params);
  }

  private _setContactCategories(contact: RequestContact): void {
    if (contact.addresses.length) {
      contact.addresses.forEach((address) => {
        const listRef =
          this.additionalFieldSpec.contactFieldDefinition?.address?.category
            ?.listReference ??
          ({
            list: 'AddressCategories',
            additionalListName: '',
          } as ProfileListReference);
        let dropdownCategory = this.resolver
          .getAddressCategoryDropdownOptions(listRef)
          .find((element) => element.category?.name === address.category);
        if (dropdownCategory?.category) {
          address.category = {
            caption: dropdownCategory.category.caption,
            name: dropdownCategory.category.name,
            commonCategory: dropdownCategory.category.commonCategory,
          };
        }
        address.caption = this.setAddressCaption(address);
      });
    }
  }

  private _setPhoneCategories(contact: RequestContact): void {
    if (!!contact.phones && contact.phones.length) {
      contact.phones.forEach((phone) => {
        const listRef =
          this.additionalFieldSpec.contactFieldDefinition?.phone?.category
            ?.listReference ??
          ({
            list: 'PhoneCategories',
            additionalListName: '',
          } as ProfileListReference);
        let dropdownCategory = this.resolver
          .getPhoneCategoryDropdownOptions(listRef)
          .find((element) => element.category?.name === phone.category);
        if (dropdownCategory?.category) {
          phone.category = {
            caption: dropdownCategory.category.caption,
            name: dropdownCategory.category.name,
            commonCategory: dropdownCategory.category.commonCategory,
          };
        }
      });
    }
  }

  private _setEmailCategories(contact: RequestContact): void {
    if (!!contact.emails && contact.emails.length) {
      contact.emails.forEach((email) => {
        const listRef =
          this.additionalFieldSpec.contactFieldDefinition?.email?.category
            ?.listReference ??
          ({
            list: 'EmailCategories',
            additionalListName: '',
          } as ProfileListReference);
        let dropdownCategory = this.resolver
          .getEmailCategoryDropdownOptions(listRef)
          .find((element) => element.category?.name === email.category);
        if (dropdownCategory?.category) {
          email.category = {
            caption: dropdownCategory.category.caption,
            name: dropdownCategory.category.name,
            commonCategory: dropdownCategory.category.commonCategory,
          };
        }
      });
    }
  }

  fileRemovedEventHandler(documentInfo: DocumentInfo) {
    this.fileRemovedFromAdditionalFieldEvent.emit(documentInfo);
  }

  public filesUploadedFromSelectionFieldEventHandler(
    filesUploadedEventParams: FilesUploadedEventParams,
  ) {
    this.filesUploadedFromAdditionalFieldEvent.emit(filesUploadedEventParams);
  }

  public fileRemovedFromSelectionFieldEventHandler(documentInfo: DocumentInfo) {
    this.fileRemovedFromAdditionalFieldEvent.emit(documentInfo);
  }

  public validate(): void {
    if (this.addressField) {
      this.addressField.validate();
    }
    if (this.textField) {
      this.textField.validate();
    }
    if (this.numberField) {
      this.numberField.validate();
    }
    if (this.dateField) {
      this.dateField.validate();
    }
    if (this.currencyField) {
      this.currencyField.validate();
    }
    if (this.booleanField) {
      this.booleanField.validate();
    }
    if (this.singleSelectionField) {
      this.singleSelectionField.validate();
    }
    if (this.searchField) {
      this.searchField.validate();
    }
    if (this.fileUploadField) {
      this.fileUploadField.validate();
    }
    if (this.contactField) {
      this.contactField.validate();
    }
    if (this.participantField) {
      this.participantField.validate();
    }
  }
}
