import { Component, Input, OnInit } from '@angular/core';
import {
  FieldCategory,
  FilingProfileSummary,
  FilingProfileSummaryItemTree,
  ProfileLists,
  StringSelectionFieldDefinition,
} from '@fsx/fsx-shared';
import { FSXFormControlService } from '../../../services';
import { DropdownOption } from '../../../types';
import { FsxBaseSearchComponent } from '../base-search/base-search.component';

@Component({
  selector: 'fsx-profile-search-component',
  templateUrl: '../base-search/base-search.component.html',
  styleUrls: ['../base-search/base-search.component.scss'],
})
export class FsxProfileSearchComponent
  extends FsxBaseSearchComponent
  implements OnInit
{
  @Input() profileData!: FilingProfileSummaryItemTree;

  override fieldDefinition: StringSelectionFieldDefinition = {
    defaultValue: '',
    listReference: {
      list: ProfileLists.AdditionalList,
      additionalListName: '',
    },
    maxAllowed: 1,
    minRequired: 0,
    selectionDependentFields: [],
    readOnly: false,
    visible: true,
  };
  override caption = 'Search Type of Case';
  override hint = 'Type or Select Type of Case';

  constructor(private readonly fsxFormControlService: FSXFormControlService) {
    super();
    this.inputFormControl = this.fsxFormControlService.createFormControl(
      this.fieldDefinition,
      FieldCategory.Selection,
      this.inputFormControl?.value || this.fieldDefinition.defaultValue,
    );
  }

  override ngOnInit(): void {
    this.dropdownOptions = this.traverseTree(this.profileData);
    this.selectionDisplayFn = this.selectionDisplayFn.bind(this);
    this.setDropdownOptionCaptions();
    this.setDropdownOptions();
  }

  public override createDropdownOption(
    profileItem: FilingProfileSummary,
  ): DropdownOption<void> {
    const dropdownOption = {
      caption: profileItem.caption,
      name: profileItem.id,
      selected: false,
      keywords: [],
      itemPath: [],
      icon: 'gavel',
      rating: 0,
    };
    this.addWordToKeyWords(profileItem.caption, dropdownOption, false);
    return dropdownOption;
  }

  private setDropdownOptionCaptions(): void {
    this.dropdownOptions.forEach((option) => {
      if (option.itemPath && option.itemPath.length) {
        const path = option.itemPath.reverse().join(' - ');
        option.secondaryCaption = path;
      }
    });
  }
}
