<form class="fsx-person-name-component" *ngIf="personNameComponentSpec">
  <ng-container
    *ngIf="personNameComponentSpec.givenName && personNameComponentSpec.surName"
  >
    <fsx-text-component
      #prefixField
      style="flex: 0"
      *ngIf="
        personNameComponentSpec.givenName?.visible &&
        personNameComponentSpec.surName?.visible &&
        personNameComponentSpec.prefix?.visible &&
        personNameComponentSpec.prefix
      "
      [caption]="'Prefix'"
      [height]="height"
      [width]="'70px'"
      [resolver]="resolver"
      [fieldDefinition]="personNameComponentSpec.prefix"
      [initialValue]="initialValues ? initialValues.prefix ?? '' : ''"
      (formControlEmitter)="setControl($event, 'prefix')"
    ></fsx-text-component>

    <fsx-text-component
      #firstNameField
      *ngIf="
        personNameComponentSpec.givenName?.visible &&
        personNameComponentSpec.surName?.visible &&
        personNameComponentSpec.givenName
      "
      [caption]="'First Name'"
      [resolver]="resolver"
      [height]="height"
      [fieldDefinition]="personNameComponentSpec.givenName"
      [initialValue]="initialValues ? initialValues.givenName ?? '' : ''"
      (formControlEmitter)="setControl($event, 'givenName')"
    ></fsx-text-component>

    <fsx-text-component
      #middleNameField
      style="flex: 0"
      *ngIf="
        personNameComponentSpec.givenName?.visible &&
        personNameComponentSpec.surName?.visible &&
        personNameComponentSpec.middleName
      "
      [caption]="'Middle Name'"
      [height]="height"
      [width]="'150px'"
      [resolver]="resolver"
      [fieldDefinition]="personNameComponentSpec.middleName"
      [initialValue]="initialValues ? initialValues.middleName ?? '' : ''"
      (formControlEmitter)="setControl($event, 'middleName')"
    ></fsx-text-component>

    <fsx-text-component
      #lastNameField
      *ngIf="
        personNameComponentSpec.givenName?.visible &&
        personNameComponentSpec.surName?.visible &&
        personNameComponentSpec.surName
      "
      [caption]="'Last Name'"
      [height]="height"
      [resolver]="resolver"
      [fieldDefinition]="personNameComponentSpec.surName"
      [initialValue]="initialValues ? initialValues.surName ?? '' : ''"
      (formControlEmitter)="setControl($event, 'surName')"
    ></fsx-text-component>

    <fsx-text-component
      #suffixField
      *ngIf="
        personNameComponentSpec.givenName?.visible &&
        personNameComponentSpec.surName?.visible &&
        personNameComponentSpec.suffix?.visible &&
        personNameComponentSpec.suffix
      "
      [caption]="'Suffix'"
      [width]="'70px'"
      [resolver]="resolver"
      [fieldDefinition]="personNameComponentSpec.suffix"
      [initialValue]="initialValues ? initialValues.suffix ?? '' : ''"
      (formControlEmitter)="setControl($event, 'suffix')"
    ></fsx-text-component>
  </ng-container>

  <fsx-text-component
    #fullNameField
    *ngIf="personNameComponentSpec.fullName"
    [ngStyle]="{
      flex:
        !personNameComponentSpec.givenName?.visible &&
        !personNameComponentSpec.surName?.visible
          ? 1
          : 0,
    }"
    [caption]="'Full Name'"
    [width]="'100%'"
    [hidden]="
      !!(
        personNameComponentSpec.givenName?.visible &&
        personNameComponentSpec.surName?.visible
      )
    "
    [resolver]="resolver"
    [fieldDefinition]="personNameComponentSpec.fullName"
    [initialValue]="initialValues ? initialValues.fullName : ''"
    (formControlEmitter)="setControl($event, 'fullName')"
  ></fsx-text-component>
</form>
