<div
  class="documents-container"
  *ngIf="documentsVm$ | async as vm"
  fxLayout="column"
  fxFill
>
  <div
    class="file-upload-row"
    fxFlex="row"
    fxFill
    fxLayoutAlign="center center"
    *ngIf="vm.documentsGridRows.length === 0; else documentsGrid"
  >
    <fsx-file-upload-large
      (filesUploadedLargeEvent)="onFilesUploaded($event)"
      [permittedFileTypes]="vm.allowedFileTypeExtensions"
      [maxFileSize]="vm.maxFileSize"
    >
    </fsx-file-upload-large>
  </div>
  <ng-template #documentsGrid>
    <fsx-documents-grid
      *ngIf="combinedFilingData$ | async as combinedFilingData"
      [documentsGridRows]="vm.documentsGridRows"
      [combinedFilingData]="combinedFilingData"
      [resolver]="resolver"
      (removeDocumentEvent)="removeDocumentEventHandler($event)"
      (cancelUploadEvent)="cancelUploadEventHandler($event)"
      (toggleExpandRowEvent)="toggleExpandRowEventHandler($event)"
      (reorderDocumentsEvent)="reorderDocumentsEventHandler($event)"
      (supportingFilesUploadedEvent)="
        supportingFilesUploadedEventHandler($event)
      "
      (documentFileUploadedEvent)="documentFileUploadedEventHandler($event)"
      (filesUploadedFromAdditionalFieldEvent)="
        filesUploadedFromAdditionalFieldEventHandler($event)
      "
    >
    </fsx-documents-grid>
  </ng-template>
  <div fxFlex>
    <fsx-file-upload-zone
      *ngIf="vm.documentsGridRows.length > 0"
      (filesUploadedEvent)="onFileDropped($event)"
    >
    </fsx-file-upload-zone>
  </div>
</div>
