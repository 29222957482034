import { Injectable, InjectionToken } from '@angular/core';
import { ContactAliasViewModel, NewContactViewModel } from '@fsx/fsx-shared';

/**
 * The InjectionToken to use in the providers array to specify a concrete-implementation
 * of the IOrganizationFormService to use at runtime.
 */
export const FsxOrganizationFormService =
  new InjectionToken<IOrganizationFormService>('FsxOrganizationFormService');

export interface IOrganizationFormService {
  setOrganizationValues(
    contact: NewContactViewModel | ContactAliasViewModel,
  ): void;
}

@Injectable()
export class OrganizationFormService implements IOrganizationFormService {
  public setOrganizationValues(
    contact: NewContactViewModel | ContactAliasViewModel,
  ): void {
    if (contact.organization && !contact.organization?.caption) {
      contact.organization.caption = '';
    }
    if (contact.organization && !contact.organization?.title) {
      contact.organization.title = '';
    }
  }
}
