import {
  Component,
  EventEmitter,
  Inject,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import {
  CaseRequestViewModel,
  CombinedFilingData,
  DocumentInfo,
  DocumentRenderingInfo,
  FilingProfileHelperService,
  FsxFilingApiService,
  IFilingApiService,
  RenderingStatus,
  RequestDocumentViewModel,
  Severity,
} from '@fsx/fsx-shared';
import { IUploadedFile } from '@fsx/ui-components';
import {
  BehaviorSubject,
  combineLatest,
  filter,
  interval,
  map,
  merge,
  Observable,
  of,
  Subject,
  switchMap,
  take,
  takeUntil,
  tap,
  withLatestFrom,
} from 'rxjs';
import {
  FilingEditorErrorsService,
  IDocumentRuleErrorParams,
  IProfileRuleErrorParams,
} from '../filing-editor/services/filing-editor-errors.service';
import {
  FilingEditorEventService,
  FsxFilingEditorEventService,
  IFileUploadStartedEventParams,
  IFilingEditorEventParams,
  IFilingEditorEventService,
  IRequestDocumentCreatedParams,
} from '../filing-editor/services/filing-editor-events.service';
import {
  FsxUpdateCaseRequestOrchestrationService,
  IUpdateCaseRequestOrchestrationService,
  IUpdateCaseRequestParams,
} from '../parties/orchestration-services/update-case-request-orchestration.service';
import { FilesUploadedFromAdditionalFieldEventParams } from './document-form/document-form.component';
import {
  IDocumentFileUploadedEventParams,
  ISupportingFilesUploadedEventParams,
  RemoveDocumentEventParams,
} from './documents-grid-item/documents-grid-item.component';
import {
  DocumentsGridRow,
  RowError,
} from './documents-grid/documents-grid.model';
import {
  AddSupportingDocumentParams,
  FsxAddSupportingDocumentOrchestrationService,
  IAddSupportingDocumentOrchestrationService,
} from './services/add-supporting-document-orchestration.service';
import {
  FsxRemoveDocumentOrchestrationService,
  IRemoveDocumentOrchestrationService,
} from './services/remove-document-orchestration.service';
import {
  FsxReplaceDocumentFileOrchestrationService,
  IReplaceDocumentFileOrchestrationService,
  ReplaceDocumentFileParams,
} from './services/replace-document-file-orchestration.service';
import { TotalSizeService } from './total-size/total-size.service';
import {
  FsxDocumentInfoDataService,
  IDocumentInfoDataService,
} from '../filing-editor/services/document-info-data.service';
import { FsxReferenceResolver } from '../shared/resolvers/list-reference.resolver';

export interface DocumentsVm {
  documentsGridRows: DocumentsGridRow[];
  allowedFileTypeExtensions: string[];
  maxFileSize: number;
}

@Component({
  selector: 'fsx-documents',
  templateUrl: './documents.component.html',
  styleUrls: ['./documents.component.scss'],
})
export class DocumentsComponent implements OnInit {
  @Input() combinedFilingData$!: Observable<CombinedFilingData>;
  @Input() isMaxLeadDocumentsAllowed$!: Observable<boolean>;
  @Input() isMinLeadDocumentsRequired$!: Observable<boolean>;
  @Input() resolver!: FsxReferenceResolver;
  @Output() filesUploadedEvent = new EventEmitter<IUploadedFile[]>();
  @Output() supportingFilesUploadedEvent = new EventEmitter<IUploadedFile[]>();
  @Output() documentsGridRowsUpdatedEvent = new EventEmitter<
    DocumentsGridRow[]
  >();
  @Output() filesLoadedEvent = new EventEmitter<DocumentInfo[]>();
  @Output() filesUploadedFromAdditionalFieldEvent =
    new EventEmitter<FilesUploadedFromAdditionalFieldEventParams>();

  documentsVm$!: Observable<DocumentsVm>;

  filingId$!: Observable<string>;

  private setExpandedRowIndex$$ = new Subject<number | null>();

  maxLeadDocumentsAllowedReached$ =
    this.filingEditorErrorsService
      .maxLeadDocumentsAllowedGuardConditionReached$;

  minLeadDocumentsRequiredReached$ =
    this.filingEditorErrorsService
      .minLeadDocumentsRequiredGuardConditionReached$;

  private documentErrorsCache$$ = new BehaviorSubject<RowError[]>([]);

  private initialDocumentErrors$: Observable<RowError[]> = of([]);

  private accDocumentErrors$: Observable<RowError[]> =
    this.filingEditorErrorsService.profileRuleError$.pipe(
      withLatestFrom(this.documentErrorsCache$$),
      map(
        ([guardConditionReachedParams, rowErrors]: [
          IProfileRuleErrorParams,
          RowError[],
        ]) => {
          const docGuardConditionReachedParams =
            guardConditionReachedParams as IDocumentRuleErrorParams;
          const { message } = docGuardConditionReachedParams;
          const rowIdentifier: string =
            docGuardConditionReachedParams.requestDocument.id!;
          const validationCode =
            FilingEditorEventService.guardConditionToValidationCode(
              docGuardConditionReachedParams.profileRuleError,
            );
          const newRowError = new RowError(rowIdentifier, {
            code: validationCode,
            caption: message,
            severity: Severity.Error,
          });
          const accRowErrors = [...rowErrors, newRowError];
          return accRowErrors;
        },
      ),
    );

  private clearDocumentErrors$$: Subject<RequestDocumentViewModel> =
    new Subject<RequestDocumentViewModel>();
  clearedDocumentErrors$: Observable<RowError[]> =
    this.clearDocumentErrors$$.pipe(
      withLatestFrom(this.documentErrorsCache$$),
      map(
        ([requestDocument, rowErrors]: [
          RequestDocumentViewModel,
          RowError[],
        ]) => {
          const filteredRowErrors: RowError[] = rowErrors.filter((rowError) => {
            return rowError.rowIdentifier !== requestDocument.id;
          });
          return filteredRowErrors;
        },
      ),
    );

  private documentErrors$: Observable<RowError[]> = merge(
    this.initialDocumentErrors$,
    this.accDocumentErrors$,
    this.clearedDocumentErrors$,
  ).pipe(
    tap((rowErrors: RowError[]) => {
      this.documentErrorsCache$$.next(rowErrors);
    }),
  );

  constructor(
    @Inject(FsxRemoveDocumentOrchestrationService)
    private readonly removeDocumentOrchestrationService: IRemoveDocumentOrchestrationService,
    @Inject(FsxUpdateCaseRequestOrchestrationService)
    private readonly updateCaseRequestOrchestrationService: IUpdateCaseRequestOrchestrationService,
    @Inject(FsxAddSupportingDocumentOrchestrationService)
    private readonly addSupportingDocumentOrchestrationService: IAddSupportingDocumentOrchestrationService,
    @Inject(FsxReplaceDocumentFileOrchestrationService)
    private readonly replaceDocumentFileOrchestrationService: IReplaceDocumentFileOrchestrationService,
    @Inject(FsxFilingEditorEventService)
    private readonly filingEditorEventService: IFilingEditorEventService,
    private readonly filingEditorErrorsService: FilingEditorErrorsService,
    private readonly filingProfileHelperService: FilingProfileHelperService,
    @Inject(FsxFilingApiService)
    private readonly filingApiService: IFilingApiService,
    @Inject(FsxDocumentInfoDataService)
    private readonly documentInfoDataService: IDocumentInfoDataService,
    private readonly totalSizeService: TotalSizeService,
  ) {}

  ngOnInit(): void {
    this.filingId$ = this.combinedFilingData$.pipe(
      map((combinedFilingData) => {
        return combinedFilingData.filing.id;
      }),
    );

    const hasNoPendingFileUploads$$ = new Subject<void>();

    const requestDocuments$: Observable<RequestDocumentViewModel[]> =
      this.combinedFilingData$.pipe(
        map((combinedFilingData: CombinedFilingData) => {
          return combinedFilingData.caseRequest.documents || [];
        }),
      );

    const requestDocumentsEmpty$: Observable<RequestDocumentViewModel[]> =
      requestDocuments$.pipe(
        filter((requestDocuments: RequestDocumentViewModel[]) => {
          const hasNoDocuments: boolean = requestDocuments.length === 0;
          return hasNoDocuments;
        }),
      );

    const stopPollingStreams$ = merge(
      requestDocumentsEmpty$,
      this.filingEditorEventService.filingSubTabChangedNotDocuments$,
      hasNoPendingFileUploads$$,
    );

    const pollForFilingId$: Observable<string> = merge(
      this.filingEditorEventService.filingSubTabChangedToDocuments$,
      this.filingEditorEventService.fileUploadStarted$,
    ).pipe(
      withLatestFrom(this.filingId$),
      filter(([params, filingId]: [IFilingEditorEventParams, string]) => {
        return params.filingId === filingId;
      }),
      map(([params]) => {
        return params.filingId;
      }),
    );

    const pollDocumentInfos$: Observable<DocumentInfo[]> =
      pollForFilingId$.pipe(
        switchMap((filingId: string) => {
          return interval(5000).pipe(
            takeUntil(stopPollingStreams$),
            switchMap(() => {
              return this.filingApiService.getDocumentInfos(filingId).pipe(
                tap((docInfos: DocumentInfo[]) => {
                  this.documentInfoDataService.setDocumentInfos(docInfos);
                }),
              );
            }),
          );
        }),
      );

    const loadDocumentInfos$: Observable<DocumentInfo[]> =
      this.filingEditorEventService.fileUploadStarted$.pipe(
        switchMap(
          (fileUploadStartedEventParams: IFileUploadStartedEventParams) => {
            return this.filingApiService.getDocumentInfos(
              fileUploadStartedEventParams.filingId,
            );
          },
        ),
      );

    const documentInfos$: Observable<DocumentInfo[]> = merge(
      pollDocumentInfos$,
      loadDocumentInfos$,
      this.documentInfoDataService.documentInfos$,
    ).pipe(
      tap((documentInfos: DocumentInfo[]) => {
        const renderings: DocumentRenderingInfo[] = documentInfos.flatMap(
          (docInfo: DocumentInfo) => {
            return docInfo?.renderings ? docInfo.renderings : [];
          },
        );

        const convertedPdfRenderings: DocumentRenderingInfo[] =
          renderings.filter((rendering: DocumentRenderingInfo) => {
            return rendering.name === 'converted-pdf';
          });

        this.totalSizeService.calculate(convertedPdfRenderings);

        const pendingFileUploads: DocumentRenderingInfo[] =
          convertedPdfRenderings.filter(
            (docRenderingInfo: DocumentRenderingInfo) => {
              return docRenderingInfo.status === RenderingStatus.Pending;
            },
          );

        if (pendingFileUploads.length === 0) {
          hasNoPendingFileUploads$$.next();
        }

        this.filesLoadedEvent.emit(documentInfos);
      }),
    );

    const defaultExpandedRowIndex$ = of(null);

    const newRequestDocumentIndex$: Observable<number> =
      this.filingEditorEventService.requestDocumentCreated$.pipe(
        map((requestDocumentCreatedParams: IRequestDocumentCreatedParams) => {
          return requestDocumentCreatedParams.documentIndex;
        }),
      );

    const expandedRowIndex$: Observable<number | null> = merge(
      newRequestDocumentIndex$,
      defaultExpandedRowIndex$,
      this.setExpandedRowIndex$$,
    );

    const documentsGridRows$: Observable<DocumentsGridRow[]> = combineLatest([
      requestDocuments$,
      documentInfos$,
      expandedRowIndex$,
      this.documentErrors$,
    ]).pipe(
      map(
        ([requestDocuments, documentInfos, expandedRowIndex, rowErrors]: [
          RequestDocumentViewModel[],
          DocumentInfo[],
          number | null,
          RowError[],
        ]) => {
          const documentsGridRows: DocumentsGridRow[] = requestDocuments.map(
            (reqDoc: RequestDocumentViewModel, index: number) => {
              const documentInfo = documentInfos.find(
                (docInfo: DocumentInfo) => docInfo.id === reqDoc.id,
              ) as DocumentInfo;
              const expanded = expandedRowIndex === index;
              const filteredRowErrors: RowError[] = rowErrors.filter(
                (rowError) => {
                  return rowError.rowIdentifier === reqDoc.id;
                },
              );

              // TODO - validate documentInfos

              return new DocumentsGridRow(
                index,
                reqDoc,
                documentInfo,
                expanded,
                filteredRowErrors,
              );
            },
          );
          return documentsGridRows;
        },
      ),
      tap((documentsGridRows: DocumentsGridRow[]) => {
        this.documentsGridRowsUpdatedEvent.emit(documentsGridRows);
      }),
    );

    // TODO - validate files
    // combineLatest(documentsGridRows$, this.combinedFilingData$).subscribe(([gridRows, combinedFilingData]) => {
    //   combinedFilingData.validationService.validateDocumentFiles(gridRows[0].requestDocument)
    // });

    const allowedFileTypeExtensions$: Observable<string[]> =
      this.combinedFilingData$.pipe(
        map((combinedFilingData: CombinedFilingData) => {
          const allowedFileTypeExtensions: string[] =
            this.filingProfileHelperService.getFileTypeExtensionsForAllowedContentTypes(
              combinedFilingData,
            );
          return allowedFileTypeExtensions;
        }),
      );

    const maxFileSize$: Observable<number> = this.combinedFilingData$.pipe(
      map((combinedFilingData: CombinedFilingData) => {
        const isLeadDocument = true;
        const maxFileSize: number =
          this.filingProfileHelperService.getFirstDocumentSpecMaxFileSize(
            combinedFilingData,
            isLeadDocument,
          );
        return maxFileSize;
      }),
    );

    this.documentsVm$ = combineLatest([
      documentsGridRows$,
      allowedFileTypeExtensions$,
      maxFileSize$,
    ]).pipe(
      map(
        ([documentsGridRows, allowedFileTypeExtensions, maxFileSize]: [
          DocumentsGridRow[],
          string[],
          number,
        ]) => {
          const documentsVm: DocumentsVm = {
            documentsGridRows,
            allowedFileTypeExtensions,
            maxFileSize,
          };
          return documentsVm;
        },
      ),
    );
  }

  onFilesUploaded(uploadedFiles: IUploadedFile[]): void {
    this.filesUploadedEvent.emit(uploadedFiles);
  }

  removeDocumentEventHandler(params: RemoveDocumentEventParams) {
    this.combinedFilingData$
      .pipe(
        take(1),
        tap((combinedFilingData: CombinedFilingData) => {
          this.clearDocumentErrors$$.next(params.requestDocument);
          this.removeDocumentOrchestrationService.removeDocument({
            filingId: combinedFilingData.filing.id,
            caseRequest: combinedFilingData.caseRequest,
            filingProfile: combinedFilingData.filingProfile,
            documentsToRemove: [{ ...params }],
          });
        }),
      )
      .subscribe();
  }

  cancelUploadEventHandler(requestDocument: RequestDocumentViewModel) {
    this.filingEditorEventService.dispatchCancelUploadEvent(requestDocument);
  }

  toggleExpandRowEventHandler(documentsGridRow: DocumentsGridRow) {
    const expandedRowIndex: number | null = !documentsGridRow.expanded
      ? documentsGridRow.rowIndex
      : null;

    this.setExpandedRowIndex$$.next(expandedRowIndex);
  }

  reorderDocumentsEventHandler(documentsGridRows: DocumentsGridRow[]) {
    this.combinedFilingData$
      .pipe(
        take(1),
        tap((combinedFilingData: CombinedFilingData) => {
          const reorderedRequestDocuments: RequestDocumentViewModel[] =
            documentsGridRows.map((dgr: DocumentsGridRow, index: number) => {
              if (index === 0) {
                dgr.requestDocument.isLeadDocument = true;
              }
              return dgr.requestDocument;
            });

          const partialCaseRequest: Partial<CaseRequestViewModel> = {
            documents: reorderedRequestDocuments,
          };

          const params: IUpdateCaseRequestParams = {
            filingId: combinedFilingData.filing.id,
            caseRequest: combinedFilingData.caseRequest,
            partialCaseRequest,
          };

          this.updateCaseRequestOrchestrationService.updateCaseRequest(params);
        }),
      )
      .subscribe();
  }

  supportingFilesUploadedEventHandler(
    params: ISupportingFilesUploadedEventParams,
  ): void {
    this.combinedFilingData$
      .pipe(
        take(1),
        tap((combinedFilingData: CombinedFilingData) => {
          const addSupportingDocumentParams: AddSupportingDocumentParams = {
            filingId: combinedFilingData.filing.id,
            caseRequest: combinedFilingData.caseRequest,
            filingProfile: combinedFilingData.filingProfile,
            requestDocument: params.requestDocument,
            uploadedFiles: params.uploadedFiles,
            leadDocumentIndex: params.rowIndex,
            combinedFilingData,
          };

          this.addSupportingDocumentOrchestrationService.addSupportingDocument(
            addSupportingDocumentParams,
          );
        }),
      )
      .subscribe();
  }

  documentFileUploadedEventHandler(params: IDocumentFileUploadedEventParams) {
    this.combinedFilingData$
      .pipe(
        take(1),
        tap((combinedFilingData: CombinedFilingData) => {
          this.clearDocumentErrors$$.next(params.requestDocument);
          const replaceDocumentFileParams: ReplaceDocumentFileParams = {
            filingId: combinedFilingData.filing.id,
            caseRequest: combinedFilingData.caseRequest,
            filingProfile: combinedFilingData.filingProfile,
            requestDocument: params.requestDocument,
            uploadedFile: params.uploadedFile,
            combinedFilingData,
          };

          this.replaceDocumentFileOrchestrationService.replaceDocumentFile(
            replaceDocumentFileParams,
          );
        }),
      )
      .subscribe();
  }

  public filesUploadedFromAdditionalFieldEventHandler(
    params: FilesUploadedFromAdditionalFieldEventParams,
  ) {
    this.filesUploadedFromAdditionalFieldEvent.emit(params);
  }

  onFileDropped(uploadedFiles: IUploadedFile[]) {
    this.filesUploadedEvent.emit(uploadedFiles);
  }
}
