import { Inject, Injectable, InjectionToken } from '@angular/core';
import {
  IValidatable,
  ContactFieldDefinition,
  ContactType,
  EmailAddress,
  EmailAddressSpec,
  RequestContact,
  FilingProfile,
} from '../../../types';
import {
  FsxAddressValidationService,
  IAddressValidationService,
} from './address-validation.service';
import {
  FsxIdentificationValidationService,
  IIdentificationValidationService,
} from './identification-validation.service';
import {
  FsxOrganizationValidationService,
  IOrganizationValidationService,
} from './organization-validation.service';
import {
  FsxPersonValidationService,
  IPersonValidationService,
} from './person-validation.service';
import {
  FsxPhoneValidationService,
  IPhoneValidationService,
} from './phone-validation.service';
import {
  FsxTextFieldValidationService,
  ITextFieldValidationService,
} from './text-field-validation.service';
import {
  FsxValidationHelperService,
  IValidationHelperService,
} from './validation-helper.service';

export const FsxContactValidationService =
  new InjectionToken<IContactValidationService>('FsxContactValidationService');

export interface IContactValidationService {
  validateContacts(
    contacts: RequestContact[] | null | undefined,
    spec: ContactFieldDefinition | null | undefined,
    scope: IValidatable,
    filingProfile: FilingProfile,
  ): boolean;

  validateContact(
    contact: RequestContact,
    spec: ContactFieldDefinition,
    scope: IValidatable,
    filingProfile: FilingProfile,
  ): boolean;
}

@Injectable()
export class ContactValidationService implements IContactValidationService {
  constructor(
    @Inject(FsxTextFieldValidationService)
    private readonly textFieldValidationService: ITextFieldValidationService,
    @Inject(FsxValidationHelperService)
    private readonly validationHelperService: IValidationHelperService,
    @Inject(FsxOrganizationValidationService)
    private readonly organizationValidationService: IOrganizationValidationService,
    @Inject(FsxPhoneValidationService)
    private readonly phoneValidationService: IPhoneValidationService,
    @Inject(FsxIdentificationValidationService)
    private readonly identificationValidationService: IIdentificationValidationService,
    @Inject(FsxAddressValidationService)
    private readonly addressValidationService: IAddressValidationService,
    @Inject(FsxPersonValidationService)
    private readonly personValidationService: IPersonValidationService,
  ) {}

  public validateContacts(
    contacts: RequestContact[] | null | undefined,
    spec: ContactFieldDefinition | null | undefined,
    scope: IValidatable,
    filingProfile: FilingProfile,
  ): boolean {
    if (!spec) {
      return true;
    }

    if (!contacts) {
      contacts = [];
    }

    if (contacts.length < spec.minRequired) {
      return this.validationHelperService.markItemAsInvalid(scope);
    }

    if (contacts.length > spec.maxAllowed) {
      return this.validationHelperService.markItemAsInvalid(scope);
    }

    for (const contact of contacts) {
      if (!this.validateContact(contact, spec, scope, filingProfile)) {
        return this.validationHelperService.markItemAsInvalid(scope);
      }
    }

    return true;
  }

  public validateContact(
    contact: RequestContact,
    spec: ContactFieldDefinition,
    scope: IValidatable,
    filingProfile: FilingProfile,
  ): boolean {
    if (!spec) {
      return true;
    }

    if (
      !spec.allowOrganization &&
      contact.contactType === ContactType.Organization
    ) {
      return this.validationHelperService.markItemAsInvalid(scope);
    }

    if (!spec.allowPerson && contact.contactType === ContactType.Person) {
      return this.validationHelperService.markItemAsInvalid(scope);
    }

    if (
      spec.allowOrganization &&
      contact.contactType === ContactType.Organization
    ) {
      this.organizationValidationService.validateOrganization(
        contact.organization,
        spec.organization,
        scope,
        filingProfile,
      );

      if (contact.person) {
        // contact should be empty for an organization
        return this.validationHelperService.markItemAsInvalid(scope);
      }
    }

    if (spec.allowPerson && contact.contactType === ContactType.Person) {
      this.personValidationService.validatePerson(
        contact.person,
        spec.person,
        scope,
        filingProfile,
      );

      if (contact.organization) {
        // organization should be empty for a person
        return this.validationHelperService.markItemAsInvalid(scope);
      }
    }

    if (
      !this.phoneValidationService.validatePhones(
        contact.phones,
        spec.phone,
        scope,
        filingProfile,
      )
    ) {
      return this.validationHelperService.markItemAsInvalid(scope);
    }

    if (
      !this.addressValidationService.validateAddresses(
        contact.addresses,
        spec.address,
        scope,
        filingProfile,
      )
    ) {
      return this.validationHelperService.markItemAsInvalid(scope);
    }

    if (
      !this.validateContactEmails(
        contact.emails,
        spec.email,
        scope,
        filingProfile,
      )
    ) {
      return this.validationHelperService.markItemAsInvalid(scope);
    }

    if (
      !this.identificationValidationService.validateIdentifications(
        contact.identifications,
        spec.identification,
        scope,
        filingProfile,
      )
    ) {
      return this.validationHelperService.markItemAsInvalid(scope);
    }

    return true;
  }

  private validateContactEmails(
    emails: EmailAddress[],
    spec: EmailAddressSpec | null | undefined,
    scope: IValidatable,
    filingProfile: FilingProfile,
  ): boolean {
    if (!spec) {
      return true;
    }

    if (emails.length < spec.minRequired) {
      return this.validationHelperService.markItemAsInvalid(scope);
    }

    if (emails.length > spec.maxAllowed) {
      return this.validationHelperService.markItemAsInvalid(scope);
    }

    for (const email of emails) {
      if (!this.validateContactEmail(email, spec, scope, filingProfile)) {
        return this.validationHelperService.markItemAsInvalid(scope);
      }
    }

    return true;
  }

  private validateContactEmail(
    email: EmailAddress,
    spec: EmailAddressSpec,
    scope: IValidatable,
    filingProfile: FilingProfile,
  ): boolean {
    if (!spec) {
      return true;
    }

    // TODO - should this validate the category? The backend service doesn't.

    if (
      this.textFieldValidationService.validateTextField(
        scope,
        filingProfile,
        spec.address,
        email.address,
      )
    ) {
      return this.validationHelperService.markItemAsInvalid(scope);
    }

    return true;
  }
}
