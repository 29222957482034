import { Inject, Injectable, InjectionToken } from '@angular/core';
import {
  SearchResultItem,
  SearchFieldDefinition,
  IValidatable,
} from '../../../types';
import {
  FsxValidationHelperService,
  IValidationHelperService,
} from './validation-helper.service';

export const FsxSearchFieldValidationService =
  new InjectionToken<ISearchFieldValidationService>(
    'FsxSearchFieldValidationService',
  );

export interface ISearchFieldValidationService {
  validateSearchField(
    selection: SearchResultItem | null | undefined,
    spec: SearchFieldDefinition | null | undefined,
    scope: IValidatable,
  ): boolean;
}

@Injectable()
export class SearchFieldValidationService
  implements ISearchFieldValidationService
{
  constructor(
    @Inject(FsxValidationHelperService)
    private readonly validationHelperService: IValidationHelperService,
  ) {}

  public validateSearchField(
    selection: SearchResultItem | null | undefined,
    spec: SearchFieldDefinition | null | undefined,
    scope: IValidatable,
  ): boolean {
    if (!spec) {
      return true;
    }

    if (!selection) {
      if (spec.required) {
        return this.validationHelperService.markItemAsInvalid(scope);
      }
      return true;
    }

    if (!selection.efmKey) {
      return this.validationHelperService.markItemAsInvalid(scope);
    }

    return true;
  }
}
