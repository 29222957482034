import {
  Component,
  EventEmitter,
  Inject,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import {
  AuthService,
  ContactQueryResultItemGridResult,
  Direction,
  Filing,
  FILING_SUB_TABS,
  FilingSubTabItem,
  FsxContactApiService,
  IContactApiService,
  Operator,
  Query,
} from '@fsx/fsx-shared';
import {
  SelectionFieldType,
  DropdownOption,
  ErrorPosition,
} from '@fsx/ui-components';
import {
  Observable,
  combineLatest,
  map,
  take,
  tap,
  switchMap,
  EMPTY,
  of,
} from 'rxjs';
import { PollingState } from '../filing-editor.component';
import {
  FsxFilingSubTabsService,
  IFilingSubTabsService,
} from '../filing-sub-tabs-container/filing-sub-tabs.service';
import {
  FsxCombinedFilingDataService,
  ICombinedFilingDataService,
} from '../services/combined-filing-data.service';
import {
  FsxCaseRequestDataService,
  ICaseRequestDataService,
} from '../services/case-request-data.service';
import {
  FsxValidationIndicatorService,
  IValidationIndicatorService,
} from '../services/validation-indicator.service';

@Component({
  selector: 'fsx-filing-footer',
  templateUrl: './filing-footer.component.html',
  styleUrls: ['./filing-footer.component.scss'],
})
export class FilingFooterComponent implements OnInit {
  @Input() pollingState$: Observable<PollingState> = of(PollingState.None);

  @Output() submitFilingEvent: EventEmitter<void> = new EventEmitter<void>();
  @Output() authorizerSelectedEvent = new EventEmitter<string>();
  @Output() filingEditorTabChangeEvent = new EventEmitter<FILING_SUB_TABS>();
  @Output() participantsListNotEmptyEmitter = new EventEmitter<boolean>();

  public formattedDate!: string;
  public formattedTime!: string;
  public filingSubTabs = FILING_SUB_TABS;
  public selectionType = SelectionFieldType;
  public participantsList: DropdownOption<void>[] = [];

  public isEmpty = true;

  activeSubTabItem$: Observable<FilingSubTabItem> =
    this.filingSubTabsService.activeSubTabItem$;

  vm$ = combineLatest([
    this.conbinedFilingDataService.filing$,
    this.filingSubTabsService.activeSubTabItem$,
    this.validationIndicatorService.isValidCrossTab$,
    this.pollingState$,
  ]).pipe(
    map(([filing, activeSubTabItem, isValidCrossTab, pollingState]) => {
      const authorizerSet = !!filing.authorizer;
      const pollingCheck = pollingState === PollingState.InProgress;
      const isDisabledSubmitButton: boolean =
        !authorizerSet || pollingCheck || !isValidCrossTab;

      return {
        filing,
        activeSubTabItem,
        isDisabledSubmitButton,
        pollingState,
      };
    }),
  );

  constructor(
    @Inject(FsxValidationIndicatorService)
    private readonly validationIndicatorService: IValidationIndicatorService,
    @Inject(FsxContactApiService)
    private readonly contactApiService: IContactApiService,
    @Inject(FsxCombinedFilingDataService)
    private readonly conbinedFilingDataService: ICombinedFilingDataService,
    @Inject(FsxFilingSubTabsService)
    private readonly filingSubTabsService: IFilingSubTabsService,
    @Inject(FsxCaseRequestDataService)
    private readonly caseRequestDataService: ICaseRequestDataService,
    private readonly authService: AuthService,
  ) {}

  ngOnInit() {
    let dateOptions: Intl.DateTimeFormatOptions;
    let timeOptions: Intl.DateTimeFormatOptions;

    this.conbinedFilingDataService.filing$
      ?.pipe(
        take(1),
        tap((filing: Filing) => {
          if (isValidTimeZone(filing.courtSummary.timeZone)) {
            timeOptions = {
              hour: 'numeric',
              minute: 'numeric',
              timeZone: filing.courtSummary.timeZone,
              timeZoneName: 'shortOffset',
            };
            this.formattedTime = new Intl.DateTimeFormat(
              'en-US',
              timeOptions,
            ).format(new Date());
          } else {
            this.formattedTime = 'Court time not available';
          }
          dateOptions = {
            day: 'numeric',
            month: 'numeric',
            year: 'numeric',
          };
          this.formattedDate = new Intl.DateTimeFormat(
            'en-US',
            dateOptions,
          ).format(new Date());
        }),
      )
      .subscribe();

    // Get session user and then get the contacts for the user's organization.
    this.authService.sessionUser
      .pipe(
        take(1),
        switchMap((user) => {
          if (!!user) {
            const userOrgId = user?.organization?.primaryContact?.id;
            if (!!userOrgId) {
              // Setup query config to get contacts for the user's organization.
              const queryConfig: Query = {
                skip: 0,
                limit: 250,
                filters: [
                  {
                    column: 'IdentificationCommonCategory',
                    operator: Operator.Equal,
                    value1: 'BarNumber',
                  },
                  {
                    column: 'ParentOrgId',
                    operator: Operator.Equal,
                    value1: userOrgId,
                  },
                ],
                sort: [
                  {
                    column: 'caption',
                    direction: Direction.Ascending,
                  },
                ],
                options: [
                  {
                    name: 'IncludeIdentifications',
                  },
                ],
                exactTotal: false,
              };
              // Return the contacts for the user's organization.
              return this.contactApiService.getContacts(queryConfig).pipe(
                tap((contacts: ContactQueryResultItemGridResult) => {
                  this.participantsList =
                    contacts.data.map((contact) => {
                      const option: DropdownOption<void> = {
                        caption: contact.contactSummary.caption,
                        name:
                          contact.contactSummary.id ??
                          contact.contactSummary.clientNameText,
                        selected: false,
                      };
                      return option;
                    }) ?? [];
                  this.isEmpty = this.participantsList.length === 0;
                  this.participantsListNotEmptyEmitter.emit(this.isEmpty);
                }),
              );
            }
          }
          return EMPTY;
        }),
      )
      .subscribe();
  }

  onSubmitNowClicked(): void {
    this.submitFilingEvent.emit();
  }

  setParticipantSelected($event: string): void {
    this.authorizerSelectedEvent.emit($event);
  }

  onBackButtonClicked(): void {
    this.filingSubTabsService.activatePrevious();
  }

  onNextButtonClicked(): void {
    this.filingSubTabsService.activateNext();
  }

  protected readonly PollingState = PollingState;
  protected readonly FILING_SUB_TABS = FILING_SUB_TABS;
  protected readonly ErrorPosition = ErrorPosition;
}

function isValidTimeZone(tz: string) {
  try {
    Intl.DateTimeFormat(undefined, { timeZone: tz });
    return true;
  } catch (ex) {
    return false;
  }
}
