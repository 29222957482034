<div fxLayout="column" class="participant-table-container">
  <div
    fxLayout="column"
    fxLayoutAlign="space-around start"
    fxFill
    *ngIf="participant?.contactType"
  >
    <div fxLayout="row" fxFill>
      <fsx-row-validation
        [isValid]="participant.isValid"
        [cssClass]="'tile'"
      ></fsx-row-validation>

      <div id="column-one" fxLayout="column" fxFlex="no-grow">
        <p
          id="participant-caption"
          fxLayout="column"
          fxLayoutAlign="start start"
        >
          <ng-container *ngIf="!isReadonly; else readOnly">
            <span (click)="onParticipantCaptionClicked(participant)">{{
              participant.caption
            }}</span>
          </ng-container>
          <ng-template #readOnly>
            <span class="readonly">{{ participant.caption }}</span>
          </ng-template>

          <label class="readonly-label">{{ participant.contactType }}</label>
        </p>
        <div *ngFor="let participantAddress of participant.addresses">
          <fsx-text-component
            #addressField
            *ngIf="participantSpec && filingProfile"
            [caption]="participantAddress.category?.caption ?? ''"
            [helpText]="''"
            [hint]="''"
            [width]="'375px'"
            [fieldDefinition]="participantAddressFieldDefinition"
            [initialValue]="
              participantAddress
                | addressCaption: participantSpec : filingProfile
            "
            (formControlEmitter)="setParticipantAddressesFormControl($event)"
          ></fsx-text-component>
        </div>
      </div>

      <div fxLayout="column" fxFlex>
        <fsx-text-component
          #clientNumberField
          *ngIf="participantClientNumberInitialValue"
          [caption]="'Client No.'"
          [helpText]="''"
          [hint]="''"
          [width]="'190px'"
          [fieldDefinition]="participantClientNumberFieldDefinition"
          [initialValue]="participantClientNumberInitialValue"
          (formControlEmitter)="setParticipantClientNumberFormControl($event)"
        ></fsx-text-component>

        <div *ngFor="let participantPhone of participant.phones">
          <fsx-text-component
            #phoneField
            [caption]="participantPhone.category?.caption ?? ''"
            [helpText]="''"
            [hint]="''"
            [width]="'190px'"
            [fieldDefinition]="participantPhonesFieldDefinition"
            [initialValue]="participantPhone.fullNumber"
            (formControlEmitter)="setParticipantPhoneNumberFormControl($event)"
          ></fsx-text-component>
        </div>

        <div *ngFor="let participantEmails of participant.emails">
          <fsx-text-component
            #emailField
            [caption]="participantEmails.category?.caption ?? ''"
            [helpText]="''"
            [hint]="''"
            [width]="'190px'"
            [fieldDefinition]="participantPhonesFieldDefinition"
            [initialValue]="participantEmails.address"
            [overflowWrap]="true"
            (formControlEmitter)="setParticipantEmailsFormControl($event)"
          ></fsx-text-component>
        </div>
      </div>

      <div
        *ngIf="!isReadonly && showIcons"
        fxLayout="row"
        fxLayoutAlign="end start"
      >
        <mat-icon
          *ngIf="!isAssociatedParty"
          class="participant-action-icon edit-participant-icon"
          style="cursor: pointer"
          (click)="onEditParticipantIconClicked(participant)"
        ></mat-icon>
        <mat-icon
          class="participant-action-icon clear-participant-icon"
          (click)="onClearParticipantClicked()"
          style="cursor: pointer"
        ></mat-icon>
      </div>
    </div>

    <div *ngFor="let participantAlias of participantAliasInfo">
      <fsx-text-component
        #aliasField
        [caption]="participantAlias.caption"
        [helpText]="''"
        [hint]="''"
        [width]="'100%'"
        [fieldDefinition]="participantAliasFieldDefinition"
        [initialValue]="participantAlias.value"
      ></fsx-text-component>
    </div>
  </div>

  <!-- <fsx-additional-fields-component #additionalFields
    *ngFor="let addlFieldSpec of additionalFieldSpec;let index = index"
    [combinedFilingData]="combinedFilingData"
    [additionalFieldSpec]="addlFieldSpec"
    [resolver]="resolver"
    [initialValues]="resolver.getAdditionalFieldInitialValue(addlFieldSpec, additionalFieldValues)"
    [additionalFieldValues]="additionalFieldValues"
    [fileUploadDocumentInfos]="fileUploadDocumentInfos"
    (filesUploadedFromAdditionalFieldEvent)="filesUploadedFromAdditionalFieldEventHandler($event)"
    (additionalFieldValueEvent)="additionalFieldValueEventHandler($event)"
    (fileRemovedFromAdditionalFieldEvent)="fileRemovedFromAdditionalFieldEventHandler($event)"
  ></fsx-additional-fields-component> -->
</div>
