import { Inject, Injectable, InjectionToken } from '@angular/core';
import {
  CaseRequestViewModel,
  DocumentInfo,
  FileFieldDefinition,
  RenderingStatus,
  RequestDocumentViewModel,
} from '../../../types';
import {
  FsxValidationHelperService,
  IValidationHelperService,
} from './validation-helper.service';

export const FsxDocumentFilesValidationService =
  new InjectionToken<IDocumentFilesValidationService>(
    'FsxDocumentFilesValidationService',
  );

export interface IDocumentFilesValidationService {
  setDocumentFiles(documentFiles: DocumentInfo[] | undefined): void;

  validateDocumentFiles(
    fileValues: string[] | null | undefined,
    spec: FileFieldDefinition | null | undefined,
    scope: CaseRequestViewModel,
    document: RequestDocumentViewModel | null | undefined,
  ): boolean;
}

@Injectable()
export class DocumentFilesValidationService
  implements IDocumentFilesValidationService
{
  private documentFiles: DocumentInfo[] | undefined;

  constructor(
    @Inject(FsxValidationHelperService)
    private readonly validationHelperService: IValidationHelperService,
  ) {}

  public setDocumentFiles(documentFiles: DocumentInfo[] | undefined): void {
    this.documentFiles = documentFiles;
  }

  public validateDocumentFiles(
    fileValues: string[] | null | undefined,
    spec: FileFieldDefinition | null | undefined,
    scope: CaseRequestViewModel,
    document: RequestDocumentViewModel | null | undefined,
  ): boolean {
    if (!spec) {
      return true;
    }

    if (!fileValues) {
      fileValues = [];
    }

    if (fileValues.length < spec.minRequired) {
      return this.validationHelperService.markItemAsInvalid(scope);
    }

    if (fileValues.length > spec.maxAllowed) {
      return this.validationHelperService.markItemAsInvalid(scope);
    }

    for (const fileId of fileValues) {
      if (!this.documentFiles) {
        // TODO - this may need to change if additional field files are moved outside the scope of documentInfos
        return this.validationHelperService.markItemAsInvalid(scope);
      }
      const matchingDocInfos = this.documentFiles.filter(
        (f) => f.id === fileId,
      );

      if (matchingDocInfos.length > 1) {
        // file is associated with multiple documents
        return this.validationHelperService.markItemAsInvalid(scope);
      }

      const fileDocumentInfo = matchingDocInfos[0];

      if (!fileDocumentInfo) {
        // file not found in the document collection
        return this.validationHelperService.markItemAsInvalid(scope);
      }

      // https://dev.azure.com/fileandserve/FileAndServeXpress/_workitems/edit/151684
      // backend may or may not validate this in the future, but for now this is a false positive.
      // if (fileDocumentInfo.originalFileName.length > spec.fileNameMaxLength) {
      //     return this.validationHelperService.markItemAsInvalid(scope);
      // }

      if (
        fileDocumentInfo.pendingChecks.length > 0 ||
        fileDocumentInfo.failedChecks.length > 0
      ) {
        return this.validationHelperService.markItemAsInvalid(scope);
      }

      if (
        !fileDocumentInfo.renderings ||
        fileDocumentInfo.renderings?.length === 0
      ) {
        return this.validationHelperService.markItemAsInvalid(scope);
      }

      if (document) {
        document.isFileSizeTooLarge = false;
      }
      for (const rendering of fileDocumentInfo.renderings) {
        if (rendering.status !== RenderingStatus.Ready) {
          return this.validationHelperService.markItemAsInvalid(scope);
        }

        if (rendering.size > spec.fileSizeMaxBytes) {
          if (document) {
            document.isFileSizeTooLarge = true;
          }

          return this.validationHelperService.markItemAsInvalid(scope);
        }
      }
    }

    return true;
  }
}
