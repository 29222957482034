import { Inject, Injectable, InjectionToken } from '@angular/core';
import {
  CaseRequestViewModel,
  RequestParticipantViewModel,
  ICaseRequestUpdateService,
  FsxCaseRequestUpdateService,
  CasePartyViewModel,
  ICaseRequestBuilderService,
  FsxCaseRequestBuilderService,
  CaseParty,
  RequestParticipantRepresentationViewModel,
} from '@fsx/fsx-shared';
import { BehaviorSubject, Observable, Subject, switchMap, tap } from 'rxjs';
import {
  FsxValidatePartiesOrchestrationService,
  IValidatePartiesOrchestrationService,
} from '../../filing-editor/services/orchestration/validate-parties-orchestration.service';

/**
 * The InjectionToken to use in the providers array to specify a concrete-implementation
 * of the IEditParticipantOrchestrationService to use at runtime.
 */
export const FsxEditParticipantOrchestrationService =
  new InjectionToken<IEditParticipantOrchestrationService>(
    'FsxEditParticipantOrchestrationService',
  );

/**
 * The parameters needed to run the Edit Participant Orchestration.
 */
export interface IEditParticipantParams {
  /**
   * The filingId of the filing being edited.
   */
  filingId: string;

  /**
   * The caseRequest object on which the participant being edited resides.
   */
  caseRequest: CaseRequestViewModel;

  /**
   * The edited RequestParticipant object.
   */
  participant: RequestParticipantViewModel;
}

/**
 * A blueprint for an orchestration service, which handles the editing of a RequestParticipant
 * object on a CaseRequest object for a given Filing. Triggered when the "Save" button is clicked
 * on the ParticpantFormPanelComponent whilst in EditParticipant mode.
 */
export interface IEditParticipantOrchestrationService {
  /**
   * The pipeline of orchestration steps needed to apply RequestParticipant object edits on
   * a CaseRequest object.
   */
  editParticipantInCaseRequest$: Observable<CaseRequestViewModel>;

  /**
   * A boolean value indicatimg whether the orchestration is in progress,
   * exposed as an Observable.
   */
  isOrchestrationInProgress$: Observable<boolean>;

  /**
   * A public method to allow the orchestration to be triggered.
   */
  editParticipant(params: IEditParticipantParams): void;
}

/**
 * A concrete implementation of an orchestration service, which handles the editing of a
 * RequestParticipant object on a CaseRequest object for a given Filing. Triggered when the
 * "Save" button is clicked on the ParticpantFormPanelComponent (in both Add and Edit modes).
 */
@Injectable()
export class EditParticipantOrchestrationService
  implements IEditParticipantOrchestrationService
{
  /**
   * A subject to use as the trigger for the orchestration.
   */
  private editParticipant$$ = new Subject<IEditParticipantParams>();

  /**
   * A boolean value indicatimg whether the orchestration is in progress,
   * stored in a BehaviorSubject.
   */
  private isOrchestrationInProgress$$ = new BehaviorSubject<boolean>(false);

  /**
   * A boolean value indicatimg whether the orchestration is in progress,
   * exposed as an Observable.
   */
  isOrchestrationInProgress$: Observable<boolean> =
    this.isOrchestrationInProgress$$.asObservable();

  /**
   * The pipeline of orchestration steps needed to apply RequestParticipant object edits on
   * a CaseRequest object.
   */
  editParticipantInCaseRequest$: Observable<CaseRequestViewModel> =
    this.editParticipant$$.pipe(
      switchMap((params: IEditParticipantParams) => {
        this.isOrchestrationInProgress$$.next(true);
        const caseRequestBackup = {
          ...params.caseRequest,
        } as CaseRequestViewModel;
        const { filingId, caseRequest, participant } = params;

        return this.caseRequestBuilderService
          .setParticipantInCaseRequest({
            caseRequest,
            property: participant,
            participantName: participant.name,
          })
          .pipe(
            switchMap(() => {
              // An array of participantNames to restrict validation to on completion. Initially
              // empty string, but assigned when matching CaseParty or RequestParticipantRepresentation
              // object is found.
              let participantNamesToValidate: string[] = [];

              const caseRequestParties = caseRequest.parties || [];

              // We don't know if the participant is a party or representation, so we
              // use the participantName to find the correct object on the CaseRequest object.

              // Try to find a matching CaseParty object.
              const party: CaseParty | undefined = caseRequestParties.find(
                (p: CasePartyViewModel) => {
                  const found = p.participantName === participant.name;
                  if (found) {
                    // if we've found a party, we will validate against just the party.
                    participantNamesToValidate.push(p.participantName);
                  }
                  return found;
                },
              );

              // Apply the updates to the CaseParty object.
              // (this works because we are working with a refernce from the CaseRequest object)
              if (party) {
                party.caption = participant.caption;
                party.caseId = caseRequest.cases![0].caseId;
              }

              // Try to find a matching RequestParticipantRepresentation object.
              const representation:
                | RequestParticipantRepresentationViewModel
                | undefined = caseRequestParties.reduce(
                (
                  acc: RequestParticipantRepresentationViewModel | undefined,
                  cur: CasePartyViewModel,
                ) => {
                  const partyRepresentation = cur.representation || [];
                  if (!acc) {
                    // Try to find a matching RequestParticipantRepresentation object.
                    const rep:
                      | RequestParticipantRepresentationViewModel
                      | undefined = partyRepresentation.find(
                      (r: RequestParticipantRepresentationViewModel) => {
                        return r.participantName === participant.name;
                      },
                    );

                    if (rep) {
                      // if we've found a representation, we will validate both the party and the representation.
                      participantNamesToValidate = [
                        cur.participantName,
                        rep.participantName,
                      ];
                      return rep;
                    }
                  }

                  return acc;
                },
                undefined,
              );

              // Apply the updates to the RequestParticipantRepresentation object.
              // (this works because we are working with a refernce from the CaseRequest object)
              if (representation) {
                representation.caption = participant.caption;
              }

              // Make PUT request to apply the CaseRequest updates on the server.
              return this.caseRequestUpdateService
                .optimisticPutOrRestore(
                  filingId,
                  caseRequest,
                  caseRequestBackup,
                )
                .pipe(
                  tap(() => {
                    const inclusions = [...participantNamesToValidate];
                    this.validatePartiesOrchestrationService.validateParties({
                      includedParticipantNames: inclusions,
                    });
                    this.isOrchestrationInProgress$$.next(false);
                  }),
                );
            }),
          );
      }),
    );

  constructor(
    @Inject(FsxCaseRequestBuilderService)
    private readonly caseRequestBuilderService: ICaseRequestBuilderService,
    @Inject(FsxCaseRequestUpdateService)
    private readonly caseRequestUpdateService: ICaseRequestUpdateService,
    @Inject(FsxValidatePartiesOrchestrationService)
    private readonly validatePartiesOrchestrationService: IValidatePartiesOrchestrationService,
  ) {}

  /**
   * A public method to allow the orchestration to be triggered.
   */
  editParticipant(params: IEditParticipantParams): void {
    this.editParticipant$$.next(params);
  }
}
