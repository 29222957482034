import {
  Component,
  EventEmitter,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import {
  FsxContactsListTwoService,
  IContactsListTwoService,
} from '../contacts-list-two.service';
import { Subject, debounceTime, takeUntil, tap } from 'rxjs';
import {
  AttorneySearchTypeEnum,
  ContactsSearchTypeEnum,
} from '../../contacts.model';

@Component({
  selector: 'fsx-contacts-list-actions',
  templateUrl: './contacts-list-actions.component.html',
  styleUrls: ['./contacts-list-actions.component.scss'],
})
export class ContactsListActionsComponent implements OnInit, OnDestroy {
  /**
   * The search type, used here to toggle the "My Firm" or "Other" buttons
   * in por out of view. Should only display them when search type is Attorney.
   */
  @Input() searchType!: ContactsSearchTypeEnum;

  /**
   * An output event to trigger the opening of the new contact form from the parent
   * panel component. We do this in the parent container component rather than here
   * to absolve this component of any unnecessary dependencies.
   */
  @Output() addNewContactEvent = new EventEmitter<void>();

  /**
   * An output event to trigger the refresh of the list.
   */
  @Output() refreshListEvent = new EventEmitter<void>();
  /**
   * A subject to use to help tear down the searchTerm.valueChanges subscription
   * when the component is destroyed.
   */
  private destroy$: Subject<void> = new Subject();

  /**
   * The search type enum to use in the template. Used to show/hide the attorney
   * search type buttons.
   */
  searchTypeEnum = ContactsSearchTypeEnum;

  /**
   * The form control that we assign to the search term textbox and listen to changes on.
   */
  searchTerm = new FormControl<string>('', { nonNullable: true });

  /**
   * The selected attorney search type. For use in the form to set/remove
   * the 'active' class on the buttons.
   */
  attorneySearchType = AttorneySearchTypeEnum.Firm;

  /**
   * The enum to use in the template to help set/remove the 'active'
   * class on the buttons.
   */
  attorneySearchTypeEnum = AttorneySearchTypeEnum;

  constructor(
    @Inject(FsxContactsListTwoService)
    public readonly contactsListTwoService: IContactsListTwoService,
  ) {}

  ngOnInit(): void {
    // Listen for changes to the search term field value and attempt to set
    // (or clear) the filter when a new searchTerm value is emitted.
    this.searchTerm.valueChanges
      .pipe(
        debounceTime(500),
        tap((searchTerm: string) => {
          this.contactsListTwoService.setSearchTerm(searchTerm);
        }),
        takeUntil(this.destroy$),
      )
      .subscribe();
  }

  /**
   * Lifecycle hook to help tear down the searchTerm.valueChanges subscription.
   */
  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  /**
   * A handler function for the Add New button's click event. Here we use it to
   * open the contact form in add mode.
   */
  addNewContact(): void {
    this.addNewContactEvent.emit();
  }

  /**
   * A handler function for the attorney search type button click events.
   *
   * @param attorneySearchType The selected attorney search type
   */
  setAttorneySearchType(attorneySearchType: AttorneySearchTypeEnum) {
    this.contactsListTwoService.setAttorneySearchType(attorneySearchType);
    this.attorneySearchType = attorneySearchType;
  }

  public jumpToTableHeader(event: Event): void {
    event.preventDefault();
  }

  /**
   * A handler fucntion for the refresh icon's click event.
   */
  refreshIconClicked(): void {
    this.refreshListEvent.emit();
  }
}
