import { Inject, Injectable, InjectionToken, Injector } from '@angular/core';
import { Observable, Subject, map, of, switchMap } from 'rxjs';
import { FsxPanelService, IPanelService } from './panel.service';
import { ContactsSearchTypeEnum } from '../../contacts/contacts.model';
import {
  FsxCombinedFilingDataService,
  ICombinedFilingDataService,
} from '../../filing-editor/services/combined-filing-data.service';
import {
  ContactListItemViewModel,
  ParticipantCommonCategory,
  ParticipantSpec,
} from '@fsx/fsx-shared';

/**
 * The InjectionToken to use in the providers array to specify a concrete-implementation
 * of the IOpenContactsListOrchestrationService to use at runtime.
 */
export const FsxOpenContactsListOrchestrationService =
  new InjectionToken<IOpenContactsListOrchestrationService>(
    'FsxOpenContactsListOrchestrationService',
  );

/**
 * The parameters to pass to the orchestration service so
 * that it can do its work.
 */
export interface OpenContactsListOrchestrationParams {
  /**
   *
   */
  searchType: ContactsSearchTypeEnum;

  /**
   * The ParticipantCommonCategory to lookup the ParticipantSpec objects to
   * pass into the nested PartiicpantSpecSelectionComponent.
   */
  commonCategory?: ParticipantCommonCategory;

  /**
   * The callback function to run when user clicks the add button. This is
   * specified by the calling component so that it can be handled in different
   * contexts.
   *
   * @param contactSummaries The selected contact summaries.
   */
  addCallback?: (contactSummaries: ContactListItemViewModel[]) => void;
}

/**
 * A blueprint for an orchestration service, which handles the opening of the contacts list.
 */
export interface IOpenContactsListOrchestrationService {
  /**
   * The orchestration steps needed to open the contacts list.
   */
  openContactsListOrchestration$: Observable<void>;

  /**
   * A method to allow orchestration to be triggered from components.
   *
   * @param params The params object to run the orchestration.
   */
  openContactsList(params: OpenContactsListOrchestrationParams): void;
}

/**
 * A concrete implementation of an orchestration service, which handles the opening of
 * the contacts list.
 */
@Injectable()
export class OpenContactsListOrchestrationService
  implements IOpenContactsListOrchestrationService
{
  /**
   * A subject to use as the trigger for the orchestration.
   */
  private openContactsListAction$ =
    new Subject<OpenContactsListOrchestrationParams>();

  /**
   * The orchestration steps needed to open the contacts list.
   */
  openContactsListOrchestration$: Observable<void> =
    this.openContactsListAction$.pipe(
      switchMap((params: OpenContactsListOrchestrationParams) => {
        // Conditionally lookup the participant specs if we have a commonCategory to look them up with.
        const getParticipantSpecs$ = params.commonCategory
          ? this.combinedFilingDataService.getParticipantSpecsForCommonCategory(
              params.commonCategory,
            )
          : of([]); // We just use an empty array if there's no commonCategory.

        // Subscribe to the conditional observable to return an array of ParticipantSpec objects.
        return getParticipantSpecs$.pipe(
          map((participantSpecs: ParticipantSpec[]) => {
            // Open the contact list panel, passing in the ParticipantSpec objects
            this.panelService.openContactsListPanel({
              contactsListConfig: {
                searchType: params.searchType,
                participantSpecs: participantSpecs, // Needed to set the "Party Type" / "Attorney Type" dropdown options in the Contacts List.
                addCallback: params.addCallback,
              },
              injector: this.injector,
            });

            return;
          }),
        );
      }),
    );

  /**
   *
   * @param panelService The utility service for opening panels.
   * @param injector The injector to pass on to the mat dialog, used to ensure the dialog uses the same providers.
   */
  public constructor(
    @Inject(FsxPanelService) private readonly panelService: IPanelService,
    @Inject(FsxCombinedFilingDataService)
    private readonly combinedFilingDataService: ICombinedFilingDataService,
    private readonly injector: Injector,
  ) {}

  /**
   * A method to allow orchestration to be triggered from components.
   *
   * @param params The params object to run the orchestration.
   */
  openContactsList(params: OpenContactsListOrchestrationParams): void {
    this.openContactsListAction$.next(params);
  }
}
