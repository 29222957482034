import { Injectable, InjectionToken } from '@angular/core';
import {
  FSXFormControlService,
  FieldDefinition,
  FormControlWithModel,
} from '@fsx/ui-components';
import {
  AdditionalFieldSpec,
  AdditionalFieldValue,
  CurrencyValue,
  FieldCategory,
  SearchResultItem,
} from '@fsx/fsx-shared';
import { FsxReferenceResolver } from '../../shared/resolvers/list-reference.resolver';

/**
 * The InjectionToken to use in the providers array to specify a concrete-implementation
 * of the IAdditionalFieldsFormService to use at runtime.
 */
export const FsxAdditionalFieldsFormService =
  new InjectionToken<IAdditionalFieldsFormService>(
    'FsxAdditionalFieldsFormService',
  );

/**
 * A type to combine the AdditonalFieldSpec and AdditionalFieldValue into a single object.
 */
export interface AdditionalFieldSpecAndValue {
  /**
   * A single AdditionalFieldSpec object to derive a ui control for.
   */
  additionalFieldSpec: AdditionalFieldSpec;

  /**
   * A single AdditionalFieldValue object to set the initial ui control value from.
   */
  additionalFieldValue?: AdditionalFieldValue;
}

/**
 * A type to combine the AdditonalFieldSpec and FormControl into a single object.
 */
export interface AdditionalFieldSpecValueAndFormControl {
  /**
   * A single AdditionalFieldSpec object to derive a ui control for.
   */
  additionalFieldSpec: AdditionalFieldSpec;

  /**
   * The AdditionalFieldValue object related to the AdditionalFieldSpec
   * - optional because there isn't always going to be an existing AdditionalFieldValue on first load.
   */
  additionalFieldValue?: AdditionalFieldValue;

  /**
   * A single FormControl object to pass into the AdditionalFieldControls component.
   * - optional because the formControl isn't always created upfront (but it should be)
   */
  formControl?: FormControlWithModel<FieldDefinition>;
}

export interface IAdditionalFieldsFormService {
  /**
   * A public helper function for deriving the collection of AdditonalFieldSpecAndFormControl objects.
   *
   * @param specs The collection of AdditionalFieldSpec objects to derive FormControls for.
   *
   * @param values The collection of AdditionalFieldValue objects to set the values of the derived FormControls.
   *
   * @param resolver Used by FSXFormControlService, try to replace if possible.
   */
  deriveArrayOfSpecAndFormControl(
    specs: AdditionalFieldSpec[],
    values: AdditionalFieldValue[],
    resolver: FsxReferenceResolver,
  ): AdditionalFieldSpecValueAndFormControl[];
}

@Injectable()
export class AdditionalFieldsFormService
  implements IAdditionalFieldsFormService
{
  constructor(private readonly fsxFormControlService: FSXFormControlService) {}

  /**
   * A public helper function for deriving the collection of AdditonalFieldSpecAndFormControl objects.
   *
   * @param specs The collection of AdditionalFieldSpec objects to derive FormControls for.
   *
   * @param values The collection of AdditionalFieldValue objects to set the values of the derived FormControls.
   *
   * @param resolver Used by FSXFormControlService, try to replace if possible.
   */
  deriveArrayOfSpecAndFormControl(
    specs: AdditionalFieldSpec[],
    values: AdditionalFieldValue[],
    resolver: FsxReferenceResolver,
  ): AdditionalFieldSpecValueAndFormControl[] {
    // First we pair the AdditionalFieldSpec with the matching AdditionalFieldValue.
    const arrayOfSpecAndValue: AdditionalFieldSpecAndValue[] =
      this.deriveArrayOfSpecAndValue(specs, values);

    // Then we iterate over that collection and pair the AdditionalFieldSpec with newly generated FormControl.
    return arrayOfSpecAndValue.map(
      (specAndValue: AdditionalFieldSpecAndValue) => {
        const { additionalFieldSpec, additionalFieldValue } = specAndValue;

        let formControl: FormControlWithModel<FieldDefinition> | undefined =
          undefined;
        if (specAndValue.additionalFieldSpec.textFieldDefinition) {
          formControl = this.fsxFormControlService.createFormControl(
            additionalFieldSpec.textFieldDefinition!,
            FieldCategory.Text,
            additionalFieldValue?.textValue || '',
            resolver,
          );
        }
        if (specAndValue.additionalFieldSpec.selectionFieldDefinition) {
          const initialValue: string[] =
            additionalFieldValue?.selectionValue || [''];
          formControl = this.fsxFormControlService.createFormControl(
            additionalFieldSpec.textFieldDefinition!,
            FieldCategory.Selection,
            initialValue,
            resolver,
          );
        }
        if (specAndValue.additionalFieldSpec.searchFieldDefinition) {
          const initialValue: SearchResultItem | null =
            additionalFieldValue?.searchResultItem || null;
          formControl = this.fsxFormControlService.createFormControl(
            additionalFieldSpec.textFieldDefinition!,
            FieldCategory.Selection,
            initialValue,
            resolver,
          );
        }
        if (specAndValue.additionalFieldSpec.dateFieldDefinition) {
          const initialValue: string | null =
            additionalFieldValue?.dateValue || null;
          formControl = this.fsxFormControlService.createFormControl(
            additionalFieldSpec.textFieldDefinition!,
            FieldCategory.Selection,
            initialValue,
            resolver,
          );
        }
        if (specAndValue.additionalFieldSpec.numberFieldDefinition) {
          const initialValue: string | null =
            additionalFieldValue?.numberValue || null;
          formControl = this.fsxFormControlService.createFormControl(
            additionalFieldSpec.textFieldDefinition!,
            FieldCategory.Selection,
            initialValue,
            resolver,
          );
        }
        if (specAndValue.additionalFieldSpec.booleanFieldDefinition) {
          formControl = this.fsxFormControlService.createFormControl(
            additionalFieldSpec.booleanFieldDefinition!,
            FieldCategory.Boolean,
            additionalFieldValue?.booleanValue || false,
            resolver,
          );
        }
        if (specAndValue.additionalFieldSpec.currencyFieldDefinition) {
          const defaultCurrencyValue: CurrencyValue = {
            currencyKey: '$',
            amount:
              specAndValue.additionalFieldSpec.currencyFieldDefinition
                .defaultValue || 0,
          };
          const initialValue: CurrencyValue | null =
            additionalFieldValue?.currencyValue || defaultCurrencyValue;
          formControl = this.fsxFormControlService.createFormControl(
            additionalFieldSpec.currencyFieldDefinition!,
            FieldCategory.Currency,
            initialValue,
            resolver,
          );
        }

        console.warn(
          `formControl not created for "${specAndValue.additionalFieldSpec.caption}" field of type ${specAndValue.additionalFieldSpec.fieldType}`,
        );

        // Combine the AdditionalFieldSpec and FormControl into a single object.
        const specAndFormControl: AdditionalFieldSpecValueAndFormControl = {
          additionalFieldSpec,
          additionalFieldValue,
          formControl,
        };
        return specAndFormControl;
      },
    );
  }

  /**
   * A private helper function for deriving the collection of AdditonalFieldSpecAndValue objects.
   */
  private deriveArrayOfSpecAndValue(
    specs: AdditionalFieldSpec[],
    values: AdditionalFieldValue[],
  ): AdditionalFieldSpecAndValue[] {
    return specs.map((additionalFieldSpec: AdditionalFieldSpec) => {
      // Try to find and existing additional field value first (we want to use that if it exists)
      const additionalFieldValue: AdditionalFieldValue | undefined =
        values.find((addlFieldvalue: AdditionalFieldValue) => {
          return (
            addlFieldvalue.additionalFieldName === additionalFieldSpec.name
          );
        });

      // Combine the AdditionalFieldSpec and the AdditionalFieldValue into single object.
      const specAndValue: AdditionalFieldSpecAndValue = {
        additionalFieldSpec,
        additionalFieldValue,
      };
      return specAndValue;
    });
  }
}
