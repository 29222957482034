import { ValidatorFn, AbstractControl, ValidationErrors } from '@angular/forms';

export function invalidOptionValidator(options: string[]): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    if (!control.value) {
      return null;
    }

    const inputValue = String(control.value).replace(/,/g, '');
    const isValidOption: boolean = options.includes(inputValue);
    if (isValidOption) {
      return null;
    }

    return {
      invalidOption: `Invalid option selected`,
    };
  };
}
