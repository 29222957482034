import { Inject, Injectable, InjectionToken } from '@angular/core';
import { FormGroup } from '@angular/forms';
import {
  ContactProfile,
  FilingProfile,
  Identification,
  IdentificationCategory,
  IdentificationCommonCategory,
  IdentificationSpec,
  IdentificationViewModel,
  RequestParticipant,
} from '@fsx/fsx-shared';
import { NewContact } from '@fsx/fsx-shared';
import {
  FormArrayWithModel,
  IdentificationFormGroup,
} from '@fsx/ui-components';
import {
  FsxContactProfileDataService,
  IContactProfileDataService,
} from 'projects/apps/fsx-ui/src/app/filing-editor/services/contact-profile-data.service';
import { take, tap } from 'rxjs';

/**
 * The InjectionToken to use in the providers array to specify a concrete-implementation
 * of the IIdentificationsFormService to use at runtime.
 */
export const FsxIdentificationsFormService =
  new InjectionToken<IIdentificationsFormService>(
    'FsxIdentificationsFormService',
  );

export interface IIdentificationsFormService {
  setIdentificationsCategories(
    contact: NewContact | RequestParticipant,
    barIdentifications:
      | FormArrayWithModel<FormGroup<IdentificationFormGroup>>
      | undefined,
    otherIdentifications:
      | FormArrayWithModel<FormGroup<IdentificationFormGroup>>
      | undefined,
  ): void;

  getIdentificationsFromFilingProfile(
    contactIdentifications: IdentificationViewModel[] | undefined,
    identificationSpec: IdentificationSpec | undefined,
    filingProfile: FilingProfile | null,
  ): IdentificationViewModel[];
}

@Injectable()
export class IdentificationsFormService implements IIdentificationsFormService {
  constructor(
    @Inject(FsxContactProfileDataService)
    private readonly contactProfileDataService: IContactProfileDataService,
  ) {}

  public setIdentificationsCategories(
    contact: NewContact | RequestParticipant,
    barIdentifications:
      | FormArrayWithModel<FormGroup<IdentificationFormGroup>>
      | undefined,
    otherIdentifications:
      | FormArrayWithModel<FormGroup<IdentificationFormGroup>>
      | undefined,
  ): void {
    this.contactProfileDataService.contactProfile$
      .pipe(
        take(1),
        tap((contactProfile: ContactProfile) => {
          if (!contact.identifications) {
            contact.identifications = [];
          }
          if (barIdentifications?.controls) {
            barIdentifications.controls.forEach((identification) => {
              if (
                identification.controls.regionKey.value &&
                identification.controls.identificationKey.value
              ) {
                let newIdentification = identification.value as Identification;

                const category = contactProfile.identificationCategories.find(
                  (category: IdentificationCategory) => {
                    return (category.commonCategory =
                      IdentificationCommonCategory.BarNumber);
                  },
                )!;

                newIdentification.category = category;

                contact.identifications.push(newIdentification);
              }
            });
          }
          if (otherIdentifications?.controls) {
            otherIdentifications.controls.forEach((identification) => {
              if (
                identification.controls.regionKey.value &&
                identification.controls.identificationKey.value
              ) {
                let newId = identification.value as Identification;
                const identificationCategory =
                  identification.controls.category?.value;
                const dropdownOptions =
                  identification.controls.category?.dropdownOptions;
                const selectedOption = dropdownOptions?.find(
                  (option) => option.name === identificationCategory,
                );

                if (newId.category && selectedOption) {
                  newId.category = {
                    name: selectedOption.name,
                    caption: selectedOption.caption,
                    commonCategory: selectedOption.category
                      ?.commonCategory as IdentificationCommonCategory,
                  };
                }
                contact.identifications.push(newId);
              }
            });
          }
        }),
      )
      .subscribe();
  }

  public getIdentificationsFromFilingProfile(
    contactIdentifications: IdentificationViewModel[] | undefined,
    identificationSpec: IdentificationSpec | undefined,
    filingProfile: FilingProfile | null,
  ): IdentificationViewModel[] {
    const participantIdentifications: IdentificationViewModel[] = [];
    const identificationsList = identificationSpec?.category?.listReference;
    let selectedCategory: IdentificationCategory | undefined;

    contactIdentifications?.forEach((id) => {
      if (identificationsList?.list === 'AdditionalList') {
        const list = filingProfile?.additionalLists?.find(
          (list) => list.name === identificationsList.additionalListName,
        );
        const listElement = list?.items.find(
          (item) => item.name === id.category?.name,
        );
        if (listElement && id.category) {
          selectedCategory = id.category;
        }
      } else {
        selectedCategory = filingProfile?.identificationCategories?.find(
          (item) => item.commonCategory === id.category?.commonCategory,
        );
      }

      const newIdentification: IdentificationViewModel = {
        ...id,
        category: selectedCategory,
      } as IdentificationViewModel;

      if (
        newIdentification.category &&
        identificationSpec &&
        participantIdentifications.length <= identificationSpec.maxAllowed
      ) {
        participantIdentifications.push(newIdentification);
      }
    });

    return participantIdentifications;
  }
}
