import { Injectable } from '@angular/core';
import {
  ContactViewModel,
  ParticipantCategory,
  RequestParticipantRepresentationViewModel,
} from '@fsx/fsx-shared';
import { Observable, of } from 'rxjs';

export interface ICreateRepresentationFromContactParams {
  /**
   * The unique identifier to use as the new RequestParticipantRepresentation object's participantname.
   */
  uniqueIdentifier: string;

  /**
   * The Contact object from which to create the new RequestParticipantRepresentation object.
   */
  contact: ContactViewModel;

  /**
   * The ParticipantCategory to assign to the new RequestParticipantRepresentation object.
   */
  participantCategory: ParticipantCategory;
}

@Injectable({
  providedIn: 'root',
})
export class CreateRepresentationService {
  /**
   * A helper method to create a RequestParticipantRepresentation object from a Contact object.
   *
   * @param params The parameters needed to create a RequestParticipantRepresentation object from a Contact object
   *
   * @returns A RequestParticipantRepresentation object.
   */
  createRepresentationFromContact(
    params: ICreateRepresentationFromContactParams,
  ): Observable<RequestParticipantRepresentationViewModel> {
    const representation: RequestParticipantRepresentationViewModel = {
      caption: params.contact.caption,
      participantName: params.uniqueIdentifier,
      efmKey: null,
      participantCategory: params.participantCategory,
      additionalFieldValues: null,
      parentOrganizationCaption: params.contact.parentOrganization?.caption,
    } as RequestParticipantRepresentationViewModel;

    return of(representation);
  }
}
