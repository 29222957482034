<div
  class="fsx-side-panel-header-component"
  [ngClass]="headerConfig.headerBackground"
  [ngStyle]="{
    justifyContent: headerConfig.titlePosition,
  }"
>
  <span
    *ngIf="headerConfig.titleIcon"
    class="material-icons title-icon"
    [ngClass]="headerConfig.titleIconColor"
    >{{ headerConfig.titleIcon }}</span
  >

  <img
    *ngIf="headerConfig.titleIconPath"
    [src]="headerConfig.titleIconPath"
    alt="header icon"
  />

  <h1 [ngClass]="headerConfig.titleColor">
    {{ headerConfig.title }}
  </h1>

  <h1 class="subtitle">
    {{ headerConfig.subtitle }}
  </h1>
</div>

<div class="exit-container">
  <button
    *ngIf="headerConfig.exitIcon && headerConfig.exitAction"
    class="icon-button"
    (keydown.enter)="headerConfig.exitAction(); preventEvent($event)"
  >
    <span
      class="material-icons exit"
      [ngClass]="headerConfig.titleIconColor"
      (click)="headerConfig.exitAction()"
    >
      highlight_off
    </span>
  </button>
</div>
