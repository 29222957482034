import { Inject, Injectable, InjectionToken } from '@angular/core';
import {
  FilingProfile,
  FsxAddressFormService,
  FsxAliasFormService,
  FsxEmailFormService,
  FsxIdentificationsFormService,
  FsxPersonFormService,
  FsxPhoneFormService,
  IAddressFormService,
  IAliasFormService,
  IEmailFormService,
  IIdentificationsFormService,
  IPersonFormService,
  IPhoneFormService,
  ParticipantSpec,
} from '@fsx/fsx-shared';
import {
  ContactViewModel,
  ContactType,
  RequestParticipantViewModel,
} from '@fsx/fsx-shared';
import { Observable, of } from 'rxjs';

/**
 * The InjectionToken to use in the providers array to specify a concrete-implementation
 * of the IPartiesFormService to use at runtime.
 */
export const FsxPartiesFormService = new InjectionToken<IPartiesFormService>(
  'FsxPartiesFormService',
);

export interface IPartiesFormService {
  transformContactToParticipant(
    contact: ContactViewModel,
    participantSpec: ParticipantSpec,
    filingProfile: FilingProfile,
  ): Observable<RequestParticipantViewModel | undefined>;
}

@Injectable()
export class PartiesFormService implements IPartiesFormService {
  constructor(
    @Inject(FsxPersonFormService)
    private readonly personFormService: IPersonFormService,
    @Inject(FsxAliasFormService)
    private readonly aliasFormService: IAliasFormService,
    @Inject(FsxPhoneFormService)
    private readonly phoneFormService: IPhoneFormService,
    @Inject(FsxEmailFormService)
    private readonly emailFormService: IEmailFormService,
    @Inject(FsxAddressFormService)
    private readonly addressFormService: IAddressFormService,
    @Inject(FsxIdentificationsFormService)
    private readonly identificationsFormService: IIdentificationsFormService,
  ) {}

  public transformContactToParticipant(
    contact: ContactViewModel,
    participantSpec: ParticipantSpec,
    filingProfile: FilingProfile,
  ): Observable<RequestParticipantViewModel | undefined> {
    let participant: RequestParticipantViewModel | undefined;

    if (participantSpec && filingProfile) {
      participant = {
        caption: contact.caption,
        linkedContact: {
          id: contact.id,
          caption: contact.caption,
          contactType: this.getContactType(contact.type ?? ContactType.Unknown),
          clientNameText: contact.effectiveClientNameText,
        },
        name: contact.id,
        contactType: this.getContactType(contact.type ?? ContactType.Person),
        person:
          contact.type === ContactType.Person
            ? this.personFormService.getPersonNameFromFilingProfile(
                contact.person,
                participantSpec,
              )
            : null,
        organization:
          contact.type === ContactType.Organization
            ? {
                caption: contact.organization?.caption ?? '',
                title: contact.organization?.title ?? '',
              }
            : null,
        aliases: this.aliasFormService.getAliasesFromFilingProfile(
          contact.aliases,
          participantSpec,
          filingProfile,
        ),
        addresses: this.addressFormService.getAddressFromFilingProfile(
          contact.addresses,
          participantSpec.address ?? undefined,
          filingProfile,
        ),
        emails: this.emailFormService.getEmailsFromFilingProfile(
          contact.emails,
          participantSpec.email ?? undefined,
          filingProfile,
        ),
        identifications:
          this.identificationsFormService.getIdentificationsFromFilingProfile(
            contact.identifications,
            participantSpec.identification ?? undefined,
            filingProfile,
          ),
        phones: this.phoneFormService.getPhonesFromFilingProfile(
          contact.phones,
          participantSpec.phone ?? undefined,
          filingProfile,
        ),
      } as unknown as RequestParticipantViewModel;
    }

    return of(participant);
  }

  private getContactType(type: ContactType): ContactType {
    let contactType = type;
    if (type === ContactType.TenantOrganization) {
      contactType = ContactType.Organization;
    } else if (
      type === ContactType.Actor ||
      type === ContactType.User ||
      type === ContactType.Unknown
    ) {
      contactType = ContactType.Person;
    }
    return contactType;
  }
}
