<div class="document-drag-drop-container">
  <input
    [disabled]="disabled"
    type="file"
    class="fileDropRef"
    #fileDropRef2
    [id]="id"
    multiple
    (change)="fileBrowseHandler($event)"
  />

  <div class="no-files-dropzone">
    <div
      class="placeholder"
      fsxUploadDropTarget
      [ngClass]="{
        disabled: disabled,
      }"
      (fileDropped)="onFileDropped($event)"
    >
      <div class="placeholder-icon">
        <span class="documents-icon"></span>
      </div>

      <div class="caption-container">
        <div class="caption-l">Drop Documents Here</div>

        <div class="caption-sm">
          <span>Or</span
          ><label [for]="id" class="caption-sm-highlight"
            >Browse a Document</label
          >
        </div>
      </div>

      <div class="file-specs">
        <div class="file-specs-text">
          {{ permittedFileTypesStr | uppercase }} | Max file size
          {{ maxFileSize | fileSize }}
        </div>
      </div>
    </div>
  </div>
</div>
