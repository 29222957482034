import { Inject, Injectable, InjectionToken } from '@angular/core';
import {
  Observable,
  catchError,
  combineLatest,
  concatMap,
  filter,
  forkJoin,
  from,
  map,
  of,
  switchMap,
  take,
  toArray,
  zip,
} from 'rxjs';
import {
  FsxCombinedFilingDataService,
  ICombinedFilingDataService,
} from '../../filing-editor/services/combined-filing-data.service';
import {
  CombinedFilingData,
  FilingModeSpec,
  ParticipantCommonCategory,
  ParticipantSpec,
  RequestParticipant,
  RequestParticipantRepresentationViewModel,
  RequestParticipantViewModel,
} from '@fsx/fsx-shared';
import { RepresentationAndParticipant } from '../parties.component';
import { RepresentationGridRow } from '@fsx/ui-components';
import { PartiesGridRow } from './parties-grid.model';
import { PartiesGridConfig } from './parties-grid.component';
import {
  FsxAdditionalFieldSpecLookupService,
  IAdditionalFieldSpecLookupService,
} from './additional-field-spec-lookup.service';
import {
  AdditionalFieldNamePlusDocumentCategory,
  AssociatedPartyConstants,
  FsxAdditionalFieldValueLookupService,
  IAdditionalFieldValueLookupService,
} from './additional-field-value-lookup.service';
import {
  FsxParticipantDataService,
  IParticipantDataService,
} from '../../filing-editor/services/participant-data.service';
import {
  FsxPartyAndParticipantDataService,
  IPartyAndParticipantDataService,
  PartyAndParticipant,
} from '../../filing-editor/services/party-and-participant-data.service';

/**
 * The InjectionToken to use in the providers array to specify a concrete-implementation
 * of the IPartiesGridConfigService to use at runtime.
 */
export const FsxPartiesGridConfigService =
  new InjectionToken<IPartiesGridConfigService>('FsxPartiesGridConfigService');

/**
 * A blueprint for a ui service, which creates a Parties Grid view model.
 */
export interface IPartiesGridConfigService {
  /**
   * A helper function to derive the PartiesGridConfig view model object.
   *
   * @param participantCommonCategory The ParticipantCommonCategory to
   * derive the grid view model for.
   *
   * @returns A PartiesGridConfig view model object for a given ParticipantCommonCategory
   */
  getGridVm(
    participantCommonCategory?: ParticipantCommonCategory,
  ): Observable<PartiesGridConfig>;
}

/**
 * A concrete-implementation of a ui service, which creates a Parties Grid config object.
 */
@Injectable()
export class PartiesGridConfigService implements IPartiesGridConfigService {
  /**
   * The FilingModeSpec as taken from the CombinedFilingData.
   */
  private modeSpec$ = this.combinedFilingDataService.combinedFilingData$.pipe(
    map((combinedFilingData) => combinedFilingData.modeSpec),
  );

  /**
   * The array of all ParticipantSpec objects as taken from the FilingModeSpec,
   * excluding null and undefined values.
   */
  private participantSpecs$ = this.modeSpec$.pipe(
    map((modeSpec: FilingModeSpec | null | undefined) => {
      return modeSpec?.participant;
    }),
    filter((participantSpecs: ParticipantSpec[] | undefined) => {
      return !!participantSpecs;
    }),
    map((participantSpecs: ParticipantSpec[] | undefined) => {
      return participantSpecs as ParticipantSpec[];
    }),
  );

  /**
   * An array of all the RequestParticipant objects on the CaseRequest object.
   */
  private caseRequestParticipants$: Observable<RequestParticipantViewModel[]> =
    this.combinedFilingDataService.combinedFilingData$.pipe(
      map((combinedFilingData: CombinedFilingData) => {
        const caseRequestParticipants =
          combinedFilingData.caseRequest.participants || [];
        return caseRequestParticipants;
      }),
    );

  /**
   * An array containing all of the RequestParticipant objects from the CaseRequest object
   * and all of the RequestParticipant objects stored in the ParticipantDataService as taken
   * from the getParticipant() call on first load.
   */
  private allParticipants$ = combineLatest([
    this.caseRequestParticipants$,
    this.participantDataService.participants$,
  ]).pipe(
    map(
      ([p1, p2]: [
        RequestParticipantViewModel[],
        RequestParticipantViewModel[],
      ]) => {
        return [...p1, ...p2];
      },
    ),
  );

  /**
   * @param combinedFilingDataService The service containing the combinedFilingData to derive the config object from.
   * @param additionalFieldValueLookupService The service allowing us to lookup the AdditionalFieldValue name that a party was selected for.
   * @param additionalFieldSpecLookupService The service allowing us to lookup the AdditionalFIeldSpec caption that a party was selected for.
   */
  constructor(
    @Inject(FsxCombinedFilingDataService)
    private readonly combinedFilingDataService: ICombinedFilingDataService,
    @Inject(FsxAdditionalFieldValueLookupService)
    private readonly additionalFieldValueLookupService: IAdditionalFieldValueLookupService,
    @Inject(FsxAdditionalFieldSpecLookupService)
    private readonly additionalFieldSpecLookupService: IAdditionalFieldSpecLookupService,
    @Inject(FsxParticipantDataService)
    private readonly participantDataService: IParticipantDataService,
    @Inject(FsxPartyAndParticipantDataService)
    private readonly partyAndParticipantDataService: IPartyAndParticipantDataService,
  ) {}

  /**
   * A helper function to derive the array of related CaseParty and RequestParticpant objects
   * for the specified ParticipantCommonCategory.
   *
   * @param participantCommonCategory The ParticipantCommonCategory to filter the array of
   * related CaseParty and RequestParticipant objects on.
   *
   * @returns An array of related CaseParty and RequestParticipant objects filtered by the
   * given ParticipantCommonCategory.
   */
  private getPartiesWithParticipants(
    participantCommonCategory?: ParticipantCommonCategory,
  ): Observable<PartyAndParticipant[]> {
    return this.partyAndParticipantDataService.partiesWithParticipants$.pipe(
      map((partiesWithParticipants: PartyAndParticipant[]) => {
        // Return all partiesWithParticipants when participantCommonCategory is not provided.
        // Return filtered partiesWithParticipants when participantCommonCategory is provided.
        return !participantCommonCategory
          ? partiesWithParticipants
          : partiesWithParticipants.filter((pObj) => {
              return (
                pObj.party.participantCategory?.commonCategory ===
                participantCommonCategory
              );
            });
      }),
      catchError((err, caught) => {
        console.error('Error getting parties with participants', err);
        return caught;
      }),
    );
  }

  /**
   * A helper function to derive the array of ParticipantSpec objects for a
   * given ParticipantCommonCategory.
   *
   * @param participantCommonCategory The ParticipantCommonCategory to filter
   * the array of ParticipantSpec objects on.
   *
   * @returns An array of ParticipantSpec objects filtered by the given
   * ParticipantCommonCategory.
   */
  private getParticipantSpecs(
    participantCommonCategory?: ParticipantCommonCategory,
  ): Observable<ParticipantSpec[]> {
    return this.participantSpecs$.pipe(
      map((participantSpecs: ParticipantSpec[]) => {
        // Return all participantSpecs when participantCommonCategory is not provided.
        // Return filtered participantSpecs when participantCommonCategory is provided.
        return !participantCommonCategory
          ? participantSpecs
          : participantSpecs.filter((pSpec: ParticipantSpec) => {
              return (
                pSpec.participantCategory.commonCategory ===
                participantCommonCategory
              );
            });
      }),
      catchError((err, caught) => {
        console.error('Error getting partiicpant specs', err);
        return caught;
      }),
    );
  }

  /**
   * A helper function to look up the ParticipantSpec for the selected "Party Type" (participantCategory)
   * for a given participantCategoryName (string)
   *
   * @param participantCategoryName The participantCategoryName string to lookup the ParticipantSpec for.
   *
   * @returns The selected ParticipantSpec object for the given PartyAndParticipant.
   */
  private getParticipantSpec(
    participantCategoryName: string,
  ): Observable<ParticipantSpec> {
    return this.participantSpecs$.pipe(
      take(1), // Ensures that the stream completes with the values that it returns.
      map((participantSpecs: ParticipantSpec[]) => {
        // Lookup the ParticipantSpec
        return participantSpecs.find((pSpec) => {
          return participantCategoryName === pSpec.participantCategory.name;
        })!;
      }),
      catchError((err, caught) => {
        console.error('Error getting participant spec', err);
        return caught;
      }),
    );
  }

  /**
   * A helper function to lookup the AdditionalFieldValue names for a given PartyAndParticipant.
   *
   * @param pObj The PartyAndParticipant object to lookup the AdditionalFieldValue names for.
   *
   * @returns An array of AdditionalFieldNamePlusDocumentCategory objects
   */
  private getAdditionalFieldNames(
    pObj: PartyAndParticipant,
  ): Observable<AdditionalFieldNamePlusDocumentCategory[]> {
    // Get the AdditionalFieldNames for the current emission of PartyAndParticipant.
    return this.additionalFieldValueLookupService
      .getAdditionalFieldNames(pObj.participant.name)
      .pipe(
        take(1), // Ensures that the stream completes with the values that it returns.
        map(
          (additionalFieldNames: AdditionalFieldNamePlusDocumentCategory[]) => {
            return additionalFieldNames;
          },
        ),
        catchError((err, caught) => {
          console.error('Error getting additional field names', err);
          return caught;
        }),
      );
  }

  /**
   * A helper function to lookup the AdditionalFieldSpec captions for a given PartyAndParticipant.
   *
   * @param pObj The PartyAndParticipant object to lookup the AdditionalFieldSpec captions for.
   *
   * @returns An array of AdditionalFieldSpec captions (ui friendly strings)
   */
  private getAdditionalFieldCaptions(
    pObj: PartyAndParticipant,
  ): Observable<string[]> {
    // Get the AdditionalFieldNames for the current emission of PartyAndParticipant.
    return this.getAdditionalFieldNames(pObj).pipe(
      switchMap(
        (additionalFieldNames: AdditionalFieldNamePlusDocumentCategory[]) => {
          // Emit each additionalFieldName one by one so that we can lookup the AdditionalFieldSpec caption one at a time.
          const hasAdditionalFieldNames = additionalFieldNames.length > 0; // ?  additionalFieldNames : [];//['new_filed_by'];

          // Create an observable for determining how to retrieve AdditionalFieldSpec captions.
          // If we have additionalFieldNames to lookup, then use them to look them up.
          // If we DO NOt have additionalFIeldNames to lookup, then return an empty array of captions instead.
          const additionalFieldCaptions$ = !hasAdditionalFieldNames
            ? of([])
            : from(additionalFieldNames).pipe(
                switchMap(
                  (
                    additionalFieldName: AdditionalFieldNamePlusDocumentCategory,
                  ) => {
                    // Use the additionalFieldName to get the AdditionalFieldSpec caption.
                    const getAdditionalFieldSpecCaption$ =
                      this.additionalFieldSpecLookupService
                        .getAdditionalFieldSpecCaption(additionalFieldName)
                        .pipe(
                          map((additionalFieldCaption: string) => {
                            return additionalFieldCaption;
                          }),
                        );

                    // Setup a conditional observable to get the caption for either the Additional Field or the Asscoaited Party.
                    const caption$: Observable<string> =
                      additionalFieldName.additionalFieldName ===
                      AssociatedPartyConstants.filedBy.associatedPartyName
                        ? of(AssociatedPartyConstants.filedBy.caption)
                        : additionalFieldName.additionalFieldName ===
                            AssociatedPartyConstants.asTo.associatedPartyName
                          ? of(AssociatedPartyConstants.asTo.caption)
                          : getAdditionalFieldSpecCaption$;

                    return caption$;
                  },
                ),
                toArray(),
              );

          // Whichever observable we have set, the switchMap subscribes to it and returns the result.
          return additionalFieldCaptions$;
        },
      ),
      catchError((err, caught) => {
        console.error('Error getting additional field captions', err);
        return caught;
      }),
    );
  }

  /**
   * A helper function to derive the array of RepresentationGridRow objects.
   *
   * @returns An array of RepresentationGridRow objects.
   */
  private getRepresentationGridRows(
    pObj: PartyAndParticipant,
  ): Observable<RepresentationGridRow[]> {
    // All of the RequestParticipantRepresentation objects as taken from the CaseParty object.
    const partyRepresentation = pObj.party.representation || [];

    // All of the RequestParticipantRepresentation objects as an observable.
    const representation$ = of(partyRepresentation);

    return zip([representation$, this.allParticipants$]).pipe(
      take(1), // Ensures that the stream completes with the values that it returns.
      map(
        ([partyRepresentation, participants]: [
          RequestParticipantRepresentationViewModel[],
          RequestParticipantViewModel[],
        ]) => {
          // console.log('partyRepresentation', partyRepresentation);
          // console.log('participants', participants);

          // We want to ignore any representation with null participantName property.
          const representationWithParticipantNames = partyRepresentation.filter(
            (rep: RequestParticipantRepresentationViewModel) => {
              const hasParticipantName = rep.participantName !== null;
              return hasParticipantName;
            },
          );

          // Derive an array of RepresentationAndParticipant objects from an array of RequestParticipantRepresentation objects.
          const arrayOfRepresentationAndParticipant: RepresentationAndParticipant[] =
            representationWithParticipantNames.map(
              (representation: RequestParticipantRepresentationViewModel) => {
                // Lookup the RequestParticipant object that maps to the RequestParticipantRepresentation object.
                const participant: RequestParticipantViewModel | undefined =
                  participants.find((participant: RequestParticipant) => {
                    return participant.name === representation.participantName;
                  });

                if (!participant) {
                  throw `No matching RequestParticipant object found for RequestParticipantRepresentation (${representation.participantName}: ${representation.caption})`;
                }

                // Combine the RequestParticipantRepresentation object and the RequestParticipant object into a single type.
                const representationAndParticipant: RepresentationAndParticipant =
                  {
                    representation,
                    participant,
                  };

                // Return the combined RepresentationAndParticipant and RequestParticipant object.
                return representationAndParticipant;
              },
            );

          // Return the array of combined RepresentationAndParticipant and RequestParticipant objects.
          return arrayOfRepresentationAndParticipant;
        },
      ),
      switchMap(
        (allRepresentationWithParticipants: RepresentationAndParticipant[]) => {
          // Filter the array of RepresentationAndParticipant objects for the given PartyAndParticipant object.
          const filteredRepresentationWithParticipants: RepresentationAndParticipant[] =
            allRepresentationWithParticipants.filter((rObj) => {
              const partyRepresentationIds: string[] =
                pObj.party.representation?.map((rep) => rep.participantName) ||
                [];
              return partyRepresentationIds.indexOf(rObj.participant.name) > -1;
            });

          // Emit each RepresentationAndParticipant one by one so that we can work with them individually.
          return from(filteredRepresentationWithParticipants).pipe(
            switchMap((rObj: RepresentationAndParticipant, rIndex: number) => {
              // Lookup nested representation row-level items here...
              // NOTE: Any nested streams must complete.
              return forkJoin([
                this.getParticipantSpec(
                  rObj.representation.participantCategory?.name!,
                ),
                // TODO: Any other representation row-level lookups go here...
              ]).pipe(
                // Return one RepresentationGridRow for each emission of RepresentationAndParticipant.
                map(([participantSpec]: [ParticipantSpec]) => {
                  return new RepresentationGridRow(
                    rIndex,
                    rObj.representation,
                    rObj.participant,
                    participantSpec,
                  );
                }),
              );
            }),
            // Combine each emitted PartiesGridRow back into an array.
            toArray(),
          );
        },
      ),
      catchError((err) => {
        console.error('Error getting representation grid rows', err);
        return of([]);
      }),
    );
  }

  /**
   * A helper function to derive the array of PartiesGridRow objects
   * for a given ParticipantCommonCategory.
   *
   * @param participantCommonCategory The ParticipantCommonCategory to
   * use to derive the rows.
   *
   * @returns An array of PartiesGridRow objects.
   */
  private getPartiesGridRows(
    participantCommonCategory?: ParticipantCommonCategory,
  ): Observable<PartiesGridRow[]> {
    return this.getPartiesWithParticipants(participantCommonCategory).pipe(
      switchMap((partiesWithParticipants: PartyAndParticipant[]) => {
        // Emit each PartyAndParticipant one by one so that we can work with them individually.
        return from(partiesWithParticipants).pipe(
          concatMap((pObj: PartyAndParticipant, pIndex: number) => {
            // Lookup nested parties row-level items here...
            // NOTE: Any nested streams must complete
            return forkJoin([
              this.getAdditionalFieldCaptions(pObj),
              this.getParticipantSpec(pObj.party.participantCategory?.name!),
              this.getRepresentationGridRows(pObj),
              // TODO: Any other parties row-level lookups go here...
            ]).pipe(
              // Return one PartiesGridRow for each emission of PartyAndParticipant.
              map(
                ([
                  additionalFieldCaptions,
                  participantSpec,
                  representationGridRows,
                ]) => {
                  return new PartiesGridRow(
                    pIndex,
                    pObj.party,
                    pObj.participant,
                    participantSpec,
                    representationGridRows,
                    pObj.partyIndex,
                    additionalFieldCaptions,
                  );
                },
              ),
            );
          }),
          // Combine each emitted PartiesGridRow back into an array.
          toArray(),
        );
      }),
      catchError((err, caught) => {
        console.error('Error getting parties grid rows', err);
        return caught;
      }),
    );
  }

  /**
   * A helper function to derive the PartiesGridConfig view model object.
   *
   * @param participantCommonCategory The ParticipantCommonCategory to
   * derive the grid view model for.
   *
   * @returns A PartiesGridConfig view model object for a given ParticipantCommonCategory
   */
  getGridVm(
    participantCommonCategory?: ParticipantCommonCategory,
  ): Observable<PartiesGridConfig> {
    return combineLatest([
      this.getPartiesGridRows(participantCommonCategory),
      this.getParticipantSpecs(participantCommonCategory),
      this.getParticipantSpecs(ParticipantCommonCategory.Attorney),
    ]).pipe(
      map(
        ([partiesGridRows, participantSpecs, attorneySpecs]: [
          PartiesGridRow[],
          ParticipantSpec[],
          ParticipantSpec[],
        ]) => {
          return {
            participantCommonCategory,
            partiesGridRows,
            participantSpecs,
            attorneySpecs,
          };
        },
      ),
      catchError((err, caught) => {
        console.error('Error trying to derive parties grid data', err);
        return caught;
      }),
    );
  }
}
