<div *ngIf="abstractControl; else old">
  <div class="form-title">
    <fsx-row-validation
      [isValid]="abstractControl.valid || !abstractControl.touched"
      [cssClass]="'inline-heading'"
    ></fsx-row-validation>
    {{ labelText }}
  </div>
  <fsx-validation-errors
    [abstractControl]="abstractControl"
  ></fsx-validation-errors>
</div>
<ng-template #old>
  <p class="form-title">
    <fsx-row-validation
      [isValid]="validationErrors.length === 0"
      [cssClass]="'inline-heading'"
    ></fsx-row-validation>
    {{ labelText }}
  </p>
  <ng-container *ngIf="validationErrors.length > 0">
    <div class="validation-error-container">
      <div
        class="validation-error-message"
        *ngFor="let validationError of validationErrors"
      >
        {{ validationError.errorMessage }}
      </div>
    </div>
  </ng-container>
</ng-template>
