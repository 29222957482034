import { NgModule } from '@angular/core';
import { SharedModule } from '@fsx/fsx-shared';
import { FsxUiComponentsModule } from '@fsx/ui-components';
import { DetailsModule } from '../details/details.module';
import { DocumentsModule } from '../documents/documents.module';
import { PartiesModule } from '../parties/parties.module';
import { ReviewModule } from '../review/review.module';
import { TransactionsModule } from '../transactions/transactions.module';
import { ValidationModule } from '../validation/validation.module';
import { FilingContentComponent } from './filing-content/filing-content.component';
import { FilingEditorRoutingModule } from './filing-editor-routing.module';
import { FilingEditorComponent } from './filing-editor.component';
import { FilingFooterComponent } from './filing-footer/filing-footer.component';
import { FilingSubTabItemComponent } from './filing-sub-tab-item/filing-sub-tab-item.component';
import { FilingSubTabsContainerComponent } from './filing-sub-tabs-container/filing-sub-tabs-container.component';
import { FilingSubmissionConfirmationComponent } from './filing-submission-confirmation/filing-submission-confirmation.component';
import { FilingSubmissionProgressComponent } from './filing-submission-progress/filing-submission-progress.component';
import { FilingTabsComponent } from './filing-tabs/filing-tabs.component';
@NgModule({
  declarations: [
    FilingContentComponent,
    FilingFooterComponent,
    FilingSubTabItemComponent,
    FilingSubTabsContainerComponent,
    FilingSubmissionProgressComponent,
    FilingEditorComponent,
    FilingTabsComponent,
    FilingSubmissionConfirmationComponent,
    FilingSubmissionProgressComponent,
  ],
  imports: [
    SharedModule,
    FilingEditorRoutingModule,
    FsxUiComponentsModule,
    DetailsModule,
    PartiesModule,
    DocumentsModule,
    ReviewModule,
    ValidationModule,
    TransactionsModule,
  ],
  providers: [],
})
export class FilingEditorModule {}
