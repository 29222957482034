import { Inject, Injectable, InjectionToken } from '@angular/core';
import {
  Observable,
  Subject,
  filter,
  map,
  switchMap,
  tap,
  withLatestFrom,
} from 'rxjs';
import {
  FsxValidateCaseOrchestrationService,
  IValidateCaseOrchestrationService,
} from './validate-case-orchestration.service';
import {
  FsxValidatePartiesOrchestrationService,
  IValidatePartiesOrchestrationService,
} from './validate-parties-orchestration.service';
import {
  FsxValidateDocumentsOrchestrationService,
  IValidateDocumentsOrchestrationService,
} from './validate-documents-orchestration.service';
import {
  FsxValidateReviewOrchestrationService,
  IValidateReviewOrchestrationService,
} from './validate-review-orchestration.service';
import {
  FsxFilingApiService,
  IFilingApiService,
  ProblemDetails,
} from '@fsx/fsx-shared';
import {
  FsxPollingStateService,
  IPollingStateService,
} from '../polling-state.service';
import { PollingState } from '../../filing-editor.component';
import {
  FsxValidationErrorsService,
  IValidationErrorsService,
  ValidationError,
} from '../validation-errors.service';
import {
  FsxValidationResultMappingService,
  IValidationResultMappingService,
} from '../validation-result-mapping.service';
import {
  FilingFeesResponse,
  FsxFilingFeesGetterService,
  IFilingFeesGetterService,
} from '../filing-fees-getter.service';

/**
 * The InjectionToken to use in the providers array to specify a concrete-implementation
 * of the ISubmitFilingOrchestrationService to use at runtime.
 */
export const FsxSubmitFilingOrchestrationService =
  new InjectionToken<ISubmitFilingOrchestrationService>(
    'FsxSubmitFilingOrchestrationService',
  );

/**
 * A blueprint for an orchestration service, which handles filing submission
 * and validation
 */
export interface ISubmitFilingOrchestrationService {
  /**
   * The orchestration steps needed to valdate and submit a filing
   */
  submitFilingOrchestration$: Observable<void>;

  /**
   * A method to allow orchestration to be triggered from components
   *
   * @param filingId the id of the filing to attempt to submit
   */
  submitFiling(filingId: string): void;
}

/**
 * A concrete implementation of an orchestration service, which handles
 * filing submission and validation
 */
@Injectable()
export class SubmitFilingOrchestrationService
  implements ISubmitFilingOrchestrationService
{
  /**
   * A subject to use as the trigger for the orchestration.
   */
  private submitFilingAction$ = new Subject<string>();

  /**
   * The orchestration steps needed to valdate and submit a filing
   */
  submitFilingOrchestration$: Observable<void> = this.submitFilingAction$.pipe(
    tap(() => {
      this.validateCaseOrchestrationService.validateCase();
      this.validatePartiesOrchestrationService.validateParties({});
      this.validateDocumentsOrchestrationService.validateDocuments({});
      this.validateReviewOrchestrationService.validateReview();
    }),
    switchMap((filingId: string) => {
      // Make the call to get fees ahead of submission.
      return this.filingFeesGetterService.getFilingFees(filingId, false).pipe(
        withLatestFrom(this.validationErrorsService.validationErrors$),
        filter(
          ([_filingFeesResponse, validationErrors]: [
            FilingFeesResponse,
            ValidationError[],
          ]) => {
            // Only continue to attempt to submit if there are no validation errors.
            return validationErrors.length === 0;
          },
        ),
        switchMap(
          ([_filingFeesResponse, _validationErrors]: [
            FilingFeesResponse,
            ValidationError[],
          ]) => {
            // Continue to attempt to submit...
            this.pollingStateService.setPollingState(PollingState.InProgress);
            return this.filingApiService.submitFiling(filingId).pipe(
              map((result: ProblemDetails) => {
                const errors = result.validationResult?.errors ?? 0;
                const serverValidationErrors: ValidationError[] =
                  this.validationResultMappingService.map(
                    result.validationResult!,
                  );
                serverValidationErrors.forEach(
                  (validationError: ValidationError) => {
                    this.validationErrorsService.addValidationError(
                      validationError,
                    );
                  },
                );

                // Possible Responses: 200, 400, 403, 404, 501, 503 (500 unintentional)
                if (result.status === 200 || (!result.status && errors === 0)) {
                  this.pollingStateService.setPollingState(
                    PollingState.Succeeded,
                  );
                  return;
                } else if (
                  errors > 0 ||
                  result.status === 500 ||
                  result.status === 501 ||
                  result.status === 503
                ) {
                  this.pollingStateService.setPollingState(
                    PollingState.UserError,
                  );
                  return;
                } else if (
                  result.status === 400 ||
                  result.status === 403 ||
                  result.status === 404 ||
                  result.status === 405
                ) {
                  this.pollingStateService.setPollingState(
                    PollingState.ServerError,
                  );
                  return;
                } else {
                  this.pollingStateService.setPollingState(PollingState.None);
                  return;
                }
              }),
            );
          },
        ),
      );
    }),
  );

  /**
   *
   * @param filingApiService The api service which holds the submit endpoint
   * @param filingTabsService The ui state service which stores the open filing tabs
   * @param pollingStateService The state service which holds the in-flight submission status
   * @param validatePartiesOrchestrationService The validation service for validating parties and participants
   * @param validateDocumentsOrchestrationService The validation service for validating documents
   * @param validateCaseOrchestrationService The validation service for validating case properties
   * @param validateReviewOrchestrationService The validation service for validating review properties
   */

  /**
   *
   * @param filingFeesGetterService The worker service for handling the retrieval of fees.
   * @param filingApiService The api service which holds the submit endpoint.
   * @param pollingStateService he state service which holds the in-flight submission status.
   * @param validatePartiesOrchestrationService The validation service for validating parties and participants.
   * @param validateDocumentsOrchestrationService The validation service for validating documents.
   * @param validateCaseOrchestrationService The validation service for validating case properties.
   * @param validateReviewOrchestrationService The validation service for validation review tab fields and profile rules.
   * @param validationErrorsService The staet service for storing validation errors for display in the checklist.
   * @param validationResultMappingService The mapping service for converting server validation errors into validation errors that can be displayed in the checklist.
   * @param loadFilingFeesOrchestrationService
   */
  public constructor(
    @Inject(FsxFilingFeesGetterService)
    private readonly filingFeesGetterService: IFilingFeesGetterService,
    @Inject(FsxFilingApiService)
    private readonly filingApiService: IFilingApiService,
    @Inject(FsxPollingStateService)
    private readonly pollingStateService: IPollingStateService,
    @Inject(FsxValidatePartiesOrchestrationService)
    private readonly validatePartiesOrchestrationService: IValidatePartiesOrchestrationService,
    @Inject(FsxValidateDocumentsOrchestrationService)
    private readonly validateDocumentsOrchestrationService: IValidateDocumentsOrchestrationService,
    @Inject(FsxValidateCaseOrchestrationService)
    private readonly validateCaseOrchestrationService: IValidateCaseOrchestrationService,
    @Inject(FsxValidationErrorsService)
    private readonly validationErrorsService: IValidationErrorsService,
    @Inject(FsxValidationResultMappingService)
    private readonly validationResultMappingService: IValidationResultMappingService,
    @Inject(FsxValidateReviewOrchestrationService)
    private readonly validateReviewOrchestrationService: IValidateReviewOrchestrationService,
  ) {}

  /**
   * A method to allow orchestration to be triggered from components
   *
   * @param filingId the id of the filing to attempt to submit
   */
  submitFiling(filingId: string): void {
    this.submitFilingAction$.next(filingId);
  }
}
