<div class="parties-container" fxLayout="column" fxFill>
  <div *ngIf="combinedFilingData$ | async as combinedFilingData">
    <div
      *ngIf="
        combinedFilingData.filing.mode === FilingMode.OriginalPetition;
        else subsequent
      "
    >
      <fsx-parties-grid
        *ngIf="initiatingGridConfig$ | async as partiesGridConfig"
        [partiesGridConfig]="partiesGridConfig"
        [partiesGridOptions]="{
          title: 'Initiating',
          descriptor: 'Initiating',
          expanded: true,
        }"
        [isInitiating]="true"
        [combinedFilingData]="combinedFilingData"
        [combinedGridRows]="combinedPartiesGridRows$ | async"
        [validationFilteredClass]="validationFilteredClass"
        (validationFilteredEvent)="validationFilteredEventHandler($event)"
      >
      </fsx-parties-grid>

      <fsx-parties-grid
        *ngIf="additionalGridConfig$ | async as partiesGridConfig"
        [partiesGridConfig]="partiesGridConfig"
        [partiesGridOptions]="{
          title: 'Other Case Participants',
          descriptor: '',
          expanded: true,
        }"
        [isInitiating]="false"
        [combinedFilingData]="combinedFilingData"
        [combinedGridRows]="combinedPartiesGridRows$ | async"
        [validationFilteredClass]="validationFilteredClass"
        (validationFilteredEvent)="validationFilteredEventHandler($event)"
      >
      </fsx-parties-grid>
    </div>
    <ng-template #subsequent>
      <fsx-subf-parties-grid
        *ngIf="casePartiesGridConfig$ | async as gridVm"
        [config]="{
          combinedFilingData: combinedFilingData,
          partiesGridRows: gridVm.partiesGridRows,
          participantSpecs: gridVm.participantSpecs,
          attorneySpecs: gridVm.attorneySpecs,
        }"
      ></fsx-subf-parties-grid>
    </ng-template>
  </div>
</div>
