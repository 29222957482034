import { Injectable, InjectionToken } from '@angular/core';
import { FormGroup } from '@angular/forms';
import {
  ContactAliasViewModel,
  RequestParticipantViewModel,
  RequestParticipantAliasViewModel,
} from '@fsx/fsx-shared';
import {
  AddressViewModel,
  AddressCategory,
  AddressCategorySelectionFieldDefinition,
  AddressCommonCategory,
  AddressProfile,
  AddressProfileSpec,
  AddressSpec,
  ContactAliasFormGroup,
  ContactFormGroup,
  FilingProfile,
  NamedListItem,
  NewContactViewModel,
  StringSelectionFieldDefinition,
} from '@fsx/fsx-shared';

/**
 * The InjectionToken to use in the providers array to specify a concrete-implementation
 * of the IAddressFormService to use at runtime.
 */
export const FsxAddressFormService = new InjectionToken<IAddressFormService>(
  'FsxAddressFormService',
);

export interface IAddressFormService {
  setAddressCategories(
    contact:
      | NewContactViewModel
      | RequestParticipantViewModel
      | RequestParticipantAliasViewModel
      | ContactAliasViewModel,
    formGroup: FormGroup<ContactFormGroup> | FormGroup<ContactAliasFormGroup>,
  ): void;

  getAddressFromFilingProfile(
    contactAddresses: AddressViewModel[] | undefined,
    addressSpec: AddressSpec | undefined,
    filingProfile: FilingProfile,
  ): AddressViewModel[];

  setAddressCaptions(
    contact: NewContactViewModel | ContactAliasViewModel,
    formGroup: FormGroup<ContactFormGroup> | FormGroup<ContactAliasFormGroup>,
  ): void;
}

@Injectable()
export class AddressFormService implements IAddressFormService {
  public setAddressCategories(
    contact:
      | NewContactViewModel
      | RequestParticipantViewModel
      | RequestParticipantAliasViewModel
      | ContactAliasViewModel,
    formGroup: FormGroup<ContactFormGroup> | FormGroup<ContactAliasFormGroup>,
  ): void {
    formGroup.controls.addresses?.controls.forEach((address, index) => {
      const addressCategory = address.controls.category?.value;
      const dropdownOptions = address.controls.category?.dropdownOptions;

      const selectedOption = dropdownOptions?.find(
        (option) => option.name === addressCategory,
      );

      if (!!contact && contact.addresses && contact.addresses[index]) {
        contact.addresses[index].caption = '';

        if (contact.addresses[index].category && selectedOption) {
          contact.addresses[index].category = {
            name: selectedOption.name,
            caption: selectedOption.caption,
            commonCategory: selectedOption.category
              ?.commonCategory as AddressCommonCategory,
          };
        }
      }
    });
  }

  public getAddressFromFilingProfile(
    contactAddresses: AddressViewModel[] | undefined,
    addressSpec: AddressSpec | undefined,
    filingProfile: FilingProfile,
  ): AddressViewModel[] {
    const participantAddresses: AddressViewModel[] = [];
    const addressProfileName = addressSpec?.addressProfileName;
    let addressProfile: AddressProfile | undefined = undefined;
    let defaultAddressProfile: AddressProfile | undefined = undefined;
    let addressProfileSpecs: AddressProfileSpec | undefined = undefined;

    defaultAddressProfile = filingProfile?.addressProfiles?.find(
      (profile) => profile.name === filingProfile.defaultAddressProfileName,
    );

    if (addressProfileName) {
      addressProfile = filingProfile?.addressProfiles?.find(
        (profile) => profile.name === addressProfileName,
      );
    }

    addressProfileSpecs = addressProfile?.spec || defaultAddressProfile?.spec;

    contactAddresses?.forEach((address: AddressViewModel) => {
      const newAddress: AddressViewModel = {
        ...address,
        category: this.getAddressCategory(
          address.category ?? undefined,
          addressSpec?.category ?? undefined,
          filingProfile,
        ),
        country: this.validateValueWithAdditionalList(
          address.country,
          addressSpec?.country ?? undefined,
          filingProfile,
        ),
        administrativeArea: this.validateValueWithAdditionalList(
          address.administrativeArea ?? undefined,
          addressProfileSpecs?.administrativeArea ?? undefined,
          filingProfile,
        ),
      } as AddressViewModel;

      if (
        newAddress.category &&
        addressSpec &&
        participantAddresses.length <= addressSpec.maxAllowed
      ) {
        participantAddresses.push(newAddress);
      }
    });

    return participantAddresses;
  }

  public setAddressCaptions(
    contact: NewContactViewModel | ContactAliasViewModel,
    formGroup: FormGroup<ContactFormGroup> | FormGroup<ContactAliasFormGroup>,
  ): void {
    formGroup.controls.addresses?.controls.forEach((_, index) => {
      if (!!contact.addresses) {
        if (contact.addresses[index].addressLine1) {
          contact.addresses[index].caption =
            `${contact.addresses[index].addressLine1}`;
        }

        if (contact.addresses[index].addressLine2) {
          contact.addresses[index].caption +=
            ` ${contact.addresses[index].addressLine1}`;
        }

        if (contact.addresses[index].locality) {
          contact.addresses[index].caption +=
            `, ${contact.addresses[index].locality}`;
        }

        if (contact.addresses[index].postalCode) {
          contact.addresses[index].caption +=
            ` ${contact.addresses[index].postalCode}`;
        }
      }
    });
  }

  private validateValueWithAdditionalList(
    value: string | undefined,
    fieldDefinition:
      | StringSelectionFieldDefinition
      | AddressCategorySelectionFieldDefinition
      | undefined,
    filingProfile: FilingProfile | null,
  ): string {
    let selectedValue: NamedListItem | undefined = undefined;

    if (fieldDefinition?.listReference?.list === 'AdditionalList') {
      const list = filingProfile?.additionalLists?.find(
        (list) =>
          list.name === fieldDefinition?.listReference?.additionalListName,
      );

      if (list) {
        selectedValue = list.items?.find(
          (item) => item.name.toLowerCase() === value?.toLowerCase(),
        );
      }
    }

    return selectedValue?.name ?? '';
  }

  private getAddressCategory(
    addressCategory: AddressCategory | undefined,
    fieldDefinition: AddressCategorySelectionFieldDefinition | undefined,
    filingProfile: FilingProfile,
  ): AddressCategory | null {
    const addressList = fieldDefinition?.listReference;
    let selectedCategory: AddressCategory | undefined;

    if (addressList && addressList.list === 'AdditionalList') {
      const list = filingProfile?.additionalLists?.find(
        (list) => list.name === addressList.additionalListName,
      );
      const listElement = list?.items?.find(
        (item) => item.name === addressCategory?.name,
      );
      if (listElement && addressCategory) {
        selectedCategory = addressCategory;
      }
    } else {
      selectedCategory = filingProfile.addressCategories?.find(
        (item) => item.commonCategory === addressCategory?.commonCategory,
      );
    }

    return selectedCategory ? selectedCategory : null;
  }
}
