import moment from 'moment';
import { TimeFieldDefinition, IValidatable } from '../../../types';
import { Injectable, InjectionToken } from '@angular/core';

export const FsxTimeValidationService =
  new InjectionToken<ITimeValidationService>('FsxTimeValidationService');

export interface ITimeValidationService {
  validateTime(
    value: string | null | undefined,
    spec: TimeFieldDefinition | null | undefined,
    scope: IValidatable,
  ): boolean;
}

@Injectable()
export class TimeValidationService implements ITimeValidationService {
  public validateTime(
    value: string | null | undefined,
    spec: TimeFieldDefinition | null | undefined,
    scope: IValidatable,
  ): boolean {
    if (!spec) {
      return true;
    }

    if (!value) {
      if (spec.required) {
        scope.isValid = false;
        return false;
      }
    }

    const time = moment.duration(value);
    const minTime = moment.duration(spec.minTime);
    const maxTime = moment.duration(spec.maxTime);

    if (time < minTime) {
      scope.isValid = false;
      return false;
    }

    if (time > maxTime) {
      scope.isValid = false;
      return false;
    }

    return true;
  }
}
