<div
  class="fsx-boolean-component"
  [ngStyle]="{
    width: width,
    display: fieldDefinition.visible ? 'inline-block' : 'none',
  }"
>
  <label [ngClass]="{ invalid: !isValid }">
    {{ caption }} <span *ngIf="!isValid">(pick {{ validationMessage }})</span>
  </label>

  <div
    class="checkbox-container"
    *ngFor="let option of options; let index = index"
  >
    <fsx-boolean-component
      [caption]="option.caption"
      [fieldType]="fieldType"
      [width]="width"
      [fieldDefinition]="booleanFieldDefinition"
      [initialValue]="false"
      (formControlEmitter)="setFormControl($event, index)"
    ></fsx-boolean-component>
  </div>
</div>
