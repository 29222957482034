import { Inject, Injectable, InjectionToken } from '@angular/core';
import {
  CaseRequestViewModel,
  CombinedFilingData,
  FILING_SUB_TABS,
  FilingSubTabItem,
  RequestDocumentViewModel,
} from '@fsx/fsx-shared';
import {
  Observable,
  Subject,
  filter,
  map,
  merge,
  pairwise,
  withLatestFrom,
} from 'rxjs';
import {
  FsxFilingSubTabsService,
  IFilingSubTabsService,
} from '../../filing-sub-tabs-container/filing-sub-tabs.service';
import {
  FsxCombinedFilingDataService,
  ICombinedFilingDataService,
} from '../combined-filing-data.service';
import {
  FsxDocumentValidationService,
  IDocumentValidationService,
} from 'projects/libs/shared/src/lib/services/core/validation/document-validation.service';
import {
  FsxCaseRequestDataService,
  ICaseRequestDataService,
} from '../case-request-data.service';
import {
  FsxValidationErrorsService,
  IValidationErrorsService,
} from '../validation-errors.service';
import { ValidationGroupConstants } from '../validation-group-errors.service';

export const FsxValidateDocumentsOrchestrationService =
  new InjectionToken<IValidateDocumentsOrchestrationService>(
    'FsxValidateDocumentsOrchestrationService',
  );

export interface ValidateDocumentsParams {
  /**
   * An array of the document GUID strings to include in the checks.
   */
  includedDocumentIds?: string[];

  /**
   * A flag to pass which when true will force form-level validation.
   */
  forceFormValidation?: boolean;
}

export interface ValidateDocumentFormParams {
  documentId: string;
  force: boolean;
}

export interface IValidateDocumentsOrchestrationService {
  /**
   * An observable that components can listen to to trigger form-level validation.
   */
  validateDocumentForm$: Observable<ValidateDocumentFormParams>;

  /**
   * The pipeline of orchestration steps needed to validate RequestDocument
   * objects on the CaseRequest object.
   */
  validateDocuments$: Observable<CaseRequestViewModel>;

  /**
   * A public method to allow the orchestration to be triggered.
   *
   * @param params The parameters needed to run this orchestration stream.
   */
  validateDocuments(params: ValidateDocumentsParams): void;
}

@Injectable()
export class ValidateDocumentsOrchestrationService
  implements IValidateDocumentsOrchestrationService
{
  /**
   * A subject to trigger form-level validation.
   */
  private validateDocumentForm$$ = new Subject<ValidateDocumentFormParams>();

  /**
   * An observable that components can listen to to trigger form-level validation.
   */
  validateDocumentForm$: Observable<ValidateDocumentFormParams> =
    this.validateDocumentForm$$.asObservable();

  /**
   * A subject to use as the trigger for the orchestration.
   */
  private validateDocuments$$ = new Subject<ValidateDocumentsParams>();

  /**
   * An observable stream that emits when the user navigates away from the "Documents" tab.
   * We use this to automatically trigger the document validation.
   */
  private documentsTabDeactivated$: Observable<ValidateDocumentsParams> =
    this.filingSubTabsService.activeSubTabItem$.pipe(
      pairwise(),
      filter(([previous, current]: [FilingSubTabItem, FilingSubTabItem]) => {
        return (
          previous.name === FILING_SUB_TABS.DOCUMENTS &&
          current.name !== FILING_SUB_TABS.DOCUMENTS
        );
      }),
      map(() => {
        return {
          // We're changing tabs so we want to force-validate the form-fields we're leaving behind.
          forceFormValidation: true,
        };
      }),
    );

  /**
   * The pipeline of orchestration steps needed to validate RequestDocument
   * objects on the CaseRequest object.
   */
  validateDocuments$: Observable<CaseRequestViewModel> = merge(
    this.documentsTabDeactivated$,
    this.validateDocuments$$,
    // TODO: Add anymore document validation triggers here
  ).pipe(
    withLatestFrom(this.combinedFilingDataService.combinedFilingData$),
    map(
      ([params, combinedFilingData]: [
        ValidateDocumentsParams,
        CombinedFilingData,
      ]) => {
        const { caseRequest, filingProfile, modeSpec } = combinedFilingData;
        const includedDocumentIds = params.includedDocumentIds || [];
        caseRequest.isDocumentsValid =
          this.documentValidationService.validateAllDocuments(
            caseRequest,
            modeSpec,
            filingProfile,
            includedDocumentIds,
          );

        // Search for invalid parties and generate any validation errors here...
        const caseRequestDocuments = caseRequest.documents || [];
        caseRequestDocuments.forEach((document: RequestDocumentViewModel) => {
          let errorCode = `invalidDocument${document.id!}`;
          if (document.isValid === false) {
            const caption = document.title || 'A document';
            this.validationErrorsService.addValidationError({
              errorCode: errorCode,
              errorMessage: `${caption} requires further attention`,
              group: ValidationGroupConstants.documents,
              metadata: {
                entityId: document.id!,
              },
            });

            // Trigger form-level validation here.
            this.validateDocumentForm$$.next({
              documentId: document.id!,
              force: params.forceFormValidation || false,
            });
          } else {
            this.validationErrorsService.removeValidationError(errorCode);
          }
        });

        // Trigger a filing-editor instance-wide update of the validated caseRequest object
        this.caseRequestDataService.setCaseRequestData(caseRequest);

        return caseRequest;
      },
    ),
  );

  public constructor(
    @Inject(FsxCombinedFilingDataService)
    private readonly combinedFilingDataService: ICombinedFilingDataService,
    @Inject(FsxDocumentValidationService)
    private readonly documentValidationService: IDocumentValidationService,
    @Inject(FsxCaseRequestDataService)
    private readonly caseRequestDataService: ICaseRequestDataService,
    @Inject(FsxFilingSubTabsService)
    private readonly filingSubTabsService: IFilingSubTabsService,
    @Inject(FsxValidationErrorsService)
    private readonly validationErrorsService: IValidationErrorsService,
  ) {}

  /**
   * A public method to allow the orchestration to be triggered.
   *
   * @param params The parameters needed to run this orchestration stream.
   */
  validateDocuments(params: ValidateDocumentsParams): void {
    params.forceFormValidation = false;
    this.validateDocuments$$.next(params);
  }
}
