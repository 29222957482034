import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  QueryList,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import {
  FilingProfileSummary,
  FilingProfileSummaryItemTree,
} from '@fsx/fsx-shared';
import { asyncScheduler } from 'rxjs';
import { FormControlWithoutModel } from '../../models';
import { DropdownOption } from '../../types';
import { FsxBasicSingleSelectionComponent } from '../../../public-api';

@Component({
  selector: 'fsx-profile-selection-component',
  templateUrl: './profile-selection.component.html',
  styleUrls: ['./profile-selection.component.scss'],
})
export class FsxProfileSelectionComponent implements OnInit {
  @Input() profileSelectionData!: FilingProfileSummaryItemTree;

  @Output() formControlEmitter = new EventEmitter<FormControlWithoutModel>();
  @Output() public browsedProfileSelected = new EventEmitter<{
    profileId: string;
    profileName: string;
    profilePath?: string[];
  }>();

  @ViewChildren('selectionField')
  selectionFields!: QueryList<FsxBasicSingleSelectionComponent>;
  @ViewChild('caseTypeSelectionField')
  caseTypeSelectionField!: FsxBasicSingleSelectionComponent;

  public formControlsList: FormControlWithoutModel[] = [];
  public valuesArray: string[] = [];
  public labelsArray: string[] = [];
  public captionsArray: string[] = [];
  public dropdownOptionsArray: DropdownOption<void>[][] = [];
  public profileDropdownOptions: DropdownOption<void>[] = [];

  ngOnInit(): void {
    this.setDropdownData(
      this.profileSelectionData,
      this.dropdownOptionsArray.length,
    );
  }

  public setControl(control: FormControlWithoutModel) {
    this.formControlsList.push(control);
  }

  private setDropdownData(
    dropdownData: FilingProfileSummaryItemTree,
    index: number,
  ): void {
    this.labelsArray.push(dropdownData.childLabel ?? '');

    this.dropdownOptionsArray[index] =
      dropdownData.children?.map((child: FilingProfileSummaryItemTree) => {
        const dropdownOption: DropdownOption<void> = {
          name: child.name ?? '',
          caption: child.caption ?? '',
          selected: false,
        };
        return dropdownOption;
      }) ?? [];
  }

  public selectedValue(value: string, index: number | null) {
    if (
      index !== null &&
      index < this.valuesArray.length &&
      this.valuesArray[index] !== value
    ) {
      this.resetDropdownOptions(index);
    }

    if (index !== null) {
      const arrayValue = this.valuesArray.at(index);
      if (arrayValue) {
        if (this.valuesArray[index] === value) {
          return;
        }
        this.valuesArray[index] = value;
      } else {
        this.valuesArray.push(value);
      }
      this.searchInDataTree(index);
    } else {
      const selectedProfile = this.profileDropdownOptions.find(
        (option) => option.name === value,
      );

      this.captionsArray.push(selectedProfile?.caption ?? '');
      this.captionsArray = [
        this.profileSelectionData.caption,
        ...this.captionsArray,
      ];

      this.browsedProfileSelected.emit({
        profileName: this.captionsArray[this.captionsArray.length - 1],
        profilePath: this.captionsArray.slice(0, this.captionsArray.length - 1),
        profileId: value,
      });
    }
  }

  private searchInDataTree(index: number): void {
    let dropdownData: FilingProfileSummaryItemTree = {
      ...this.profileSelectionData,
    };

    this.valuesArray.forEach((value, arrayIndex) => {
      const child = dropdownData.children?.find(
        (child: FilingProfileSummaryItemTree) => child.name === value,
      );

      if (child && child.children) {
        dropdownData = child;

        if (arrayIndex === index) {
          if (dropdownData.children?.length) {
            this.captionsArray.push(dropdownData.caption ?? '');
          }
          this.setDropdownData(dropdownData, index + 1);
        }
      }

      if (child && child.items && arrayIndex === index) {
        this.profileDropdownOptions = [];
        asyncScheduler.schedule(() => this.setProfileLists(child));
      } else if (child && !child.items) {
        this.profileDropdownOptions = [];
      }
    });
  }

  private setProfileLists(dropdownData: FilingProfileSummaryItemTree): void {
    this.captionsArray.push(dropdownData.caption ?? '');

    this.profileDropdownOptions =
      dropdownData.items?.map((item: FilingProfileSummary) => {
        const dropdownOption: DropdownOption<void> = {
          name: item.id ?? '',
          caption: item.caption ?? '',
          selected: false,
        };
        return dropdownOption;
      }) ?? [];
  }

  public clearDropdown(index?: number): void {
    if (index) {
      this.resetDropdownOptions(index, true);
    }
  }

  private resetDropdownOptions(index: number, clear = false): void {
    this.valuesArray = this.valuesArray.slice(0, clear ? index : index + 1);
    this.captionsArray = this.captionsArray.slice(1, index);
    this.formControlsList = this.formControlsList.slice(0, index + 1);
    this.dropdownOptionsArray = this.dropdownOptionsArray.slice(0, index + 1);
    this.labelsArray = this.labelsArray.slice(0, index + 1);
    this.profileDropdownOptions = [];
  }

  public validate(): void {
    if (this.dropdownOptionsArray.length) {
      this.selectionFields.toArray().forEach((field) => {
        field.validate();
      });
    }

    if (this.profileDropdownOptions.length) {
      this.caseTypeSelectionField.validate();
    }
  }
}
