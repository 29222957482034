import { Inject, Injectable, InjectionToken } from '@angular/core';
import {
  Subject,
  Observable,
  mergeMap,
  filter,
  forkJoin,
  of,
  withLatestFrom,
  map,
  switchMap,
} from 'rxjs';
import {
  FsxFilingEditorEventService,
  IFilingEditorEventService,
} from '../../filing-editor/services/filing-editor-events.service';
import {
  CaseRequestViewModel,
  CombinedFilingData,
  FsxCaseRequestBuilderService,
  FsxCaseRequestUpdateService,
  FsxCreateRequestDocumentService,
  ICaseRequestBuilderService,
  ICaseRequestUpdateService,
  ICreateRequestDocumentService,
  RequestDocumentCreatedParams,
  RequestDocumentViewModel,
} from '@fsx/fsx-shared';
import {
  FsxValidateDocumentsOrchestrationService,
  IValidateDocumentsOrchestrationService,
} from '../../filing-editor/services/orchestration/validate-documents-orchestration.service';
import {
  FsxCombinedFilingDataService2,
  ICombinedFilingDataService,
} from '../../filing-editor/services/combined-filing-data.service';

/**
 * The parameters needed to run the Add Dcoument Orchestration.
 * We pass the unique identifiers in here so that we can assert
 * that they are correctly set into new request document objects
 * on the caseRequest object.
 */
export interface AddDocumentParams {
  uniqueIdentifiers: string[];
}

/**
 * The InjectionToken to use in the providers array to specify a concrete-implementation
 * of the IAddDocumentOrchestrationService to use at runtime.
 */
export const FsxAddDocumentOrchestrationService =
  new InjectionToken<IAddDocumentOrchestrationService>(
    'FsxAddDocumentOrchestrationService',
  );

/**
 * A blueprint for an orchestration service, which handles the adding of documents
 * through the "Add Documents" button in DocumentActionsComponent.
 */
export interface IAddDocumentOrchestrationService {
  /**
   * The pipeline of orchestration steps needed to add a document.
   */
  addDocumentStream$: Observable<CaseRequestViewModel>;

  /**
   * A public method to allow the orchestration to be triggered.
   */
  addDocument(params: AddDocumentParams): void;
}

/**
 * A concrete implementation of an orchestration service, which handles the adding
 * of documents through the "Add Documents" button in DocumentActionsComponent.
 */
@Injectable()
export class AddDocumentOrchestrationService
  implements IAddDocumentOrchestrationService
{
  /**
   * A subject to use as the trigger for the orchestration.
   */
  private addDocumentParams$$ = new Subject<AddDocumentParams>();

  /**
   * The pipeline of orchestration steps needed to add a document.
   */
  addDocumentStream$: Observable<CaseRequestViewModel> =
    this.addDocumentParams$$.pipe(
      withLatestFrom(this.combinedFilingDataService2.combinedFilingData$),
      mergeMap(
        ([addDocumentParams, combinedFilingData]: [
          AddDocumentParams,
          CombinedFilingData,
        ]) => {
          const { filing, caseRequest } = combinedFilingData;
          const { uniqueIdentifiers } = addDocumentParams;
          const filingId: string = filing.id;
          const caseRequestDocuments = caseRequest.documents || [];
          const caseRequestBackup = JSON.parse(
            JSON.stringify(caseRequest),
          ) as CaseRequestViewModel;

          const fakeProfileRuleReached$ = of(false);
          const filingProfileGuards$ = forkJoin([
            fakeProfileRuleReached$,
            // isMaxLeadDocumentsAllowed$
          ]).pipe(
            filter((guardConditions) => {
              const noGuardConditionsReached = !guardConditions.includes(true);
              return noGuardConditionsReached;
            }),
          );

          const cases = caseRequest?.cases;

          if (!cases) {
            throw new Error('No cases when adding a document');
          }
          if (cases.length === 0) {
            throw new Error(
              'No cases when adding a document; cases exists but is empty',
            );
          }
          if (!cases[0].caseId) {
            throw new Error('No case id when adding a document');
          }

          return filingProfileGuards$.pipe(
            mergeMap(() => {
              return this.createRequestDocumentService
                .bulkCreateRequestDocumentFromIds(
                  uniqueIdentifiers,
                  true,
                  cases[0]!.caseId!,
                )
                .pipe(
                  mergeMap(
                    (
                      requestDocumentCreatedParams: RequestDocumentCreatedParams[],
                    ) => {
                      const requestDocuments: RequestDocumentViewModel[] =
                        requestDocumentCreatedParams.map(
                          (p) => p.requestDocument,
                        );
                      const nextDocumentIndex = caseRequestDocuments.length;
                      const documentIndex = nextDocumentIndex;
                      return this.caseRequestBuilderService
                        .bulkAddRequestDocument({
                          caseRequest,
                          requestDocuments,
                          documentIndex,
                        })
                        .pipe(
                          mergeMap(() => {
                            return this.caseRequestUpdateService
                              .optimisticPutOrRestore(
                                filingId,
                                caseRequest,
                                caseRequestBackup,
                              )
                              .pipe(
                                switchMap(() => {
                                  this.validateDocumentsOrchestrationService.validateDocuments(
                                    { includedDocumentIds: [''] },
                                  );
                                  const requestDocument: RequestDocumentViewModel =
                                    caseRequest.documents![documentIndex];
                                  this.filingEditorEventService.dispatchRequestDocumentCreatedEvent(
                                    {
                                      filingId,
                                      documentIndex,
                                      requestDocument,
                                    },
                                  );
                                  return this.combinedFilingDataService2.combinedFilingData$.pipe(
                                    map(
                                      (
                                        combinedFilingData: CombinedFilingData,
                                      ) => {
                                        return combinedFilingData.caseRequest;
                                      },
                                    ),
                                  );
                                }),
                              );
                          }),
                        );
                    },
                  ),
                );
            }),
          );
        },
      ),
    );

  constructor(
    @Inject(FsxCaseRequestBuilderService)
    private readonly caseRequestBuilderService: ICaseRequestBuilderService,
    @Inject(FsxCaseRequestUpdateService)
    private readonly caseRequestUpdateService: ICaseRequestUpdateService,
    @Inject(FsxCombinedFilingDataService2)
    private readonly combinedFilingDataService2: ICombinedFilingDataService,
    @Inject(FsxFilingEditorEventService)
    private readonly filingEditorEventService: IFilingEditorEventService,
    @Inject(FsxCreateRequestDocumentService)
    private readonly createRequestDocumentService: ICreateRequestDocumentService,
    @Inject(FsxValidateDocumentsOrchestrationService)
    private readonly validateDocumentsOrchestrationService: IValidateDocumentsOrchestrationService,
  ) {}

  /**
   * A public method to allow the orchestration to be triggered.
   */
  addDocument(params: AddDocumentParams): void {
    this.addDocumentParams$$.next(params);
  }
}
