import { Inject, Injectable } from '@angular/core';
import {
  CurrentPath,
  EnvConfig,
  CourtSummaryItemTreeTreeResult,
  FilingProfileSummaryItemTreeTreeResult,
  FilingProfile,
  CaseQueryResultItemGridResult,
  CaseDetail,
} from '@fsx/fsx-shared';
import { ENV_CONFIG, AppConfig } from '@fsx/fsx-shared';
import { map, Observable, shareReplay, switchMap } from 'rxjs';
import { ApiV2Service } from '../core/api/api.v2.service';

@Injectable({
  providedIn: 'root',
})
export class CourtsApiService {
  public constructor(
    @Inject(ENV_CONFIG) public envConfig: Observable<EnvConfig>,
    private readonly appConfig: AppConfig,
    private readonly api: ApiV2Service,
  ) {}

  public getCourtSelectionTree(
    data: CurrentPath[],
  ): Observable<CourtSummaryItemTreeTreeResult> {
    return this.api
      .post<
        { data: CurrentPath[] },
        CourtSummaryItemTreeTreeResult
      >(this.appConfig.fsxApi.court.getCourtSelectionTree(), { data: data })
      .pipe(shareReplay());
  }

  public getFilingProfileSelectionTree(
    courtId: string,
  ): Observable<FilingProfileSummaryItemTreeTreeResult> {
    return this.api
      .post<
        { data: CurrentPath[] },
        FilingProfileSummaryItemTreeTreeResult
      >(this.appConfig.fsxApi.court.getProfileSelectionTree(courtId), { data: [] })
      .pipe(shareReplay());
  }

  public getCourtCases(
    courtId: string,
    searchText: string,
  ): Observable<CaseQueryResultItemGridResult> {
    return this.api
      .post<
        {
          limit: number;
          sort: any[];
          filters: any[];
        },
        CaseQueryResultItemGridResult
      >(this.appConfig.fsxApi.court.getCourtCases(courtId), {
        limit: 40,
        filters: [
          {
            column: 'searchText',
            value1: searchText,
            operator: 'Contains',
          },
        ],
        sort: [
          {
            column: 'title',
            direction: 'ascending',
          },
        ],
      })
      .pipe(shareReplay());
  }

  public getCourtSubCases(
    courtId: string,
    caseEfmKey: string,
  ): Observable<CaseQueryResultItemGridResult> {
    return this.api
      .post<{}, CaseQueryResultItemGridResult>(
        this.appConfig.fsxApi.court.getCourtCases(courtId),
        {
          limit: 40,
          filters: [
            {
              column: 'efmKey',
              value1: caseEfmKey,
              operator: 'Equal',
            },
          ],
          sort: [
            {
              column: 'title',
              direction: 'ascending',
            },
          ],
        },
      )
      .pipe(shareReplay());
  }

  public getFilingProfile(
    courtId: string,
    profileId: string,
  ): Observable<FilingProfile> {
    return this.api
      .get<FilingProfile>(
        this.appConfig.fsxApi.court.getFilingProfile(courtId, profileId),
      )
      .pipe(shareReplay());
  }

  public attest(
    courtId: string,
    caseKey: string,
    statement: string,
  ): Observable<void> {
    return this.api
      .post<
        { statement: string; answer: string },
        void
      >(this.appConfig.fsxApi.court.attest(courtId, caseKey), { statement: statement, answer: 'yes' })
      .pipe(shareReplay());
  }

  public getCaseDetail(
    courtId: string,
    caseKey: string,
  ): Observable<CaseDetail> {
    return this.envConfig.pipe(
      switchMap((envConfig: EnvConfig) => {
        const url = `${envConfig.ApiServer.BaseURL}/${envConfig.API_VERSION}/courts/${courtId}/cases/key/${caseKey}`;
        return this.api.get<CaseDetail>(url).pipe(
          map((caseDetail: CaseDetail) => {
            return caseDetail;
          }),
        );
      }),
    );
  }
}
