import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export function maxLengthValidator(length: number): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    if ((control.value && control.value.length <= length) || !control.value) {
      return null;
    }
    return { invalidMaxLength: `Can't exceed ${length} characters` };
  };
}
