import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';
import {
  FieldCategory,
  SearchFieldDefinition,
  SearchResultItem,
  SearchResultItemGridResult,
  StringSelectionFieldDefinition,
} from '@fsx/fsx-shared';
import {
  debounceTime,
  Observable,
  tap,
  Subject,
  switchMap,
  takeUntil,
  of,
} from 'rxjs';
import { FormControlWithModel } from '../../models';
import { ReferenceResolver } from '../../types';

@Component({
  selector: 'fsx-efm-search-component',
  templateUrl: './efm-search.component.html',
  styleUrls: ['./efm-search.component.scss'],
})
export class FsxEfmSearchComponent implements OnInit, OnChanges, OnDestroy {
  @ViewChild(MatAutocompleteTrigger, { read: MatAutocompleteTrigger })
  matAutoComplete!: MatAutocompleteTrigger;

  @Output() optionSelected: EventEmitter<SearchResultItem> =
    new EventEmitter<SearchResultItem>();
  @Input() resolver!: ReferenceResolver;
  @Input() fieldDefinition!: SearchFieldDefinition;
  @Input() searchValue!: string;
  @Input() initialValue!: SearchResultItem | null;
  @Input() caption!: string | null;
  public helpText = '';
  public hint = 'Type or Select';
  public fieldType = FieldCategory.Selection;
  public width = '300px';
  public inputFormControl!: FormControlWithModel<StringSelectionFieldDefinition>;
  public focusedElement!: string | undefined;
  public searchTerm: FormControl = new FormControl('');
  public showLoading = false;
  private destroy$: Subject<void> = new Subject();
  public filteredOptions!: Observable<SearchResultItem[]>;
  public inputFocused = false;
  public searchName!: string;

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['disabled']) {
      changes['disabled'].currentValue
        ? this.searchTerm.disable()
        : this.searchTerm.enable();
    }
  }

  ngOnInit(): void {
    this.searchName = this.fieldDefinition.searchName;
    this.hint = this.fieldDefinition.searchTooltip ?? '';
    if (this.initialValue) {
      this.searchTerm.setValue(this.initialValue.caption);
    }
    this.searchTerm.valueChanges
      .pipe(
        debounceTime(500),
        tap(() => {
          this.showLoading = true;
        }),
        switchMap((value) => {
          return this.performSearch(value);
        }),
        tap((queryItem) => {
          this.setResults(queryItem);
          this.showLoading = false;
        }),
        takeUntil(this.destroy$),
      )
      .subscribe();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  private performSearch(
    searchValue: string,
  ): Observable<SearchResultItemGridResult> {
    return this.resolver.search(searchValue, this.searchName);
  }

  private setResults(results: SearchResultItemGridResult): void {
    const resultsData = results.data as SearchResultItem[];

    this.filteredOptions = of(resultsData);
  }

  selectSingleOption(option: SearchResultItem) {
    this.optionSelected.emit(option);
  }

  focusInput(inputFocused: boolean) {
    this.inputFocused = inputFocused;
  }

  public clearInput(): void {
    this.searchTerm.reset();
    this.optionSelected.emit(null!);
  }

  public setFocusedElement(): void {
    this.focusedElement = this.matAutoComplete.activeOption?.value;
  }

  public validate(): void {
    // no requirement to validate here, but added for completeness
  }
}
