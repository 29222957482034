import { Injectable, InjectionToken } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { PollingState } from '../filing-editor.component';

export const FsxPollingStateService = new InjectionToken<IPollingStateService>(
  'FsxPollingStateService',
);

export interface IPollingStateService {
  pollingState$: Observable<PollingState>;
  setPollingState(pollingState: PollingState): void;
}

@Injectable()
export class PollingStateService implements IPollingStateService {
  private pollingState$$ = new BehaviorSubject<PollingState>(PollingState.None);

  pollingState$: Observable<PollingState> = this.pollingState$$.asObservable();

  setPollingState(pollingState: PollingState): void {
    this.pollingState$$.next(pollingState);
  }
}
