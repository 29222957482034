import { StringSelectionFieldDefinition } from '@fsx/fsx-shared';
import { FieldDefinition } from '../types';

type RequiredTypes = StringSelectionFieldDefinition;

export function hasMinRequired(
  fieldDefinition: FieldDefinition,
): fieldDefinition is RequiredTypes {
  return (fieldDefinition as RequiredTypes).minRequired !== undefined;
}
