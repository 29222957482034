<div class="entity-type-container">
  <button
    class="primary person"
    fsxPreventKeyboardEventBubbling
    (click)="onContactTypeButtonClicked(ContactType.Person)"
    [ngClass]="{
      primary: contactType === ContactType.Person,
      secondary: contactType !== ContactType.Person,
      disabled: config.disabled,
    }"
    [disabled]="config.disabled"
  >
    <span class="material-icons"> person </span> Person
  </button>
  <button
    class="secondary organization"
    fsxPreventKeyboardEventBubbling
    (click)="onContactTypeButtonClicked(ContactType.Organization)"
    [ngClass]="{
      primary: contactType === ContactType.Organization,
      secondary: contactType !== ContactType.Organization,
      disabled: config.disabled,
    }"
    [disabled]="config.disabled"
  >
    <span class="material-icons"> hub </span> Organization
  </button>
</div>
