<!-- START: History Container -->
<div
  fxFill
  fxLayout="column"
  class="document-history-container"
  *ngIf="!!transactionDocument"
>
  <!-- START: Title -->
  <div fxFLex="none" fxLayout="row" fxLayoutGap="20px">
    <span fxFlex="none" class="main-title">Document History</span>
    <button fxFlex="none" class="icon-button">
      <span class="material-icons exit" (click)="close()">highlight_off</span>
    </button>
  </div>
  <!-- END: Current Document Info -->

  <!-- START: Current Document Info -->
  <div fxFlex="100px" fxLayout="column" class="info">
    <div
      fxLayout="row"
      class="columns"
      fxLayoutAlign="start center"
      fxLayoutGap="10px"
    >
      <div fxFlex="32px"></div>
      <div fxFlex="260px">Title</div>
      <div fxFlex="145px">Access</div>
      <div fxFlex="100px">Size</div>
      <div fxFlex="auto">Status</div>
    </div>
    <mat-divider></mat-divider>
    <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
      <div fxFlex="32px" class="value column order-number">
        {{ requestDocuments | documentOrderNumber: transactionDocumentIndex }}
      </div>
      <div fxFlex="260px">
        <div class="value link">
          <span class="download" (click)="onViewPdfClicked()">{{
            transactionDocument.documentName
          }}</span>
        </div>
        <div class="caption">{{ transactionDocument.documentCategory }}</div>
      </div>
      <div fxFlex="145px" class="value">{{ transactionDocument.access }}</div>
      <div fxFlex="100px">
        <div class="value">{{ transactionDocument.pdfSize }}</div>
        <div class="caption">Pages: {{ transactionDocument.pdfPages }}</div>
      </div>
      <div fxFlex="auto" class="value">{{ transactionDocument.status }}</div>
    </div>
  </div>
  <!-- END: Current Document Info -->

  <!-- START: Document Details -->
  <div fxFlex="none" class="details" fxLayout="column">
    <div fxFlex="none" class="header">Details</div>
    <div
      fxFlex="none"
      fxLayout="row"
      fxLayoutGap="10px"
      fxLayoutAlign="start end"
    >
      <div fxFlex="100px" fxLayout="column" fxLayoutGap="1px">
        <div fxFlex="none" class="value">{{ transactionId }}</div>
        <div fxFlex="none" class="caption">Transaction ID</div>
      </div>
      <div fxFlex="195px" fxLayout="column" fxLayoutGap="1px">
        <div fxFlex="none" class="value">
          {{ dateSubmitted | date: "MM/dd/YYYY hh:mmaaa" }}
        </div>
        <div fxFlex="none" class="caption">Date Submitted</div>
      </div>
      <div fxFlex="auto" fxLayout="column" fxLayoutGap="1px">
        <div fxFlex="none" class="value">{{ mode }}</div>
        <div fxFlex="none" class="caption">Transaction Mode</div>
      </div>
    </div>
  </div>
  <!-- END: Document Details -->

  <!-- START: Document History -->
  <div fxFlex="100px" class="history" fxLayout="column">
    <div
      fxFLex="none"
      fxLayout="row"
      class="columns"
      fxLayoutAlign="start center"
      fxLayoutGap="10px"
    >
      <div fxFlex="95px">Submit Date</div>
      <div fxFlex="155px">Status/Reviewer</div>
      <div fxFlex="92px">Reason</div>
      <div fxFlex="144px">Comment</div>
      <div fxFlex="126px">Document Type</div>
      <div fxFlex="281px">Document Title</div>
      <div fxFlex="172px">Access Type</div>
      <div fxFlex="auto">Statutory Fee</div>
    </div>
    <div class="divider"></div>

    <!-- START: Historical Entries -->
    <ng-container *ngFor="let documentHistory of documentHistory$ | async">
      <div
        fxFLex="none"
        fxLayout="row"
        class="column entry"
        fxLayoutAlign="start start"
        fxLayoutGap="10px"
      >
        <!-- ToDo: What date to use?-->
        <div fxFlex="95px">
          {{ documentHistory.eventAt | date: "MM/dd/YYYY hh:mmaaa" }}
        </div>
        <div fxFlex="155px" fxLayout="column">
          <span>{{ documentHistory.reviewStatus }}</span>
          <span class="caption">-</span>
        </div>
        <div fxFlex="92px">{{ documentHistory.statusReason }}</div>
        <div fxFlex="144px">{{ documentHistory.comment }}</div>
        <div fxFlex="126px">{{ documentHistory.documentCategory.caption }}</div>
        <div fxFlex="281px">{{ documentHistory.documentTitle }}</div>
        <div fxFlex="172px">{{ documentHistory.accessCategory?.name }}</div>
        <div fxFlex="auto" fxLayout="column">
          <div
            *ngFor="let fee of documentHistory.fees?.items"
            fxLayout="row"
            fxLayoutGap="10px"
            fxLayoutAlign="start center"
          >
            <span fxFlex="auto">{{ fee.caption }}</span>
            <span fxFlex="65px">{{
              fee.amount.amount | currency: "USD" : true
            }}</span>
          </div>
        </div>
      </div>
      <div class="divider"></div>
    </ng-container>
    <!-- END: Historical Entries -->
  </div>
  <!-- END: Document History -->
</div>
<!-- END: History Container -->
