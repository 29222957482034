import { Injectable, InjectionToken } from '@angular/core';
import { ContactSummary } from '@fsx/fsx-shared';
import { BehaviorSubject, Observable, filter, map } from 'rxjs';

export const FsxUserDataService = new InjectionToken<IUserDataService>(
  'FsxUserDataService',
);

/**
 * A blueprint for a state service, which stores a the Contact Summary object
 * for the logged in user.
 */
export interface IUserDataService {
  /**
   * A public member, which exposes the logged in user's ContactSummary object as an Observable
   */
  contactSummary$: Observable<ContactSummary>;

  /**
   * A public method, which sets the contact summary for the logged in user.
   *
   * @param contactSummary The contact summary data of the logged in user to store.
   */
  setContactSummary(contactSummary: ContactSummary): void;
}

/**
 * A concrete implementation of a state service, which stores a the Contact Summary
 * object for the logged in user.
 */
@Injectable()
export class UserDataService implements IUserDataService {
  /**
   * The logged in user's ContactSummary object, stored as a BehaviorSubject.
   */
  private contactSummary$$ = new BehaviorSubject<ContactSummary | null>(null);

  /**
   * A public member, which exposes the logged in user's ContactSummary object as an Observable
   */
  contactSummary$: Observable<ContactSummary> = this.contactSummary$$
    .asObservable()
    .pipe(
      filter((contactSummary) => contactSummary !== null),
      map((contactSummary) => contactSummary as ContactSummary),
    );

  /**
   * A public method, which sets the contact summary for the logged in user.
   *
   * @param contactSummary The contact summary data of the logged in user to store.
   */
  setContactSummary(contactSummary: ContactSummary): void {
    const newCaseRequestObj = JSON.parse(JSON.stringify(contactSummary));
    this.contactSummary$$.next(newCaseRequestObj);
  }
}
