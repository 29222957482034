<div
  class="fsx-date-component"
  [ngStyle]="{
    width: width,
    display: formControl.fieldDefinition.visible ? 'block' : 'none',
  }"
  [ngClass]="{ invalid: formControl.invalid && formControl.touched }"
>
  <ng-container *ngIf="!formControl.fieldDefinition.readOnly; else readOnly">
    <label
      >{{ caption }}
      <span class="required" *ngIf="formControl.fieldDefinition.required"
        >*</span
      >
      <mat-icon *ngIf="helpText" [matTooltip]="helpText">info_outline</mat-icon>
    </label>

    <input
      #timeInput
      autocomplete="never"
      type="time"
      class="time-input disabled"
      [formControl]="time"
      (click)="setFocus()"
      [ngClass]="{
        invalid: formControl.invalid && (initialValue || formControl.touched),
      }"
    />

    <div class="custom-inputs">
      <div class="inputs-container">
        <input
          #hoursInput
          type="text"
          class="time hours"
          [formControl]="hours"
          placeholder="- -"
          (blur)="formatTime()"
        />
        <div class="colon" [ngClass]="{ disabled: formControl.disabled }">
          :
        </div>
        <input
          #minutesInput
          type="text"
          class="time minutes"
          [formControl]="minutes"
          placeholder="- -"
          (blur)="formatTime()"
        />
        <input
          #meridianInput
          type="text"
          class="time meridians"
          [formControl]="meridian"
          placeholder="- -"
          (blur)="formatTime()"
        />
      </div>
    </div>

    <p class="error-message" *ngIf="formControl.invalid && formControl.touched">
      {{ getErrorMessage() }}
    </p>
  </ng-container>

  <ng-template #readOnly>
    <p class="readonly-text">
      {{ hours.value }}:{{ minutes.value }} {{ meridian.value }}
    </p>
    <label class="readonly-label">{{ caption }}</label>
  </ng-template>
</div>
