import { Inject, Injectable, InjectionToken } from '@angular/core';
import {
  Subject,
  switchMap,
  mergeMap,
  Observable,
  tap,
  withLatestFrom,
} from 'rxjs';
import {
  CaseRequestViewModel,
  CombinedFilingData,
  FsxCaseRequestBuilderService,
  FsxCaseRequestUpdateService,
  ICaseRequestBuilderService,
  ICaseRequestUpdateService,
  ParticipantCommonCategory,
} from '@fsx/fsx-shared';
import {
  FsxValidatePartiesOrchestrationService,
  IValidatePartiesOrchestrationService,
} from '../../filing-editor/services/orchestration/validate-parties-orchestration.service';
import {
  FsxCombinedFilingDataService,
  ICombinedFilingDataService,
} from '../../filing-editor/services/combined-filing-data.service';
import { v4 as uuidv4 } from 'uuid';

export const FsxAddDefaultParticipantOrchestrationService =
  new InjectionToken<IAddDefaultParticipantOrchestrationService>(
    'FsxAddDefaultParticipantOrchestrationService',
  );

export interface IAddParticipantEventParams {
  /**
   * The category to assign to the newly added participant. For OPF this
   * will determine which grid the participant appears in. For SubF there
   * is only one grid so the category can be left blank.
   */
  participantCommonCategory?: ParticipantCommonCategory;
}

export interface IAddDefaultParticipantOrchestrationService {
  /**
   * The pipeline of orchestration steps needed to add a new default
   * party to the caseRequest.parties array.
   */
  addDefaultParticpantToCaseRequest$: Observable<CaseRequestViewModel>;

  /**
   * A public method to allow the orchestration to be triggered.
   *
   * @param params The parameters needed to run this orchestration stream.
   */
  addDefaultParticipant(params: IAddParticipantEventParams): void;
}

@Injectable()
export class AddDefaultParticipantOrchestrationService
  implements IAddDefaultParticipantOrchestrationService
{
  /**
   * A subject to use as the trigger for the orchestration.
   */
  private addDefaultParticipant$$ = new Subject<IAddParticipantEventParams>();

  /**
   * The pipeline of orchestration steps needed to add a new default
   * party to the caseRequest.parties array.
   */
  addDefaultParticpantToCaseRequest$: Observable<CaseRequestViewModel> =
    this.addDefaultParticipant$$.pipe(
      withLatestFrom(this.combinedFilingDataService.combinedFilingData$),
      mergeMap(
        ([params, combinedFilingData]: [
          IAddParticipantEventParams,
          CombinedFilingData,
        ]) => {
          const { caseRequest, filing, modeSpec } = combinedFilingData;
          const caseRequestBackup: CaseRequestViewModel = {
            ...caseRequest,
          } as CaseRequestViewModel;

          // The unique identifier that will be used as the participantname downstream.
          const uniqueIdentifier = uuidv4();

          // Defer to the CaseRequestBuilderService to build a new default party and participant
          // into the caseRequest.parties and caseRequest.participant arrays respectively.
          return this.caseRequestBuilderService
            .addDefaultPartyAndParticipant(
              uniqueIdentifier, // The id to use as the participantName
              caseRequest,
              params.participantCommonCategory, //
              modeSpec.participant,
            )
            .pipe(
              switchMap(() => {
                return this.caseRequestUpdateService
                  .optimisticPutOrRestore(
                    filing.id,
                    caseRequest,
                    caseRequestBackup,
                  )
                  .pipe(
                    tap(() => {
                      /**
                       * When adding a participant the only rules we can clear/violate are min/max rules and
                       * any duplicate parties (similar to removing a party)
                       *
                       * Here we prevent other parties from inadvertently being flagged as invalid by passing
                       * in an empty string into the inclusions array. We could have passed in the id of the
                       * newly added party using the uniqueIdentifier (declared above) but we don't actually
                       * want to flag the newly added party as immediately invalid.
                       *
                       * TODO: Set any other flags here to include/exclude cross-party rule checks that adding
                       * a particpant could possibly violate.
                       */
                      this.validatePartiesOrchestrationService.validateParties({
                        includedParticipantNames: [''], // A single empty string will prevent all parties from being validated.
                        includedParticipantCommonCatgeories:
                          params.participantCommonCategory
                            ? [params.participantCommonCategory]
                            : [],
                      });
                    }),
                  );
              }),
            );
        },
      ),
    );

  constructor(
    @Inject(FsxCombinedFilingDataService)
    private readonly combinedFilingDataService: ICombinedFilingDataService,
    @Inject(FsxValidatePartiesOrchestrationService)
    private readonly validatePartiesOrchestrationService: IValidatePartiesOrchestrationService,
    @Inject(FsxCaseRequestBuilderService)
    private readonly caseRequestBuilderService: ICaseRequestBuilderService,
    @Inject(FsxCaseRequestUpdateService)
    private readonly caseRequestUpdateService: ICaseRequestUpdateService,
  ) {}

  /**
   * A public method to allow the orchestration to be triggered.
   *
   * @param params The parameters needed to run this orchestration stream.
   */
  addDefaultParticipant(params: IAddParticipantEventParams): void {
    this.addDefaultParticipant$$.next(params);
  }
}
