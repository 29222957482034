import { Injectable } from '@angular/core';
import Perfume from 'perfume.js';
import {
  IAnalyticsTrackerOptions,
  IPerfumeNavigationTiming,
  IPerfumeNetworkInformation,
} from 'perfume.js/dist/types/types';
import { Subject } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';
import { ApplicationInsightsService } from '../app-insights/application-insights.service';

@Injectable({
  providedIn: 'root',
})
export class PerfumePerfMonitoringService {
  private initAppInsightsCount = 1;

  public constructor(
    private readonly applicationInsightsService: ApplicationInsightsService,
  ) {}

  public initPerfume(): void {
    const unSubscribe$ = new Subject();
    this.applicationInsightsService.appInsights
      .pipe(
        tap((appInsightsInstance) => {
          if (appInsightsInstance) {
            this.runPerfumePerf();
            // unSubscribe$.next();
            // unSubscribe$.complete();
          } else if (this.initAppInsightsCount < 3) {
            this.initAppInsightsCount++;
            setTimeout(() => {
              return this.initPerfume();
            }, 1000 * this.initAppInsightsCount);
          }
          unSubscribe$.next(null);
          unSubscribe$.complete();
        }),
        takeUntil(unSubscribe$),
      )
      .subscribe();
  }

  private runPerfumePerf(): void {
    new Perfume({
      analyticsTracker: (options: IAnalyticsTrackerOptions) => {
        const { metricName } = options;
        const data:
          | number
          | IPerfumeNavigationTiming
          | IPerfumeNetworkInformation = options.data;

        if (data && typeof data !== 'number') {
          const navData: IPerfumeNavigationTiming = Object.assign(
            {},
            data as IPerfumeNavigationTiming,
          );
          if (metricName === 'navigationTiming' && navData.timeToFirstByte) {
            this.applicationInsightsService.logEvent({
              name: 'navigationTiming',
              properties: { data },
            });
          } else if (metricName === 'networkInformation') {
            const networkData: IPerfumeNetworkInformation = Object.assign(
              {},
              data as IPerfumeNetworkInformation,
            );
            if (networkData.effectiveType) {
              this.applicationInsightsService.logEvent({
                name: 'networkInformation',
                properties: { data },
              });
            }
          }
        }

        switch (metricName) {
          case 'fp':
            this.applicationInsightsService.logEvent({
              name: 'firstPaint',
              properties: { duration: data },
            });
            break;
          case 'fcp':
            this.applicationInsightsService.logEvent({
              name: 'firstContentfulPaint',
              properties: { duration: data },
            });
            break;
          case 'fid':
            this.applicationInsightsService.logEvent({
              name: 'firstInputDelay',
              properties: { duration: data },
            });
            break;
          case 'lcp':
            this.applicationInsightsService.logEvent({
              name: 'largestContentfulPaint',
              properties: { duration: data },
            });
            break;
          case 'lcpFinal':
            this.applicationInsightsService.logEvent({
              name: 'largestContentfulPaintFinal',
              properties: { duration: data },
            });
            break;
          case 'cls':
            this.applicationInsightsService.logEvent({
              name: 'cumulativeLayoutShift',
              properties: { duration: data },
            });
            break;
          case 'clsFinal':
            this.applicationInsightsService.logEvent({
              name: 'cumulativeLayoutShiftFinal',
              properties: { duration: data },
            });
            break;
          case 'tbt':
            this.applicationInsightsService.logEvent({
              name: 'totalBlockingTime',
              properties: { duration: data },
            });
            break;
          case 'tbt5S':
            this.applicationInsightsService.logEvent({
              name: 'totalBlockingTime5S',
              properties: { duration: data },
            });
            break;
          case 'tbt10S':
            this.applicationInsightsService.logEvent({
              name: 'totalBlockingTime10S',
              properties: { duration: data },
            });
            break;
          case 'tbtFinal':
            this.applicationInsightsService.logEvent({
              name: 'totalBlockingTimeFinal',
              properties: { duration: data },
            });
            break;
          default:
            this.applicationInsightsService.logEvent({
              name: metricName,
              properties: { duration: data },
            });
            break;
        }
      },
    });
  }
}
