import { Inject, Injectable, InjectionToken } from '@angular/core';
import {
  RequestContactOrganizationViewModel,
  OrganizationSpec,
  IValidatable,
  FilingProfile,
} from '../../../types';
import {
  FsxTextFieldValidationService,
  ITextFieldValidationService,
} from './text-field-validation.service';

export const FsxOrganizationValidationService =
  new InjectionToken<IOrganizationValidationService>(
    'FsxOrganizationValidationService',
  );

export interface IOrganizationValidationService {
  validateOrganization(
    organization: RequestContactOrganizationViewModel | null | undefined,
    spec: OrganizationSpec | null | undefined,
    scope: IValidatable,
    filingProfile: FilingProfile,
  ): boolean | undefined;
}

@Injectable()
export class OrganizationValidationService
  implements IOrganizationValidationService
{
  constructor(
    @Inject(FsxTextFieldValidationService)
    private readonly textFieldValidationService: ITextFieldValidationService,
  ) {}

  public validateOrganization(
    organization: RequestContactOrganizationViewModel | null | undefined,
    spec: OrganizationSpec | null | undefined,
    scope: IValidatable,
    filingProfile: FilingProfile,
  ): boolean | undefined {
    if (!spec || !organization) {
      return;
    }

    return this.textFieldValidationService.validateTextField(
      organization,
      filingProfile,
      spec.title,
      organization.title,
    );
  }
}
