import { Inject, Injectable, InjectionToken } from '@angular/core';
import {
  DocumentsApiService,
  FsxContactApiService,
  FsxDocumentApiService,
  FsxFilingApiService,
  IContactApiService,
  IFilingApiService,
} from '@fsx/fsx-shared';
import { map, Observable, shareReplay, switchMap, zip } from 'rxjs';
import {
  CaseRequestViewModel,
  ContactProfile,
  DocumentInfo,
  Filing,
  FilingModeSpec,
  FilingProfile,
} from '../../types';
import {
  FsxFilingModeSpecLookupService,
  IFilingModeSpecLookupService,
} from './filing-mode-spec-lookup.service';

export const FsxCombinedFilingApiService =
  new InjectionToken<ICombinedFilingApiService>('FsxCombinedFilingApiService');

export interface CombinedFilingData {
  filing: Filing;
  filingProfile: FilingProfile;
  contactProfile: ContactProfile;
  caseRequest: CaseRequestViewModel;
  documentInfos: DocumentInfo[];
  modeSpec: FilingModeSpec;
}

export interface ICombinedFilingApiService {
  getCombinedFilingData(filingId: string): Observable<CombinedFilingData>;
}

@Injectable()
export class CombinedFilingApiService implements ICombinedFilingApiService {
  constructor(
    @Inject(FsxFilingApiService)
    private readonly filingApiService: IFilingApiService,
    @Inject(FsxDocumentApiService)
    private readonly documentsApiService: DocumentsApiService,
    @Inject(FsxFilingModeSpecLookupService)
    private readonly filingModeSpecLookupService: IFilingModeSpecLookupService,
    @Inject(FsxContactApiService)
    private readonly contactApiService: IContactApiService,
  ) {}

  getCombinedFilingData(filingId: string): Observable<CombinedFilingData> {
    return this.filingApiService.getFiling(filingId).pipe(
      switchMap((filing: Filing) => {
        return zip([
          this.filingApiService.getFilingProfile(filing),
          this.contactApiService.getContactProfile(),
          this.filingApiService.getCaseRequest(filingId),
          this.documentsApiService.getDocuments(filingId),
        ]).pipe(
          map(([filingProfile, contactProfile, caseRequest, documentInfos]) => {
            const modeSpec = this.filingModeSpecLookupService.getModeSpec(
              filing.mode,
              filingProfile,
            );
            return {
              filing,
              contactProfile,
              filingProfile,
              caseRequest,
              modeSpec,
              documentInfos,
            };
          }),
        );
      }),
      shareReplay(),
    );
  }
}
