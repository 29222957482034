<!-- START: Footer Submission Progress -->
<div class="footer-progress-container">
  <fsx-filing-submission-progress
    [pollingState$]="pollingState$"
  ></fsx-filing-submission-progress>
</div>
<!-- END: Footer Submission Progress -->

<!-- START: Footer Submit -->
<div
  *ngIf="vm$ | async as vm"
  class="footer-container"
  [ngClass]="{
    'review-and-submit': vm.activeSubTabItem.name === filingSubTabs.REVIEW,
    empty: isEmpty,
  }"
>
  <div
    *ngIf="vm.activeSubTabItem.name === filingSubTabs.REVIEW"
    class="confirmation"
    fxLayout="column"
    fxLayoutAlign="end start"
  >
    <div
      *ngIf="participantsList.length && vm.filing"
      fxFlex="none"
      class="statement"
    >
      I, <b>{{ vm.filing.filer.caption }}</b
      >, am submitting this transaction on behalf of
      <fsx-basic-single-selection
        [readOnly]="vm.pollingState === PollingState.InProgress"
        [width]="'160px'"
        [height]="'24px'"
        [initialValue]="vm.filing.authorizerUserId ?? ''"
        [selectionType]="selectionType.StringSelectionFieldDefinition"
        [inputDropdownOptions]="participantsList"
        [required]="true"
        [showAsterisk]="false"
        [hint]="'Select Authorizer *'"
        [errorPosition]="ErrorPosition.ABOVE"
        (keydown.backspace)="setParticipantSelected('')"
        (keydown.delete)="setParticipantSelected('')"
        (selectedValue)="setParticipantSelected($event)"
      >
      </fsx-basic-single-selection>
      and I authorize the published system fees and final court fees to be
      charged to my account.
    </div>
  </div>
  <div class="footer-row" fxLayout="row" fxLayoutAlign="space-between center">
    <div class="footer-data" fxFlex>
      <div fxLayout="row" fxLayoutAlign="start center">
        <div class="footer-data-col">
          <p>
            <span class="footer-label filing-label">Filing:</span
            >{{ vm.filing.caption }}
          </p>
        </div>
        <div class="spacer">|</div>
        <div class="footer-data-col">
          <p>
            <span class="footer-label">Court: </span
            >{{ vm.filing.courtSummary.caption }}
          </p>
        </div>
        <div class="spacer">|</div>
        <div class="footer-data-col">
          <p>{{ formattedDate }}</p>
        </div>
        <div class="spacer">|</div>
        <div class="footer-data-col">
          <p>{{ formattedTime }}</p>
        </div>
      </div>
    </div>
    <div class="footer-buttons" fxFlex="nogrow">
      <div
        *ngIf="vm.activeSubTabItem.name !== filingSubTabs.REVIEW; else Review"
        fxLayout="row"
        fxLayoutAlign="end center"
      >
        <button
          class="tertiary"
          *ngIf="vm.activeSubTabItem.name !== FILING_SUB_TABS.DETAILS"
          (click)="onBackButtonClicked()"
          (keypress.enter)="onBackButtonClicked()"
        >
          Back
        </button>
        <button
          class="primary"
          (click)="onNextButtonClicked()"
          (keypress.enter)="onNextButtonClicked()"
        >
          Next
        </button>
      </div>
      <ng-template #Review>
        <button class="primary validate" (click)="onSubmitNowClicked()">
          Submit Now
        </button>
      </ng-template>
    </div>
  </div>
</div>
<!-- END: Footer Submit -->
