import {
  Component,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import {
  MatAutocompleteSelectedEvent,
  MatAutocompleteTrigger,
} from '@angular/material/autocomplete';
import {
  DropdownOptionsTypes,
  DropdownOption,
  SelectionFieldType,
  ReferenceResolver,
} from '../../../../types';
import {
  AdditionalFieldSpec,
  AdditionalFieldValue,
  CombinedFilingData,
  DocumentInfo,
  FieldCategory,
} from '@fsx/fsx-shared';
import { debounceTime, map, Observable, startWith } from 'rxjs';

import { FsxBaseComponent } from '../../../base/base.component';
import { FilesUploadedEventParams } from '../../../../../public-api';

export enum ErrorPosition {
  ABOVE,
  BELOW,
}
@Component({
  templateUrl: './single-selection-base.component.html',
  styleUrls: ['./single-selection-base.component.scss'],
})
export class FsxSingleSelectionBaseSelectionComponent extends FsxBaseComponent {
  @ViewChild(MatAutocompleteTrigger, { read: MatAutocompleteTrigger })
  matAutoComplete!: MatAutocompleteTrigger;

  @Input() caption!: string;
  @Input() helpText!: string;
  @Input() hint: string = '';
  @Input() fieldType = FieldCategory.Selection;
  @Input() width!: string;
  @Input() clearable = true;
  @Input() selectionType!: SelectionFieldType;
  @Input() required = false;

  /**
   * a boolean input binding to allow consuming componens to set the enabled/disabled
   * state of this single selection component.
   */
  @Input() disabled = false;

  @Input() visible = true;
  @Input() readOnly = false;
  @Input() height = '63px';

  /**
   * The array of existing AdditionalField value objects to pass onto nested
   * instances of the AdditionalFieldComponent
   */
  @Input() additionalFieldValues: AdditionalFieldValue[] | null = null;

  @Input() combinedFilingData!: CombinedFilingData | null;
  @Input() id: string | number | null = null;
  @Input() showAsterisk = true;
  @Input() errorPosition: ErrorPosition = ErrorPosition.BELOW;
  @Input() dropdownOptionLink: string = '';

  @Output() clearValue = new EventEmitter<void>();
  @Output() selectedValue = new EventEmitter<string>();
  @Output() updateAdditionalFieldValues =
    new EventEmitter<AdditionalFieldValue>();
  @Output() filesUploadedFromAdditionalFieldEvent =
    new EventEmitter<FilesUploadedEventParams>();
  @Output() fileRemovedFromAdditionalFieldEvent =
    new EventEmitter<DocumentInfo>();
  @Output() dropdownOptionLinkClicked = new EventEmitter<void>();

  public dropdownOptions: DropdownOption<DropdownOptionsTypes>[] = [];
  public filteredDropdownOptions: DropdownOption<DropdownOptionsTypes>[] = [];
  public formControl!: FormControl;
  public formControlTemp!: FormControl;
  public filteredOptions$!: Observable<DropdownOption<DropdownOptionsTypes>[]>;
  public selectedSingleOptionCaption = '';
  public focusedElement!: DropdownOption<DropdownOptionsTypes> | null;
  public additionalFieldSpec: AdditionalFieldSpec[] = [];
  public resolver!: ReferenceResolver;

  protected readonly ErrorPosition = ErrorPosition;

  public selectionDisplayFn(
    option: DropdownOption<DropdownOptionsTypes>,
  ): string {
    return option?.caption ?? this.focusedElement?.caption ?? '';
  }

  public clearSelection(): void {
    if (this.formControl?.disabled) {
      return;
    }

    this.formControlTemp?.setValue(null);
    this.formControl?.setValue(null);
    this.formControl.markAsPristine();
    this.selectedSingleOptionCaption = '';
    this.focusedElement = null;
    this.clearValue.emit();
  }

  public getErrorMessage(): string {
    return Object.values({
      ...this.formControlTemp?.errors,
      ...this.formControl?.errors,
    })[0];
  }

  /**
   * a hnadler function for when the user selects a dropdown option
   */
  public optionSelected(event: MatAutocompleteSelectedEvent): void {
    if (event.option.value?.name) {
      // Normal dropdown option selected so default behaviour here...
      this.formControl?.setValue(event.option.value.name);
      this.formControl.markAsDirty();
      this.selectedSingleOptionCaption = event.option.value.caption;
      this.selectedValue.emit(event.option.value.name);
    } else {
      // Custom drodpown option link was clicked so do something
      // custom via the event...
      this.dropdownOptionLinkClicked.emit();
    }
  }

  public onSingleSelectDropdownClose(): void {
    let currentValue: string;
    this.focusedElement = null;

    if (
      this.formControlTemp?.value &&
      typeof this.formControlTemp?.value === 'string'
    ) {
      currentValue = this.formControlTemp?.value;
      const optionFound = this.dropdownOptions.find(
        (option) => option.caption.toLowerCase() === currentValue.toLowerCase(),
      );
      if (!optionFound) {
        this.formControl?.setValue(currentValue);
        this.formControl?.setErrors({ invalid: 'Invalid option selected' });
        this.formControlTemp?.setErrors({ invalid: 'Invalid option selected' });
      } else {
        this.formControlTemp?.setValue(optionFound);
        this.formControl?.setValue(optionFound.name);
        this.formControl.markAsDirty();
        this.selectedValue.emit(optionFound.name);
      }
    }
  }

  public setFocusedElement(): void {
    this.focusedElement = this.matAutoComplete.activeOption?.value;
  }

  public selectOption(): void {
    if (this.focusedElement) {
      this.formControlTemp?.setValue(this.focusedElement);
      this.formControl?.setValue(String(this.focusedElement.name));
      this.formControl.markAsDirty();
      this.selectedSingleOptionCaption = this.focusedElement.caption;
      this.selectedValue.emit(this.focusedElement.name);
    }
  }

  public setFilteredOptions() {
    this.filteredOptions$ = this.formControlTemp?.valueChanges.pipe(
      startWith(''),
      debounceTime(250),
      map((value: string | DropdownOption<DropdownOptionsTypes>) => {
        if (typeof value === 'string') {
          this.filteredDropdownOptions = this.filterDropdownOptions(value);
          return this.filteredDropdownOptions.length
            ? this.filteredDropdownOptions
            : this.dropdownOptions;
        } else {
          return this.dropdownOptions;
        }
      }),
    );
  }

  public filterDropdownOptions(
    value: string,
  ): DropdownOption<DropdownOptionsTypes>[] {
    return this.dropdownOptions.filter((option) => {
      return option.caption.toLowerCase().includes(value.toLowerCase());
    });
  }

  public valueChange(value: Event): void {
    const target = value.target as HTMLInputElement;
    if (!target.value) {
      this.formControl.setErrors(this.formControlTemp.errors);
      this.formControl.setValue(null);
    }
  }

  public additionalFieldValueEventHandler(value: AdditionalFieldValue) {
    this.updateAdditionalFieldValues.emit(value);
  }

  public inputTouched(): void {
    this.formControl.updateValueAndValidity();
  }

  public filesUploadedFromSelectionFieldEventHandler(
    filesUploadedEventParams: FilesUploadedEventParams,
  ) {
    this.filesUploadedFromAdditionalFieldEvent.emit(filesUploadedEventParams);
  }

  public fileRemovedFromSelectionFieldEventHandler(documentInfo: DocumentInfo) {
    this.fileRemovedFromAdditionalFieldEvent.emit(documentInfo);
  }
}
