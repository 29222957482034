<fsx-contact-component
  #contactField
  [initialValues]="
    additionalFieldValue ? additionalFieldValue.contactValues! : []
  "
  [resolver]="resolver"
  [fieldDefinition]="additionalFieldSpec.contactFieldDefinition!"
  (formArrayEmitter)="setContactFormArray($event)"
>
</fsx-contact-component>
