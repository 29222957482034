import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export function maxAllowedArrayLengthValidator(
  maxAllowed: number,
): ValidatorFn {
  return (formArray: AbstractControl): ValidationErrors | null => {
    const isValid = formArray.value.length <= maxAllowed;
    return isValid
      ? null
      : {
          maxAllowedArrayLength: `Must not provide more than ${maxAllowed}`,
        };
  };
}
