<div *ngIf="resolver">
  <fsx-address-component
    #addressField
    [resolver]="resolver"
    [caption]="additionalFieldSpec.caption"
    [fieldDefinition]="
      getAddressFieldDefinition(additionalFieldSpec.addressFieldDefinition!)
    "
    [initialValues]="
      additionalFieldValue && additionalFieldValue.addressValues
        ? additionalFieldValue.addressValues
        : []
    "
    (formArrayEmitter)="setAddressFormArray($event)"
  ></fsx-address-component>
</div>
