import { Inject, Injectable, InjectionToken } from '@angular/core';
import {
  CasePartyViewModel,
  CaseRequestViewModel,
  ParticipantCategory,
  ICaseRequestUpdateService,
  FsxCaseRequestUpdateService,
} from '@fsx/fsx-shared';
import { Observable, Subject, switchMap, tap } from 'rxjs';
import {
  FsxCaseRequestDataService,
  ICaseRequestDataService,
} from '../../filing-editor/services/case-request-data.service';
import {
  FsxValidatePartiesOrchestrationService,
  IValidatePartiesOrchestrationService,
} from '../../filing-editor/services/orchestration/validate-parties-orchestration.service';

export const FsxUpdateParticipantOrchestrationService =
  new InjectionToken<IUpdateParticipantOrchestrationService>(
    'FsxUpdateParticipantOrchestrationService',
  );

export interface IUpdateParticipantParams {
  filingId: string;
  caseRequest: CaseRequestViewModel;

  /**
   * The index of the party in the caseRequest.parties array. This is used
   * in subsequent PATCH requests to the server when attempting updates.
   */
  caseRequestPartyIndex: number;

  /**
   * The new ParticipantCategory to set.
   */
  participantCategory: ParticipantCategory;
}

export interface IUpdateParticipantOrchestrationService {
  /**
   * The pipeline of orchestration steps needed to patch the CaseParty object's
   * participantCategory property.
   */
  updateParticipantOnCaseRequest$: Observable<CaseRequestViewModel>;

  /**
   * A public method to allow the orchestration to be triggered.
   */
  updateParticipant(params: IUpdateParticipantParams): void;
}

@Injectable()
export class UpdateParticipantOrchestrationService
  implements IUpdateParticipantOrchestrationService
{
  /**
   * A subject to use as the trigger for the orchestration.
   */
  private updateParticipant$$ = new Subject<IUpdateParticipantParams>();

  /**
   * The pipeline of orchestration steps needed to patch the CaseParty object's
   * participantCategory property.
   */
  updateParticipantOnCaseRequest$: Observable<CaseRequestViewModel> =
    this.updateParticipant$$.pipe(
      tap((params: IUpdateParticipantParams) => {
        const caseRequestParties: CasePartyViewModel[] = [
          ...(params.caseRequest.parties || []),
        ];
        const caseParty: CasePartyViewModel =
          caseRequestParties[params.caseRequestPartyIndex];
        caseParty.participantCategory = params.participantCategory;
        params.caseRequest = {
          ...params.caseRequest,
          parties: [...caseRequestParties],
        } as CaseRequestViewModel;

        this.caseRequestDataService.setCaseRequestData(params.caseRequest);
      }),
      switchMap((params: IUpdateParticipantParams) => {
        const caseRequestBackup: CaseRequestViewModel = {
          ...params.caseRequest,
        } as CaseRequestViewModel;

        // Make the call to PATCH the participant type.
        return this.caseRequestUpdateService
          .optimisticPatchParticipantTypeOrRestore(
            params.filingId,
            params.caseRequest,
            params.caseRequestPartyIndex,
            params.participantCategory,
            caseRequestBackup,
          )
          .pipe(
            tap(() => {
              /**
               * When updating a participant, 99.9% of the time we will be doing so to clear errors.
               *
               * Here we restrict the validation to just the party beong updated to clear any validation
               * error against it and prevent any other parties from inadvertently being flagged as
               * invalid when they have not been touched.
               *
               * If there are any edge cases where issuing an update to one participant could cause
               * another party to become invalid then we would have to unrestrict the validatio here.
               */
              const caseRequestParties: CasePartyViewModel[] =
                params.caseRequest.parties || [];
              const party: CasePartyViewModel =
                caseRequestParties[params.caseRequestPartyIndex];
              const inclusions = [party.participantName];
              this.validatePartiesOrchestrationService.validateParties({
                includedParticipantNames: inclusions,
              });
            }),
          );
      }),
    );

  constructor(
    @Inject(FsxValidatePartiesOrchestrationService)
    private readonly validatePartiesOrchestrationService: IValidatePartiesOrchestrationService,
    @Inject(FsxCaseRequestUpdateService)
    private readonly caseRequestUpdateService: ICaseRequestUpdateService,
    @Inject(FsxCaseRequestDataService)
    private readonly caseRequestDataService: ICaseRequestDataService,
  ) {}

  /**
   * A public method to allow the orchestration to be triggered.
   */
  updateParticipant(params: IUpdateParticipantParams): void {
    this.updateParticipant$$.next(params);
  }
}
