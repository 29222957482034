import { Inject, Injectable, InjectionToken } from '@angular/core';
import {
  CaseRequestViewModel,
  RequestDocumentViewModel,
  RequestDocumentCreatedParams,
  CombinedFilingData,
  ICaseRequestUpdateService,
  ICaseRequestBuilderService,
  ICreateRequestDocumentService,
  FsxCaseRequestHelperService,
  ICaseRequestHelperService,
  FsxCaseRequestBuilderService,
  FsxCaseRequestUpdateService,
  FsxCreateRequestDocumentService,
} from '@fsx/fsx-shared';
import { IUploadedFile } from '@fsx/ui-components';
import { Subject, Observable, mergeMap, tap, withLatestFrom } from 'rxjs';
import {
  FsxFilingEditorEventService,
  IFilingEditorEventService,
} from '../../filing-editor/services/filing-editor-events.service';
import {
  DocumentAndFile,
  FsxUploadFilesOrchestrationService,
  IUploadFilesOrchestrationService,
  UploadFilesParams,
} from './upload-files-orchestration.service';
import {
  FsxCombinedFilingDataService,
  ICombinedFilingDataService,
} from '../../filing-editor/services/combined-filing-data.service';

export const FsxAddLeadDocumentOrchestrationService =
  new InjectionToken<IAddLeadDocumentOrchestrationService>(
    'FsxAddLeadDocumentOrchestrationService',
  );

export interface AddLeadDocumentParams {
  uploadedFiles: IUploadedFile[];
  leadDocumentIndex?: number;
}

export interface IAddLeadDocumentOrchestrationService {
  /**
   * The pipeline of orchestration steps needed to upload files and create a lead document.
   */
  addLeadDocumentStream$: Observable<CaseRequestViewModel>;

  /**
   * A public method to allow the orchestration to be triggered.
   */
  addLeadDocument(params: AddLeadDocumentParams): void;
}

@Injectable()
export class AddLeadDocumentOrchestrationService
  implements IAddLeadDocumentOrchestrationService
{
  /**
   * A subject to use as the trigger for the orchestration.
   */
  private addLeadDocumentParams$$ = new Subject<AddLeadDocumentParams>();

  /**
   * The pipeline of orchestration steps needed to upload files and create a lead document.
   */
  addLeadDocumentStream$: Observable<CaseRequestViewModel> =
    this.addLeadDocumentParams$$.pipe(
      withLatestFrom(this.combinedFilingDataService.combinedFilingData$),
      mergeMap(
        ([addLeadDocumentParams, combinedFilingData]: [
          AddLeadDocumentParams,
          CombinedFilingData,
        ]) => {
          const { uploadedFiles, leadDocumentIndex } = addLeadDocumentParams;
          const { filing, caseRequest } = combinedFilingData;
          const filingId: string = filing.id;
          const caseRequestBackup = JSON.parse(
            JSON.stringify(caseRequest),
          ) as CaseRequestViewModel;
          const caseRequestDocuments = caseRequest.documents || [];
          const isLeadDocument: boolean =
            this.caseRequestHelperService.getLastDocumentIsLeadDocument(
              caseRequest,
            );
          const cases = caseRequest?.cases;

          if (!cases) {
            throw new Error('No case when adding a document');
          }
          if (cases.length === 0) {
            throw new Error(
              'No cases when adding a document; cases exists but is empty',
            );
          }
          if (!cases[0].caseId) {
            throw new Error('No case id when adding a document');
          }

          return this.createRequestDocumentService
            .bulkCreateRequestDocumentFromFiles(
              uploadedFiles,
              isLeadDocument,
              cases[0]!.caseId!,
            )
            .pipe(
              mergeMap(
                (
                  requestDocumentCreatedParams: RequestDocumentCreatedParams[],
                ) => {
                  const requestDocuments: RequestDocumentViewModel[] =
                    requestDocumentCreatedParams.map((p) => p.requestDocument);
                  const nextDocumentIndex = caseRequestDocuments.length;
                  const documentIndex =
                    leadDocumentIndex != null
                      ? leadDocumentIndex + 1
                      : nextDocumentIndex;
                  return this.caseRequestBuilderService
                    .bulkAddRequestDocument({
                      ...addLeadDocumentParams,
                      caseRequest,
                      requestDocuments,
                      documentIndex,
                    })
                    .pipe(
                      mergeMap(() => {
                        return this.caseRequestUpdateService
                          .optimisticPutOrRestore(
                            filingId,
                            caseRequest,
                            caseRequestBackup,
                          )
                          .pipe(
                            tap(() => {
                              const requestDocument: RequestDocumentViewModel =
                                caseRequestDocuments[documentIndex];
                              this.filingEditorEventService.dispatchRequestDocumentCreatedEvent(
                                {
                                  filingId,
                                  documentIndex,
                                  requestDocument,
                                },
                              );
                            }),
                            tap(() => {
                              const documentsAndFiles: DocumentAndFile[] =
                                requestDocuments.map(
                                  (
                                    requestDocument: RequestDocumentViewModel,
                                    index: number,
                                  ) => {
                                    const uploadedFile: IUploadedFile =
                                      uploadedFiles[index];
                                    return new DocumentAndFile(
                                      requestDocument,
                                      uploadedFile,
                                    );
                                  },
                                );
                              const uploadFilesParams: UploadFilesParams = {
                                documentsAndFiles,
                              };
                              this.uploadFilesOrchestrationService.uploadFiles(
                                uploadFilesParams,
                              );
                              // No need to validate documents here.
                              // The call to uploadFiles() will trigger validation.
                            }),
                          );
                      }),
                    );
                },
              ),
            );
        },
      ),
    );

  constructor(
    @Inject(FsxCaseRequestBuilderService)
    private readonly caseRequestBuilderService: ICaseRequestBuilderService,
    @Inject(FsxCaseRequestUpdateService)
    private readonly caseRequestUpdateService: ICaseRequestUpdateService,
    @Inject(FsxCaseRequestHelperService)
    private readonly caseRequestHelperService: ICaseRequestHelperService,
    @Inject(FsxCombinedFilingDataService)
    private readonly combinedFilingDataService: ICombinedFilingDataService,
    @Inject(FsxCreateRequestDocumentService)
    private readonly createRequestDocumentService: ICreateRequestDocumentService,
    @Inject(FsxFilingEditorEventService)
    private readonly filingEditorEventService: IFilingEditorEventService,
    @Inject(FsxUploadFilesOrchestrationService)
    private readonly uploadFilesOrchestrationService: IUploadFilesOrchestrationService,
  ) {}

  /**
   * A public method to allow the orchestration to be triggered.
   */
  addLeadDocument(params: AddLeadDocumentParams): void {
    this.addLeadDocumentParams$$.next(params);
  }
}
