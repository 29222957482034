<div
  class="fsx-boolean-component"
  [ngStyle]="{
    width: width,
    height: height,
    display: formControl.fieldDefinition.visible ? 'block' : 'none',
  }"
>
  <div class="checkbox-container">
    <mat-checkbox
      #checkbox
      color="primary"
      [ngClass]="{
        readonly: formControl.fieldDefinition.readOnly,
      }"
      [formControl]="formControl"
      [checked]="formControl.value"
    ></mat-checkbox>
    <label
      [ngClass]="{
        disabled: formControl.disabled || formControl.fieldDefinition.readOnly,
      }"
      (click)="clickCheckbox()"
      >{{ caption }}
    </label>

    <label class="tooltip-container">
      <mat-icon *ngIf="helpText" [matTooltip]="helpText">info_outline</mat-icon>
    </label>
  </div>

  <p class="error-message" *ngIf="formControl.invalid && formControl.touched">
    {{ getErrorMessage() }}
  </p>

  <fsx-additional-fields-component
    *ngFor="let addlFieldSpec of additionalFieldSpec; let index = index"
    [additionalFieldSpec]="addlFieldSpec"
    [resolver]="resolver"
    [initialValues]="
      resolver.getAdditionalFieldInitialValue(
        addlFieldSpec,
        additionalFieldValues
      )
    "
    [combinedFilingData]="combinedFilingData"
    (additionalFieldValueEvent)="additionalFieldValueEventHandler($event)"
  ></fsx-additional-fields-component>
</div>
