import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import {
  Filing,
  FilingState,
  FsxFilingTabsService,
  IFilingTabsService,
  TRANSACTIONS_ACTIVITY,
  TRANSACTIONS_EDITOR,
} from '@fsx/fsx-shared';
import { map, Observable, of, Subject } from 'rxjs';
import { RECENT_TRANSACTION_ID } from '../../types/ui-state/constants';

@Injectable({
  providedIn: 'root',
})
export class OpenFilingService {
  private filingOpened$$ = new Subject<Filing>();
  public filingOpened$ = this.filingOpened$$.asObservable();

  public constructor(
    @Inject(FsxFilingTabsService)
    private readonly filingTabsService: IFilingTabsService,
    private readonly router: Router,
  ) {}

  openFiling(filing: Filing): Observable<Filing> {
    // Guard clause to prevent none draft transactions from being opened in the filing editpr
    if (filing && filing.status !== FilingState.Draft) {
      this.router.navigate([TRANSACTIONS_ACTIVITY, filing.id]);
      return of(filing);
    }

    // Happy path, continue to add a tab and open draft transaction in the filing editor
    return this.filingTabsService.addTab(filing).pipe(
      map(() => {
        localStorage.setItem(RECENT_TRANSACTION_ID, filing.id);
        this.router.navigate([TRANSACTIONS_EDITOR, filing.id]);
        return filing;
      }),
    );
  }
}
