import { Component, Inject, OnDestroy } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import {
  FsxCombinedFilingDataService,
  ICombinedFilingDataService,
} from '../../filing-editor/services/combined-filing-data.service';
import {
  FsxPanel,
  FsxPanelService,
  IPanelService,
} from '../../shared/services/panel.service';
import { ContactFormConfig } from '../contact-form/contact-form.component';
import { ContactFormGroup, ContactType } from '@fsx/fsx-shared';
import { ContactFormPanelHeaderConfig } from '../contact-form-panel-header/contact-form-panel-header.component';
import { FsxPanelConfig } from '../../shared/components/fsx-panel/fsx-panel.component';
import { FormGroup, FormControl } from '@angular/forms';
import {
  AddOrUpdateContactOrchestrationService,
  FsxAddOrUpdateContactOrchestrationService,
  IAddOrUpdateContactOrchestrationService,
} from '../../shared/services/add-or-update-contact-orchestration.service';
import { Subject, takeUntil } from 'rxjs';

/**
 * A very simple container component that contains an instance of the ContactsFormComponent.
 * This allows us to open the contacts form in a panel, and keep the contacts form decoupled
 * from all things panel related.
 */
@Component({
  selector: 'fsx-contact-form-panel',
  templateUrl: 'contact-form-panel.component.html',
  styleUrls: ['./contact-form-panel.component.scss'],
  providers: [
    {
      provide: FsxAddOrUpdateContactOrchestrationService,
      useClass: AddOrUpdateContactOrchestrationService,
    },
  ],
})
export class ContactFormPanelComponent implements FsxPanel, OnDestroy {
  /**
   * A subject to trigger the tearing down of the subscriptions when the component is destroyed.
   */
  private destroy$: Subject<void> = new Subject();

  /**
   * The form group for the contact form to pass to the ContactFormComponent. We
   * declare this here and instantiate it in the constructor to allow this parent
   * panel component to take responsibility for triggering form submission.
   */
  public contactFormGroup!: FormGroup<ContactFormGroup>;

  /**
   * The config object to pass to the FsxPanelComponent to override styles.
   */
  panelConfig: FsxPanelConfig = {
    /**
     * Override the defaukt header backgriund, for contacts panel we want grey-blue background.
     */
    headerBackgroundClass: 'grey-blue',

    /**
     * Explicitly set the content section indentation to 'indented' which will ensure
     * that the nested form highlight background appears inline with the header text.
     */
    contentIndentClass: 'indented',

    /**
     * Attach the loading indicator to the AddOrUpdateContactOrchestrationService's
     * isOrchestrationInProgress$ stream.
     */
    showLoadingIndicator$:
      this.addOrUpdateContactOrchestrationService.isOrchestrationInProgress$,
  };

  /**
   * The config object to pass to the ContactFormPanelHeaderComponent.
   */
  headerConfig: ContactFormPanelHeaderConfig = {
    formMode: this.data.formMode,
    contact: this.data.contact,
  };

  /**
   * The boolean value indicating whether we can save the form or not.
   * Used in the footer to enable/disable the Save button.
   */
  canSaveForm = false;

  /**
   * @param data The contact form config properties to pass into the contacts form component.
   * @param combinedFilingDataService The combinedFilingData to pass on to the contact form.
   * @param panelService The panel service, which allows us to close the current panel.
   */
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: ContactFormConfig,
    @Inject(FsxCombinedFilingDataService)
    public readonly combinedFilingDataService: ICombinedFilingDataService,
    @Inject(FsxPanelService) private readonly panelService: IPanelService,
    @Inject(FsxAddOrUpdateContactOrchestrationService)
    private readonly addOrUpdateContactOrchestrationService: IAddOrUpdateContactOrchestrationService,
  ) {
    // Instantiate the contact form group using the passed in config object.
    const contactType = data.contact?.type || ContactType.Person;
    this.contactFormGroup = new FormGroup<ContactFormGroup>({
      contactType: new FormControl(contactType, { nonNullable: true }),
    });

    // Subscribe to the add or update contact orchestration stream. This allows us
    // to add/update the contact from handler functions below.
    this.addOrUpdateContactOrchestrationService.addOrUpdateContactOrchestration$
      .pipe(takeUntil(this.destroy$))
      .subscribe();
  }

  /**
   * The lifecycle hook where we teardown inner subscriptions with the component.
   */
  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  /**
   * A handler function for the ContactFormComponent's contactTypeChanged event. In the form
   * the value is toggled when in add new mode to allow the user to add a Person or an
   * Organization. Here we re-set the headerConfig object which in turn triggers an update in
   * the ContactFormPanelHeaderComponent changing the text and icon.
   * @param contactType
   */
  contactTypeChangedHandler(contactType: ContactType): void {
    this.headerConfig = {
      ...this.headerConfig,
      contactType,
    };
  }

  /**
   * A handler function for the FsxPanelComponent's closePanelEvent event. More often than
   * not we just want to close the current dialog; that's all we need to do here right now.
   */
  closePanelEventHandler(): void {
    this.panelService.closeCurrentDialog();
  }

  /**
   * A handler function for the FsxPanelComponent's defaultPanelEvent, which is raised from
   * the panel when the user presses the enter key. In most cases this will trigger
   * the same orchestration as one of the buttons we pass in. Here we trigger the add/update
   * contact orchestration; the same as the save button.
   */
  defaultPanelEventHandler(): void {
    this.addOrUpdateContact();
  }

  /**
   * A handler function for the Save button's click event.
   */
  saveButtonClickHandler(): void {
    this.addOrUpdateContact();
  }

  private addOrUpdateContact(): void {
    this.addOrUpdateContactOrchestrationService.addOrUpdateContact({
      formMode: this.data.formMode,
      contactFormGroup: this.contactFormGroup,
    });
  }

  /**
   * A handler function for the Cancel button's click event.
   */
  onCancelButtonClicked(): void {
    this.panelService.closeCurrentDialog();
  }

  /**
   * A handler function for the contact form's isFormValidEvent.
   * We use this here to determine whether to enable/disable the save button.
   *
   * @param isValid The validity of the contact form.
   */
  isFormValidEventHandler(isValid: boolean): void {
    this.canSaveForm = isValid;
  }
}
