import {
  Component,
  EventEmitter,
  Inject,
  Input,
  OnChanges,
  OnInit,
  Output,
} from '@angular/core';
import {
  AdditionalFieldValue,
  CasePartyViewModel,
  CombinedFilingData,
  FieldCategory,
  IdentificationCommonCategory,
  ParticipantCategory,
  ParticipantCommonCategory,
  ParticipantSpec,
  RequestParticipantRepresentationViewModel,
} from '@fsx/fsx-shared';
import {
  DropdownOption,
  FormControlWithoutModel,
  RepresentationGridRow,
  SelectionFieldType,
} from '@fsx/ui-components';
import { PartiesGridRow } from '../parties-grid/parties-grid.model';
import { FsxReferenceResolver } from '../../shared/resolvers/list-reference.resolver';
import {
  EditRepresentationEventParams,
  ParticipantViewMinMaxValues,
} from '../parties-grid/parties-grid.component';
import {
  FsxUpdatePartyOrchestrationService,
  IUpdatePartyOrchestrationService,
} from '../orchestration-services/update-party-orchestration.service';

/**
 * An interface defining the config object for each representation grid item.
 */
export interface RepresentationGridItemConfig {
  /**
   * The array of PartiicipantSpec objects which we need to derive the "Role" dropdown options
   * on each representation grid row.
   */
  attorneySpecs: ParticipantSpec[];
}

export interface RemoveRepresentationEventParams {
  representationToRemove: RequestParticipantRepresentationViewModel;
  partyToRemoveFrom: CasePartyViewModel;
}

export interface UpdateRepresentationEventParams {
  attorneyParticipantSpec: ParticipantSpec | null;
  attorneyParticipantCategory: ParticipantCategory | null;

  /**
   * The parent participantName, used to lookup the CaseParty object when we come to do the update.
   */
  participantName: string;

  representation: RequestParticipantRepresentationViewModel;
  additionalFields: AdditionalFieldValue[] | null;
}

@Component({
  selector: 'fsx-representation-grid-item',
  templateUrl: './representation-grid-item.component.html',
  styleUrls: ['./representation-grid-item.component.scss'],
})
export class RepresentationGridItemComponent implements OnInit, OnChanges {
  /**
   * The config object for the representation grid.
   */
  @Input() repGridItemConfig!: RepresentationGridItemConfig;

  @Input() combinedFilingData!: CombinedFilingData;
  @Input() rowIndex!: number;
  @Input() repGridRow!: RepresentationGridRow;
  @Input() partyGridRow!: PartiesGridRow;
  @Input() expandedRowIndex!: number | null;
  @Input() participantSpecs!: ParticipantSpec[];
  @Input() resolver!: FsxReferenceResolver;
  @Input() participantsMap!: Map<string, ParticipantViewMinMaxValues>;

  @Output() toggleExpandRowEvent = new EventEmitter<number>();
  @Output() removeRepresentationEvent =
    new EventEmitter<RemoveRepresentationEventParams>();
  @Output() updateRepresentationEvent =
    new EventEmitter<UpdateRepresentationEventParams>();
  @Output() editRepresentationEventHandler =
    new EventEmitter<EditRepresentationEventParams>();

  /**
   * The array of dropdown options to populate the "Role" dropdown list.
   * (derived from passed in array of ParticipantSpecs)
   */
  attorneyTypeDropdownOptions: DropdownOption<void>[] = [];

  /**
   * The ParticipantSpec object that corresponds to the selected attorney
   * type in the "Role" dropdown list. We need this to pass onto the
   * ParticipantTable which uses it to lookup the AddressSpec.
   */
  repParticipantSpec!: ParticipantSpec;

  public attorneysListFormControl!: FormControlWithoutModel;
  public fieldType = FieldCategory;
  public selectionType = SelectionFieldType.StringSelectionFieldDefinition;
  public additionalFieldValues: AdditionalFieldValue[] = [];
  public isReadOnly: boolean = false;

  constructor(
    @Inject(FsxUpdatePartyOrchestrationService)
    private readonly updatePartyOrchestrationService: IUpdatePartyOrchestrationService,
  ) {}

  ngOnChanges() {
    // Lookup the ParticipantSpec object for the current row's selected attorney type in the "Role" dropdown.
    // TODO: We could (perhaps should) derive this upfront and pass with the view model instead.
    if (this.repGridItemConfig.attorneySpecs) {
      this.repParticipantSpec = this.repGridItemConfig.attorneySpecs.find(
        (pSpec: ParticipantSpec) => {
          return (
            pSpec.participantCategory.name ===
            this.repGridRow.representation?.participantCategory?.name
          );
        },
      )!;
    }

    this.checkSetReadOnly();
  }

  ngOnInit(): void {
    this.setAttorneyTypeDropdownOptions(this.repGridItemConfig.attorneySpecs);
    if (this.repGridRow.representation.additionalFieldValues) {
      this.additionalFieldValues =
        this.repGridRow.representation.additionalFieldValues;
    }
    this.checkSetReadOnly();
  }

  public setBasicAttorneyFormControl(controls: FormControlWithoutModel) {
    this.attorneysListFormControl = controls;
  }

  /**
   * A function to derive the "Role" dropdown options from a given array of ParticipantSpec objects.
   *
   * @param participantSpecs The Particpant Specs from which to derive the dropdown options.
   */
  private setAttorneyTypeDropdownOptions(
    participantSpecs: ParticipantSpec[],
  ): void {
    // Derive the dropdown options from the array of ParticipantSpec objects.
    this.attorneyTypeDropdownOptions = participantSpecs.map(
      (pSpec: ParticipantSpec) => {
        return { ...pSpec.participantCategory, selected: false };
      },
    );
  }

  onToggleExpandRow(event: Event) {
    event.stopPropagation();
    this.toggleExpandRowEvent.emit(this.rowIndex);
  }

  getBarNumber(repGridRow: RepresentationGridRow): string {
    return (
      repGridRow.participant.identifications.find(
        (id) =>
          id.category.commonCategory === IdentificationCommonCategory.BarNumber,
      )?.identificationKey ?? ''
    );
  }

  onAttorneysListClicked(event: Event) {
    event.stopPropagation();
  }

  /**
   * A method to handle the setting of AdditionalFieldValue objects for representation.
   *
   * @param additionalFieldValues The updated additionalFieldValues collection.
   * @param repGridRow The RepresentationGridRow of the RequestParticipantRepresentation to which the additionalFieldValues are being set.
   */
  public onAdditionalFieldValuesEmitted(
    additionalFieldValues: AdditionalFieldValue[],
    repGridRow: RepresentationGridRow,
  ) {
    // Get the party representation that we want to iterate over.
    const partyRepresentation: RequestParticipantRepresentationViewModel[] =
      this.partyGridRow.party.representation || [];

    // Update the party represnetation here...
    const updatedRepresentation: RequestParticipantRepresentationViewModel[] =
      partyRepresentation.map(
        (rep: RequestParticipantRepresentationViewModel) => {
          if (rep.participantName === repGridRow.participant.name) {
            // Update the RequestParticipantRepresentation object with the array of updated AdditionalFieldValue objects.
            rep.additionalFieldValues = additionalFieldValues;
          }
          return rep;
        },
      );

    // Create the partial CaseParty object
    const partialCaseParty: Partial<CasePartyViewModel> = {
      ...this.partyGridRow.party,
      representation: [...updatedRepresentation],
    };

    // Apply the update through the orchestration service.
    this.updatePartyOrchestrationService.updateParty({
      caseParty: this.partyGridRow.party,
      partialCaseParty,
    });
  }

  onAttorneyTypeSelected(params: {
    value: string;
    participantName: string;
    representation: RequestParticipantRepresentationViewModel;
    additionalFields: AdditionalFieldValue[] | null;
  }) {
    // Guard cause to prevent infinite loop (of re-setting and re-emitting the initial value)
    const isSameValue =
      params.representation.participantCategory?.name === params.value;

    if (isSameValue) {
      return;
    }

    const attorneyParticipantSpec: ParticipantSpec =
      this.repGridItemConfig.attorneySpecs.find((pSpec: ParticipantSpec) => {
        return pSpec.participantCategory.name === params.value;
      })!;

    const selectedOption = this.attorneyTypeDropdownOptions.find(
      (option) => option.name === params.value,
    );

    if (selectedOption) {
      const attorneyParticipantCategory: ParticipantCategory = {
        name: selectedOption.name,
        caption: selectedOption.caption,
        commonCategory:
          selectedOption.commonCategory as ParticipantCommonCategory,
      };

      params.representation.participantCategory = attorneyParticipantCategory;

      this.updateRepresentationEvent.emit({
        ...params,
        attorneyParticipantSpec,
        attorneyParticipantCategory,
      });
    }
  }

  onRemoveRepresentationClicked(
    event: Event,
    rep: RequestParticipantRepresentationViewModel,
    party: CasePartyViewModel,
  ) {
    event.stopPropagation();
    this.removeRepresentationEvent.emit({
      partyToRemoveFrom: party,
      representationToRemove: rep,
    });
  }

  onRepresentationClicked(): void {
    this.editRepresentationEventHandler.emit({
      representation: this.repGridRow.representation,
      participant: this.repGridRow.participant,
      participantCategory: this.repGridRow.representation.participantCategory,
    });
  }

  private checkSetReadOnly(): void {
    if (
      !!this.repGridRow &&
      !!this.repGridRow.representation &&
      !!this.participantsMap
    ) {
      if (
        this.participantsMap.has(this.repGridRow.representation.participantName)
      ) {
        this.isReadOnly = this.participantsMap.get(
          this.repGridRow.representation.participantName,
        )?.isReadOnly as boolean;
      }
    }
  }
}
