import {
  DocumentInfo,
  RequestDocumentViewModel,
  ValidationDetail,
  Severity,
} from '@fsx/fsx-shared';

export class RowError {
  rowIdentifier: string;
  message: ValidationDetail;

  constructor(rowIdentifier: string, error: ValidationDetail) {
    this.rowIdentifier = rowIdentifier;
    this.message = {
      code: error.code,
      caption: error.caption,
      severity: Severity.Error,
    };
  }
}

// tslint:disable-next-line: max-classes-per-file
export class DocumentsGridRow {
  rowIndex: number;
  requestDocument: RequestDocumentViewModel;
  documentInfo: DocumentInfo;
  expanded: boolean;
  errors: RowError[];

  constructor(
    rowIndex: number,
    document: RequestDocumentViewModel,
    documentInfo: DocumentInfo,
    expanded: boolean,
    errors: RowError[],
  ) {
    this.rowIndex = rowIndex;
    this.requestDocument = document;
    this.documentInfo = documentInfo;
    this.expanded = expanded;
    this.errors = errors;
  }
}
