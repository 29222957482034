import {
  APP_INITIALIZER,
  ErrorHandler,
  inject,
  InjectionToken,
  NgModule,
  Optional,
  SkipSelf,
} from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import {
  CaseRequestHelperService,
  CombinedFilingApiService,
  ContactApiService,
  DocumentsApiService,
  EnvConfig,
  FilingApiService,
  FilingTabsService,
  FsxCaseRequestHelperService,
  FsxCombinedFilingApiService,
  FsxContactApiService,
  FsxDocumentApiService,
  FsxFilingApiService,
  FsxFilingTabsService,
} from '@fsx/fsx-shared';
import {
  ENV_CONFIG,
  AppConfig,
  ENV_CONFIG_VALUE,
  FSXInterceptor,
  ReportingErrorHandlerService,
} from '@fsx/fsx-shared';
import { OAuthModule } from 'angular-oauth2-oidc';
import { distinctUntilChanged, Observable, pluck } from 'rxjs';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {
  MatTooltipDefaultOptions,
  MAT_TOOLTIP_DEFAULT_OPTIONS,
} from '@angular/material/tooltip';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import {
  MAT_RIPPLE_GLOBAL_OPTIONS,
  RippleGlobalOptions,
} from '@angular/material/core';
import { MAT_DIALOG_DEFAULT_OPTIONS } from '@angular/material/dialog';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { MAT_TABS_CONFIG } from '@angular/material/tabs';
import { ActivatedRoute, RouteReuseStrategy } from '@angular/router';
import { environment } from '../environments/environment';
import { SharedModule } from '@fsx/fsx-shared';
import { CustomRouteReuseStrategy } from './custom-route-reuse-strategy';
import { PartiesModule } from './parties/parties.module';
import { DocumentsModule } from './documents/documents.module';
import { ReviewModule } from './review/review.module';
import { HomeModule } from './home/home.module';
import { HelpModule } from './help/help.module';
import { ValidationModule } from './validation/validation.module';
import { FilingEditorModule } from './filing-editor/filing-editor.module';
import { TransactionActivityModule } from './transaction-activity/transaction-activity.module';
import {
  FsxOpenFilingOrchestrationService,
  OpenFilingOrchestrationService,
} from './transactions/services/orchestration/open-filing-orchestration.service';
import {
  FsxNewFilingOrchestrationService,
  NewFilingOrchestrationService,
} from './transactions/services/orchestration/new-filing-orchestration.service';
import {
  FilingModeSpecLookupService,
  FsxFilingModeSpecLookupService,
} from 'projects/libs/shared/src/lib/services/filings/filing-mode-spec-lookup.service';
import { LoadingSpinnerComponent } from '../../../../libs/components/src/lib/components/loading-spinner/loading-spinner.component';
import {
  FsxIsLoadingStateService,
  IsLoadingStateService,
} from './transactions/services/ui-state/is-loading-state.service';
import {
  FsxUserApiService,
  UserApiService,
} from 'projects/libs/shared/src/lib/services/users/user-api.service';
import {
  FsxUserDataService,
  UserDataService,
} from './filing-editor/services/user-data.service';
import { ShowHideNavStateService } from './show-hide-nav-state.service';

export function loadConfig(
  appConfigService: AppConfig,
): () => Observable<string | Error> {
  /**
   * Inject the show hide nav state service.
   */
  const showHideNavStateService = inject(ShowHideNavStateService);
  /**
   * Set the state here.
   */
  showHideNavStateService.showNav(window.location !== window.parent.location);

  return () => {
    return appConfigService.load$(environment.CONFIG_FILE);
  };
}

const globalRippleConfig: RippleGlobalOptions = {
  disabled: true,
  animation: {
    enterDuration: 300,
    exitDuration: 0,
  },
};

const toolTipDelay: Partial<MatTooltipDefaultOptions> = {
  hideDelay: 0,
  // touchendHideDelay: 0,
  showDelay: 2000,
};

export type TimespanProvider = Observable<string>;
export const TIMESPAN = new InjectionToken(
  'Subscribe to timespan query param',
  {
    factory() {
      const activatedRoute = inject(ActivatedRoute);
      return activatedRoute.queryParams.pipe(
        pluck('timespan'),
        distinctUntilChanged(),
      );
    },
  },
);

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    OAuthModule.forRoot(),
    AppRoutingModule,
    SharedModule,
    HomeModule,
    TransactionActivityModule,
    FilingEditorModule,
    PartiesModule,
    DocumentsModule,
    ReviewModule,
    HelpModule,
    ValidationModule,
    LoadingSpinnerComponent,
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: FSXInterceptor, multi: true },
    { provide: ErrorHandler, useClass: ReportingErrorHandlerService },
    { provide: MAT_RIPPLE_GLOBAL_OPTIONS, useValue: globalRippleConfig },
    { provide: MAT_TABS_CONFIG, useValue: { animationDuration: 0 } },
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: {
        appearance: 'outline',
        floatLabel: 'always',
      },
    },
    {
      provide: MAT_TOOLTIP_DEFAULT_OPTIONS,
      useValue: toolTipDelay,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: loadConfig,
      deps: [AppConfig],
      multi: true,
    },
    {
      provide: ENV_CONFIG,
      useFactory: (appConfig: AppConfig): Observable<EnvConfig> =>
        appConfig.envConfig,
      deps: [AppConfig],
    },
    {
      provide: ENV_CONFIG_VALUE,
      useFactory: (appConfig: AppConfig): EnvConfig => appConfig.envConfigAsObj,
      deps: [AppConfig],
    },
    {
      provide: MAT_DIALOG_DEFAULT_OPTIONS,
      useValue: {
        width: '400px',
      },
    },
    {
      provide: RouteReuseStrategy,
      useClass: CustomRouteReuseStrategy,
    },
    {
      provide: FsxOpenFilingOrchestrationService,
      useClass: OpenFilingOrchestrationService,
    },
    {
      provide: FsxNewFilingOrchestrationService,
      useClass: NewFilingOrchestrationService,
    },
    {
      provide: FsxCombinedFilingApiService,
      useClass: CombinedFilingApiService,
    },
    {
      provide: FsxContactApiService,
      useClass: ContactApiService,
    },
    {
      provide: FsxFilingApiService,
      useClass: FilingApiService,
    },
    {
      provide: FsxDocumentApiService,
      useClass: DocumentsApiService,
    },
    {
      provide: FsxFilingTabsService,
      useClass: FilingTabsService,
    },
    {
      provide: FsxFilingModeSpecLookupService,
      useClass: FilingModeSpecLookupService,
    },
    {
      provide: FsxCaseRequestHelperService,
      useClass: CaseRequestHelperService,
    },
    {
      provide: FsxIsLoadingStateService,
      useClass: IsLoadingStateService,
    },
    {
      provide: FsxUserApiService,
      useClass: UserApiService,
    },
    {
      provide: FsxUserDataService,
      useClass: UserDataService,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor(@Optional() @SkipSelf() parentModule: AppModule) {
    if (parentModule) {
      throw new Error(
        'AppModule is already loaded. Import it in the AppModule only',
      );
    }
  }
}
