import { Inject, Injectable, InjectionToken } from '@angular/core';
import {
  AddressViewModel,
  AddressSpec,
  IValidatable,
  AddressProfileSpec,
  FilingProfile,
} from '../../../types';
import {
  FsxSelectionFieldValidationService,
  ISelectionFieldValidationService,
} from './selection-field-validation.service';
import {
  FsxTextFieldValidationService,
  ITextFieldValidationService,
} from './text-field-validation.service';
import {
  FsxValidationErrorsService,
  IValidationErrorsService,
  ParticipantFormSectionName,
} from 'projects/apps/fsx-ui/src/app/filing-editor/services/validation-errors.service';
import { ValidationGroupConstants } from 'projects/apps/fsx-ui/src/app/filing-editor/services/validation-group-errors.service';

export const FsxAddressValidationService =
  new InjectionToken<IAddressValidationService>('FsxAddressValidationService');

export interface IAddressValidationService {
  validateAddresses(
    addresses: AddressViewModel[] | null | undefined,
    spec: AddressSpec | null | undefined,
    scope: IValidatable,
    filingProfile: FilingProfile,
    parentEntityId?: string,
  ): boolean;

  validateAddress(
    address: AddressViewModel | null | undefined,
    spec: AddressSpec | null | undefined,
    scope: IValidatable,
    filingProfile: FilingProfile,
    parentEntityId?: string,
  ): boolean;
}

@Injectable()
export class AddressValidationService implements IAddressValidationService {
  constructor(
    @Inject(FsxSelectionFieldValidationService)
    private readonly selectionFieldValidationService: ISelectionFieldValidationService,
    @Inject(FsxTextFieldValidationService)
    private readonly textFieldValidationService: ITextFieldValidationService,
    @Inject(FsxValidationErrorsService)
    private readonly validationErrorsService: IValidationErrorsService,
  ) {}

  validateAddresses(
    addresses: AddressViewModel[] | null | undefined,
    spec: AddressSpec | null | undefined,
    scope: IValidatable,
    filingProfile: FilingProfile,
    parentEntityId?: string,
  ): boolean {
    let result = true;

    if (!spec) {
      return true;
    }

    if (!addresses) {
      addresses = [];
    }

    const minAddressesErrorCode = 'minAddressesErrorCode';
    if (addresses.length < spec.minRequired) {
      result = false;
      scope.isValid = false;
      this.validationErrorsService.addValidationError({
        errorCode: minAddressesErrorCode,
        errorMessage: `Must provide at least ${spec.minRequired}`,
        group: ValidationGroupConstants.parties,
        metadata: {
          entityId: parentEntityId,
          parentEntityId: parentEntityId,
          formSectionName: 'Address',
        },
      });
    } else {
      this.validationErrorsService.removeValidationError(
        minAddressesErrorCode,
        parentEntityId,
      );
    }

    const maxAddressesErrorCode = 'maxAddressesErrorCode';
    if (addresses.length > spec.maxAllowed) {
      scope.isValid = false;
      result = false;
      this.validationErrorsService.addValidationError({
        errorCode: maxAddressesErrorCode,
        errorMessage: `Must not provide more than ${spec.maxAllowed}`,
        group: ValidationGroupConstants.parties,
        metadata: {
          entityId: parentEntityId,
          parentEntityId: parentEntityId,
          formSectionName: 'Address',
        },
      });
    } else {
      this.validationErrorsService.removeValidationError(
        maxAddressesErrorCode,
        parentEntityId,
      );
    }

    for (let address of addresses) {
      if (
        !this.validateAddress(
          address,
          spec,
          scope,
          filingProfile,
          parentEntityId,
        )
      ) {
        scope.isValid = false;
        result = false;
      }
    }

    return result;
  }

  validateAddress(
    address: AddressViewModel | null | undefined,
    spec: AddressSpec | null | undefined,
    scope: IValidatable,
    filingProfile: FilingProfile,
    parentEntityId?: string,
  ): boolean {
    let result = true;

    if (!spec || !address) {
      return true;
    }

    address.isValid = true;

    if (
      !this.selectionFieldValidationService.validateSelectionField(
        address?.category?.name,
        spec.category,
        scope,
        filingProfile,
      )
    ) {
      address.isValid = false;
      result = false;
    }

    if (
      !this.selectionFieldValidationService.validateSelectionField(
        address?.country,
        spec.country,
        scope,
        filingProfile,
      )
    ) {
      address.isValid = false;
      result = false;
    }

    const countrySpec = this.getAddressProfileSpec(
      spec,
      filingProfile,
      address.country,
    );

    if (countrySpec) {
      if (
        !this.textFieldValidationService.validateTextField(
          address,
          filingProfile,
          countrySpec.addressLine1,
          address.addressLine1,
        )
      ) {
        scope.isValid = false;
        result = false;
      }
      if (
        !this.textFieldValidationService.validateTextField(
          address,
          filingProfile,
          countrySpec.addressLine2,
          address.addressLine2,
        )
      ) {
        scope.isValid = false;
        result = false;
      }
      if (
        !this.selectionFieldValidationService.validateSelectionField(
          address.administrativeArea,
          countrySpec.administrativeArea,
          address,
          filingProfile,
        )
      ) {
        scope.isValid = false;
        result = false;
      }
      if (
        !this.textFieldValidationService.validateTextField(
          address,
          filingProfile,
          countrySpec.locality,
          address.locality,
        )
      ) {
        scope.isValid = false;
        result = false;
      }
      if (
        !this.textFieldValidationService.validateTextField(
          address,
          filingProfile,
          countrySpec.postalCode,
          address.postalCode,
        )
      ) {
        scope.isValid = false;
        result = false;
      }
    }

    return result;
  }

  private getAddressProfileSpec(
    spec: AddressSpec,
    profile: FilingProfile,
    countryName: string,
  ): AddressProfileSpec | null | undefined {
    if (!spec) {
      return null;
    }

    var addressProfileName = !spec.addressProfileName
      ? profile.defaultAddressProfileName
      : spec.addressProfileName;
    var addressProfile = profile.addressProfiles.find(
      (address) => address.name === addressProfileName,
    );

    if (!addressProfile) {
      console.warn(
        `Invalid filing profile, cannot find address profile "${addressProfileName}"`,
      );
    }

    if (!countryName) {
      return addressProfile?.spec;
    }

    const countrySpec = addressProfile?.countrySpecs.find(
      (p) => p.countryName === countryName,
    );

    if (countrySpec) {
      return countrySpec.spec;
    }

    return addressProfile?.spec;
  }
}
