import { Component, Input, OnChanges, ViewChild } from '@angular/core';
import { MatCheckbox } from '@angular/material/checkbox';
import { FormControlWithModel } from '../../models';
import { FieldDefinition } from '../../types';

@Component({
  selector: 'fsx-new-boolean',
  templateUrl: './new-boolean.component.html',
  styleUrls: ['./new-boolean.component.scss'],
})
export class FsxNewBooleanComponent implements OnChanges {
  /**
   * The FormControl as created upfront by the AdditionalFieldsFormService.
   */
  @Input() formControl!: FormControlWithModel<FieldDefinition>;

  /**
   * The label text to display above the control.
   */
  @Input() caption!: string;

  /**
   * The tooltip text to display on hover.
   */
  @Input() helpText: string = '';

  /**
   * The width of the control.
   */
  @Input() width: string = '100%';

  /**
   * The height of the control.
   */
  @Input() height: string = 'inherit';

  /**
   * The control's visibility.
   */
  @Input() visible: boolean = true;

  /**
   * The boolean indicating whether the field is readonly.
   */
  @Input() readonly: boolean = true;

  /**
   * a boolean input binding to allow consuming components to set the enabled/disabled
   * state of this text component.
   */
  @Input() disabled = false;

  /**
   * A handle onto the checkbox control in the template.
   */
  @ViewChild('checkbox') checkbox!: MatCheckbox;

  /**
   * The Angular lifecycle hook where we watch for changed of the disabled property
   * to determine whether to enable/disable the control.
   */
  ngOnChanges() {
    // Note: this causes valueChanges listener to emit and set a default value on first load.
    this.enableOrDisableFormControl();
  }

  /**
   * A helper function to set the formControl's enable or disable state based
   * on the incoming [disabled] input binding.
   */
  private enableOrDisableFormControl() {
    this.disabled ? this.formControl.disable() : this.formControl.enable();
  }

  /**
   * A public method to retrieve the first error message in the collection
   * of ValidationErrors on the FormControl.
   *
   * @returns The error message as a string.
   */
  getErrorMessage(): string {
    return Object.values({ ...this.formControl.errors })[0];
  }

  /**
   * A handler method for the label's click event. Used here to create a larger
   * 'hit' area and toggle the checkbox value.
   */
  onLabelClicked(): void {
    if (
      !this.formControl.disabled &&
      !this.formControl.fieldDefinition.readOnly
    ) {
      this.checkbox.toggle();
    }
  }

  /**
   * A public method for force validating the FormControl from parent
   * container components.
   */
  validate(): void {
    this.formControl.markAsTouched();
    this.formControl.markAsDirty();
    this.formControl.updateValueAndValidity({ emitEvent: false });
  }
}
