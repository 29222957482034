import { Inject, Injectable, InjectionToken } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import {
  FsxValidationIndicatorService,
  IValidationIndicatorService,
} from '../../filing-editor/services/validation-indicator.service';

export const FsxFilingChecklistService =
  new InjectionToken<IFilingChecklistService>('FsxFilingChecklistService');

/**
 * A blueprint for a mediator service which allows FilingChecklistComponent and
 * FilingChecklistIconComponent to work together with setting/reading the checklist
 * visibility and just reading the overall cross-tab validity from a single source of truth.
 */
export interface IFilingChecklistService {
  /**
   * A public member which exposes the open/closed state of the checklist as an Observable<boolean>
   */
  isVisible$: Observable<boolean>;

  /**
   * A public member which exposes the overall cross-tab validity as an Observable<boolean>
   */
  isValidCrossTab$: Observable<boolean>;

  /**
   * A public method to set the checklist visibility. Used when we want to explicitly open/close the checklist
   *
   * @param {boolean} isVisible The value that we want to set (true: open, false: close)
   */
  setVisibility(isVisible: boolean): void;

  /**
   * A public method to toggle the checklist visibility. Used when we want to reverse the open/closed state of the checklist
   */
  toggleVisibilty(): void;
}

/**
 * A concrete implementation of a mediator service to allow FilingChecklistComponent
 * and FilingChecklistIconComponent to work together with setting/reading the checklist
 * visibility and just reading the overall cross-tab validity from a single source of truth.
 */
@Injectable()
export class FilingChecklistService implements IFilingChecklistService {
  /**
   * A private member to store the open/closed state of the checklist as a BehaviorSubject<boolean>
   */
  private readonly isVisible$$ = new BehaviorSubject<boolean>(false);

  /**
   * A public member which exposes the open/closed state of the checklist as an Observable<boolean>
   */
  readonly isVisible$: Observable<boolean> = this.isVisible$$.asObservable();

  /**
   * A public member which exposes the overall cross-tab validity as an Observable<boolean>
   */
  readonly isValidCrossTab$: Observable<boolean> =
    this.validationIndicatorService.isValidCrossTab$;

  /**
   *
   * @param validationIndicatorService The derived-state service, which exposes the cross-tab
   * validity as the single source of truth, which we share with dependent components.
   * (i.e. FilingChecklistComponent, FilingChecklistIconComponent)
   */
  constructor(
    @Inject(FsxValidationIndicatorService)
    private readonly validationIndicatorService: IValidationIndicatorService,
  ) {}

  /**
   * A public method to set the checklist visibility. Used when we want to explicitly open/close the checklist
   *
   * @param {boolean} isVisible The value that we want to set (true: open, false: close)
   */
  setVisibility(isVisible: boolean): void {
    this.isVisible$$.next(isVisible);
  }

  /**
   * A public method to toggle the checklist visibility. Used when we want to reverse the open/closed state of the checklist
   */
  toggleVisibilty(): void {
    this.isVisible$$.next(!this.isVisible$$.value);
  }
}
