import { Pipe, PipeTransform } from '@angular/core';
import {
  AccessCommonCategoryDomainCategoryValue,
  FilingProfile,
} from '../types';

@Pipe({
  name: 'documentAccessCatgeoryCaption',
})
export class DocumentAccessCatgeoryCaptionPipe implements PipeTransform {
  transform(filingProfile: FilingProfile, accessCategoryName: string): string {
    return (
      filingProfile.documentAccessCategories.find(
        (accessCategory: AccessCommonCategoryDomainCategoryValue) => {
          return accessCategory.name === accessCategoryName;
        },
      )?.caption || ''
    );
  }
}
