<div fxFill fxLayout="column" fxLayoutAlign="stretch" class="view-container">
  <div
    fxFlex="57px"
    class="nav-bar"
    fxLayout="row"
    fxLayoutAlign="start center"
  >
    <div class="header" fxFlex="auto">Submission Receipt</div>
    <div
      fxFlex="none"
      fxLayout="row"
      fxLayoutAlign="start center"
      fxLayoutGap="12px"
    >
      <button
        fxFlex="none"
        printSectionId="print-section"
        [printTitle]="'submission-receipt'"
        [useExistingCss]="true"
        fxLayout="row"
        fxLayoutAlign="start center"
        fxLayoutGap="5px"
        ngxPrint
      >
        <mat-icon fxFlex="none" class="icon">print</mat-icon>
        <span fxFlex="none">Print</span>
      </button>

      <button fxFlex="none" (click)="downloadReceipt()">Save As Pdf</button>

      <button
        fxFlex="none"
        class="close"
        (click)="onCloseButtonClicked()"
      ></button>
    </div>
  </div>
  <div id="print-section" fxFlex="auto">
    <!-- START: Receipt Container -->
    <div
      *ngIf="transaction && transaction.fees && transaction.documents"
      #receipt
      fxFill
      class="receipt-container"
      fxLayout="column"
      fxLayoutAlign="stretch"
      fxLayoutGap="20px"
    >
      <!-- START: FSX Logo -->
      <section fxFlex="none">
        <img
          id="fsla-logo"
          src="../../assets/img/logos/los-angeles/FSX_RGB_FullColor-Dark_Horizontal_Los%20Angeles.png"
          alt="FSLA"
          class="fsla-logo"
        />
      </section>
      <!-- END: FSX Logo -->

      <!-- START: General Info -->
      <section
        class="section"
        fxFlex="none"
        fxLayout="column"
        fxLayoutAlign="start start"
        fxLayoutGap="8px"
      >
        <div
          class="row"
          fxFlex="none"
          fxLayout="row"
          fxLayoutAlign="start center"
          fxLayoutGap="5px"
        >
          <div fxFlex="150px" class="detail">
            <div class="value">{{ transaction.id }}</div>
            <div class="caption">Transaction ID</div>
          </div>

          <div fxFlex="170px" class="detail">
            <div class="value">{{ transaction.details.submittedBy }}</div>
            <div class="caption">Submitted By</div>
          </div>

          <div fxFlex="auto" class="detail">
            <div class="value">
              {{ transaction.dateSubmitted | date: "MM/dd/YYYY hh:mmaaa" }}
            </div>
            <div class="caption">Date Submitted</div>
          </div>
        </div>

        <div
          class="row"
          fxFlex="none"
          fxLayout="row"
          fxLayoutAlign="start center"
          fxLayoutGap="5px"
        >
          <div fxFlex="150px" class="detail">
            <div class="value">{{ transaction.details.mode }}</div>
            <div class="caption">Transaction Mode</div>
          </div>

          <div fxFlex="175px" class="detail">
            <div class="value">{{ transaction.details.type }}</div>
            <div class="caption">Type</div>
          </div>
        </div>
      </section>
      <!-- END: General Info -->

      <!-- START: Case Details -->
      <section
        class="section"
        fxFlex="none"
        fxLayout="column"
        fxLayoutAlign="start start"
        fxLayoutGap="8px"
      >
        <div fxFlex="none" class="title">Case Details</div>

        <div fxFlex="none" class="detail">
          <div class="value">{{ transaction.details.clientMatterNumber }}</div>
          <div class="caption">Client/Matter Number</div>
        </div>

        <div fxFlex="none" class="detail">
          <div class="value">{{ transaction.caseName }}</div>
          <div class="caption">Case Name</div>
        </div>

        <div fxFlex="none" class="detail">
          <div class="value">{{ transaction.details.court }}</div>
          <div class="caption">Court</div>
        </div>

        <div fxFlex="none" class="detail">
          <div class="value">{{ transaction.details.caseClass }}</div>
          <div class="caption">Case Class</div>
        </div>

        <div fxFlex="none" class="detail">
          <div class="value">{{ transaction.details.caseType }}</div>
          <div class="caption">Case Type</div>
        </div>
      </section>
      <!-- END: Case Details -->

      <!-- START: Parties -->
      <section
        class="section"
        fxFlex="none"
        fxLayout="column"
        fxLayoutAlign="start start"
        fxLayoutGap="8px"
      >
        <div fxFlex="none" class="title">
          Parties ({{ transaction.parties.length }})
        </div>

        <div
          fxFlex="none"
          fxLayout="row"
          fxLayoutAlign="start center"
          fxLayoutGap="5px"
        >
          <div fxFlex="210px" class="column">Party/Type</div>
          <div fxFlex="190px" class="column">Representation/Role</div>
          <div fxFlex="190px" class="column">Firm</div>
        </div>
        <mat-divider class="divider"></mat-divider>

        <ng-container *ngFor="let participant of transaction.parties">
          <div
            fxFlex="none"
            fxLayout="row"
            fxLayoutAlign="start center"
            fxLayoutGap="5px"
            class="parties"
          >
            <div fxFlex="210px" fxLayout="column">
              <div fxFlex="none" class="party">{{ participant.name }}</div>
              <div fxFlex="none" class="type">{{ participant.type }}</div>
            </div>

            <ng-container
              *ngIf="participant.representation?.length; else noRepresentation"
            >
              <div fxFlex="none" fxLayout="column" fxLayoutGap="5px">
                <div
                  *ngFor="let representation of participant.representation"
                  fxFlex="none"
                  fxLayout="row"
                  fxLayoutGap="5px"
                >
                  <div fxFlex="190px">
                    <div class="value">{{ representation.name }}</div>
                    <div class="type">{{ representation.role }}</div>
                  </div>
                  <div fxFlex fxFlexAlign="start">
                    <div class="value">{{ representation.firm }}</div>
                  </div>
                </div>
              </div>
            </ng-container>
            <ng-template #noRepresentation>
              <div fxFlex="auto" fxLayout="column" fxLayoutGap="5px">
                <div fxFlex="none" fxLayout="row" fxLayoutGap="5px">
                  <div fxFlex="190px">
                    <div class="value">Self-Represented</div>
                  </div>
                  <div fxFlex fxFlexAlign="start">
                    <div class="value">-</div>
                  </div>
                </div>
              </div>
            </ng-template>
          </div>
        </ng-container>
      </section>
      <!-- END: Parties -->

      <!-- START: Documents -->
      <section
        class="section"
        fxFlex="none"
        fxLayout="column"
        fxLayoutAlign="start start"
        fxLayoutGap="8px"
      >
        <!-- Document Section Header -->
        <div fxFlex="none" class="title">
          Documents ({{ transaction.documents.length }})
        </div>
        <div
          fxFlex="none"
          fxLayout="row"
          fxLayoutAlign="start center"
          fxLayoutGap="5px"
        >
          <div fxFlex="40px" class="column"></div>
          <div fxFlex="335px" class="column">Title</div>
          <div fxFlex="95px" class="column">Access</div>
          <div fxFlex="125px" class="column">File</div>
          <div fxFlex="auto" class="column">Statutory Fees</div>
        </div>
        <mat-divider class="divider"></mat-divider>
        <!-- Documents -->
        <ng-container
          *ngFor="
            let transactionDocument of transaction.documents;
            let docIndex = index
          "
        >
          <div
            fxFlex="none"
            class="section-content row"
            fxLayout="row"
            fxLayoutAlign="start center"
            fxLayoutGap="5px"
          >
            <div fxFlex="40px" class="value text-center">
              {{ requestDocuments | documentOrderNumber: docIndex }}
            </div>
            <div fxFlex="335px" class="document" fxLayout="column">
              <div fxFlex="none" class="value">
                <span
                  class="download"
                  (click)="openPdfViewer(transactionDocument, docIndex)"
                  >{{ transactionDocument.documentName }}</span
                >
              </div>
              <div fxFlex="none" class="type">
                {{ transactionDocument.documentCategory }}
              </div>
            </div>
            <div fxFlex="95px" class="value">
              {{ transactionDocument.access }}
            </div>
            <div fxFlex="125px" class="value" fxLayout="column">
              <div fxFlex="none" class="size">
                {{ transactionDocument.pdfSize }}
              </div>
              <div fxFlex="none" class="type">
                Pages: {{ transactionDocument.pdfPages }}
              </div>
            </div>
            <div fxFlex="auto" class="value">
              {{ transactionDocument.statutoryFees || "-" }}
            </div>
          </div>
        </ng-container>
      </section>
      <!-- END: Documents -->

      <!-- START: Fees -->
      <section
        class="section transaction-fees"
        fxFlex="none"
        fxLayout="column"
        fxLayoutAlign="start start"
      >
        <!-- Transaction Fees Header -->
        <div fxFlex="none" class="title">Estimated Transaction Fees</div>
        <!-- START: Transaction Fees -->
        <ng-container *ngFor="let transactionFee of transaction.fees">
          <div
            fxFlex="none"
            fxLayout="row"
            fxLayoutAlign="start center"
            class="row fees"
          >
            <div fxFlex="auto" class="caption">
              {{ transactionFee.caption }}
            </div>
            <div fxFlex="120px" class="value">
              {{
                transactionFee.total
                  | currency: transactionFee.currencyCode : "symbol"
              }}
            </div>
          </div>
        </ng-container>
        <!-- END: Transaction Fees -->

        <!-- Transaction Fees Total   -->
        <div
          fxFlex="none"
          fxLayout="row"
          fxLayoutAlign="start center"
          class="row fees total"
          fxLayoutGap="48px"
        >
          <div fxFlex="auto" class="caption">Total</div>
          <div fxFlex="120px" class="value">
            <ng-container [ngSwitch]="transaction.details.totalFees.total">
              <div *ngSwitchCase="'-'">-</div>
              <div *ngSwitchCase="'loading'">Loading...</div>
              <div *ngSwitchDefault class="number">
                {{
                  transaction.details.totalFees.total
                    | currency
                      : transaction.details.totalFees.currencyCode
                      : "symbol"
                }}
              </div>
            </ng-container>
          </div>
        </div>
      </section>
      <!-- END: Fees -->

      <!-- START: Payment Method ToDo: Hard Coded for "P0"-->
      <section
        class="section payment-method"
        fxFlex="none"
        fxLayout="column"
        fxLayoutAlign="start start"
        fxLayoutGap="8px"
      >
        <div fxFlex="none" class="title">Payment Method</div>
        <div fxFlex="none" class="detail">
          <div class="value">Monthly Billing / Invoiced</div>
          <div class="caption">Type</div>
        </div>
      </section>
      <!-- END: Payment Method -->

      <!-- START: Note To Clerk -->
      <section
        class="section notes-to-clerk"
        fxFlex="none"
        fxLayout="column"
        fxLayoutAlign="start start"
        fxLayoutGap="8px"
      >
        <div fxFlex="none" class="title">Note To Clerk</div>
        <div fxFlex="none" class="detail">
          <div class="value notes">{{ transaction.noteToClerk }}</div>
        </div>
      </section>
      <!-- END: Note To Clerk -->

      <!-- START: Footer -->
      <footer class="footer"></footer>
      <!-- END: Footer -->
    </div>
    <!-- END: Receipt Container -->
  </div>
</div>
