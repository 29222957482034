import {
  AbstractControlOptions,
  FormArray,
  FormControl,
  FormGroup,
  ValidatorFn,
} from '@angular/forms';
import {
  CurrencyValue,
  FieldCategory,
  SearchResultItem,
} from '@fsx/fsx-shared';
import { FormArraySpecs, DropdownOption, DropdownOptionsTypes } from '../types';

export class FormControlWithModel<T> extends FormControl {
  public fieldDefinition!: T;
  public formattedValue: string | number | undefined;
  public dropdownOptions: DropdownOption<DropdownOptionsTypes>[] | undefined;

  constructor(
    fieldType: string,
    initialValue:
      | string
      | string[]
      | SearchResultItem
      | CurrencyValue
      | boolean
      | number
      | Date
      | undefined
      | null,
    fieldDefinition: T,
    formattedValue?: string | number,
    dropdownOptions?: DropdownOption<DropdownOptionsTypes>[],
  ) {
    const updateType =
      fieldType === FieldCategory.Text ||
      fieldType === FieldCategory.Currency ||
      fieldType === FieldCategory.Selection ||
      fieldType === FieldCategory.Boolean
        ? 'change'
        : 'blur';
    super(initialValue, { updateOn: updateType });
    this.fieldDefinition = fieldDefinition;
    this.formattedValue = formattedValue;
    this.dropdownOptions = dropdownOptions;
  }
}

// tslint:disable-next-line: max-classes-per-file
export class FormControlWithoutModel extends FormControl {
  public dropdownOptions: DropdownOption<void>[] | undefined;

  constructor(
    fieldType: string,
    initialValue: string | boolean | number | Date | undefined | null,
    dropdownOptions?: DropdownOption<void>[],
  ) {
    const updateType =
      fieldType === FieldCategory.Text ||
      fieldType === FieldCategory.Currency ||
      fieldType === FieldCategory.Selection ||
      fieldType === FieldCategory.Boolean
        ? 'change'
        : 'blur';
    super(initialValue, { updateOn: updateType });
    this.dropdownOptions = dropdownOptions;
  }
}

// tslint:disable-next-line: max-classes-per-file
export class FormArrayWithModel<T extends FormGroup> extends FormArray {
  public formArraySpecs!: FormArraySpecs;
  public override controls!: T[];

  constructor(
    initialValue: T[],
    formArraySpecs: FormArraySpecs,
    validatorOrOpts?:
      | ValidatorFn
      | ValidatorFn[]
      | AbstractControlOptions
      | null,
  ) {
    super(initialValue, validatorOrOpts);
    this.formArraySpecs = formArraySpecs;
  }
}
