import {
  AddressViewModel,
  FilingProfile,
  ParticipantSpec,
} from '@fsx/fsx-shared';

export function getPersonAddress(
  address: AddressViewModel,
  participantSpec: ParticipantSpec | undefined,
  filingProfile: FilingProfile | undefined,
): string {
  if (!participantSpec) {
    return '';
  }

  let addressProfile;
  const addressDefaultProfile = filingProfile?.defaultAddressProfileName;

  if (!participantSpec.address?.addressProfileName) {
    addressProfile = filingProfile?.addressProfiles.find(
      (profile) => profile.name === addressDefaultProfile,
    )?.spec;
  } else {
    addressProfile = filingProfile?.addressProfiles.find(
      (profile) => profile.name === participantSpec.address?.addressProfileName,
    )?.spec;
  }

  let personAddress = '';
  personAddress += addressProfile?.addressLine1.visible
    ? address.addressLine1
    : '';

  personAddress +=
    addressProfile?.addressLine2?.visible && address.addressLine2
      ? ', ' + address.addressLine2
      : '';

  personAddress +=
    addressProfile?.locality.visible && address.locality
      ? ', ' + address.locality
      : '';

  personAddress +=
    addressProfile?.administrativeArea?.visible && address.administrativeArea
      ? ', ' + address.administrativeArea.toUpperCase()
      : '';

  personAddress +=
    addressProfile?.postalCode.visible && address.postalCode
      ? ', ' + address.postalCode
      : '';

  personAddress +=
    address.country && participantSpec.address?.country.visible
      ? ', ' + address.country.toUpperCase()
      : '';

  return personAddress;
}
