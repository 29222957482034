import { Inject, Injectable } from '@angular/core';
import { Subject, switchMap, tap } from 'rxjs';
import {
  Filing,
  FsxFilingApiService,
  IFilingApiService,
} from '@fsx/fsx-shared';
import { TransactionsEventService } from '../transactions-event.service';

@Injectable({
  providedIn: 'root',
})
export class RemoveTransactionOrchestrationService {
  private removeTransactionParams$$ = new Subject<Filing>();

  public removeTransaction$ = this.removeTransactionParams$$.pipe(
    switchMap((filing: Filing) => {
      return this.filingApiService.deleteFiling(filing).pipe(
        tap(() => {
          this.transactionEventService.dispatchTransactionRemovedEvent(filing);
        }),
      );
    }),
  );

  public constructor(
    @Inject(FsxFilingApiService)
    private readonly filingApiService: IFilingApiService,
    private transactionEventService: TransactionsEventService,
  ) {}

  public removeTransaction(filing: Filing) {
    this.removeTransactionParams$$.next(filing);
  }
}
