import { Component, Inject, OnDestroy, ViewChild } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NewFiling, TextFieldDefinition } from '@fsx/fsx-shared';
import { Subject } from 'rxjs';
import {
  FsxNewFilingOrchestrationService,
  INewFilingOrchestrationService,
} from '../services/orchestration/new-filing-orchestration.service';
import {
  FsxSidePanelComponent,
  SidePanelHeaderConfig,
  SidePanelFooterConfig,
  SidePanelButtonStatusEnum,
} from '@fsx/ui-components';

interface NewFilingPanelData {
  reference: MatDialog;
}

@Component({
  selector: 'fsx-app-existing-filing-panel',
  templateUrl: './existing-filing-panel.component.html',
  styleUrls: ['./existing-filing-panel.component.scss'],
})
export class ExistingFilingPanelComponent implements OnDestroy {
  @ViewChild('sidePanel') sidePanel!: FsxSidePanelComponent;
  private destroy$: Subject<void> = new Subject();
  public headerConfig: SidePanelHeaderConfig;
  public footerConfig: SidePanelFooterConfig;
  private newFiling!: NewFiling;

  showClientMatterNumber: boolean = false;
  showProposedCaseName: boolean = false;
  clientMatterValid: boolean = false;
  proposedCaseNameValid: boolean = false;

  /**
   * The definition of the client matter number field.
   */
  clientMatterNumberFieldDefinition: TextFieldDefinition = {
    required: true,
    minLength: 1,
    maxLength: 100,
    characterProfileName: 'alphanumericTextboxCharset',
    readOnly: false,
    visible: true,
  };

  /**
   * The definition of the proposed case name field.
   */
  proposedCaseNameFieldDefinition: TextFieldDefinition = {
    required: true,
    minLength: 1,
    maxLength: 200,
    characterProfileName: 'alphanumericTextboxCharset',
    readOnly: false,
    visible: true,
  };

  constructor(
    @Inject(MAT_DIALOG_DATA) public newFilingPanelData: NewFilingPanelData,
    @Inject(FsxNewFilingOrchestrationService)
    private readonly newFilingOrchestrationService: INewFilingOrchestrationService,
  ) {
    this.headerConfig = {
      title: 'Existing Case Filing',
      titleColor: 'dark',
      titlePosition: 'flex-start',
      titleIconColor: 'primary',
      titleIconPath: '../../../assets/icons/case-filing-icon.svg',
      exitIcon: true,
      exitAction: () => {
        newFilingPanelData.reference.closeAll();
      },
    };

    this.footerConfig = {
      checkboxCaption: '',
      buttons: [
        {
          buttonCaption: 'Cancel',
          buttonType: 'secondary',
          status: SidePanelButtonStatusEnum.Enabled,
          action: () => this.newFilingPanelData.reference.closeAll(),
        },
        {
          buttonCaption: 'File',
          buttonType: 'primary',
          status: SidePanelButtonStatusEnum.Disabled,
          action: () => this.createNewFiling(),
        },
        {
          buttonCaption: 'Serve',
          buttonType: 'primary',
          status: SidePanelButtonStatusEnum.Disabled,
          action: () => null,
        },
        {
          buttonCaption: 'File & Serve',
          buttonType: 'primary',
          status: SidePanelButtonStatusEnum.Disabled,
          action: () => null,
        },
      ],
    };
  }

  public createNewFiling(): void {
    this.newFilingOrchestrationService.createNewFiling(this.newFiling);
    this.newFilingPanelData.reference.closeAll();
  }

  public setNewFiling(newFiling: NewFiling | null): void {
    if (newFiling) {
      this.newFiling = newFiling;
      this.showClientMatterNumber = true;
      this.showProposedCaseName = true;
    } else {
      this.showClientMatterNumber = false;
      this.showProposedCaseName = false;
      this.clientMatterValid = false;
      this.proposedCaseNameValid = false;
      this.setFooterFocus();
    }
  }

  /**
   * Sets the proposed case name to the filing.
   * @param newName The new name of the case.
   */
  public onTransactionNameTextChanged(newName: string) {
    this.newFiling.caption = newName;
    this.proposedCaseNameValid = newName !== '' && newName.length > 0;
    this.setFooterFocus();
  }

  /**
   * Sets the client matter number of the filing.
   * @param newClientMatterNumber The new name of the case.
   */
  public onClientMatterNumberTextChanged(newClientMatterNumber: string) {
    this.newFiling.cases[0].clientMatterKey = newClientMatterNumber;
    this.clientMatterValid =
      newClientMatterNumber !== '' && newClientMatterNumber.length > 0;
    this.setFooterFocus();
  }

  /**
   * Sets the footer button focus based on the validity of the client matter number and proposed case name.
   * @private
   */
  private setFooterFocus(): void {
    if (this.clientMatterValid && this.proposedCaseNameValid) {
      this.sidePanel.setButtonStatus(
        this.footerConfig.buttons[1],
        SidePanelButtonStatusEnum.Enabled,
      );
    } else {
      this.sidePanel.setButtonStatus(
        this.footerConfig.buttons[1],
        SidePanelButtonStatusEnum.Disabled,
      );
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
