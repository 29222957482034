import { Pipe, PipeTransform } from '@angular/core';
import {
  AddressProfileSpec,
  AddressViewModel,
  FilingProfile,
  ParticipantSpec,
} from '../types';

@Pipe({
  name: 'addressCaption',
})
export class AddressCaptionPipe implements PipeTransform {
  transform(
    address: AddressViewModel,
    participantSpec: ParticipantSpec,
    filingProfile: FilingProfile,
  ): string {
    if (!filingProfile) {
      return '';
    }

    const addressDefaultProfile = filingProfile?.defaultAddressProfileName;
    let addressProfileName: string =
      participantSpec.address?.addressProfileName || addressDefaultProfile;
    let addressProfile: AddressProfileSpec | undefined =
      filingProfile.addressProfiles.find(
        (profile) => profile.name === addressProfileName,
      )?.spec;

    if (!addressProfile) {
      throw 'addressProfile could not be found';
    }

    let personAddress = '';
    personAddress += addressProfile?.addressLine1.visible
      ? address.addressLine1
      : '';

    personAddress +=
      addressProfile?.addressLine2?.visible && address.addressLine2
        ? ', ' + address.addressLine2
        : '';

    personAddress +=
      addressProfile?.locality.visible && address.locality
        ? ', ' + address.locality
        : '';

    personAddress +=
      addressProfile?.administrativeArea?.visible && address.administrativeArea
        ? ', ' + address.administrativeArea.toUpperCase()
        : '';

    personAddress +=
      addressProfile?.postalCode.visible && address.postalCode
        ? ', ' + address.postalCode
        : '';

    personAddress +=
      address.country && participantSpec.address?.country.visible
        ? ', ' + address.country.toUpperCase()
        : '';

    return personAddress;
  }
}
