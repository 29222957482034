import {
  Component,
  EventEmitter,
  Inject,
  Input,
  OnChanges,
  Output,
  QueryList,
  ViewChildren,
} from '@angular/core';
import {
  AdditionalFieldSpec,
  AdditionalFieldValue,
  CombinedFilingData,
  DocumentInfo,
  FILING_SUB_TABS,
  FieldCategory,
  ParticipantFieldSpec,
  RequestDocument,
  RequestDocumentParticipantViewModel,
  RequestParticipantViewModel,
} from '@fsx/fsx-shared';
import {
  DropdownOption,
  FilesUploadedEventParams,
  FormControlWithoutModel,
  ParticipantTableComponent,
  SelectionFieldType,
} from '@fsx/ui-components';
import { DocumentsGridRow } from '../documents-grid/documents-grid.model';
import { FsxReferenceResolver } from '../../shared/resolvers/list-reference.resolver';
import {
  FsxParticipantDataService,
  IParticipantDataService,
} from '../../filing-editor/services/participant-data.service';
import { take, tap } from 'rxjs';
import {
  FsxFilingSubTabsService,
  IFilingSubTabsService,
} from '../../filing-editor/filing-sub-tabs-container/filing-sub-tabs.service';

@Component({
  selector: 'fsx-participant-field',
  templateUrl: './participant-field.component.html',
  styleUrls: ['./participant-field.component.scss'],
})
export class ParticipantFieldComponent implements OnChanges {
  @Input() inputDropdownOptions: DropdownOption<void>[] = [];
  @Input() combinedFilingData!: CombinedFilingData;
  @Input() caption!: string;
  @Input() documentsGridRow!: DocumentsGridRow;
  @Input() selectedParticipants: RequestParticipantViewModel[] = [];
  @Input() participantFieldSpec!: ParticipantFieldSpec;
  @Input() width!: string;
  @Input() resolver!: FsxReferenceResolver;
  @Input() addlFieldSpec!: AdditionalFieldSpec[];
  @Input() fileUploadDocumentInfos!: DocumentInfo[];
  @Input() additionalFieldValues!: AdditionalFieldValue[];
  @Input()
  targetRequestDocumentParticipant!: RequestDocumentParticipantViewModel;
  @Output() selectParticipantEvent =
    new EventEmitter<RequestParticipantViewModel>();
  @Output() removeParticipantEvent =
    new EventEmitter<RequestParticipantViewModel>();
  @Output() formControlEmitter = new EventEmitter<FormControlWithoutModel>(
    true,
  );
  @Output() filesUploadedFromAdditionalFieldEvent =
    new EventEmitter<FilesUploadedEventParams>();
  @Output() fileRemovedFromAdditionalFieldEvent =
    new EventEmitter<DocumentInfo>();

  /**
   * An output event to allow the parent container component (DocumentFormComponent) to
   * handle updating of RequestDocumentParticipant additionalFieldValues in separate handler
   * functions for "Filed By" and "As To".
   */
  @Output() additionalFieldValuesEvent = new EventEmitter<
    AdditionalFieldValue[]
  >();

  @ViewChildren('participantsField')
  participantsFields!: QueryList<ParticipantTableComponent>;

  filteredDropdownOptions: DropdownOption<void>[] = [];
  public fieldType = FieldCategory;
  public selectionType = SelectionFieldType.StringSelectionFieldDefinition;
  minRequired!: number;
  maxAllowed!: number;
  additionalFieldSpecs!: AdditionalFieldSpec[] | null;
  required!: boolean;

  /**
   * @param participantDataService Used to lookup the full RequestParticipant object when selected from dropdown list.
   *
   * @param filingSubTabsService Used to navigate to the parties tab on clicking custom "Add Party" dropdown option.
   */
  constructor(
    @Inject(FsxParticipantDataService)
    private readonly participantDataService: IParticipantDataService,
    @Inject(FsxFilingSubTabsService)
    private readonly filingSubTabsService: IFilingSubTabsService,
  ) {}

  ngOnChanges(): void {
    this.setFilteredDropdownOptions();
    this.minRequired =
      this.participantFieldSpec?.participantFieldDefinition?.minRequired || 0;
    this.maxAllowed =
      this.participantFieldSpec?.participantFieldDefinition?.maxAllowed || 99;
    this.additionalFieldSpecs =
      this.participantFieldSpec?.participantFieldDefinition?.additionalFields ||
      null;

    this.required = this.selectedParticipants.length < this.minRequired;
    this.additionalFieldValues =
      this.targetRequestDocumentParticipant?.additionalFieldValues || [];
  }

  private setFilteredDropdownOptions() {
    this.filteredDropdownOptions = this.selectedParticipants.reduce(
      (acc: DropdownOption<void>[], cur: RequestParticipantViewModel) => {
        return acc.filter((aaa: DropdownOption<void>) => {
          return aaa.name !== cur.name;
        });
      },
      [...this.inputDropdownOptions],
    );
  }

  /**
   * A handler function for when the user selects a participant from the dropdown list
   */
  onParticipantSelected(params: {
    value: string;
    requestDocument: RequestDocument;
    documentIndex: number;
  }) {
    this.participantDataService.participants$
      .pipe(
        take(1),
        tap((participants: RequestParticipantViewModel[]) => {
          const participantToSelect: RequestParticipantViewModel | undefined =
            participants.find((p) => p.name === params.value);
          if (params.requestDocument.cases) {
            this.additionalFieldValues =
              params.requestDocument.cases[0].additionalFieldValues ?? [];
          }

          if (participantToSelect) {
            this.selectParticipantEvent.emit(participantToSelect);
          }
        }),
      )
      .subscribe();
  }

  clearParticipantEventHandler(
    participantToClear: RequestParticipantViewModel,
  ) {
    this.removeParticipantEvent.emit(participantToClear);
  }

  participantformControl($event: FormControlWithoutModel) {
    this.formControlEmitter.emit($event);
  }

  public filesUploadedFromAdditionalFieldEventHandler(
    params: FilesUploadedEventParams,
  ) {
    this.filesUploadedFromAdditionalFieldEvent.emit(params);
  }

  public fileRemovedFromAdditionalFieldEventHandler(
    documentInfo: DocumentInfo,
  ) {
    this.fileRemovedFromAdditionalFieldEvent.emit(documentInfo);
  }

  public validate(): void {
    if (this.participantsFields) {
      this.participantsFields.toArray().forEach((fld) => fld.validate());
    }
  }

  /**
   * A handler function for when the user clicks on a custom dropdown option link
   * provided in the participant dropdown lists.
   */
  dropdownOptionLinkClickedHandler() {
    this.filingSubTabsService.activateFilingSubTabItem(FILING_SUB_TABS.PARTIES);
  }

  /**
   * A method to handle the setting of AdditionalFieldValue objects for selected participant field.
   *
   * @param additionalFieldValues The updated additionalFieldValues collection.
   */
  additionalFieldValuesEventHandler(
    additionalFieldValues: AdditionalFieldValue[],
  ) {
    this.additionalFieldValuesEvent.emit(additionalFieldValues);
  }
}
