import { Inject, Injectable, InjectionToken } from '@angular/core';
import {
  CaseRequestViewModel,
  CombinedFilingData,
  ICaseRequestUpdateService,
  FsxCaseRequestUpdateService,
  AdditionalFieldValue,
  RequestCaseViewModel,
} from '@fsx/fsx-shared';
import { Subject, Observable, switchMap, withLatestFrom } from 'rxjs';
import {
  FsxCombinedFilingDataService,
  ICombinedFilingDataService,
} from '../../filing-editor/services/combined-filing-data.service';

/**
 * The InjectionToken to use in the providers array to specify a concrete-implementation
 * of the IUpdateAdditionalFieldValuesOrchestrationService to use at runtime.
 */
export const FsxUpdateAdditionalFieldValuesOrchestrationService =
  new InjectionToken<IUpdateAdditionalFieldValuesOrchestrationService>(
    'FsxUpdateAdditionalFieldValuesOrchestrationService',
  );

export interface UpdateAdditionalFieldValuesParams {
  /**
   * The new additionalFieldValues object to set into the CaseRequest
   */
  additionalFieldValues: AdditionalFieldValue[];
}

export interface IUpdateAdditionalFieldValuesOrchestrationService {
  /**
   * The pipeline of orchestration steps needed to update AdditionalFieldValues
   */
  updateAdditionalFieldValues$: Observable<CaseRequestViewModel>;

  /**
   * A public method to allow the orchestration to be triggered.
   */
  updateAdditionalFieldValues(params: UpdateAdditionalFieldValuesParams): void;
}

@Injectable()
export class UpdateAdditionalFieldValuesOrchestrationService
  implements IUpdateAdditionalFieldValuesOrchestrationService
{
  /**
   * A subject to use as the trigger for the orchestration.
   */
  private updateAdditionalFieldValuesStream$$ =
    new Subject<UpdateAdditionalFieldValuesParams>();

  /**
   * The pipeline of orchestration steps needed to apply updates to a RequestDocument object
   * in the caseRequest.documents array.
   */
  updateAdditionalFieldValues$: Observable<CaseRequestViewModel> =
    this.updateAdditionalFieldValuesStream$$.pipe(
      withLatestFrom(this.combinedFilingDataService.combinedFilingData$),
      switchMap(
        ([params, combinedFilingData]: [
          UpdateAdditionalFieldValuesParams,
          CombinedFilingData,
        ]) => {
          const { filing, caseRequest } = combinedFilingData;
          const filingId: string = filing.id;
          const caseRequestBackup = { ...caseRequest } as CaseRequestViewModel;

          // Only updates the case at position 0 currently (change for multi-case scenario when needed)
          const caseRequestCases: RequestCaseViewModel[] =
            caseRequest.cases || [];
          caseRequest.cases = caseRequestCases.map(
            (requestCase: RequestCaseViewModel, index: number) => {
              if (index === 0) {
                requestCase.additionalFieldValues =
                  params.additionalFieldValues;
              }
              return requestCase;
            },
          );

          // Make the PUt request to apply the update.
          return this.caseRequestUpdateService.optimisticPutOrRestore(
            filingId,
            caseRequest,
            caseRequestBackup,
            true,
          );
        },
      ),
    );

  /**
   * @param combinedFilingDataService Needed to retrieve the CaseRequest object, which we subsequently update.
   *
   * @param caseRequestBuilderService Needed to build the CaseRequest object with the RequestDocument updates.
   *
   * @param validateDocumentsOrchestrationService  Needed to initiate validation against RequestDocument objects.
   *
   * @param caseRequestUpdateService Needed to PUT the updated CaseRequest object back to the server.
   */
  constructor(
    @Inject(FsxCombinedFilingDataService)
    private readonly combinedFilingDataService: ICombinedFilingDataService,
    @Inject(FsxCaseRequestUpdateService)
    private readonly caseRequestUpdateService: ICaseRequestUpdateService,
  ) {}

  /**
   * A public method to allow the orchestration to be triggered.
   */
  updateAdditionalFieldValues(params: UpdateAdditionalFieldValuesParams): void {
    this.updateAdditionalFieldValuesStream$$.next(params);
  }
}
