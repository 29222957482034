import {
  Component,
  ElementRef,
  EventEmitter,
  Inject,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import {
  AuthService,
  FSXContactQueryResultItem,
  ContactQueryResultItem,
  IContactApiService,
  FsxContactApiService,
  ParticipantCommonCategory,
} from '@fsx/fsx-shared';
import {
  ContactViewModel,
  ContactSummaryViewModel,
  Filter,
  FsxContact,
  Operator,
  Query,
  ContactQueryResultItemGridResult,
  Direction,
  IdentificationViewModel,
} from '@fsx/fsx-shared';
import {
  debounceTime,
  Observable,
  of,
  Subject,
  switchMap,
  take,
  takeUntil,
  tap,
} from 'rxjs';
import { ContactsSearchTypeEnum } from '../contacts.model';
import {
  FsxOpenContactsListOrchestrationService,
  IOpenContactsListOrchestrationService,
} from '../../shared/services/open-contact-list-orchestration.service';

@Component({
  selector: 'fsx-contacts-search',
  templateUrl: './contacts-search.component.html',
  styleUrls: ['./contacts-search.component.scss'],
})
export class ContactsSearchComponent implements OnInit, OnChanges, OnDestroy {
  @ViewChild('searchInput') searchInput!: ElementRef;
  @Output() selectedContactEvent = new EventEmitter<ContactViewModel>();
  @Output() selectedContactSummariesEvent = new EventEmitter<
    ContactSummaryViewModel[]
  >();

  /**
   * The search type to determine what type of search to perform (contacts or attorneys)
   */
  @Input() searchType!: ContactsSearchTypeEnum;

  /**
   * The ParticipantCommonCategory which we use to lookup the relevant ParticipantSpecs
   * to populate the ParticipantSpecSelectionComponent with.
   */
  @Input() commonCategory: ParticipantCommonCategory | undefined;

  @Input() disabled: boolean | null = true;
  @Input() displaySearchTypeLabel: boolean = true;
  @Input() excludedContactsIds: string[] | undefined;

  public contactsSearchType = ContactsSearchTypeEnum;
  public searchTerm: FormControl = new FormControl('');
  public showContacts = false;
  public inputFocused = false;
  public showLoading = false;
  public filteredOptions!: Observable<FSXContactQueryResultItem[]>;
  public organizationID: string | undefined;
  private filters: Filter[] | undefined = [];
  private destroy$: Subject<void> = new Subject();
  private filingRegion = 'usa.ca'; // todo

  constructor(
    @Inject(FsxContactApiService)
    private readonly contactApiService: IContactApiService,
    @Inject(FsxOpenContactsListOrchestrationService)
    private readonly openContactsListOrchestrationService: IOpenContactsListOrchestrationService,
    private readonly authService: AuthService,
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['disabled']) {
      changes['disabled'].currentValue
        ? this.searchTerm.disable()
        : this.searchTerm.enable();
    }
  }

  ngOnInit(): void {
    this.searchTerm.valueChanges
      .pipe(
        debounceTime(500),
        tap((value: string) => {
          this.showLoading = true;
          if (!value?.length) {
            this.filters = undefined;
          } else {
            this.setFilters(value);
          }
        }),
        switchMap(() => {
          return this.getContacts();
        }),
        tap((contacts: ContactQueryResultItemGridResult) => {
          this.setContacts(contacts);
          this.showLoading = false;
        }),
        takeUntil(this.destroy$),
      )
      .subscribe();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  public selectedContact(contact: ContactSummaryViewModel): void {
    if (contact.id) {
      this.contactApiService
        .getContact(contact.id)
        .pipe(
          tap((contact: ContactViewModel) => {
            this.searchTerm.setValue(contact?.caption);
            this.selectedContactEvent.emit(contact);
          }),
          take(1),
        )
        .subscribe();
    } else {
      this.showContacts = false;
    }
  }

  public loadContacts(): void {
    this.showLoading = true;
    this.authService.sessionUser
      .pipe(
        switchMap((contact: FsxContact | null) => {
          this.organizationID =
            this.searchType === ContactsSearchTypeEnum.attorneys
              ? contact?.organization?.primaryContact?.id
              : undefined;
          return this.getRecentlyUsedContacts();
        }),
        tap((contacts: ContactQueryResultItemGridResult) => {
          const localStorageRecentlyUsedContactIds: string | null =
            localStorage.getItem('recentlyUsedContactIds');
          const recentlyUsedContactIds: string[] =
            localStorageRecentlyUsedContactIds
              ? JSON.parse(localStorageRecentlyUsedContactIds)
              : [];

          if (recentlyUsedContactIds.length > 0) {
            const filteredContacts = contacts.data.filter((contact) => {
              return recentlyUsedContactIds.includes(contact.contactSummary.id);
            });

            const filteredContactsReversed: ContactQueryResultItem[] =
              filteredContacts.reverse();
            contacts.data = [...filteredContactsReversed];
          }

          this.setContacts(contacts);
          this.showLoading = false;
        }),
        take(1),
      )
      .subscribe();
  }

  /**
   * A handler method for when the user clicks on the "Manage Contacts" or "Manage Representation"
   * link at the bottom of this contact search compoent's drodpown list.
   */
  onManageContactsClicked(): void {
    this.openContactsListOrchestrationService.openContactsList({
      searchType: this.searchType,
      commonCategory: this.commonCategory,
      addCallback: (contactSummaries: ContactSummaryViewModel[]) => {
        this.selectedContactSummariesEvent.emit(contactSummaries);
      },
    });
  }

  public focusInput(value: boolean): void {
    this.inputFocused = value;
  }

  public clearInput(): void {
    this.searchTerm.reset();
  }

  public getContactBarNumber(
    identifications: IdentificationViewModel[],
  ): string {
    const contactIdentification = identifications.find(
      (identification) => identification.regionKey === this.filingRegion,
    );
    return contactIdentification ? contactIdentification.identificationKey : '';
  }

  private getContacts(): Observable<ContactQueryResultItemGridResult> {
    return this.contactApiService.getContacts(this.getQueryConfig());
  }

  private getRecentlyUsedContacts(): Observable<ContactQueryResultItemGridResult> {
    const queryConfig = {
      skip: 0,
      limit: 100,
      filters: [], // TODO: Apply filter here when API supports filtering by an array of Contact Ids
      sort: [
        {
          column: 'ModifiedBy',
          direction: Direction.Descending,
        },
      ],
      options:
        this.searchType === ContactsSearchTypeEnum.attorneys
          ? [
              {
                name: 'IncludeIdentifications',
              },
            ]
          : [],
      exactTotal: false,
    };
    return this.contactApiService.getContacts(queryConfig);
  }

  private setContacts(contacts: ContactQueryResultItemGridResult): void {
    const contactsData = contacts.data as FSXContactQueryResultItem[];
    if (this.searchType === ContactsSearchTypeEnum.attorneys) {
      contactsData.forEach((contact) => {
        if (contact.identifications) {
          contact.barNumber = this.getContactBarNumber(contact.identifications);
        }
      });
    }
    this.filteredOptions = of(contactsData);
  }

  private getQueryConfig(): Query {
    return {
      skip: 0,
      limit: this.searchTerm.value?.length ? 50 : 3,
      filters:
        this.searchType === ContactsSearchTypeEnum.attorneys &&
        !this.filters?.length
          ? [
              {
                column: 'IdentificationCommonCategory',
                operator: Operator.Equal,
                value1: 'BarNumber',
              },
            ]
          : this.filters?.length
            ? this.filters
            : [],
      sort: [
        {
          column: 'ModifiedBy',
          direction: Direction.Descending,
        },
      ],
      options:
        this.searchType === ContactsSearchTypeEnum.attorneys
          ? [
              {
                name: 'IncludeIdentifications',
              },
            ]
          : [],
      exactTotal: false,
    };
  }

  private setFilters(value: string): void {
    if (this.searchType === ContactsSearchTypeEnum.contacts) {
      this.filters = [
        {
          column: 'Caption',
          operator: Operator.Contains,
          value1: value,
        },
      ];
    } else {
      this.filters = [
        {
          column: 'Caption',
          operator: Operator.Contains,
          value1: value,
        },
        {
          column: 'IdentificationCommonCategory',
          operator: Operator.Equal,
          value1: 'BarNumber',
        },
      ];
    }
  }
}
