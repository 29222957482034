import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';
import {
  CourtSummary,
  CourtSummaryItemTree,
  FieldCategory,
  FilingProfileSummary,
  FilingProfileSummaryItemTree,
  StringSelectionFieldDefinition,
} from '@fsx/fsx-shared';
import { startWith, debounceTime, map, Observable } from 'rxjs';
import { FormControlWithModel } from '../../../models';
import { DropdownOption } from '../../../types';

@Component({
  selector: 'fsx-base-search-component',
  template: '',
})
export class FsxBaseSearchComponent implements OnInit {
  @ViewChild(MatAutocompleteTrigger, { read: MatAutocompleteTrigger })
  matAutoComplete!: MatAutocompleteTrigger;

  @Input() useEfmKey: boolean = false;

  @Output() optionSelected: EventEmitter<DropdownOption<void>> =
    new EventEmitter<DropdownOption<void>>();

  @Output() searchTextChangedEvent = new EventEmitter<string>();
  @Input() isLoading!: boolean;

  public fieldDefinition!: StringSelectionFieldDefinition;
  public caption = 'Search';
  public helpText = '';
  public hint = 'Type or Select';
  public fieldType = FieldCategory.Selection;
  public width = '300px';
  public inputFormControl!: FormControlWithModel<StringSelectionFieldDefinition>;
  public dropdownOptions!: DropdownOption<void>[];
  public focusedElement!: string | undefined;
  public selectionInput: FormControl = new FormControl('');
  public filteredOptions!: Observable<DropdownOption<void>[]>;
  public invalidWords = ['of', '-'];
  public options: (CourtSummary | FilingProfileSummary)[] = [];

  ngOnInit(): void {
    this.selectionDisplayFn = this.selectionDisplayFn.bind(this);
  }

  public onSearchTextChanged() {
    this.searchTextChangedEvent.emit(this.selectionInput.value);
  }

  public getErrorMessage(): string {
    return Object.values({ ...this.inputFormControl.errors })[0];
  }

  public setFocusedElement(): void {
    this.focusedElement = this.matAutoComplete.activeOption?.value;
  }

  public clearSelection(): void {
    this.inputFormControl.setValue(null);
  }

  public selectionDisplayFn(value: string): string {
    const option = this.dropdownOptions.find((option) => option.name === value);

    if (option) {
      return option.caption;
    }

    return value;
  }

  public selectSingleOption(option?: DropdownOption<void>): void {
    const value = option
      ? this.useEfmKey
        ? option.efmKey
        : option.name
      : this.focusedElement;
    const selectedOption = this.dropdownOptions.find((option) => {
      if (this.useEfmKey) {
        return option.efmKey === value;
      }
      return option.name === value;
    });

    if (selectedOption) {
      this.optionSelected.emit(selectedOption);
    }

    this.setSingleSelectionValue(value);
  }

  public onSingleSelectDropdownClose(): void {
    let value: string | undefined;
    if (this.selectionInput.value) {
      const option = this.dropdownOptions.find(
        (op) => op.name === this.selectionInput.value,
      );
      value = option ? option.name : this.focusedElement;
    }
    this.setSingleSelectionValue(value);
  }

  public setDropdownOptions(): void {
    this.filteredOptions = this.selectionInput.valueChanges.pipe(
      startWith(''),
      debounceTime(500),
      map((value: string) => {
        if (!value) {
          return this.dropdownOptions;
        }

        // Control already has a value, show all options, or filter by user input
        if (this.inputFormControl.value && this.inputFormControl.value.length) {
          return this.dropdownOptions;
        } else {
          return this.filterDropdownOptions(value);
        }
      }),
    );
  }

  public filterDropdownOptions(value: string): DropdownOption<void>[] {
    const valuesArray = value.trim().toLowerCase().split(' ');
    const options = this.dropdownOptions.map((option: DropdownOption<void>) => {
      option.rating = 0;
      valuesArray.forEach((value) => {
        option.keywords?.forEach((keyword) => {
          if (keyword.word.startsWith(value) && option.rating !== undefined) {
            option.rating += keyword.value;
          }
        });
      });
      return option;
    });
    const optionsRated = options.filter((option) => option.rating);
    return this.sortByRating(optionsRated);
  }

  public setSingleSelectionValue(value: string | undefined): void {
    if (value) {
      this.inputFormControl.setValue([value.trim()]);
    } else {
      this.inputFormControl.setValue(null);
      this.inputFormControl.markAsTouched();
    }
  }

  public traverseTree(
    element: CourtSummaryItemTree | FilingProfileSummaryItemTree,
  ): DropdownOption<void>[] {
    let itemsArray: DropdownOption<void>[] = [];
    let options = [...this.options];

    if (element.children) {
      element.children.forEach((child) => {
        itemsArray = [...itemsArray, ...this.traverseTree(child)];

        itemsArray.forEach((item) => {
          this.addWordToKeyWords(element.caption, item, true);
        });
      });
    }

    if (element.items) {
      this.options = [...options, ...element.items] as FilingProfileSummary[];
      element.items.forEach((item) => {
        const dropdownOption = this.createDropdownOption(item);
        this.addWordToKeyWords(element.caption, dropdownOption, true);
        itemsArray.push(dropdownOption);
      });
    }

    return itemsArray;
  }

  public createDropdownOption(
    _item: CourtSummary | FilingProfileSummary,
  ): DropdownOption<void> {
    return {} as DropdownOption<void>;
  }

  public addWordToKeyWords(
    caption: string | null,
    dropdownOption: DropdownOption<void>,
    addToPath: boolean,
  ): void {
    if (addToPath && caption && !dropdownOption.itemPath?.includes(caption)) {
      dropdownOption.itemPath?.push(caption);
    }

    caption?.split(' ').forEach((word) => {
      const words = dropdownOption.keywords?.map((keyword) => keyword.word);

      if (words && !words.includes(word) && !this.invalidWords.includes(word)) {
        dropdownOption.keywords?.push({
          word: word.toLowerCase(),
          value: addToPath ? 1 : 100,
        });
      }
    });
  }

  public sortByRating(options: DropdownOption<void>[]): DropdownOption<void>[] {
    return options.sort((a, b) => {
      if (a.rating && b.rating && a.rating > b.rating) {
        return -1;
      }
      if (a.rating && b.rating && a.rating < b.rating) {
        return 1;
      }
      return 0;
    });
  }
}
