import { Inject, Injectable, InjectionToken } from '@angular/core';
import {
  ContactPerson,
  PersonSpec,
  IValidatable,
  FilingProfile,
} from '../../../types';
import {
  FsxPersonNameValidationService,
  IPersonNameValidationService,
} from './person-name-validation.service';

export const FsxPersonValidationService =
  new InjectionToken<IPersonValidationService>('FsxPersonValidationService');

export interface IPersonValidationService {
  validatePerson(
    person: ContactPerson | null | undefined,
    spec: PersonSpec | null | undefined,
    scope: IValidatable,
    filingProfile: FilingProfile,
  ): boolean;
}

@Injectable()
export class PersonValidationService implements IPersonValidationService {
  constructor(
    @Inject(FsxPersonNameValidationService)
    private readonly personNameValidationService: IPersonNameValidationService,
  ) {}

  public validatePerson(
    person: ContactPerson | null | undefined,
    spec: PersonSpec | null | undefined,
    scope: IValidatable,
    filingProfile: FilingProfile,
  ): boolean {
    if (!spec) {
      return true;
    }

    if (spec.personalName) {
      return this.personNameValidationService.validatePersonName(
        person?.personalName,
        spec.personalName,
        scope,
        filingProfile,
      );
    }

    return true;
  }
}
