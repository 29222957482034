import { Inject, Injectable, InjectionToken } from '@angular/core';
import {
  CaseRequestViewModel,
  FilingProfile,
  RequestDocumentViewModel,
  RequestDocumentCreatedParams,
  CombinedFilingData,
  ICaseRequestUpdateService,
  ICaseRequestBuilderService,
  ICreateRequestDocumentService,
  ICaseRequestHelperService,
  FsxCaseRequestHelperService,
  FsxCaseRequestBuilderService,
  FsxCaseRequestUpdateService,
  FsxCreateRequestDocumentService,
} from '@fsx/fsx-shared';
import { IUploadedFile } from '@fsx/ui-components';
import { Subject, Observable, mergeMap, tap } from 'rxjs';
import {
  DocumentAndFile,
  FsxUploadFilesOrchestrationService,
  IUploadFilesOrchestrationService,
  UploadFilesParams,
} from './upload-files-orchestration.service';

export const FsxAddSupportingDocumentOrchestrationService =
  new InjectionToken<IAddSupportingDocumentOrchestrationService>(
    'FsxAddSupportingDocumentOrchestrationService',
  );

export interface AddSupportingDocumentParams {
  filingId: string;
  caseRequest: CaseRequestViewModel;
  filingProfile: FilingProfile;
  requestDocument: RequestDocumentViewModel;
  leadDocumentIndex: number;
  uploadedFiles: IUploadedFile[];
  combinedFilingData: CombinedFilingData;
}

export interface IAddSupportingDocumentOrchestrationService {
  /**
   * The pipeline of orchestration steps needed to upload files and create a supporting document.
   */
  addSupportingDocumentStream$: Observable<CaseRequestViewModel>;

  /**
   * A public method to allow the orchestration to be triggered.
   */
  addSupportingDocument(params: AddSupportingDocumentParams): void;
}

@Injectable()
export class AddSupportingDocumentOrchestrationService
  implements IAddSupportingDocumentOrchestrationService
{
  /**
   * A subject to use as the trigger for the orchestration.
   */
  private addSupportingDocumentParams$$ =
    new Subject<AddSupportingDocumentParams>();

  /**
   * The pipeline of orchestration steps needed to upload files and create a supporting document.
   */
  addSupportingDocumentStream$: Observable<CaseRequestViewModel> =
    this.addSupportingDocumentParams$$.pipe(
      mergeMap((addSupportingDocumentParams: AddSupportingDocumentParams) => {
        const { filingId, uploadedFiles, leadDocumentIndex, caseRequest } =
          addSupportingDocumentParams;
        const caseRequestBackup = {
          ...addSupportingDocumentParams.caseRequest,
        } as CaseRequestViewModel;
        const isLeadDocument: boolean =
          this.caseRequestHelperService.getLastDocumentIsLeadDocument(
            caseRequest,
          );

        const cases = caseRequest?.cases;

        if (!cases) {
          throw new Error('No cases when adding a document');
        }
        if (cases.length === 0) {
          throw new Error(
            'No cases when adding a document; cases exists but is empty',
          );
        }
        if (!cases[0].caseId) {
          throw new Error('No case id when adding a document');
        }

        return this.createRequestDocumentService
          .bulkCreateRequestDocumentFromFiles(
            uploadedFiles,
            isLeadDocument,
            cases[0].caseId,
          )
          .pipe(
            mergeMap(
              (
                requestDocumentCreatedParams: RequestDocumentCreatedParams[],
              ) => {
                const requestDocuments: RequestDocumentViewModel[] =
                  requestDocumentCreatedParams.map((p) => p.requestDocument);
                const documentIndex: number = leadDocumentIndex + 1;
                return this.caseRequestBuilderService
                  .bulkAddRequestDocument({
                    ...addSupportingDocumentParams,
                    documentIndex,
                    requestDocuments,
                  })
                  .pipe(
                    mergeMap(() => {
                      return this.caseRequestUpdateService
                        .optimisticPutOrRestore(
                          filingId,
                          caseRequest,
                          caseRequestBackup,
                        )
                        .pipe(
                          tap(() => {
                            const documentsAndFiles: DocumentAndFile[] =
                              requestDocuments.map(
                                (
                                  requestDocument: RequestDocumentViewModel,
                                  index: number,
                                ) => {
                                  const uploadedFile: IUploadedFile =
                                    uploadedFiles[index];
                                  return new DocumentAndFile(
                                    requestDocument,
                                    uploadedFile,
                                  );
                                },
                              );
                            const uploadFilesParams: UploadFilesParams = {
                              documentsAndFiles,
                            };
                            this.uploadFilesOrchestrationService.uploadFiles(
                              uploadFilesParams,
                            );
                          }),
                        );
                    }),
                  );
              },
            ),
          );
      }),
    );

  constructor(
    @Inject(FsxCaseRequestBuilderService)
    private readonly caseRequestBuilderService: ICaseRequestBuilderService,
    @Inject(FsxCaseRequestUpdateService)
    private readonly caseRequestUpdateService: ICaseRequestUpdateService,
    @Inject(FsxCaseRequestHelperService)
    private readonly caseRequestHelperService: ICaseRequestHelperService,
    @Inject(FsxCreateRequestDocumentService)
    private readonly createRequestDocumentService: ICreateRequestDocumentService,
    @Inject(FsxUploadFilesOrchestrationService)
    private readonly uploadFilesOrchestrationService: IUploadFilesOrchestrationService,
  ) {}

  /**
   * A public method to allow the orchestration to be triggered.
   */
  addSupportingDocument(params: AddSupportingDocumentParams): void {
    this.addSupportingDocumentParams$$.next(params);
  }
}
