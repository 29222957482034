import { DocumentsGridRow } from '../documents-grid/documents-grid.model';
import { FsxReferenceResolver } from '../../shared/resolvers/list-reference.resolver';
import { Moment } from 'moment';
import {
  BehaviorSubject,
  Observable,
  Subject,
  combineLatest,
  distinctUntilChanged,
  filter,
  map,
  of,
  startWith,
  switchMap,
  takeUntil,
  tap,
} from 'rxjs';
import { DocumentInfoAndUploadedFile } from '../services/upload-additional-field-files-orchestration.service';
import {
  SimpleChanges,
  SimpleChange,
  OnChanges,
  OnDestroy,
  Input,
  Output,
  EventEmitter,
  ViewChild,
  ViewChildren,
  QueryList,
  Inject,
  Component,
} from '@angular/core';
import {
  RequestDocumentViewModel,
  CombinedFilingData,
  DocumentInfo,
  FieldCategory,
  TextFieldDefinition,
  DocumentCommonCategoryDomainCategoryValue,
  AdditionalFieldValue,
  AdditionalFieldSpec,
  DocumentSpec,
  NamedList,
  CasePartyViewModel,
  RequestParticipantViewModel,
  ParticipantFieldSpec,
  RequestDocumentParticipantViewModel,
  FilingProfileHelperService,
  ICaseRequestUpdateService,
  CreateDocumentInfoService,
  RequestDocumentCaseViewModel,
  FilingProfile,
  NamedListItem,
  AccessCommonCategoryDomainCategoryValue,
  IFilingApiService,
  FsxCaseRequestUpdateService,
  FsxFilingApiService,
  ParticipantFieldDefinition,
} from '@fsx/fsx-shared';
import {
  FilesUploadedEventParams,
  FsxTextComponent,
  FsxBasicSingleSelectionComponent,
  FsxParticipantComponent,
  SelectionFieldType,
  DropdownOption,
  SelectionFieldDefinition,
} from '@fsx/ui-components';
import {
  FsxValidateDocumentsOrchestrationService,
  IValidateDocumentsOrchestrationService,
  ValidateDocumentFormParams,
} from '../../filing-editor/services/orchestration/validate-documents-orchestration.service';
import {
  FsxPartyDataService,
  IPartyDataService,
} from '../../filing-editor/services/party-data.service';
import {
  FsxParticipantDataService,
  IParticipantDataService,
} from '../../filing-editor/services/participant-data.service';
import {
  FsxUpdateDocumentOrchestrationService,
  IUpdateDocumentOrchestrationService,
} from '../services/update-document-orchestration.service';
import {
  AssociatedPartyType,
  FsxAddAssociatedPartyOrchestrationService,
  IAddAssociatedPartyOrchestrationService,
} from '../services/add-associated-party-orchestration.service';
import {
  FsxUploadFilesOrchestrationService,
  IUploadFilesOrchestrationService,
} from '../services/upload-files-orchestration.service';
import {
  FsxCombinedFilingDataService,
  ICombinedFilingDataService,
} from '../../filing-editor/services/combined-filing-data.service';
import {
  FsxValidationErrorsService,
  IValidationErrorsService,
  ValidationError,
} from '../../filing-editor/services/validation-errors.service';
import { FormControl } from '@angular/forms';

export enum DocumentTypeEnum {
  Lead = 'leadDocument',
  Supporting = 'supportingDocument',
}

export interface UpdateDocumentEventParams {
  documentIndex: number;
  requestDocument: RequestDocumentViewModel;
  partialRequestDocument: Partial<RequestDocumentViewModel>;
  combinedFilingData: CombinedFilingData | null;
}

interface DocumentFormComponentBindings extends SimpleChanges {
  validationTimestamp: SimpleChange;
}

export interface FilesUploadedFromAdditionalFieldEventParams
  extends FilesUploadedEventParams {
  documentInfoAndUploadedFiles: DocumentInfoAndUploadedFile[];
}

export interface FileRemovedFromAdditionalFieldEventParams {
  documentInfo: DocumentInfo;
  documentIndex: number;
}

/**
 * An interface to define wrap the related associated party view model properties into a single type.
 */
export interface AssociatedPartyViewModel {
  /**
   * The ParticipantFieldSpec object for the associated party field or null if not set.
   */
  participantFieldSpec: ParticipantFieldSpec | null;

  /**
   * The array of available CaseParty objects for the associated party field.
   */
  availableParticipants: CasePartyViewModel[];

  /**
   * Temporary. The ParticipantFieldComponent should be generating these.
   */
  dropdownOptions: DropdownOption<void>[];

  /**
   * The array of selected RequestPartiicpant objects for the associated party field.
   */
  selectedParticipants: RequestParticipantViewModel[];

  /**
   * A boolean flag to determine whether or not there are any participants for the associated party field.
   */
  hasParticipants: boolean;

  /**
   * A boolean flag to determine whether participants are required for the associated party field.
   */
  isRequired: boolean;
}

/**
 * An interface to define the view model object to use in the componnet template.
 */
export interface DocumentFormViewModel {
  /**
   * A boolean value to indicate whether the form fields should be disabled or not
   */
  disabled: boolean;

  /**
   * All of the view model properties for the "Filed By" associated party.
   */
  filedByVm: AssociatedPartyViewModel;

  /**
   * All of the view model properties for the "As To" associated party.
   */
  asToVm: AssociatedPartyViewModel;

  /**
   * The collection of AdditionalFieldSpec objects from the current RequestDocument's DocumentSpec object.
   */
  additionalFieldSpecs: AdditionalFieldSpec[];

  /**
   * The collection of validation errors for the "Associated Party" section
   */
  associatedPartyValidationErrors: ValidationError[];
}

@Component({
  selector: 'fsx-document-form',
  templateUrl: './document-form.component.html',
  styleUrls: ['./document-form.component.scss'],
})
export class DocumentFormComponent implements OnChanges, OnDestroy {
  @Input() combinedFilingData!: CombinedFilingData;
  @Input() documentsGridRow!: DocumentsGridRow;
  @Input() validationTimestamp!: Moment;
  @Input() resolver!: FsxReferenceResolver;
  @Output() filesUploadedFromAdditionalFieldEvent =
    new EventEmitter<FilesUploadedFromAdditionalFieldEventParams>();

  @ViewChild('fileNameField') fileNameField!: FsxTextComponent;
  @ViewChild('documentTypeField')
  documentTypeField!: FsxBasicSingleSelectionComponent;
  @ViewChild('accessTypeField')
  accessTypeField?: FsxBasicSingleSelectionComponent;
  @ViewChild('documentTitleField') documentTitleField!: FsxTextComponent;
  @ViewChildren('filedByField')
  filedByFields!: QueryList<FsxParticipantComponent>;
  @ViewChildren('asToField') asToFields!: QueryList<FsxParticipantComponent>;
  @ViewChildren('additionalFields')
  additionalFields!: QueryList<FsxParticipantComponent>;

  fieldType = FieldCategory;
  selectionType = SelectionFieldType.StringSelectionFieldDefinition;
  documentTypeList: DropdownOption<void>[] = [];
  accessTypeList: DropdownOption<void>[] = [];

  fileNameFieldDefinition!: TextFieldDefinition;
  documentTitleFieldDefinition!: TextFieldDefinition;

  private documentCategories!: DocumentCommonCategoryDomainCategoryValue[];

  /**
   * The array of AdditionalFieldValue objects for the RequestDocumentCase.
   * (There are other AdditionalFieldValue arrays to not confuse this with)
   */
  public documentCaseAdditionalFieldValues: AdditionalFieldValue[] = [];

  /**
   * A boolean flag to determine whether the user has clicked the "Add Associated Party" button
   * to display the "Filed By" and "As To" dropdowns.
   */
  isAddingAssociatedParty = false;

  accessTypeFieldDefinition!: SelectionFieldDefinition | null;
  associatedPartyAsTo!: RequestDocumentParticipantViewModel;
  associatedPartyFiledBy!: RequestDocumentParticipantViewModel;

  documentAdditionalFieldValues!: AdditionalFieldValue[];
  filedByAdditionalFieldValues!: AdditionalFieldValue[];
  asToAdditionalFieldValues!: AdditionalFieldValue[];
  documentFileUploadDocumentInfos!: DocumentInfo[];
  filedByFileUploadDocumentInfos!: DocumentInfo[];
  asToFileUploadDocumentInfos!: DocumentInfo[];

  showAdditionalFields = false;

  /**
   * A subject to trigger the tearing down of the subscriptions when the component is destroyed.
   */
  private destroy$: Subject<void> = new Subject<void>();

  /**
   * The DocumentGridRow object stored in a BehaviorSubject so that we can use it as part of the observable stream.
   */
  private documentsGridRow$$ = new BehaviorSubject<DocumentsGridRow | null>(
    null,
  );

  /**
   * The DocumentGridRow object exposed as an Observable with a filter to ignore the initial null value.
   */
  private documentsGridRow$: Observable<DocumentsGridRow> =
    this.documentsGridRow$$.asObservable().pipe(
      filter((documentsGridRow: DocumentsGridRow | null) => {
        return documentsGridRow !== null;
      }),
      map((documentsGridRow: DocumentsGridRow | null) => {
        return documentsGridRow as DocumentsGridRow;
      }),
    );

  /**
   * The DocumentSpec object for the RequestDocument's selected category.
   */
  private documentSpec$: Observable<DocumentSpec | null> =
    this.documentsGridRow$.pipe(
      switchMap((documentsGridRow: DocumentsGridRow) => {
        const { requestDocument } = documentsGridRow;
        return this.combinedFilingDataService.getDocumentSpecForDocument(
          requestDocument,
        );
      }),
    );

  /**
   * The collection of AdditionalFieldSpec objects from the current RequestDocument's DocumentSpec object.
   */
  private additionalFieldSpecs$: Observable<AdditionalFieldSpec[]> =
    this.documentSpec$.pipe(
      map((documentSpec: DocumentSpec | null) => {
        const additionalFieldSpecs: AdditionalFieldSpec[] =
          documentSpec?.additionalFields || [];
        return additionalFieldSpecs;
      }),
    );

  /**
   * The "Filed By" ParticipantFieldSpec object from the current RequestDocument's DocumentSpec
   */
  private filedByParticipantFieldSpec$: Observable<ParticipantFieldSpec | null> =
    this.documentSpec$.pipe(
      map((documentSpec: DocumentSpec | null) => {
        const filedByParticipantFieldSpec: ParticipantFieldSpec | null =
          documentSpec?.filedBy || null;
        return filedByParticipantFieldSpec;
      }),
    );

  /**
   * The addional list name as taken from the ParticipantFieldSpec's ParticipantFieldDefinition object. We use
   * this to lookup the additional list which in turn restricts which participants can be displayed in the "Filed By"
   * associated party field.
   */
  private filedByAdditionalListName$: Observable<string | null> =
    this.filedByParticipantFieldSpec$.pipe(
      map((participantFieldSpec: ParticipantFieldSpec | null) => {
        const participantFieldDefinition:
          | ParticipantFieldDefinition
          | undefined = participantFieldSpec?.participantFieldDefinition;
        const additionalListName: string | null =
          participantFieldDefinition?.allowedParticipantCategoriesList
            .additionalListName || null;
        return additionalListName;
      }),
    );

  /**
   * The additional list which we use to restrict the available participants that appear in the "Filed By"
   * associated party dropdown to just those with the allowed category names ias defined by the list.
   */
  private filedByAdditionalList$: Observable<NamedList | null> = combineLatest([
    this.combinedFilingDataService.combinedFilingData$,
    this.filedByAdditionalListName$,
  ]).pipe(
    map(
      ([combinedFilingData, listName]: [CombinedFilingData, string | null]) => {
        const { filingProfile } = combinedFilingData;
        return (
          filingProfile.additionalLists.find((list: NamedList) => {
            return list.name === listName;
          }) || null
        );
      },
    ),
  );

  /**
   * The collection of CaseParty objects to display in the "Filed By" associated party field.
   */
  private availableFiledByParticipants$: Observable<CasePartyViewModel[]> =
    combineLatest([
      this.partyDataService.parties$,
      this.documentSpec$,
      this.filedByAdditionalList$,
    ]).pipe(
      map(
        ([parties, documentSpec, additionalList]: [
          CasePartyViewModel[],
          DocumentSpec | null,
          NamedList | null,
        ]) => {
          return parties.filter((party: CasePartyViewModel) => {
            // Check to include only those parties with an allowed participant category
            const allowedNames: string[] =
              additionalList?.items.map((namedListItem: NamedListItem) => {
                return namedListItem.name;
              }) || [];
            const isAllowedCategoryName: boolean = allowedNames.some(
              (name: string) =>
                name.toLocaleLowerCase() ===
                party.participantCategory?.name.toLocaleLowerCase(),
            );

            // Check to exclude new particpants if the spec doesn't allow them.
            const specAllowNewParticipants: boolean | undefined =
              documentSpec?.filedBy?.participantFieldDefinition
                .allowNewParticipants;
            const allowNewParticipants = !!specAllowNewParticipants;
            const isNewParticipant = party.efmKey === null;
            const isAllowedNewParticipant =
              allowNewParticipants && isNewParticipant;

            // Check to exclude existing particpants if the spec doesn't allow them.
            const specAllowExistingParticipants: boolean | undefined =
              documentSpec?.filedBy?.participantFieldDefinition
                .allowExistingParticipants;
            const allowExistingParticipants = !!specAllowExistingParticipants;
            const isExistingParticipant = party.efmKey !== null;
            const isAllowedExistingParticipant =
              allowExistingParticipants && isExistingParticipant;

            // Check to exclude default participants.
            const isDefaultParticipant = !party.caption;

            // Return the party only if all test conditions pass
            const result =
              isAllowedCategoryName &&
              (isAllowedNewParticipant || isAllowedExistingParticipant) &&
              !isDefaultParticipant;
            return result;
          });
        },
      ),
    );

  /**
   * The dropdown options to pass into the "Filed By" associated party field.
   */
  private filedByDropdownOptions$: Observable<DropdownOption<void>[]> =
    this.availableFiledByParticipants$.pipe(
      map((parties: CasePartyViewModel[]) => {
        return (
          parties.map((p: CasePartyViewModel) => {
            const dropdownOption: DropdownOption<void> = {
              name: p.participantName,
              caption: p.caption,
              selected: false,
            };
            return dropdownOption;
          }) || []
        );
      }),
    );

  /**
   * The "As To" ParticipantFieldSpec object from the current RequestDocument's DocumentSpec
   */
  private asToParticipantFieldSpec$: Observable<ParticipantFieldSpec | null> =
    this.documentSpec$.pipe(
      map((documentSpec: DocumentSpec | null) => {
        const asToParticipantFieldSpec: ParticipantFieldSpec | null =
          documentSpec?.asTo || null;
        return asToParticipantFieldSpec;
      }),
    );

  /**
   * The addional list name as taken from the ParticipantFieldSpec's ParticipantFieldDefinition object. We use
   * this to lookup the additional list which in turn restricts which participants can be displayed in the "As To"
   * associated party field.
   */
  private asToAdditionalListName$: Observable<string | null> =
    this.asToParticipantFieldSpec$.pipe(
      map((participantFieldSpec: ParticipantFieldSpec | null) => {
        const participantFieldDefinition:
          | ParticipantFieldDefinition
          | undefined = participantFieldSpec?.participantFieldDefinition;
        const additionalListName: string | null =
          participantFieldDefinition?.allowedParticipantCategoriesList
            .additionalListName || null;
        return additionalListName;
      }),
    );

  /**
   * The additional list which we use to restrict the available participants that appear in the "As To"
   * associated party dropdown to just those with the allowed category names ias defined by the list.
   */
  private asToAdditionalList$: Observable<NamedList | null> = combineLatest([
    this.combinedFilingDataService.combinedFilingData$,
    this.asToAdditionalListName$,
  ]).pipe(
    map(
      ([combinedFilingData, listName]: [CombinedFilingData, string | null]) => {
        const { filingProfile } = combinedFilingData;
        return (
          filingProfile.additionalLists.find((list: NamedList) => {
            return list.name === listName;
          }) || null
        );
      },
    ),
  );

  /**
   * The collection of CaseParty objects to display in the As To" associated party field.
   */
  private availableAsToParticipants$: Observable<CasePartyViewModel[]> =
    combineLatest([
      this.partyDataService.parties$,
      this.documentSpec$,
      this.asToAdditionalList$,
    ]).pipe(
      map(
        ([parties, documentSpec, additionalList]: [
          CasePartyViewModel[],
          DocumentSpec | null,
          NamedList | null,
        ]) => {
          return parties.filter((party: CasePartyViewModel) => {
            const allowedNames: string[] =
              additionalList?.items.map((namedListItem: NamedListItem) => {
                return namedListItem.name;
              }) || [];
            const isAllowedCategoryName: boolean = allowedNames.some(
              (name: string) =>
                name.toLocaleLowerCase() ===
                party.participantCategory?.name.toLocaleLowerCase(),
            );

            // Check to exclude new particpants if the spec doesn't allow them.
            const specAllowNewParticipants: boolean | undefined =
              documentSpec?.asTo?.participantFieldDefinition
                .allowNewParticipants;
            const allowNewParticipants = !!specAllowNewParticipants;
            const isNewParticipant = party.efmKey === null;
            const isAllowedNewParticipant =
              allowNewParticipants && isNewParticipant;

            // Check to exclude existing particpants if the spec doesn't allow them.
            const specAllowExistingParticipants: boolean | undefined =
              documentSpec?.asTo?.participantFieldDefinition
                .allowExistingParticipants;
            const allowExistingParticipants = !!specAllowExistingParticipants;
            const isExistingParticipant = party.efmKey !== null;
            const isAllowedExistingParticipant =
              allowExistingParticipants && isExistingParticipant;

            // Check to exclude default participants.
            const isDefaultParticipant = !party.caption;

            // Return the party only if all test conditions pass
            const result =
              isAllowedCategoryName &&
              (isAllowedNewParticipant || isAllowedExistingParticipant) &&
              !isDefaultParticipant;
            return result;
          });
        },
      ),
    );

  /**
   * The dropdown options to pass into the "As To" associated party field.
   */
  private asToDropdownOptions$: Observable<DropdownOption<void>[]> =
    this.availableAsToParticipants$.pipe(
      map((parties: CasePartyViewModel[]) => {
        return (
          parties.map((p: CasePartyViewModel) => {
            const dropdownOption: DropdownOption<void> = {
              name: p.participantName,
              caption: p.caption,
              selected: false,
            };
            return dropdownOption;
          }) || []
        );
      }),
    );

  /**
   * A boolean value indicating whether "Filed By" participants are required to be added
   * in the "Associated Parties" section or not.
   */
  private isFiledByRequired$: Observable<boolean> = this.documentSpec$.pipe(
    map((documentSpec: DocumentSpec | null) => {
      const filedByFieldDefinition: ParticipantFieldDefinition | undefined =
        documentSpec?.filedBy?.participantFieldDefinition;
      const isFiledByRequired = filedByFieldDefinition
        ? filedByFieldDefinition.minRequired > 0
        : false;
      return isFiledByRequired;
    }),
  );

  /**
   * A boolean value indicating whether "Filed By" participants are required to be added
   * in the "Associated Parties" section or not.
   */
  private isAsToRequired$: Observable<boolean> = this.documentSpec$.pipe(
    map((documentSpec: DocumentSpec | null) => {
      const asToFieldDefinition: ParticipantFieldDefinition | undefined =
        documentSpec?.asTo?.participantFieldDefinition;
      const isFiledByRequired = asToFieldDefinition
        ? asToFieldDefinition.minRequired > 0
        : false;
      return isFiledByRequired;
    }),
  );

  /**
   * The array of "As To" RequestPartiicpant objects which we pass on to the "As To" instance
   * of the ParticipantFieldComponent.
   */
  private selectedAsToParticipants$ =
    this.participantDataService.participants$.pipe(
      map((participants: RequestParticipantViewModel[]) => {
        const requestDocumentCases: RequestDocumentCaseViewModel[] =
          this.documentsGridRow.requestDocument.cases || [];
        return requestDocumentCases.flatMap(
          (rdq: RequestDocumentCaseViewModel) => {
            return rdq.filedAsTo!?.map(
              (rdq: RequestDocumentParticipantViewModel) => {
                // Get asTo Participants to be able to edit and save to it's appropriate additional fields
                this.associatedPartyAsTo = rdq;

                // Use the FiledAsTo participantName to lookup the full RequestParticipant object held in the ParticipantDataService.
                const asToParticipant: RequestParticipantViewModel | undefined =
                  participants.find((p: RequestParticipantViewModel) => {
                    return rdq.participantName === p.name;
                  })!;

                // Return the found participant
                return asToParticipant;
              },
            );
          },
        );
      }),
      distinctUntilChanged(
        (
          prev: RequestParticipantViewModel[],
          cur: RequestParticipantViewModel[],
        ) => {
          // If we have the same participants as before then there is no need to emit; we're already displaying them.
          // - We don't want to re-generate the PartiicpantTableComponent instance for RequestPartiicpant object.
          const areEqual = JSON.stringify(prev) === JSON.stringify(cur);
          return areEqual;
        },
      ),
    );

  /**
   * The array of "Filed By" RequestPartiicpant objects which we pass on to the "Filed By" instance
   * of the ParticipantFieldComponent.
   */
  private selectedFiledByParticipants$: Observable<
    RequestParticipantViewModel[]
  > = this.participantDataService.participants$.pipe(
    map((participants: RequestParticipantViewModel[]) => {
      const requestDocumentCases: RequestDocumentCaseViewModel[] =
        this.documentsGridRow.requestDocument.cases || [];
      const selectedFiledByParticipants = requestDocumentCases.flatMap(
        (rdq: RequestDocumentCaseViewModel) => {
          return rdq.filedBy!?.map(
            (rdq: RequestDocumentParticipantViewModel) => {
              // Get filedBy Participants to be able to edit and save to it's appropriate additional fields
              this.associatedPartyFiledBy = rdq;

              // Use the FiledBy participantName to lookup the full RequestParticipant object held in the ParticipantDataService.
              const filedByParticipant:
                | RequestParticipantViewModel
                | undefined = participants.find(
                (p: RequestParticipantViewModel) => {
                  return rdq.participantName === p.name;
                },
              )!;

              // Return the found participant
              return filedByParticipant;
            },
          );
        },
      );
      return selectedFiledByParticipants;
    }),
    distinctUntilChanged(
      (
        prev: RequestParticipantViewModel[],
        cur: RequestParticipantViewModel[],
      ) => {
        // If we have the same participants as before then there is no need to emit; we're already displaying them.
        // - We don't want to re-generate the PartiicpantTableComponent instance for RequestPartiicpant object.
        const areEqual = JSON.stringify(prev) === JSON.stringify(cur);
        return areEqual;
      },
    ),
  );

  /**
   * All of the view model properties for the "Filed By" associated party field.
   */
  private filedByVm$: Observable<AssociatedPartyViewModel> = combineLatest([
    this.filedByParticipantFieldSpec$,
    this.selectedFiledByParticipants$,
    this.isFiledByRequired$,
    this.availableFiledByParticipants$,
    this.filedByDropdownOptions$,
  ]).pipe(
    map(
      ([
        participantFieldSpec,
        selectedParticipants,
        isRequired,
        availableParticipants,
        dropdownOptions,
      ]) => {
        const hasParticipants = selectedParticipants.length > 0;
        return {
          participantFieldSpec,
          selectedParticipants,
          isRequired,
          hasParticipants,
          availableParticipants,
          dropdownOptions,
        };
      },
    ),
  );

  /**
   * All of the view model properties for the "As To" associated party field.
   */
  private asToVm$: Observable<AssociatedPartyViewModel> = combineLatest([
    this.asToParticipantFieldSpec$,
    this.selectedAsToParticipants$,
    this.isAsToRequired$,
    this.availableAsToParticipants$,
    this.asToDropdownOptions$,
  ]).pipe(
    map(
      ([
        participantFieldSpec,
        selectedParticipants,
        isRequired,
        availableParticipants,
        dropdownOptions,
      ]) => {
        const hasParticipants = selectedParticipants.length > 0;
        return {
          participantFieldSpec,
          selectedParticipants,
          isRequired,
          hasParticipants,
          availableParticipants,
          dropdownOptions,
        };
      },
    ),
  );

  /**
   * The collection of ValidationErrors for the "Associated Party" section.
   */
  associatedPartyValidationErrors$: Observable<ValidationError[]> =
    this.documentsGridRow$.pipe(
      switchMap((documentGridRow: DocumentsGridRow) => {
        return this.validationErrorsService.getValidationErrorsForFormSection(
          documentGridRow.requestDocument.id!,
          'Associated Party',
        );
      }),
    );

  /**
   * The view model object which we subscribe to in the template.
   */
  vm$: Observable<DocumentFormViewModel> = combineLatest([
    this.uploadFilesOrchestrationService.isRunning$.pipe(startWith(false)),
    this.filedByVm$,
    this.asToVm$,
    this.additionalFieldSpecs$,
    this.associatedPartyValidationErrors$,
  ]).pipe(
    map(
      ([
        isRunning,
        filedByVm,
        asToVm,
        additionalFieldSpecs,
        associatedPartyValidationErrors,
      ]: [
        boolean,
        AssociatedPartyViewModel,
        AssociatedPartyViewModel,
        AdditionalFieldSpec[],
        ValidationError[],
      ]) => {
        return {
          disabled: isRunning, // disable the form controls if orchestration service stream is running.
          filedByVm,
          asToVm,
          additionalFieldSpecs,
          associatedPartyValidationErrors,
        };
      },
    ),
  );

  constructor(
    private filingProfileHelperService: FilingProfileHelperService,
    @Inject(FsxAddAssociatedPartyOrchestrationService)
    private readonly addAssociatedPartyOrchestrationService: IAddAssociatedPartyOrchestrationService,
    @Inject(FsxUpdateDocumentOrchestrationService)
    private readonly updateDocumentOrchestrationService: IUpdateDocumentOrchestrationService,
    @Inject(FsxFilingApiService)
    private readonly filingApiService: IFilingApiService,
    @Inject(FsxCaseRequestUpdateService)
    private readonly caseRequestUpdateService: ICaseRequestUpdateService,
    @Inject(FsxValidateDocumentsOrchestrationService)
    private readonly validateDocumentsOrchestrationService: IValidateDocumentsOrchestrationService,
    @Inject(FsxPartyDataService)
    private readonly partyDataService: IPartyDataService,
    @Inject(FsxParticipantDataService)
    private readonly participantDataService: IParticipantDataService,
    @Inject(FsxUploadFilesOrchestrationService)
    private readonly uploadFilesOrchestrationService: IUploadFilesOrchestrationService,
    @Inject(FsxCombinedFilingDataService)
    private readonly combinedFilingDataService: ICombinedFilingDataService,
    private readonly createDocumentInfoService: CreateDocumentInfoService,
    @Inject(FsxValidationErrorsService)
    private readonly validationErrorsService: IValidationErrorsService,
  ) {
    // Ensures that form-level errors show when we raise validation errors.
    this.validateDocumentsOrchestrationService.validateDocumentForm$
      .pipe(
        takeUntil(this.destroy$),
        tap((params: ValidateDocumentFormParams) => {
          this.validate(params.force);
        }),
      )
      .subscribe();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  ngOnChanges(bindings: DocumentFormComponentBindings) {
    // Pass the incoming DocumentGridRow object into the BehaviorSubject to use in the
    // observable stream which derives the vm$ view model.
    this.documentsGridRow$$.next(this.documentsGridRow);

    if (this.combinedFilingData) {
      const { filingProfile } = this.combinedFilingData;
      const isLeadDocument =
        this.documentsGridRow.requestDocument.isLeadDocument;

      this.setDocumentTypeList(this.combinedFilingData, isLeadDocument);
      this.setAccessTypeList(filingProfile);

      // this.resolver = new FsxReferenceResolver(filingProfile,
      //   { filingApi: this.filingApiService, filingId: this.combinedFilingData?.filing.id, caseRequestUpdateService: this.caseRequestUpdateService, cfd: this.combinedFilingData });

      if (this.documentsGridRow.requestDocument.isNew) {
        this.documentsGridRow.requestDocument.isNew = false;
      }

      if (
        bindings.validationTimestamp &&
        bindings.validationTimestamp.currentValue &&
        !bindings.validationTimestamp.isFirstChange()
      ) {
        this.validate();
      }

      this.fileNameFieldDefinition =
        this.filingProfileHelperService.getFirstDocumentSpecFileNameTextFieldDefinition(
          this.combinedFilingData,
          isLeadDocument,
        )!;
      this.documentTitleFieldDefinition =
        this.filingProfileHelperService.getFirstDocumentSpecTitleTextFieldDefinition(
          this.combinedFilingData,
          isLeadDocument,
        )!;

      // DocumentSpec doesn't yet have accessCategory as a SelectionFieldDefinition
      // see https://dev.azure.com/fileandserve/FileAndServeXpress/_workitems/edit/148563
      // So this hack is just to allow us to check for the property so it will appear once
      // that case is done, in which case we can revert to standard property access
      this.accessTypeFieldDefinition =
        filingProfile.documentAccessCategories.length > 0
          ? ({} as SelectionFieldDefinition)
          : null;

      // Set the selected as to and filed by participants...

      const requestDocumentCases: RequestDocumentCaseViewModel[] =
        this.documentsGridRow.requestDocument.cases || [];

      if (requestDocumentCases[0].additionalFieldValues) {
        this.documentCaseAdditionalFieldValues =
          requestDocumentCases[0].additionalFieldValues;
      }

      const caseRequestDocuments: RequestDocumentViewModel[] =
        this.combinedFilingData!?.caseRequest!.documents || [];
      const documentInfos = this.combinedFilingData.documentInfos || [];

      const docIndex: number = this.documentsGridRow.rowIndex;
      const documentCases: RequestDocumentCaseViewModel[] =
        caseRequestDocuments[docIndex].cases || [];
      if (documentCases.length > 0) {
        this.documentAdditionalFieldValues =
          documentCases[0].additionalFieldValues || [];
        const documentFileValues: string[] =
          this.documentAdditionalFieldValues.flatMap(
            (addlFieldValue: AdditionalFieldValue) => {
              return addlFieldValue.fileValues || [];
            },
          );
        this.documentFileUploadDocumentInfos = documentInfos.filter(
          (docInfo: DocumentInfo) => {
            return documentFileValues.includes(docInfo.id);
          },
        );

        const filedByParticipants: RequestDocumentParticipantViewModel[] =
          documentCases[0].filedBy || [];
        if (filedByParticipants.length > 0) {
          this.filedByAdditionalFieldValues =
            filedByParticipants[0].additionalFieldValues || [];
          this.filedByFileUploadDocumentInfos =
            this.getFileUploadDocmentInfosFromAdditionaFieldValues(
              this.filedByAdditionalFieldValues,
              documentInfos,
            );
        }

        const asToParticipants: RequestDocumentParticipantViewModel[] =
          documentCases[0].filedAsTo || [];
        if (asToParticipants.length > 0) {
          this.asToAdditionalFieldValues =
            asToParticipants[0].additionalFieldValues || [];
          this.asToFileUploadDocumentInfos =
            this.getFileUploadDocmentInfosFromAdditionaFieldValues(
              this.asToAdditionalFieldValues,
              documentInfos,
            );
        }
      }
    }
    if (this.documentsGridRow) {
      this.showAdditionalFields =
        !!this.documentsGridRow.requestDocument.category?.name;
    }
  }

  private getFileUploadDocmentInfosFromAdditionaFieldValues(
    additionalFieldValues: AdditionalFieldValue[],
    documentInfos: DocumentInfo[],
  ): DocumentInfo[] {
    if (additionalFieldValues) {
      const filedByFileValues: string[] = additionalFieldValues.flatMap(
        (addlFieldValue: AdditionalFieldValue) => {
          return addlFieldValue.fileValues || [];
        },
      );
      return documentInfos.filter((docInfo: DocumentInfo) => {
        return filedByFileValues.includes(docInfo.id);
      });
    }

    return [];
  }

  private setDocumentTypeList(
    combinedFilingData: CombinedFilingData,
    isLeadDocument: boolean,
  ) {
    this.documentCategories =
      this.filingProfileHelperService.geDocumentCategories(
        combinedFilingData,
        isLeadDocument,
      );
    this.documentTypeList = this.documentCategories.map(
      (documentCommonCategory: DocumentCommonCategoryDomainCategoryValue) => {
        return { ...documentCommonCategory, selected: false };
      },
    );
  }

  private setAccessTypeList(filingProfile: FilingProfile) {
    this.accessTypeList =
      filingProfile.documentAccessCategories.map(
        (accessCommonCategory: AccessCommonCategoryDomainCategoryValue) => {
          return { ...accessCommonCategory, selected: false };
        },
      ) || [];
  }

  public additionalFieldValueEventHandler(
    value: AdditionalFieldValue,
    documentsGridRow: DocumentsGridRow,
  ) {
    this.resolver.updateAdditionalFieldValues(
      this.documentCaseAdditionalFieldValues,
      value,
    );

    if (documentsGridRow.requestDocument.cases) {
      documentsGridRow.requestDocument.cases[0].additionalFieldValues =
        this.documentCaseAdditionalFieldValues;
    }

    if (this.combinedFilingData?.caseRequest.documents) {
      this.combinedFilingData.caseRequest.documents[documentsGridRow.rowIndex] =
        documentsGridRow.requestDocument;
      this.resolver.updateCaseRequestPut(
        this.combinedFilingData.caseRequest,
        this.combinedFilingData.filing.id,
      );
    }

    this.validateDocumentsOrchestrationService.validateDocuments({});
  }

  onDocumentTypeSelected(params: {
    value: string;
    requestDocument: RequestDocumentViewModel;
    documentIndex: number;
  }) {
    // Guard clause to prevent infinite loop
    const isSameValue = params.requestDocument.category?.name === params.value;
    if (isSameValue) {
      return;
    }

    const documentCategory = this.documentCategories.find(
      (documentCat: DocumentCommonCategoryDomainCategoryValue) => {
        return documentCat.name === params.value;
      },
    );

    const partialRequestDocument: Partial<RequestDocumentViewModel> = {
      category: documentCategory,
    };

    this.updateDocumentOrchestrationService.updateDocument({
      requestDocument: params.requestDocument,
      partialRequestDocument,
    });
  }

  onAccessTypeSelected(params: {
    value: string;
    requestDocument: RequestDocumentViewModel;
    documentIndex: number;
  }) {
    // Guard clause to prevent infinite loop
    const isSameValue =
      params.requestDocument.accessCategoryName === params.value;
    if (isSameValue) {
      return;
    }

    const partialRequestDocument: Partial<RequestDocumentViewModel> = {
      accessCategoryName: params.value,
    };

    this.updateDocumentOrchestrationService.updateDocument({
      requestDocument: params.requestDocument,
      partialRequestDocument,
    });
  }

  onFileNameTextChanged(params: {
    value: string;
    requestDocument: RequestDocumentViewModel;
    documentIndex: number;
  }) {
    const isSameValue = params.requestDocument.fileName === params.value;
    if (isSameValue) {
      return;
    }

    const partialRequestDocument: Partial<RequestDocumentViewModel> = {
      fileName: params.value,
    };

    this.updateDocumentOrchestrationService.updateDocument({
      requestDocument: params.requestDocument,
      partialRequestDocument,
    });
  }

  onDocumentTitleTextChanged(params: {
    value: string;
    requestDocument: RequestDocumentViewModel;
    documentIndex: number;
  }) {
    const isSameValue = params.requestDocument.title === params.value;
    if (isSameValue) {
      return;
    }

    const partialRequestDocument: Partial<RequestDocumentViewModel> = {
      title: params.value,
    };

    this.updateDocumentOrchestrationService.updateDocument({
      requestDocument: params.requestDocument,
      partialRequestDocument,
    });
  }

  onAddAssociatedPartyClicked() {
    this.isAddingAssociatedParty = true;
  }

  onAsToRemoved(params: {
    selectedParticipant: RequestParticipantViewModel;
    requestDocument: RequestDocumentViewModel;
    documentIndex: number;
  }) {
    const requestDocumentCases: RequestDocumentCaseViewModel[] =
      params.requestDocument.cases || [];
    const requestDocumentCase: RequestDocumentCaseViewModel =
      requestDocumentCases[0];
    requestDocumentCase.filedAsTo = requestDocumentCase.filedAsTo!?.filter(
      (rdp: RequestDocumentParticipantViewModel) => {
        return rdp.participantName !== params.selectedParticipant.name;
      },
    );

    const otherRequestDocumentCases = requestDocumentCases.filter(
      (rdc: RequestDocumentCaseViewModel) =>
        rdc.caseId !== requestDocumentCase.caseId,
    );
    const partialRequestDocument: Partial<RequestDocumentViewModel> = {
      cases: [...otherRequestDocumentCases, requestDocumentCase],
    };

    this.updateDocumentOrchestrationService.updateDocument({
      requestDocument: params.requestDocument,
      partialRequestDocument,
    });
  }

  onFiledByRemoved(params: {
    selectedParticipant: RequestParticipantViewModel;
    requestDocument: RequestDocumentViewModel;
    documentIndex: number;
  }) {
    const requestDocumentCases: RequestDocumentCaseViewModel[] =
      params.requestDocument.cases || [];
    const requestDocumentCase: RequestDocumentCaseViewModel =
      requestDocumentCases[0];
    requestDocumentCase.filedBy = requestDocumentCase.filedBy!?.filter(
      (rdp: RequestDocumentParticipantViewModel) => {
        return rdp.participantName !== params.selectedParticipant.name;
      },
    );

    const otherRequestDocumentCases = requestDocumentCases.filter(
      (rdc: RequestDocumentCaseViewModel) =>
        rdc.caseId !== requestDocumentCase.caseId,
    );
    const partialRequestDocument: Partial<RequestDocumentViewModel> = {
      cases: [...otherRequestDocumentCases, requestDocumentCase],
    };

    this.updateDocumentOrchestrationService.updateDocument({
      requestDocument: params.requestDocument,
      partialRequestDocument,
    });
  }

  onAsToSelected(params: {
    selectedParticipant: RequestParticipantViewModel;
    requestDocument: RequestDocumentViewModel;
  }) {
    if (params.selectedParticipant) {
      this.addAssociatedPartyOrchestrationService.addAssociatedParty({
        participant: params.selectedParticipant,
        requestDocument: params.requestDocument,
        associatedPartyType: AssociatedPartyType.AsTo,
      });
    }
  }

  onFiledBySelected(params: {
    selectedParticipant: RequestParticipantViewModel;
    requestDocument: RequestDocumentViewModel;
  }) {
    if (params.selectedParticipant) {
      this.addAssociatedPartyOrchestrationService.addAssociatedParty({
        participant: params.selectedParticipant,
        requestDocument: params.requestDocument,
        associatedPartyType: AssociatedPartyType.FiledBy,
      });
    }
  }

  public filesUploadedFromDocumentAdditionalFieldEventHandler(
    params: FilesUploadedEventParams,
  ) {
    of(params)
      .pipe(
        takeUntil(this.destroy$),
        switchMap((filesUploadedEventParams: FilesUploadedEventParams) => {
          const { uploadedFiles } = filesUploadedEventParams;
          const arrayOfDocumentInfoAndUploadedFile$: Observable<DocumentInfoAndUploadedFile>[] =
            this.createDocumentInfoService.getArrayOfDocumentInfoAndUploadedFile(
              uploadedFiles,
              this.combinedFilingData.filing.id,
            );
          return combineLatest([...arrayOfDocumentInfoAndUploadedFile$]).pipe(
            tap(
              (documentInfoAndUploadedFiles: DocumentInfoAndUploadedFile[]) => {
                // Update documents[0].cases[0].additionalFieldValues here...
                const requestDocumentCases: RequestDocumentCaseViewModel[] =
                  this.documentsGridRow.requestDocument.cases || [];
                const updatedRequestDocumentCases: RequestDocumentCaseViewModel[] =
                  requestDocumentCases.map(
                    (
                      reqDocCase: RequestDocumentCaseViewModel,
                      recDocCaseIndex: number,
                    ) => {
                      // Conditionally set the case additionalFieldValues here
                      if (recDocCaseIndex === 0) {
                        const existingAdditionalFieldValues: AdditionalFieldValue[] =
                          reqDocCase.additionalFieldValues || [];
                        let specAdditionalField =
                          existingAdditionalFieldValues.find(
                            (f) =>
                              f.additionalFieldName ===
                              params.additionalFieldName,
                          );

                        if (!specAdditionalField) {
                          specAdditionalField = {
                            additionalFieldName: params.additionalFieldName,
                            fileValues: [],
                          };
                          reqDocCase.additionalFieldValues?.push(
                            specAdditionalField,
                          );
                        }
                        documentInfoAndUploadedFiles.forEach((f) =>
                          specAdditionalField!.fileValues!.push(
                            f.documentInfo.id,
                          ),
                        );
                        reqDocCase.additionalFieldValues =
                          existingAdditionalFieldValues;

                        this.documentCaseAdditionalFieldValues =
                          reqDocCase.additionalFieldValues;
                        this.documentFileUploadDocumentInfos =
                          this.getFileUploadDocmentInfosFromAdditionaFieldValues(
                            reqDocCase.additionalFieldValues,
                            this.combinedFilingData.documentInfos || [],
                          );
                      }
                      return reqDocCase;
                    },
                  );

                const partialRequestDocument: Partial<RequestDocumentViewModel> =
                  {
                    cases: updatedRequestDocumentCases,
                  };

                this.updateDocumentOrchestrationService.updateDocument({
                  requestDocument: this.documentsGridRow.requestDocument,
                  partialRequestDocument,
                });

                this.filesUploadedFromAdditionalFieldEvent.emit({
                  ...params,
                  documentInfoAndUploadedFiles,
                });
              },
            ),
          );
        }),
      )
      .subscribe();
  }

  public filesUploadedFromAsToAdditionalFieldEventHandler(
    filesUploadedEventParams: FilesUploadedEventParams,
  ) {
    of(filesUploadedEventParams)
      .pipe(
        takeUntil(this.destroy$),
        switchMap((filesUploadedEventParams: FilesUploadedEventParams) => {
          const { uploadedFiles } = filesUploadedEventParams;
          const arrayOfDocumentInfoAndUploadedFile$: Observable<DocumentInfoAndUploadedFile>[] =
            this.createDocumentInfoService.getArrayOfDocumentInfoAndUploadedFile(
              uploadedFiles,
              this.combinedFilingData.filing.id,
            );
          return combineLatest([...arrayOfDocumentInfoAndUploadedFile$]).pipe(
            tap(
              (documentInfoAndUploadedFiles: DocumentInfoAndUploadedFile[]) => {
                // Update documents[0].cases[0].filedBy[0].additionalFieldValues here...
                const requestDocumentCases: RequestDocumentCaseViewModel[] =
                  this.documentsGridRow.requestDocument.cases || [];
                const updatedRequestDocumentCases: RequestDocumentCaseViewModel[] =
                  requestDocumentCases.map(
                    (
                      reqDocCase: RequestDocumentCaseViewModel,
                      recDocCaseIndex: number,
                    ) => {
                      const asToParticipants: RequestDocumentParticipantViewModel[] =
                        reqDocCase.filedAsTo || [];
                      reqDocCase.filedAsTo =
                        recDocCaseIndex !== 0
                          ? asToParticipants // Not the first case so just return it as is.
                          : asToParticipants.map(
                              (
                                asToParticipant: RequestDocumentParticipantViewModel,
                                asToParticipantIndex: number,
                              ) => {
                                // Conditionally set the filedBy additionalFieldValues here
                                if (asToParticipantIndex === 0) {
                                  const existingAdditionalFieldValues: AdditionalFieldValue[] =
                                    asToParticipant.additionalFieldValues || [];
                                  let specAdditionalField =
                                    existingAdditionalFieldValues.find(
                                      (f) =>
                                        f.additionalFieldName ===
                                        filesUploadedEventParams.additionalFieldName,
                                    );

                                  if (!specAdditionalField) {
                                    specAdditionalField = {
                                      additionalFieldName:
                                        filesUploadedEventParams.additionalFieldName,
                                      fileValues: [],
                                    };
                                    asToParticipant.additionalFieldValues?.push(
                                      specAdditionalField,
                                    );
                                  }
                                  documentInfoAndUploadedFiles.forEach((f) =>
                                    specAdditionalField!.fileValues!.push(
                                      f.documentInfo.id,
                                    ),
                                  );
                                  asToParticipant.additionalFieldValues =
                                    existingAdditionalFieldValues;

                                  this.documentCaseAdditionalFieldValues =
                                    asToParticipant.additionalFieldValues;
                                  this.asToFileUploadDocumentInfos =
                                    this.getFileUploadDocmentInfosFromAdditionaFieldValues(
                                      asToParticipant.additionalFieldValues,
                                      this.combinedFilingData.documentInfos ||
                                        [],
                                    );
                                }
                                return asToParticipant;
                              },
                            );
                      return reqDocCase;
                    },
                  );

                const partialRequestDocument: Partial<RequestDocumentViewModel> =
                  {
                    cases: updatedRequestDocumentCases,
                  };

                this.updateDocumentOrchestrationService.updateDocument({
                  requestDocument: this.documentsGridRow.requestDocument,
                  partialRequestDocument,
                });

                this.filesUploadedFromAdditionalFieldEvent.emit({
                  ...filesUploadedEventParams,
                  documentInfoAndUploadedFiles,
                });
              },
            ),
          );
        }),
      )
      .subscribe();
  }

  public filesUploadedFromFiledByAdditionalFieldEventHandler(
    filesUploadedEventParams: FilesUploadedEventParams,
  ) {
    of(filesUploadedEventParams)
      .pipe(
        takeUntil(this.destroy$),
        switchMap((filesUploadedEventParams: FilesUploadedEventParams) => {
          const { uploadedFiles } = filesUploadedEventParams;
          const arrayOfDocumentInfoAndUploadedFile$: Observable<DocumentInfoAndUploadedFile>[] =
            this.createDocumentInfoService.getArrayOfDocumentInfoAndUploadedFile(
              uploadedFiles,
              this.combinedFilingData.filing.id,
            );
          return combineLatest([...arrayOfDocumentInfoAndUploadedFile$]).pipe(
            tap(
              (documentInfoAndUploadedFiles: DocumentInfoAndUploadedFile[]) => {
                // Update documents[0].cases[0].filedBy[0].additionalFieldValues here...
                const requestDocumentCases: RequestDocumentCaseViewModel[] =
                  this.documentsGridRow.requestDocument.cases || [];
                const updatedRequestDocumentCases: RequestDocumentCaseViewModel[] =
                  requestDocumentCases.map(
                    (
                      reqDocCase: RequestDocumentCaseViewModel,
                      recDocCaseIndex: number,
                    ) => {
                      const filedByParticipants: RequestDocumentParticipantViewModel[] =
                        reqDocCase.filedBy || [];
                      reqDocCase.filedBy =
                        recDocCaseIndex !== 0
                          ? filedByParticipants // Not the first case so just return it as is.
                          : filedByParticipants.map(
                              (
                                filedByParticipant: RequestDocumentParticipantViewModel,
                                filedByParticipantIndex: number,
                              ) => {
                                // Conditionally set the filedBy additionalFieldValues here
                                if (filedByParticipantIndex === 0) {
                                  let existingAdditionalFieldValues: AdditionalFieldValue[] =
                                    filedByParticipant.additionalFieldValues ||
                                    [];

                                  let specAdditionalField =
                                    existingAdditionalFieldValues.find(
                                      (f) =>
                                        f.additionalFieldName ===
                                        filesUploadedEventParams.additionalFieldName,
                                    );

                                  if (!specAdditionalField) {
                                    specAdditionalField = {
                                      additionalFieldName:
                                        filesUploadedEventParams.additionalFieldName,
                                      fileValues: [],
                                    };
                                    filedByParticipant.additionalFieldValues?.push(
                                      specAdditionalField,
                                    );
                                  }
                                  documentInfoAndUploadedFiles.forEach((f) =>
                                    specAdditionalField!.fileValues!.push(
                                      f.documentInfo.id,
                                    ),
                                  );
                                  filedByParticipant.additionalFieldValues =
                                    existingAdditionalFieldValues;

                                  this.documentCaseAdditionalFieldValues =
                                    filedByParticipant.additionalFieldValues;
                                  this.filedByFileUploadDocumentInfos =
                                    this.getFileUploadDocmentInfosFromAdditionaFieldValues(
                                      filedByParticipant.additionalFieldValues,
                                      this.combinedFilingData.documentInfos ||
                                        [],
                                    );
                                }
                                return filedByParticipant;
                              },
                            );
                      return reqDocCase;
                    },
                  );

                const partialRequestDocument: Partial<RequestDocumentViewModel> =
                  {
                    cases: updatedRequestDocumentCases,
                  };

                this.updateDocumentOrchestrationService.updateDocument({
                  requestDocument: this.documentsGridRow.requestDocument,
                  partialRequestDocument,
                });

                this.filesUploadedFromAdditionalFieldEvent.emit({
                  ...filesUploadedEventParams,
                  documentInfoAndUploadedFiles,
                });
              },
            ),
          );
        }),
      )
      .subscribe();
  }

  fileRemovedFromDocumentAdditionalFieldEventHandler(
    documentInfo: DocumentInfo,
  ) {
    // 1. Create a new object with the caseRequest property updates here...
    // (in this instance we are manipulating the requestDocument.cases property)
    const requestDocumentCases: RequestDocumentCaseViewModel[] =
      this.documentsGridRow.requestDocument.cases || [];
    const updatedRequestDocumentCases: RequestDocumentCaseViewModel[] =
      requestDocumentCases.map(
        (reqDocCase: RequestDocumentCaseViewModel, reqDocCaseIndex: number) => {
          // Conditionally update the additional field values
          const additionalFieldValues: AdditionalFieldValue[] =
            reqDocCase.additionalFieldValues || [];
          reqDocCase.additionalFieldValues =
            reqDocCaseIndex !== 0
              ? additionalFieldValues // Not the first case so just return as is.
              : additionalFieldValues.map(
                  (addlFieldVal: AdditionalFieldValue) => {
                    // Filter out the fileValue that has the id we want to remove
                    const fileValues: string[] = addlFieldVal.fileValues || [];
                    addlFieldVal.fileValues = fileValues.filter(
                      (fileValue: string) => {
                        return fileValue !== documentInfo.id;
                      },
                    );
                    return addlFieldVal;
                  },
                );
          return reqDocCase;
        },
      );

    // 2. Assign the updated object to the appropriate property on a partial RequestDocument
    const partialRequestDocument: Partial<RequestDocumentViewModel> = {
      cases: updatedRequestDocumentCases,
    };

    // 3. Apply the update
    this.updateDocumentOrchestrationService.updateDocument({
      requestDocument: this.documentsGridRow.requestDocument,
      partialRequestDocument,
    });
  }

  fileRemovedFromFiledByAdditionalFieldEventHandler(
    documentInfo: DocumentInfo,
  ) {
    // 1. Create a new object with the requestDocument property updates here...
    // (in this instance we are manipulating the requestDocument.cases property)
    const requestDocumentCases: RequestDocumentCaseViewModel[] =
      this.documentsGridRow.requestDocument.cases || [];
    const updatedRequestDocumentCases: RequestDocumentCaseViewModel[] =
      requestDocumentCases.map(
        (reqDocCase: RequestDocumentCaseViewModel, reqDocCaseIndex: number) => {
          const filedByParticipants: RequestDocumentParticipantViewModel[] =
            reqDocCase.filedBy || [];
          // Conditionally update the filedBy property
          reqDocCase.filedBy =
            reqDocCaseIndex !== 0
              ? filedByParticipants // Not the first case so just return as is.
              : filedByParticipants.map(
                  (
                    filedByParticipant: RequestDocumentParticipantViewModel,
                    filedByIndex,
                  ) => {
                    // Conditionally update the additional field values
                    const additionalFieldValues: AdditionalFieldValue[] =
                      filedByParticipant.additionalFieldValues || [];
                    filedByParticipant.additionalFieldValues =
                      filedByIndex !== 0
                        ? additionalFieldValues // Not the first filedByParticpant so just return as is.
                        : additionalFieldValues.map(
                            (addlFieldVal: AdditionalFieldValue) => {
                              // Filter out the fileValue that has the id we want to remove
                              const fileValues: string[] =
                                addlFieldVal.fileValues || [];
                              addlFieldVal.fileValues = fileValues.filter(
                                (fileValue: string) => {
                                  return fileValue !== documentInfo.id;
                                },
                              );
                              return addlFieldVal;
                            },
                          );

                    this.documentCaseAdditionalFieldValues =
                      filedByParticipant.additionalFieldValues;
                    this.filedByFileUploadDocumentInfos =
                      this.getFileUploadDocmentInfosFromAdditionaFieldValues(
                        filedByParticipant.additionalFieldValues,
                        this.combinedFilingData.documentInfos || [],
                      );
                    return filedByParticipant;
                  },
                );

          return reqDocCase;
        },
      );

    // 2. Assign the updated object to the appropriate property on a partial RequestDocument
    const partialRequestDocument: Partial<RequestDocumentViewModel> = {
      cases: updatedRequestDocumentCases,
    };

    // 3. Apply the update
    this.updateDocumentOrchestrationService.updateDocument({
      requestDocument: this.documentsGridRow.requestDocument,
      partialRequestDocument,
    });

    // 4. And remove the actual file
    // otherwise, if they add another
  }

  fileRemovedFromAsToAdditionalFieldEventHandler(documentInfo: DocumentInfo) {
    // 1. Create a new object with the requestDocument property updates here...
    // (in this instance we are manipulating the requestDocument.cases property)
    const requestDocumentCases: RequestDocumentCaseViewModel[] =
      this.documentsGridRow.requestDocument.cases || [];
    const updatedRequestDocumentCases: RequestDocumentCaseViewModel[] =
      requestDocumentCases.map(
        (reqDocCase: RequestDocumentCaseViewModel, reqDocCaseIndex: number) => {
          const asToParticipants: RequestDocumentParticipantViewModel[] =
            reqDocCase.filedAsTo || [];
          // Conditionally update the filedAsTo property
          reqDocCase.filedAsTo =
            reqDocCaseIndex !== 0
              ? asToParticipants // Not the first case so just return as is.
              : asToParticipants.map(
                  (
                    asToParticipant: RequestDocumentParticipantViewModel,
                    asToIndex,
                  ) => {
                    // Conditionally update the additional field values
                    const additionalFieldValues: AdditionalFieldValue[] =
                      asToParticipant.additionalFieldValues || [];
                    asToParticipant.additionalFieldValues =
                      asToIndex !== 0
                        ? additionalFieldValues // Not the first filedByParticpant so just return as is.
                        : additionalFieldValues.map(
                            (addlFieldVal: AdditionalFieldValue) => {
                              // Filter out the fileValue that has the id we want to remove
                              const fileValues: string[] =
                                addlFieldVal.fileValues || [];
                              addlFieldVal.fileValues = fileValues.filter(
                                (fileValue: string) => {
                                  return fileValue !== documentInfo.id;
                                },
                              );
                              return addlFieldVal;
                            },
                          );
                    this.documentCaseAdditionalFieldValues =
                      asToParticipant.additionalFieldValues;
                    this.asToFileUploadDocumentInfos =
                      this.getFileUploadDocmentInfosFromAdditionaFieldValues(
                        asToParticipant.additionalFieldValues,
                        this.combinedFilingData.documentInfos || [],
                      );
                    return asToParticipant;
                  },
                );
          return reqDocCase;
        },
      );

    // 2. Assign the updated object to the appropriate property on a partial RequestDocument
    const partialRequestDocument: Partial<RequestDocumentViewModel> = {
      cases: updatedRequestDocumentCases,
    };

    // 3. Apply the update
    this.updateDocumentOrchestrationService.updateDocument({
      requestDocument: this.documentsGridRow.requestDocument,
      partialRequestDocument,
    });
  }

  /**
   * A method to trigger form-level validation.
   *
   * @param force
   */
  private validate(force: boolean = false): void {
    // Conditionally force-set FormControl touched status for validation.
    if (force) {
      if (this.fileNameField) {
        this.markForValidation(this.fileNameField.formControl);
      }
      if (this.documentTypeField) {
        this.markForValidation(this.documentTypeField.formControl);
      }
      if (this.accessTypeField) {
        this.markForValidation(this.accessTypeField.formControl);
      }
      if (this.documentTitleField) {
        this.markForValidation(this.documentTitleField.formControl);
      }
    }

    // Conditionally validate the FormControls if they have been touched.
    if (this.fileNameField && this.fileNameField.formControl.touched) {
      this.fileNameField.validate();
    }
    if (this.documentTypeField && this.documentTypeField.formControl.touched) {
      this.documentTypeField.validate();
    }
    if (this.accessTypeField && this.accessTypeField.formControl.touched) {
      this.accessTypeField.validate();
    }
    if (
      this.documentTitleField &&
      this.documentTitleField.formControl.touched
    ) {
      this.documentTitleField.validate();
    }
    if (this.filedByFields) {
      this.filedByFields.toArray().forEach((fld) => fld.validate());
    }
    if (this.asToFields) {
      this.asToFields.toArray().forEach((fld) => fld.validate());
    }
    if (this.additionalFields) {
      this.additionalFields.toArray().forEach((fld) => fld.validate());
    }
  }

  /**
   * A helper method for marking a FormControl for validation.
   *
   * @param formControl The FormControl to mark for validation.
   */
  private markForValidation(formControl: FormControl) {
    formControl.markAsTouched();
    formControl.markAsDirty();
    formControl.updateValueAndValidity({ emitEvent: false });
  }

  /**
   * A handler function for  updating the "Filed By" associated-party additional-fields.
   */
  filedByAdditionalFieldValueEventHandler(
    additionalFieldValues: AdditionalFieldValue[],
  ): void {
    // 1. Create a new object with the requestDocument property updates here...
    // (in this instance we are manipulating the requestDocument.cases property)
    const requestDocumentCases: RequestDocumentCaseViewModel[] =
      this.documentsGridRow.requestDocument.cases || [];
    const updatedRequestDocumentCases: RequestDocumentCaseViewModel[] =
      requestDocumentCases.map(
        (reqDocCase: RequestDocumentCaseViewModel, reqDocCaseIndex: number) => {
          const filedByParticipants: RequestDocumentParticipantViewModel[] =
            reqDocCase.filedBy || [];
          // Conditionally update the filedBy property
          reqDocCase.filedBy =
            reqDocCaseIndex !== 0
              ? filedByParticipants // Not the first case so just return as is.
              : filedByParticipants.map(
                  (filedByParticipant: RequestDocumentParticipantViewModel) => {
                    // Append the new AdditionalFieldValue object to the filtered AdditionalFieldValue objects.
                    filedByParticipant.additionalFieldValues =
                      additionalFieldValues;
                    return filedByParticipant;
                  },
                );
          return reqDocCase;
        },
      );

    // 2. Assign the updated object to the appropriate property on a partial RequestDocument
    const partialRequestDocument: Partial<RequestDocumentViewModel> = {
      cases: updatedRequestDocumentCases,
    };

    // 3. Apply the update
    this.updateDocumentOrchestrationService.updateDocument({
      requestDocument: this.documentsGridRow.requestDocument,
      partialRequestDocument,
    });
  }

  /**
   * A handler function for  updating the "As To" associated-party additional-fields.
   */
  asToAdditionalFieldValueEventHandler(
    additionalFieldValues: AdditionalFieldValue[],
  ): void {
    // 1. Create a new object with the requestDocument property updates here...
    // (in this instance we are manipulating the requestDocument.cases property)
    const requestDocumentCases: RequestDocumentCaseViewModel[] =
      this.documentsGridRow.requestDocument.cases || [];
    const updatedRequestDocumentCases: RequestDocumentCaseViewModel[] =
      requestDocumentCases.map(
        (reqDocCase: RequestDocumentCaseViewModel, reqDocCaseIndex: number) => {
          const asToParticipants: RequestDocumentParticipantViewModel[] =
            reqDocCase.filedAsTo || [];
          // Conditionally update the asTo property
          reqDocCase.filedAsTo =
            reqDocCaseIndex !== 0
              ? asToParticipants // Not the first case so just return as is.
              : asToParticipants.map(
                  (asToParticipant: RequestDocumentParticipantViewModel) => {
                    // Append the new AdditionalFieldValue object to the filtered AdditionalFieldValue objects.
                    asToParticipant.additionalFieldValues =
                      additionalFieldValues;
                    return asToParticipant;
                  },
                );
          return reqDocCase;
        },
      );

    // 2. Assign the updated object to the appropriate property on a partial RequestDocument
    const partialRequestDocument: Partial<RequestDocumentViewModel> = {
      cases: updatedRequestDocumentCases,
    };

    // 3. Apply the update
    this.updateDocumentOrchestrationService.updateDocument({
      requestDocument: this.documentsGridRow.requestDocument,
      partialRequestDocument,
    });
  }

  /**
   * A method to handle the setting of AdditionalFieldValue objects for parties.
   *
   * @param additionalFieldValues The updated additionalFieldValues collection.
   * @param row The DocumentsGridRow of the document to which the additionalFieldValue is being set.
   */
  onAdditionalFieldValuesEmitted(
    additionalFieldValues: AdditionalFieldValue[],
    row: DocumentsGridRow,
  ) {
    let requestDocumentCases: RequestDocumentCaseViewModel[] =
      row.requestDocument.cases || [];
    // Index accessor okay as we always create one requestDocumentCase with the RequestDocument
    const requestDocumentCase: RequestDocumentCaseViewModel =
      requestDocumentCases[0];

    // Create the partial CaseParty object
    // Here we update the RequestDocumentCase additionalFieldValues array.
    const partiaRequestDocument: Partial<RequestDocumentViewModel> = {
      cases: [
        {
          ...requestDocumentCase,
          additionalFieldValues: [...additionalFieldValues],
        },
      ],
    };

    // Apply the update through the orchestration service.
    this.updateDocumentOrchestrationService.updateDocument({
      requestDocument: row.requestDocument,
      partialRequestDocument: partiaRequestDocument,
    });
  }
}
