import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  QueryList,
  ViewChildren,
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import {
  AddressViewModel,
  FieldCategory,
  TextFieldDefinition,
} from '@fsx/fsx-shared';
import {
  FormArrayWithModel,
  FormControlWithModel,
} from '../../models/form-control.model';
import {
  AddressComponentFieldDefinition,
  AddressFormGroup,
  SelectionFieldDefinition,
  SelectionFieldType,
} from '../../types/form-control.types';
import { FsxBaseComponent } from '../base/base.component';
import {
  FsxBasicSingleSelectionComponent,
  FsxProfileSingleSelectionComponent,
  FsxTextBoxComponent,
  FsxTextComponent,
  ReferenceResolver,
} from '../../../public-api';
import { minRequiredArrayLengthValidator } from '../../validators/min-required-array-length.validator';
import { maxAllowedArrayLengthValidator } from '../../validators/max-allowed-array-length.validator';

@Component({
  selector: 'fsx-address-component',
  templateUrl: './address.component.html',
  styleUrls: ['./address.component.scss'],
})
export class FsxAddressComponent
  extends FsxBaseComponent
  implements OnInit, AfterViewInit
{
  @Input() fieldDefinition!: AddressComponentFieldDefinition | null;
  @Input() initialValues!: AddressViewModel[];
  @Input() resolver!: ReferenceResolver;
  @Input() editMode!: boolean;
  @Input() caption!: string;

  @Output() formArrayEmitter = new EventEmitter<
    FormArrayWithModel<FormGroup<AddressFormGroup>>
  >(true);

  @ViewChildren('categoryField')
  categoryFields!: QueryList<FsxProfileSingleSelectionComponent>;
  @ViewChildren('countryField')
  countryFields!: QueryList<FsxBasicSingleSelectionComponent>;
  @ViewChildren('addressField1')
  addressFields1!: QueryList<FsxTextBoxComponent>;
  @ViewChildren('addressField2') addressFields2!: QueryList<FsxTextComponent>;
  @ViewChildren('localityField') localityFields!: QueryList<FsxTextComponent>;
  @ViewChildren('adminstrativeAreaField')
  adminstrativeAreaFields!: QueryList<FsxProfileSingleSelectionComponent>;
  @ViewChildren('postalCodeField')
  postalCodeFields!: QueryList<FsxTextComponent>;

  public addressFormArray!: FormArrayWithModel<FormGroup<AddressFormGroup>>;
  public showAddNewForm = true;
  public fieldType = FieldCategory;
  public selectionType = SelectionFieldType;
  public addressInitialValue = [];

  ngOnInit(): void {
    let addressFormGroups: FormGroup<AddressFormGroup>[] = [];

    if (this.initialValues.length) {
      addressFormGroups = this.initialValues.map(
        () => new FormGroup<AddressFormGroup>({} as AddressFormGroup),
      );
    } else {
      const minRequired = this.fieldDefinition
        ? this.fieldDefinition.minRequired
        : 0;
      for (let i = 0; i < minRequired; i++) {
        addressFormGroups.push(
          new FormGroup<AddressFormGroup>({} as AddressFormGroup),
        );
      }
    }

    const minRequired = this.fieldDefinition?.minRequired ?? 0;
    const maxAllowed = this.fieldDefinition?.maxAllowed ?? 0;
    this.addressFormArray = new FormArrayWithModel<FormGroup<AddressFormGroup>>(
      [...addressFormGroups],
      { minRequired, maxAllowed },
      [
        minRequiredArrayLengthValidator(minRequired),
        maxAllowedArrayLengthValidator(maxAllowed),
      ],
    );
  }

  /**
   * The Angular lifecycle hook to wait for nested component (and FormControl) initialisation
   * before emitting the formArray which we then add more custom validators to.
   */
  ngAfterViewInit(): void {
    this.formArrayEmitter.emit(this.addressFormArray);
  }

  /**
   * A handler method for nested component's formControlEmitter events.
   *
   * @param control The FormControl being emitted by the nested compoent.
   *
   * @param controlName The name (string) of the FormGroup property to set the FormControl into.
   *
   * @param index The index of the FormGroup in the FormArray.
   */
  setControl(
    control:
      | FormControlWithModel<SelectionFieldDefinition>
      | FormControlWithModel<TextFieldDefinition>,
    controlName: keyof AddressFormGroup,
    index: number,
  ): void {
    const formGroup = this.getAddressFormGroup(index);
    formGroup.setControl(controlName, control);
  }

  /**
   * A helper method for retrieving a FormGroup from a given index in the FormArray.
   *
   * @param index The index of the FormGroup in the FormArray.
   *
   * @returns The FormGroup at the given index of the FormArray.
   */
  private getAddressFormGroup(index: number): FormGroup<AddressFormGroup> {
    let formGroup = this.addressFormArray.at(
      index,
    ) as FormGroup<AddressFormGroup>;
    if (!formGroup) {
      formGroup = new FormGroup<AddressFormGroup>({} as AddressFormGroup);
    }
    return formGroup;
  }

  /**
   * A handler method for the trashcan icon's click event.
   *
   * @param index The formArray index of the formGroup to remove.
   */
  removeForm(index: number): void {
    if (this.addressFormArray.disabled) {
      return;
    }

    this.addressFormArray.removeAt(index);
    this.initialValues.splice(index, 1);
  }

  /**
   * A handler method for the "Add Another Address" link button's click event.
   */
  addNewForm(): void {
    if (this.addressFormArray.enabled) {
      this.addressFormArray.push(
        new FormGroup<AddressFormGroup>({} as AddressFormGroup),
      );
    }
  }

  public validate(): void {
    if (this.addressFormArray.controls.length) {
      // if (this.fieldDefinition?.minRequired == 0) {
      //    // if it's not required, see if any of the fields are filled in, if so it needs to validate
      // } else {
      //   // at least one is required, so we ...
      // }
      if (this.shouldValidateFormArray(this.addressFormArray)) {
        if (this.categoryFields) {
          this.categoryFields.toArray().forEach((fld) => fld.validate());
        }
        if (this.countryFields) {
          this.countryFields.toArray().forEach((fld) => fld.validate());
        }
        if (this.addressFields1) {
          this.addressFields1.toArray().forEach((fld) => fld.validate());
        }
        if (this.addressFields2) {
          this.addressFields2.toArray().forEach((fld) => fld.validate());
        }
        if (this.localityFields) {
          this.localityFields.toArray().forEach((fld) => fld.validate());
        }
        if (this.adminstrativeAreaFields) {
          this.adminstrativeAreaFields
            .toArray()
            .forEach((fld) => fld.validate());
        }
        if (this.postalCodeFields) {
          this.postalCodeFields.toArray().forEach((fld) => fld.validate());
        }
      }
    }
  }
}
