import { Inject, Injectable, InjectionToken } from '@angular/core';
import {
  DefaultParticipantService,
  CasePartyViewModel,
  ICaseRequestUpdateService,
  ICaseRequestBuilderService,
  FsxCaseRequestBuilderService,
  FsxCaseRequestUpdateService,
  FsxDefaultPartyService,
  IDefaultPartyService,
  RequestParticipantRepresentationViewModel,
} from '@fsx/fsx-shared';
import { CaseRequestViewModel } from '@fsx/fsx-shared';
import { Observable, Subject, switchMap, tap } from 'rxjs';
import {
  FsxValidatePartiesOrchestrationService,
  IValidatePartiesOrchestrationService,
} from '../../filing-editor/services/orchestration/validate-parties-orchestration.service';
import {
  FsxValidationErrorsService,
  IValidationErrorsService,
} from '../../filing-editor/services/validation-errors.service';

export const FsxClearParticipantOrchestrationService =
  new InjectionToken<IClearParticipantOrchestrationService>(
    'FsxClearParticipantOrchestrationService',
  );

export interface IClearParticipantEventParams {
  filingId: string;
  caseRequest: CaseRequestViewModel;
  partyToClear: CasePartyViewModel;
}

export interface IClearParticipantOrchestrationService {
  /**
   * The pipeline of orchestration steps needed to clear a party and related
   * representation from the CaseRequest object.
   */
  clearParticipantInCaseRequest$: Observable<CaseRequestViewModel>;

  /**
   * A public method to allow the orchestration to be triggered.
   *
   * @param params The parameters needed to run this orchestration stream.
   */
  clearParticipant(params: IClearParticipantEventParams): void;
}

@Injectable()
export class ClearParticipantOrchestrationService
  implements IClearParticipantOrchestrationService
{
  /**
   * A subject to use as the trigger for the orchestration.
   */
  private clearParticipant$$ = new Subject<IClearParticipantEventParams>();

  /**
   * The pipeline of orchestration steps needed to clear a party and related
   * representation from the CaseRequest object.
   */
  clearParticipantInCaseRequest$: Observable<CaseRequestViewModel> =
    this.clearParticipant$$.pipe(
      switchMap((params: IClearParticipantEventParams) => {
        const caseRequestBackup: CaseRequestViewModel = {
          ...params.caseRequest,
        } as CaseRequestViewModel;
        // Defer to the CaseRequestBuilderService to remove any representation from the participan on the CaseRequest.
        return this.caseRequestBuilderService
          .removeRepresentationAndParticipants({
            ...params,
            partyToRemoveFrom: params.partyToClear,
          })
          .pipe(
            switchMap(() => {
              // Reset the CaseParty object in the CaseRequest.parties array.
              return this.defaultPartyService
                .setAsDefaultParty(
                  params.caseRequest,
                  params.partyToClear.participantName, // Keep the same participantName after resetting.
                  params.partyToClear.participantCategory?.commonCategory!,
                )
                .pipe(
                  switchMap(() => {
                    // Reset the RequestParticipant object in the CaseRequest.participants array.
                    return this.defaultParticipantService
                      .setAsDefaultParticipant(
                        params.partyToClear.participantName, // Keep the same participantName after resetting.
                        params.caseRequest,
                      )
                      .pipe(
                        tap(() => {
                          // Remove any related validation errors for the party and representation.
                          this.validationErrorsService.removeEntityValidationErrors(
                            params.partyToClear.participantName,
                          );
                          params.partyToClear.representation?.forEach(
                            (
                              rep: RequestParticipantRepresentationViewModel,
                            ) => {
                              this.validationErrorsService.removeEntityValidationErrors(
                                rep.participantName,
                              );
                            },
                          );
                        }),
                        switchMap(() => {
                          return this.caseRequestUpdateService
                            .optimisticPutOrRestore(
                              params.filingId,
                              params.caseRequest,
                              caseRequestBackup,
                            )
                            .pipe(
                              tap(() => {
                                /**
                                 * When clearing a participant from the caseRequest we want to validate it to flag it as
                                 * invalid; because you have to have entered/selected a participant, we can't justr continue
                                 * with the default uninitialised object.
                                 *
                                 * Here we prevent other parties from inadvertently being flagged as invalid by passing
                                 * in the cleared party's participantName into the inclusions array. Remember: clearing
                                 * a participant does not mean removing it from the CaseRequest object; it just means
                                 * resetting the CaseParty back to it's default state, it is still there to be validated.
                                 *
                                 * TODO: Set any other flags here to include/exclude cross-party rule checks that clearing
                                 * a particpant could possibly violate.
                                 */
                                this.validatePartiesOrchestrationService.validateParties(
                                  {
                                    includedParticipantNames: [
                                      params.partyToClear.participantName,
                                    ],
                                  },
                                );
                              }),
                            );
                        }),
                      );
                  }),
                );
            }),
          );
      }),
    );

  constructor(
    private readonly defaultParticipantService: DefaultParticipantService,
    @Inject(FsxDefaultPartyService)
    private readonly defaultPartyService: IDefaultPartyService,
    @Inject(FsxValidatePartiesOrchestrationService)
    private readonly validatePartiesOrchestrationService: IValidatePartiesOrchestrationService,
    @Inject(FsxCaseRequestBuilderService)
    private readonly caseRequestBuilderService: ICaseRequestBuilderService,
    @Inject(FsxCaseRequestUpdateService)
    private readonly caseRequestUpdateService: ICaseRequestUpdateService,
    @Inject(FsxValidationErrorsService)
    private readonly validationErrorsService: IValidationErrorsService,
  ) {}

  /**
   * A public method to allow the orchestration to be triggered.
   *
   * @param params The parameters needed to run this orchestration stream.
   */
  clearParticipant(params: IClearParticipantEventParams): void {
    this.clearParticipant$$.next(params);
  }
}
