import { Inject, Injectable, InjectionToken } from '@angular/core';
import {
  Subject,
  Observable,
  withLatestFrom,
  map,
  switchMap,
  of,
  combineLatest,
} from 'rxjs';
import {
  CasePartyViewModel,
  CaseRequestViewModel,
  CombinedFilingData,
  FsxCaseRequestBuilderService,
  FsxCaseRequestUpdateService,
  ICaseRequestBuilderService,
  ICaseRequestUpdateService,
  RequestDocumentCaseViewModel,
  RequestDocumentParticipantViewModel,
  RequestDocumentViewModel,
  RequestParticipant,
  RequestParticipantRepresentationViewModel,
  RequestParticipantViewModel,
} from '@fsx/fsx-shared';
import {
  FsxCombinedFilingDataService2,
  ICombinedFilingDataService,
} from '../../filing-editor/services/combined-filing-data.service';
import {
  FsxUpdateDocumentOrchestrationService,
  IUpdateDocumentOrchestrationService,
} from './update-document-orchestration.service';
import {
  FsxPartyDataService,
  IPartyDataService,
} from '../../filing-editor/services/party-data.service';
import {
  FsxParticipantDataService,
  IParticipantDataService,
} from '../../filing-editor/services/participant-data.service';

export enum AssociatedPartyType {
  FiledBy,
  AsTo,
}

export interface AddAssociatedPartyParams {
  /**
   * The RequestParticipant object that was selected as an Associated Party (Filed By / As To)
   */
  participant: RequestParticipant;

  /**
   * The RequestDocument object to which we're adding an Associated Party to.
   */
  requestDocument: RequestDocumentViewModel;

  /**
   * Whether the Associated Party is a "Filed By" or an "As To".
   */
  associatedPartyType: AssociatedPartyType;
}

/**
 * The InjectionToken to use in the providers array to specify a concrete-implementation
 * of the IAddAssociatedPartyOrchestrationService to use at runtime.
 */
export const FsxAddAssociatedPartyOrchestrationService =
  new InjectionToken<IAddAssociatedPartyOrchestrationService>(
    'FsxAddAssociatedPartyOrchestrationService',
  );

/**
 * A blueprint for an orchestration service, which handles the adding of participants
 * as associated parties (Filed By / As To)
 */
export interface IAddAssociatedPartyOrchestrationService {
  /**
   * The pipeline of orchestration steps needed to add a participant as an associated party.
   */
  addAssociatedParty$: Observable<CaseRequestViewModel>;

  /**
   * A public method to allow the orchestration to be triggered.
   */
  addAssociatedParty(params: AddAssociatedPartyParams): void;
}

/**
 * A concrete implementation of an orchestration service, which handles the adding of participants
 * as associated parties (Filed By / As To)
 */
@Injectable()
export class AddAssociatedPartyOrchestrationService
  implements IAddAssociatedPartyOrchestrationService
{
  /**
   * A subject to use as the trigger for the orchestration.
   */
  private addAssociatedPartyParams$$ = new Subject<AddAssociatedPartyParams>();

  /**
   * The pipeline of orchestration steps needed to add a participant as an associated party.
   */
  addAssociatedParty$: Observable<CaseRequestViewModel> =
    this.addAssociatedPartyParams$$.pipe(
      withLatestFrom(
        this.combinedFilingDataService2.combinedFilingData$,
        this.partyDataService.parties$,
        this.participantDataService.participants$,
      ),
      switchMap(
        ([params, combinedFilingData, parties, participants]: [
          AddAssociatedPartyParams,
          CombinedFilingData,
          CasePartyViewModel[],
          RequestParticipant[],
        ]) => {
          const { caseRequest, filing } = combinedFilingData;
          const caseRequestBackup = { ...caseRequest } as CaseRequestViewModel;
          const { participant } = params;
          return this.caseRequestBuilderService
            .checkAddParticipant({ caseRequest, participant })
            .pipe(
              switchMap(() => {
                const partyOrUndefined = parties.find(
                  (p: CasePartyViewModel) => {
                    return participant.name === p.participantName;
                  },
                );

                const conditionalCheckAddParty$ = partyOrUndefined
                  ? this.caseRequestBuilderService
                      .checkAddParty({ caseRequest, party: partyOrUndefined! })
                      .pipe(
                        switchMap(() => {
                          // And lookup the participants for the party's representation and check to add them too...
                          const representation: RequestParticipantRepresentationViewModel[] =
                            partyOrUndefined.representation || [];
                          const arrayOfCheckAddParticipant$ =
                            representation.length > 0
                              ? representation.map(
                                  (
                                    rep: RequestParticipantRepresentationViewModel,
                                  ) => {
                                    const participantOrUndefined =
                                      participants.find(
                                        (p: RequestParticipantViewModel) => {
                                          return p.name === rep.participantName;
                                        },
                                      );
                                    return this.caseRequestBuilderService.checkAddParticipant(
                                      {
                                        caseRequest,
                                        participant: participantOrUndefined!,
                                      },
                                    );
                                  },
                                )
                              : [of(caseRequest)]; // Just return the CaseRequest if there is no representation.

                          return combineLatest([
                            ...arrayOfCheckAddParticipant$,
                          ]).pipe(
                            map(() => {
                              return caseRequest;
                            }),
                          );
                        }),
                      )
                  : of(caseRequest);

                return conditionalCheckAddParty$.pipe(
                  switchMap(() => {
                    let requestDocumentCases: RequestDocumentCaseViewModel[] =
                      params.requestDocument.cases || [];

                    // Index accessor okay as we always create one requestDocumentCase with the RequestDocument
                    const requestDocumentCase: RequestDocumentCaseViewModel =
                      requestDocumentCases[0];

                    const requestDocumentParticipant: RequestDocumentParticipantViewModel =
                      {
                        participantName: participant.name,
                        additionalFieldValues: [],
                        isValid: true,
                      };

                    if (
                      params.associatedPartyType === AssociatedPartyType.FiledBy
                    ) {
                      requestDocumentCase.filedBy!?.push(
                        requestDocumentParticipant,
                      );
                    }
                    if (
                      params.associatedPartyType === AssociatedPartyType.AsTo
                    ) {
                      requestDocumentCase.filedAsTo!?.push(
                        requestDocumentParticipant,
                      );
                    }

                    const otherRequestDocumentCases =
                      requestDocumentCases.filter(
                        (rdc: RequestDocumentCaseViewModel) =>
                          rdc.caseId !== requestDocumentCase.caseId,
                      );

                    const partialRequestDocument: Partial<RequestDocumentViewModel> =
                      {
                        cases: [
                          ...otherRequestDocumentCases,
                          requestDocumentCase,
                        ],
                      };

                    return this.caseRequestUpdateService
                      .optimisticPutOrRestore(
                        filing.id,
                        caseRequest,
                        caseRequestBackup,
                      )
                      .pipe(
                        map(() => {
                          this.updateDocumentOrchestrationService.updateDocument(
                            {
                              requestDocument: params.requestDocument,
                              partialRequestDocument,
                            },
                          );
                          return combinedFilingData.caseRequest;
                        }),
                      );
                  }),
                );
              }),
            );
        },
      ),
    );

  constructor(
    @Inject(FsxCaseRequestBuilderService)
    private readonly caseRequestBuilderService: ICaseRequestBuilderService,
    @Inject(FsxCaseRequestUpdateService)
    private readonly caseRequestUpdateService: ICaseRequestUpdateService,
    @Inject(FsxCombinedFilingDataService2)
    private readonly combinedFilingDataService2: ICombinedFilingDataService,
    @Inject(FsxPartyDataService)
    private readonly partyDataService: IPartyDataService,
    @Inject(FsxParticipantDataService)
    private readonly participantDataService: IParticipantDataService,
    @Inject(FsxUpdateDocumentOrchestrationService)
    private readonly updateDocumentOrchestrationService: IUpdateDocumentOrchestrationService,
  ) {}

  /**
   * A public method to allow the orchestration to be triggered.
   */
  addAssociatedParty(params: AddAssociatedPartyParams): void {
    this.addAssociatedPartyParams$$.next(params);
  }
}
