<div
  class="grid-container documents-grid-container prevent-select"
  [ngClass]="validationFilteredClass"
>
  <div class="header-row documents-header-row">
    <fsx-row-header-validation
      [errorCount]="errorCount"
      (errorFilterChanged)="validationFilterChanged($event)"
    ></fsx-row-header-validation>
    <div fxFlex="none" class="icon-col"></div>
    <mat-checkbox fxFlex="nogrow" class="checkbox-col"></mat-checkbox>
    <div fxFlex="nogrow" class="header-col document-order-number-col"></div>
    <div fxFlex="nogrow" class="header-col title-col">TITLE</div>
    <div fxFlex class="header-col document-col">DOCUMENT</div>
    <div fxFlex class="header-col access-col">ACCESS</div>
  </div>
  <div class="content-row" cdkDropListGroup>
    <div
      class="drop-list-1 validatable-row"
      cdkDropList
      [cdkDropListData]="documentsGridRows"
      (cdkDropListDropped)="cdkDropListDroppedHandler($event)"
    >
      <div
        *ngFor="
          let row of documentsGridRows;
          let docRowIndex = index;
          trackBy: identify
        "
        cdkDrag
        class="filterable-row"
        [ngClass]="row.requestDocument.isValid ? validationFilteredClass : ''"
      >
        <div class="custom-placeholder lead" *cdkDragPlaceholder>
          <div class="custom-placeholder-inner"></div>
        </div>
        <div fxFlex="row" fxLayout="start center">
          <div fxFlex="none" class="icon-col icon" cdkDragHandle></div>
          <fsx-documents-grid-item
            fxFlex="auto"
            [rowIndex]="docRowIndex"
            [documentsGridRow]="row"
            [combinedFilingData]="combinedFilingData"
            [resolver]="resolver"
            [documentOrderNumber]="
              requestDocuments | documentOrderNumber: docRowIndex
            "
            (removeDocumentEvent)="removeDocumentEventHandler($event)"
            (toggleExpandRowEvent)="toggleExpandRowEventHandler($event)"
            (cancelUploadEvent)="cancelUploadEventHandler($event)"
            (supportingFilesUploadedEvent)="
              supportingFilesUploadedEventHandler($event)
            "
            (documentFileUploadedEvent)="
              documentFileUploadedEventHandler($event)
            "
            (documentErrorChangedEvent)="documentErrorChangedEvent()"
            (filesUploadedFromAdditionalFieldEvent)="
              filesUploadedFromAdditionalFieldEventHandler($event)
            "
          >
          </fsx-documents-grid-item>
        </div>
      </div>
    </div>
  </div>
</div>
