import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { FieldCategory, TimeFieldDefinition } from '@fsx/fsx-shared';
import { distinctUntilChanged, Subject, takeUntil, tap } from 'rxjs';
import { FormControlWithModel } from '../../models/form-control.model';
import { FSXFormControlService } from '../../services';

interface TimeInput extends HTMLInputElement {
  showPicker(): unknown;
}

@Component({
  selector: 'fsx-time-component',
  templateUrl: './time.component.html',
  styleUrls: ['./time.component.scss'],
})
export class FsxTimeComponent implements OnInit, OnDestroy {
  @Input() fieldDefinition!: TimeFieldDefinition;
  @Input() initialValue!: string;
  @Input() caption!: string;
  @Input() helpText!: string;
  @Input() hint!: string;
  @Input() width!: string;

  @Output() formControlEmitter = new EventEmitter<
    FormControlWithModel<TimeFieldDefinition>
  >();

  @ViewChild('hoursInput') hoursInput!: ElementRef<HTMLInputElement>;
  @ViewChild('minutesInput') minutesInput!: ElementRef<HTMLInputElement>;
  @ViewChild('meridianInput') meridianInput!: ElementRef<HTMLInputElement>;
  @ViewChild('timeInput') timeInput!: ElementRef<TimeInput>;

  hours: FormControl = new FormControl<string>('');
  minutes: FormControl = new FormControl<string>('');
  meridian: FormControl = new FormControl<string>('');
  time: FormControl = new FormControl<string>('');
  formControl!: FormControlWithModel<TimeFieldDefinition>;

  private destroy$: Subject<unknown> = new Subject();

  constructor(private readonly fsxFormControlService: FSXFormControlService) {}

  ngOnInit(): void {
    this.formControl = this.fsxFormControlService.createFormControl(
      this.fieldDefinition,
      FieldCategory.Time,
      this.initialValue,
    );
    this.formControlEmitter.emit(this.formControl);

    this.formControl.statusChanges
      .pipe(
        distinctUntilChanged(),
        tap((status: string) => {
          if (status === 'DISABLED') {
            this.time.disable();
            this.hours.disable();
            this.minutes.disable();
            this.meridian.disable();
          } else if (status === 'VALID' && this.time.disabled) {
            this.time.enable();
            this.hours.enable();
            this.minutes.enable();
            this.meridian.enable();
          }
        }),
        takeUntil(this.destroy$),
      )
      .subscribe();

    this.time.valueChanges
      .pipe(
        distinctUntilChanged(),
        tap((value: string) => {
          if (value) {
            const [hours, minutes] = value.split(':');
            this.hours.setValue(String(hours));
            this.minutes.setValue(String(minutes));
            this.meridian.setValue(+hours >= 12 ? 'PM' : 'AM');
            this.formatTime();
          }
        }),
        takeUntil(this.destroy$),
      )
      .subscribe();

    this.hours.valueChanges
      .pipe(
        tap((value: string) => {
          if (value.length === 2 && !this.minutes.value) {
            this.minutesInput.nativeElement.focus();
          } else if (value && value.length > 2 && value.includes(':')) {
            this.hours.setValue(String(value).split(':')[0].slice(0, 2).trim());
            this.minutes.setValue(
              String(value).split(':')[1].slice(0, 2).trim(),
            );
            value.includes('p')
              ? this.meridian.setValue('PM')
              : this.meridian.setValue('AM');
          }
        }),
        takeUntil(this.destroy$),
      )
      .subscribe();

    this.minutes.valueChanges
      .pipe(
        tap((value: string) => {
          if (value.length === 2 && !this.meridian.value) {
            this.meridianInput.nativeElement.focus();
          }
        }),
        takeUntil(this.destroy$),
      )
      .subscribe();
  }

  ngOnDestroy(): void {
    this.destroy$.next(null);
    this.destroy$.complete();
  }

  getErrorMessage(): string {
    if (this.formControl.errors) {
      return this.formControl.errors['invalid'];
    }

    return '';
  }

  setFocus(): void {
    this.hoursInput.nativeElement.focus();
  }

  formatTime(): void {
    setTimeout(() => {
      if (!document.activeElement?.classList.contains('time')) {
        this.formatHours();
        this.formatMinutes();
        this.formatMeridian();
        this.formatTimeValue();
        if (this.formControl.valid) {
          this.transformTo24HourFormat();
        }
      }
    });
  }

  private formatHours(): void {
    if (!this.hours.value || !new RegExp('^[0-9]+$').test(this.hours.value)) {
      return;
    }

    if (this.hours.value.length && this.hours.value.length <= 2) {
      this.hours.setValue(parseInt(this.hours.value));
    }
  }

  private formatMinutes(): void {
    if (
      !this.minutes.value ||
      !new RegExp('^[0-9]+$').test(this.minutes.value)
    ) {
      return;
    }

    if (this.minutes.value.length === 1) {
      this.minutes.setValue(`0${this.minutes.value}`);
    }
  }

  private formatMeridian(): void {
    if (!this.hours.value || !this.minutes.value) {
      return;
    }

    if (this.meridian.value.toLowerCase().includes('p')) {
      this.meridian.setValue('PM');
    } else {
      this.meridian.setValue('AM');
    }
  }

  private formatTimeValue(): void {
    // validate if there any errors with the input
    if (!this.hours.value || !this.minutes.value) {
      if (!this.formControl.fieldDefinition.required) {
        return;
      }
      this.formControl.setErrors({ invalid: 'A time is required' });
    } else if (
      !new RegExp('^[0-9]+$').test(this.hours.value) ||
      !new RegExp('^[0-9]+$').test(this.minutes.value)
    ) {
      this.formControl.setErrors({
        invalid: 'Please use the following time format (hh:mm am/pm)',
      });
    } else if (
      parseInt(this.hours.value) >= 24 ||
      parseInt(this.minutes.value) >= 60 ||
      (this.meridian.value === 'AM' && parseInt(this.hours.value) > 12)
    ) {
      this.formControl.setErrors({ invalid: 'Invalid time range' });
    } else {
      // if the value entered is valid we reset any previous error
      this.formControl.setErrors(null);
    }
    this.formControl.markAsTouched();

    if (this.formControl.invalid) {
      return;
    }

    // format to our canonical time hh:mm (am/pm) and hours < 10 no padding
    if (this.meridian.value === 'PM' && parseInt(this.hours.value) > 12) {
      this.hours.setValue(parseInt(this.hours.value) - 12);
    } else if (
      this.meridian.value === 'AM' &&
      parseInt(this.hours.value) === 12
    ) {
      this.hours.setValue('0');
    }
  }

  private transformTo24HourFormat(): void {
    const maxHours = this.formControl.fieldDefinition.maxTime.split(':')[0];
    const minHours = this.formControl.fieldDefinition.minTime.split(':')[0];
    const maxMinutes = this.formControl.fieldDefinition.maxTime.split(':')[1];
    const minMinutes = this.formControl.fieldDefinition.minTime.split(':')[1];
    let hours = this.hours.value;

    // compare to max hours
    if (
      (this.meridian.value === 'PM' &&
        parseInt(this.hours.value) !== 12 &&
        parseInt(this.hours.value) + 12 > parseInt(maxHours)) ||
      parseInt(this.hours.value) > parseInt(maxHours)
    ) {
      this.formControl.setErrors({
        invalid: `The selected time can't be after ${this.formControl.fieldDefinition.maxTime}`,
      });
      // if the hours are the same compare minutes
    } else if (
      ((this.meridian.value === 'PM' &&
        parseInt(this.hours.value) + 12 === parseInt(maxHours)) ||
        parseInt(this.hours.value) === parseInt(maxHours)) &&
      parseInt(this.minutes.value) > parseInt(maxMinutes)
    ) {
      this.formControl.setErrors({
        invalid: `The selected time can't be after ${this.formControl.fieldDefinition.maxTime}`,
      });
      // compare to min hours
    } else if (
      (this.meridian.value === 'PM' &&
        parseInt(this.hours.value) + 12 < parseInt(minHours)) ||
      (this.meridian.value === 'AM' &&
        parseInt(this.hours.value) < parseInt(minHours))
    ) {
      this.formControl.setErrors({
        invalid: `The selected time can't be before ${this.formControl.fieldDefinition.minTime}`,
      });
      // if the hours are the same compare minutes
    } else if (
      ((this.meridian.value === 'PM' &&
        parseInt(this.hours.value) + 12 === parseInt(minHours)) ||
        (this.meridian.value === 'AM' &&
          parseInt(this.hours.value) === parseInt(minHours))) &&
      parseInt(this.minutes.value) < parseInt(minMinutes)
    ) {
      this.formControl.setErrors({
        invalid: `The selected time can't be before ${this.formControl.fieldDefinition.minTime}`,
      });
    } else {
      this.formControl.setErrors(null);
    }
    this.formControl.markAsTouched();

    if (this.formControl.invalid) {
      return;
    }

    if (this.meridian.value === 'PM' && parseInt(this.hours.value) !== 12) {
      hours = `${parseInt(this.hours.value) + 12}`;
    }

    if (this.hours.value && this.minutes.value) {
      this.formControl.setValue(
        `${hours < 10 ? '0' : ''}` + `${hours}:${this.minutes.value}:00`,
      );
    }
  }

  public validate(): void {
    this.formControl.markAsTouched();
    this.formControl.markAsDirty();
    this.formControl.updateValueAndValidity({ emitEvent: false });
  }
}
