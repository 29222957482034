import { Inject, Injectable } from '@angular/core';
import { DocumentInfo } from '@fsx/fsx-shared';
import { IUploadedFile } from '@fsx/ui-components';
import { forkJoin, map, Observable, of, Subject } from 'rxjs';
import {
  DocumentsApiService,
  FsxDocumentApiService,
} from './documents-api.service';
import { DocumentInfoAndUploadedFile } from '../../../../../../apps/fsx-ui/src/app/documents/services/upload-additional-field-files-orchestration.service';

export interface CreateDocumentInfoParams {
  filingId: string;
  uploadedFile: IUploadedFile;
}

export interface DocumentInfoCreatedParams {
  documentInfo: DocumentInfo;
  filingId: string;
}

@Injectable({
  providedIn: 'root',
})
export class CreateDocumentInfoService {
  private newDocumentInfoCreated$$ = new Subject<DocumentInfoCreatedParams>();
  public newDocumentInfoCreated$ = this.newDocumentInfoCreated$$.asObservable();

  constructor(
    @Inject(FsxDocumentApiService)
    private readonly documentsApiService: DocumentsApiService,
  ) {}

  createDocumentInfo(
    createDocumentParams: CreateDocumentInfoParams,
  ): Observable<DocumentInfoCreatedParams> {
    return this.documentsApiService
      .createDocumentInfo(
        createDocumentParams.filingId,
        createDocumentParams.uploadedFile,
      )
      .pipe(
        map((documentInfo: DocumentInfo) => {
          const documentInfoCreatedParams: DocumentInfoCreatedParams = {
            documentInfo,
            filingId: createDocumentParams.filingId,
          };
          this.newDocumentInfoCreated$$.next(documentInfoCreatedParams);
          return documentInfoCreatedParams;
        }),
      );
  }

  public getArrayOfDocumentInfoAndUploadedFile(
    uploadedFiles: IUploadedFile[],
    filingId: string,
  ): Observable<DocumentInfoAndUploadedFile>[] {
    // Convert an array of IUploadedFile to an array of DocumentInfoAndUploadedFile...
    return uploadedFiles.map((uploadedFile: IUploadedFile) => {
      return forkJoin([
        this.createDocumentInfo({
          filingId: filingId,
          uploadedFile,
        }),
        of(uploadedFile),
      ]).pipe(
        map(
          ([documentInfoCreatedParams, uploadedFile]: [
            DocumentInfoCreatedParams,
            IUploadedFile,
          ]) => {
            const { documentInfo } = documentInfoCreatedParams;
            return new DocumentInfoAndUploadedFile(documentInfo, uploadedFile);
          },
        ),
      );
    });
  }
}
