import { Inject, Injectable, InjectionToken } from '@angular/core';
import {
  CasePartyViewModel,
  CaseRequestViewModel,
  RequestParticipantRepresentationViewModel,
  RequestParticipantViewModel,
} from '@fsx/fsx-shared';
import { Observable, combineLatest, distinctUntilChanged, map } from 'rxjs';
import {
  CaseRequestDataService,
  FsxCaseRequestDataService,
} from './case-request-data.service';
import {
  FsxValidationErrorsService,
  IValidationErrorsService,
  ValidationError,
} from './validation-errors.service';
import { ValidationGroupConstants } from './validation-group-errors.service';

/**
 * The InjectionToken to use in the providers array to specify a concrete-implementation
 * of the IValidationIndicatorService to use at runtime.
 */
export const FsxValidationIndicatorService =
  new InjectionToken<IValidationIndicatorService>(
    'FsxValidationIndicatorService',
  );

/**
 * A blueprint for a derived-state service, which derives the validation indicators
 * for the tabs and checklist-icon.
 */
export interface IValidationIndicatorService {
  /**
   * A public member, which exposes the validity of the Case Details tab as an Observable
   */
  isValidCaseDetails$: Observable<boolean>;

  /**
   * A public member, which exposes the validity of the Parties tab as an Observable
   */
  isValidPartiesAndRepresentation$: Observable<boolean>;

  /**
   * A public member, which exposes the validity of the Documents tab as an Observable
   */
  isValidDocuments$: Observable<boolean>;

  /**
   * A public member, which exposes the validity of the Review tab as an Observable
   */
  isValidReview$: Observable<boolean>;

  /**
   * A public member, which exposes the overall cross-tab validity as an Observable
   */
  isValidCrossTab$: Observable<boolean>;
}

/**
 * A concrete implementation of a derived-state service, which derives the validation
 * indicators for the tabs and checklist-icon.
 */
@Injectable()
export class ValidationIndicatorService implements IValidationIndicatorService {
  /**
   * A public member, which exposes the validity of the Case Details tab as an Observable
   */
  isValidCaseDetails$: Observable<boolean> = combineLatest([
    this.caseRequessDataService.caseRequest$,
    this.validationErrorsService.getValidationErrorsForGroup(
      ValidationGroupConstants.detail,
    ),
  ]).pipe(
    map(
      ([caseRequest, validationErrors]: [
        CaseRequestViewModel,
        ValidationError[],
      ]) => {
        const hasValidationErrors = validationErrors.length > 0;
        const hasInvalidFields = caseRequest.isCaseDetailsValid === false;
        const isValidCaseDetails = !hasInvalidFields && !hasValidationErrors;
        return isValidCaseDetails;
      },
    ),
    distinctUntilChanged(),
  );

  /**
   * A public member, which exposes the validity of the Parties tab as an Observable
   */
  isValidPartiesAndRepresentation$: Observable<boolean> = combineLatest([
    this.caseRequessDataService.caseRequest$,
    this.validationErrorsService.getValidationErrorsForGroup(
      ValidationGroupConstants.parties,
    ),
  ]).pipe(
    map(
      ([caseRequest, validationErrors]: [
        CaseRequestViewModel,
        ValidationError[],
      ]) => {
        const hasValidationErrors = validationErrors.length > 0;

        // Check for invalid parties
        let hasInvalidParties = false;
        if (caseRequest && caseRequest.parties) {
          hasInvalidParties = caseRequest.parties.some(
            (p: CasePartyViewModel) => {
              return p.isValid === false;
            },
          );
        }

        // Check for invalid participants
        let hasInvalidParticipants = false;
        if (caseRequest && caseRequest.participants) {
          hasInvalidParticipants = caseRequest.participants.some(
            (p: RequestParticipantViewModel) => {
              return p.isValid === false;
            },
          );
        }

        // Check for invalid party representation
        let hasInvalidRepresentation = false;
        if (caseRequest && caseRequest.parties) {
          caseRequest.parties.forEach((p: CasePartyViewModel) => {
            if (p.representation) {
              hasInvalidRepresentation = p.representation.some(
                (r: RequestParticipantRepresentationViewModel) => {
                  return (
                    r.isValid === false || p.isRepresentationValid === false
                  );
                },
              );
            }
          });
        }

        const isValidPartiesAndRepresentation =
          !hasInvalidParties &&
          !hasInvalidParticipants &&
          !hasInvalidRepresentation &&
          caseRequest.isPartiesValid !== false &&
          !hasValidationErrors;

        return isValidPartiesAndRepresentation;
      },
    ),
    distinctUntilChanged(),
  );

  /**
   * A public member, which exposes the validity of the Documents tab as an Observable
   */
  isValidDocuments$: Observable<boolean> = combineLatest([
    this.caseRequessDataService.caseRequest$,
    this.validationErrorsService.getValidationErrorsForGroup(
      ValidationGroupConstants.documents,
    ),
  ]).pipe(
    map(
      ([caseRequest, validationErrors]: [
        CaseRequestViewModel,
        ValidationError[],
      ]) => {
        const hasValidationErrors = validationErrors.length > 0;
        const hasInvalidFields = caseRequest.isDocumentsValid === false;
        const isValidDocuments = !hasInvalidFields && !hasValidationErrors;
        return isValidDocuments;
      },
    ),
    distinctUntilChanged(),
  );

  /**
   * A public member, which exposes the validity of the Review tab as an Observable
   */
  isValidReview$: Observable<boolean> = combineLatest([
    this.caseRequessDataService.caseRequest$,
    this.validationErrorsService.getValidationErrorsForGroup(
      ValidationGroupConstants.review,
    ),
  ]).pipe(
    map(
      ([caseRequest, validationErrors]: [
        CaseRequestViewModel,
        ValidationError[],
      ]) => {
        const hasValidationErrors = validationErrors.length > 0;
        const hasInvalidFields = caseRequest.isReviewValid === false;
        const isValidReview = !hasInvalidFields && !hasValidationErrors;
        return isValidReview;
      },
    ),
    distinctUntilChanged(),
  );

  /**
   * A public member, which exposes the overall cross-tab validity as an Observable
   */
  isValidCrossTab$: Observable<boolean> = combineLatest([
    this.isValidCaseDetails$,
    this.isValidPartiesAndRepresentation$,
    this.isValidDocuments$,
    this.isValidReview$,
    this.validationErrorsService.validationErrors$,
  ]).pipe(
    map(
      ([
        isValidCaseDetails,
        isValidPartiesAndRepresentation,
        isValidDocuments,
        isValidReview,
        validationErrors,
      ]: [boolean, boolean, boolean, boolean, ValidationError[]]) => {
        const hasValidationErrors: boolean = validationErrors.length > 0;
        const isCaseRequestValid =
          isValidCaseDetails &&
          isValidPartiesAndRepresentation &&
          isValidDocuments &&
          isValidReview &&
          !hasValidationErrors;
        return isCaseRequestValid === false ? false : true;
      },
    ),
    distinctUntilChanged(),
  );

  /**
   *
   * @param validationErrorsService The state service for validation errors, which we use
   * as part of the cross-tab validity check.
   *
   * @param validationGroupErrorsService The state service for grouping validation errors
   * with a count of the number of errors on the tab. We use this as part of the cross-tab
   * validity check.
   *
   * @param caseRequessDataService The single source of truth for CaseRequest data, which
   * we use to lookup the high-level caseRequest object validity flags.
   */
  constructor(
    @Inject(FsxValidationErrorsService)
    private readonly validationErrorsService: IValidationErrorsService,
    @Inject(FsxCaseRequestDataService)
    private readonly caseRequessDataService: CaseRequestDataService,
  ) {}
}
