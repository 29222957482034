import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import {
  AdditionalFieldSpec,
  AdditionalFieldValue,
  CurrencyFieldDefinition,
  CurrencyValue,
  DateFieldDefinition,
  NumberFieldDefinition,
  RequestContact,
  SearchResultItem,
  SelectionDependentFields,
} from '@fsx/fsx-shared';
import {
  FieldDefinition,
  FormControlWithModel,
  SelectionFieldType,
} from '@fsx/ui-components';
import { Subject, debounceTime, takeUntil } from 'rxjs';
import { FsxReferenceResolver } from '../../../shared/resolvers/list-reference.resolver';
import { AdditionalFieldSpecValueAndFormControl } from '../additional-fields-form.service';

@Component({
  selector: 'fsx-additional-field-control',
  templateUrl: './additional-field-control.component.html',
  styleUrls: ['./additional-field-control.component.css'],
})
export class FsxAdditionalFieldControlComponent implements OnInit, OnDestroy {
  /**
   * A single AdditionalFieldSpec object to derive a ui control for.
   */
  @Input() additionalFieldSpec!: AdditionalFieldSpec;

  /**
   * A single AdditionalFieldValue object to use as the initial display value.
   */
  @Input() additionalFieldValue!: AdditionalFieldValue;

  /**
   * The collection of AdditionalFieldValue objects to pass onto the nested instance
   * of the FsxAdditionalFieldContainerComponent.
   */
  @Input() additionalFieldValues: AdditionalFieldValue[] = [];

  /**
   * The AdditionalFieldSpec and optional FormControl.
   * This was a workaround when unable to pass in optional formControl directly.
   */
  @Input() specAndFormControl!: AdditionalFieldSpecValueAndFormControl;

  /**
   * Shouldn't have to bring this in but we have to pass it on to the nested instance of the
   * AdditionalFieldsContainerComponent which uses it to create FormControl objects.
   */
  @Input() resolver!: FsxReferenceResolver;

  /**
   * An event to notify FsxAdditionalFieldsContainerComponent that the AdditonalFieldValue
   * has been updated and that it should update it's collection of AdditionalFieldValue objects.
   */
  @Output() additionalFieldValueEvent =
    new EventEmitter<AdditionalFieldValue>();

  /**
   * An event to notify FsxAdditionalFieldsContainerComponent that the nested instance of
   * FsxAdditionalFieldsContainerComponent has been cleared and that it should remove any
   *  AdditionalFieldValue objects for those fields.
   */
  @Output() clearAdditionalFieldValuesEvent = new EventEmitter<string[]>();

  /**
   * The collection of AdditionalFieldSpec objects nested within the parent AdditrionalFieldSpec object.
   * We pass these onto a nested instance of the FsxAdditionalFieldContainerComponent.
   */
  nestedAdditionalFieldSpecs: AdditionalFieldSpec[] = [];

  /**
   * A boolean value indicating whether the nested instance of FsxAdditionalFieldContainerComponent should
   * be displayed or remain hidden (default).
   */
  displayNestedAdditionalFields: boolean = false;

  /**
   * A subject to trigger the tearing down of the subscriptions when the component is destroyed.
   */
  private destroy$: Subject<void> = new Subject();

  /**
   * The enum of the various selection field types.
   */
  public selectionType = SelectionFieldType;

  /**
   * The lifecycle hook where we setup the subscription to listen FormControl values changes.
   */
  ngOnInit(): void {
    if (this.specAndFormControl.formControl) {
      this.listenToFormControlValueChanges();
      this.listenToFormControlValueChanges2();
    }

    this.setNestedAdditionalFieldSpecs();
  }

  /**
   * A helper method to set the collection of AdditionalFieldSpec objects to pass onto
   * the nested instance of the FsxAdditionalFieldContainerComponent.
   */
  setNestedAdditionalFieldSpecs(
    additionalFieldSpecs: AdditionalFieldSpec[] = [],
  ): void {
    this.nestedAdditionalFieldSpecs = additionalFieldSpecs;
    // The booleanFieldDefinition can have nested additionalFieldSpecs so we check to set them here.
    if (this.additionalFieldSpec.booleanFieldDefinition) {
      const additionalFieldSpecs: AdditionalFieldSpec[] =
        this.additionalFieldSpec.booleanFieldDefinition.additionalFields || [];
      this.nestedAdditionalFieldSpecs = additionalFieldSpecs;
    }
    if (this.additionalFieldSpec.selectionFieldDefinition) {
      const sdf: SelectionDependentFields | undefined =
        this.additionalFieldSpec.selectionFieldDefinition.selectionDependentFields.find(
          (selectionDependentField: SelectionDependentFields) => {
            return (
              selectionDependentField.selectedName ===
              this.additionalFieldValue.selectionValue![0]
            );
          },
        );

      this.nestedAdditionalFieldSpecs = sdf?.additionalFields || [];
    }
  }

  /**
   * The lifecycle hook where we teardown inner subscriptions with the component.
   */
  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  /**
   * A private helper method to setup the FormControl.valueChanges() subscriptipn, which
   * we debunce to prevent redundant emissions. We use this to determine when to notify
   * the parent FsxAdditionalFieldsContainer when the AdditionalFieldValue object has
   * been updated.
   */
  private listenToFormControlValueChanges(): void {
    if (this.specAndFormControl.formControl) {
      this.specAndFormControl.formControl.valueChanges
        .pipe(takeUntil(this.destroy$), debounceTime(700))
        .subscribe((value) => {
          this.notifyParentOfUpdatedAdditionalFieldValue(value);

          if (this.additionalFieldSpec.selectionFieldDefinition) {
            const sdf: SelectionDependentFields | undefined =
              this.additionalFieldSpec.selectionFieldDefinition.selectionDependentFields.find(
                (selectionDependentField: SelectionDependentFields) => {
                  return selectionDependentField.selectedName === value;
                },
              );

            this.nestedAdditionalFieldSpecs = sdf?.additionalFields || [];
          }
        });
    }
  }

  /**
   * A private helper method to setup a second FormControl.valueChanges() subscription,
   * which we use to deterkine when to notify the parent FsxAdditionalFieldsContainer
   * that additional fields have been removed from the view and that it should remove
   * any related AdditionalFieldValue objects.
   */
  private listenToFormControlValueChanges2(): void {
    if (this.specAndFormControl.formControl) {
      this.specAndFormControl.formControl.valueChanges
        .pipe(takeUntil(this.destroy$), debounceTime(700))
        .subscribe((value) => {
          this.notifyParentOfRemovedAdditionalFields(value);
        });
    }
  }

  /**
   * A private helper method to update the AdditionalFieldValue object for this instance of the
   * FsxAdditionalFieldControlComponent and to notify the FsxAdditionalFieldsContainerComponent
   * of the update.
   *
   * @param value The emitted ForemControl value to apply to the AdditionalFieldValue object.
   */
  private notifyParentOfUpdatedAdditionalFieldValue(
    value:
      | string
      | string[]
      | number
      | boolean
      | SearchResultItem
      | CurrencyValue
      | RequestContact[],
  ): void {
    // Initialise AdditionalFieldValue object.
    const updatedAdditionalFieldValue: AdditionalFieldValue = {
      additionalFieldName: this.additionalFieldSpec.name,
    };

    // Append the appropriate value property based on the control type
    if (this.additionalFieldSpec.booleanFieldDefinition) {
      const typedValue: boolean = value as boolean;
      updatedAdditionalFieldValue.booleanValue = typedValue;
    }
    if (this.additionalFieldSpec.textFieldDefinition) {
      const typedValue: string = value as string;
      updatedAdditionalFieldValue.textValue = typedValue;
    }
    if (this.additionalFieldSpec.selectionFieldDefinition) {
      const typedValue: string = value as string;
      updatedAdditionalFieldValue.selectionValue = [typedValue];
    }
    if (this.additionalFieldSpec.searchFieldDefinition) {
      const typedValue: SearchResultItem = value as SearchResultItem;
      updatedAdditionalFieldValue.searchResultItem = typedValue;
    }
    if (this.additionalFieldSpec.dateFieldDefinition) {
      const typedValue: string = value as string;
      updatedAdditionalFieldValue.dateValue = typedValue;
    }
    if (this.additionalFieldSpec.numberFieldDefinition) {
      const typedValue: string = value as string;
      updatedAdditionalFieldValue.numberValue = typedValue;
    }
    if (this.additionalFieldSpec.contactFieldDefinition) {
      const typedValue: RequestContact[] = value as RequestContact[];
      updatedAdditionalFieldValue.contactValues = typedValue;
    }
    if (this.additionalFieldSpec.currencyFieldDefinition) {
      const typedValue: CurrencyValue = {
        currencyKey: '$',
        amount: value,
      } as CurrencyValue;
      updatedAdditionalFieldValue.currencyValue = typedValue;
    }

    // Notify parent of the updated AdditionalFieldValue object,
    this.additionalFieldValueEvent.emit(updatedAdditionalFieldValue);
  }

  /**
   * A private helper method to notify the parent FsxAdditionalFieldsContainerComponent that
   * any nested additional fields have been removed from view and that it should in turn
   * remove any related AdditionalFieldValue objects.
   *
   * @param value The emitted ForemControl value
   */
  private notifyParentOfRemovedAdditionalFields(value: string | boolean): void {
    // First we check for a value that allows nested additional fields to be displayed.
    this.displayNestedAdditionalFields = !!value;

    // If we are no longer displaying the additional fields, then we should notify the parent.
    if (!this.displayNestedAdditionalFields) {
      const additionalFieldNames: string[] =
        this.nestedAdditionalFieldSpecs.map(
          (spec: AdditionalFieldSpec) => spec.name,
        );
      this.clearAdditionalFieldValuesEvent.emit(additionalFieldNames);
    }
  }

  /**
   * A handler method for when the nested instance of FsxAdditionalFieldValuesComponent emits
   * an updated collection of AdditionalFieldValue objects through its additionalFieldValueEvent.
   *
   * @param additionalFieldValues The updated collection of AdditonalFieldValue objects.
   */
  onNestedAdditionalFieldValuesEmitted(
    nestedAdditionalFieldValues: AdditionalFieldValue[],
  ) {
    nestedAdditionalFieldValues.forEach((afv: AdditionalFieldValue) => {
      this.additionalFieldValueEvent.emit(afv);
    });
  }

  /**
   * An attempt to bring in the old way of setting the formControl, to save from creating copies
   * of component-library components, which insists on creating the formControl for us.
   *
   * @param formControl The emiitted formControl that the component created for us.
   */
  setFormControl(formControl: FormControlWithModel<FieldDefinition>): void {
    this.setFormControlAndStartListening(formControl);
  }

  /**
   * An attempt to bring in the old way of extracting the selected SearchResultItem
   * from the efm search component.
   *
   * @param _option The selected SearchResultItem
   */
  setSearchFormControl(_option: SearchResultItem) {
    if (this.specAndFormControl.formControl) {
      // The formControl value is not set from within the efm search component so we set it here instead.
      this.specAndFormControl.formControl.setValue(_option);
    }
  }

  /**
   * An attempt to bring in the old way of setting the formControl for date component.
   *
   * @param formControl The formCntrol emitted by the date component.
   */
  setDateFormControl(formControl: FormControlWithModel<DateFieldDefinition>) {
    this.setFormControlAndStartListening(formControl);
  }

  /**
   * An attempt to bring in the old way of setting the formControl for number component.
   *
   * @param formControl The formCntrol emitted by the number component.
   */
  setNumberFormControl(
    formControl: FormControlWithModel<NumberFieldDefinition>,
  ) {
    this.setFormControlAndStartListening(formControl);
  }

  /**
   * An attempt to bring in the old way of setting the formControl for currency component.
   *
   * @param formControl The formCntrol emitted by the currency component.
   */
  setCurrencyFormControl(
    formControl: FormControlWithModel<CurrencyFieldDefinition>,
  ) {
    this.setFormControlAndStartListening(formControl);
  }

  /**
   * A private helper method to allow for the old way of setting the formControl from the
   * nested component instances.
   *
   * @param formControl The formControl emitted by the nested component instances.
   */
  private setFormControlAndStartListening(
    formControl: FormControlWithModel<FieldDefinition>,
  ) {
    this.specAndFormControl.formControl = formControl;
    this.listenToFormControlValueChanges();
    this.listenToFormControlValueChanges2();
  }

  /**
   * A handler method for when the FsxAdditionalFieldControlsComponent emits an updated
   * AdditionalFieldValue object.
   *
   * @param additionalFieldValue The updated AdditionalFieldValue object.
   */
  additionalFieldValueEventHandler(
    additionalFieldValue: AdditionalFieldValue,
  ): void {
    this.additionalFieldValueEvent.emit(additionalFieldValue);
  }
}
