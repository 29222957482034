import { Component, EventEmitter, Inject, Input, Output } from '@angular/core';
import {
  FsxCombinedFilingDataService,
  ICombinedFilingDataService,
} from '../../services/combined-filing-data.service';
import {
  AdditionalFieldSpec,
  AdditionalFieldValue,
  RequestDocumentParticipant,
} from '@fsx/fsx-shared';
import { ReferenceResolver } from '@fsx/ui-components';

@Component({
  selector: 'fsx-participants-additional-field',
  templateUrl: './participants-additional-field.component.html',
  styleUrls: ['./participants-additional-field.component.scss'],
})
export class ParticipantsAdditionalFieldComponent {
  /**
   * Passed in here to forward onto the contact component.
   */
  @Input() resolver!: ReferenceResolver;

  /**
   * A single AdditionalFieldSpec object to derive the participants formArray.
   */
  @Input() additionalFieldSpec!: AdditionalFieldSpec;

  /**
   * A single AdditionalFieldValue object to derive the participants formArray.
   */
  @Input() additionalFieldValue!: AdditionalFieldValue;

  /**
   * An event to notify FsxAdditionalFieldsContainerComponent that the AdditonalFieldValue
   * has been updated and that it should update it's collection of AdditionalFieldValue objects.
   */
  @Output() additionalFieldValueEvent =
    new EventEmitter<AdditionalFieldValue>();

  combinedFilingData$ = this.combinedFilingDataService.combinedFilingData$;

  constructor(
    @Inject(FsxCombinedFilingDataService)
    private readonly combinedFilingDataService: ICombinedFilingDataService,
  ) {}

  setParticipantSelectedValue(option: RequestDocumentParticipant[]) {
    const additionalFieldValue: AdditionalFieldValue = {
      additionalFieldName: this.additionalFieldSpec.name,
      participantValues: option.filter((o) => !!o.participantName),
    };
    this.additionalFieldValueEvent.emit(additionalFieldValue);
  }
}
