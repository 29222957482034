import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NumberFieldDefinition } from '@fsx/fsx-shared';
import { formatValue } from '../../helpers/input-formatter.helper';
import { parseValue } from '../../helpers/input-parser.helper';
import { sanitizeValue } from '../../helpers/input-sanitizer.helper';
import { FormControlWithModel } from '../../models/form-control.model';
import { FSXFormControlService } from '../../services';

@Component({
  selector: 'fsx-number-component',
  templateUrl: './number.component.html',
  styleUrls: ['./number.component.scss'],
})
export class FsxNumberComponent implements OnInit {
  @Input() fieldDefinition!: NumberFieldDefinition;
  @Input() initialValue!: string | number | null;
  @Input() caption!: string;
  @Input() helpText!: string;
  @Input() hint!: string;
  @Input() fieldType!: string;
  @Input() width!: string;
  @Output() formControlEmitter = new EventEmitter<
    FormControlWithModel<NumberFieldDefinition>
  >();

  public formControl!: FormControlWithModel<NumberFieldDefinition>;
  public parseError!: string | null;

  constructor(private readonly fsxFormControlService: FSXFormControlService) {}

  ngOnInit(): void {
    this.formControl = this.fsxFormControlService.createFormControl(
      this.fieldDefinition,
      this.fieldType,
      this.initialValue,
    );
    this.formControlEmitter.emit(this.formControl);

    this.formatValue();
  }

  public getErrorMessage(): string {
    return Object.values({ ...this.formControl.errors })[0];
  }

  public formatValue(): void {
    const value = String(this.formControl.value ?? '').trim();
    const fieldType = this.fieldType;
    const allowDecimals = this.formControl.fieldDefinition.allowedDecimals;

    if (value !== null && value.length) {
      const sanitizedValue = sanitizeValue(value, fieldType);
      const parsedValue = parseValue(sanitizedValue, fieldType);
      if (typeof parsedValue !== 'number') {
        this.parseError = 'Invalid numeric value';
        this.formControl.setValue(value);
        return;
      }
      this.parseError = null;
      const formattedValue = formatValue(parsedValue, fieldType, {
        allowDecimals,
      });
      this.formControl.formattedValue = parseFloat(String(parsedValue));
      this.formControl.setValue(formattedValue);
    } else {
      this.formControl.setValue(null);
    }
  }

  public validate(): void {
    this.formControl.markAsTouched();
    this.formControl.markAsDirty();
    this.formControl.updateValueAndValidity({ emitEvent: false });
  }
}
