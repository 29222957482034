import { OnInit, OnDestroy, Component, Inject } from '@angular/core';
import { ParamMap, ActivatedRoute } from '@angular/router';
import {
  FilingState,
  FILING_SUB_TABS,
  TRANSACTION_ID,
  Filing,
  DataAttribute,
  DocumentInfo,
  CombinedFilingData,
  FilingSubTabItem,
  CaseRequestViewModel,
  RequestCaseViewModel,
  FilingProfileRulesService,
  ICaseRequestUpdateService,
  CaseRequestUpdateService,
  UploadFileService,
  CaseRequestBuilderService,
  ValidationService,
  IFilingTabsService,
  CreateRequestDocumentService,
  ProblemDetails,
  FsxFilingTabsService,
  IFilingApiService,
  FilingCaseUpdate,
  FilingUpdate,
  FsxCaseRequestBuilderService,
  FsxCaseRequestUpdateService,
  FsxCreateRequestDocumentService,
  FsxFilingApiService,
  FsxUploadFileService,
  FsxValidationService,
  DefaultPartyService,
  FsxDefaultPartyService,
  FsxOrganizationFormService,
  OrganizationFormService,
} from '@fsx/fsx-shared';
import { IUploadedFile } from '@fsx/ui-components';
import moment, { Moment } from 'moment';
import {
  BehaviorSubject,
  Observable,
  filter,
  map,
  Subject,
  switchMap,
  tap,
  take,
} from 'rxjs';
import { DocumentsGridRow } from '../documents/documents-grid/documents-grid.model';
import {
  AddDocumentOrchestrationService,
  FsxAddDocumentOrchestrationService,
  IAddDocumentOrchestrationService,
} from '../documents/services/add-document-orchestration.service';
import {
  AddLeadDocumentOrchestrationService,
  FsxAddLeadDocumentOrchestrationService,
  IAddLeadDocumentOrchestrationService,
} from '../documents/services/add-lead-documents-orchestration.service';
import {
  FsxUpdateDocumentOrchestrationService,
  UpdateDocumentOrchestrationService,
} from '../documents/services/update-document-orchestration.service';
import {
  FsxUploadAdditionalFieldFilesOrchestrationService,
  UploadAdditionalFieldFilesOrchestrationService,
} from '../documents/services/upload-additional-field-files-orchestration.service';
import { PartiesGridRow } from '../parties/parties-grid/parties-grid.model';
import { FsxReferenceResolver } from '../shared/resolvers/list-reference.resolver';
import {
  FilingEditorActionsService,
  FsxFilingEditorActionsService,
  IFilingEditorActionsService,
} from './services/filing-editor-actions.service';
import {
  FilingEditorEventService,
  FsxFilingEditorEventService,
} from './services/filing-editor-events.service';
import {
  CloseFilingOrchestrationService,
  FsxCloseFilingOrchestrationService,
} from './services/orchestration/close-filing-orchestration.service';
import {
  FsxSwitchFilingOrchestrationService,
  SwitchFilingOrchestrationService,
} from './services/orchestration/switch-filing-orchestration.service';
import { FilingSubmissionReceiptService } from '../filing-submission-receipt/filing-submission-receipt.service';
import {
  CombinedFilingDataService,
  FsxCombinedFilingDataService,
  FsxCombinedFilingDataService2,
  ICombinedFilingDataService,
} from './services/combined-filing-data.service';
import {
  FilingSubTabsService,
  FsxFilingSubTabsService,
  IFilingSubTabsService,
} from './filing-sub-tabs-container/filing-sub-tabs.service';
import {
  FilingDataService,
  FsxFilingDataService,
  IFilingDataService,
} from './services/filing-data.service';
import {
  CaseRequestDataService,
  FsxCaseRequestDataService,
} from './services/case-request-data.service';
import {
  FilingProfileDataService,
  FsxFilingProfileDataService,
} from './services/filing-profile-data.service';
import {
  FirstLoadOrchestrationService,
  FsxFirstLoadOrchestrationService,
} from './services/orchestration/first-load-orchestration.service';
import {
  FilingSubTabsFactoryService,
  FsxFilingSubTabsFactoryService,
} from './filing-sub-tabs-container/filing-sub-tabs-factory.service';
import {
  FsxValidateCaseOrchestrationService,
  ValidateCaseOrchestrationService,
} from './services/orchestration/validate-case-orchestration.service';
import {
  AddSupportingDocumentOrchestrationService,
  FsxAddSupportingDocumentOrchestrationService,
} from '../documents/services/add-supporting-document-orchestration.service';
import {
  FsxReplaceDocumentFileOrchestrationService,
  ReplaceDocumentFileOrchestrationService,
} from '../documents/services/replace-document-file-orchestration.service';
import {
  FsxUpdateCaseRequestOrchestrationService,
  UpdateCaseRequestOrchestrationService,
} from '../parties/orchestration-services/update-case-request-orchestration.service';
import {
  FsxRemoveDocumentOrchestrationService,
  RemoveDocumentOrchestrationService,
} from '../documents/services/remove-document-orchestration.service';
import {
  FsxUploadFilesOrchestrationService,
  UploadFilesOrchestrationService,
} from '../documents/services/upload-files-orchestration.service';
import {
  AddContactAsParticipantOrchestrationService,
  FsxAddContactAsParticipantOrchestrationService,
} from '../parties/orchestration-services/add-contact-as-participant-orchestration.service';
import {
  AddDefaultParticipantOrchestrationService,
  FsxAddDefaultParticipantOrchestrationService,
} from '../parties/orchestration-services/add-default-participant-orchestration.service';
import {
  AddRepresentationOrchestrationService,
  FsxAddRepresentationOrchestrationService,
} from '../parties/orchestration-services/add-representation-orchestration.service';
import {
  AddSelectedContactsAsParticipantsOrchestrationService,
  FsxAddSelectedContactsAsParticipantsOrchestrationService,
} from '../parties/orchestration-services/add-selected-contacts-as-participants-orchestration.service';
import {
  AddSelectedContactsAsRepresentationOrchestrationService,
  FsxAddSelectedContactsAsRepresentationOrchestrationService,
} from '../parties/orchestration-services/add-selected-contacts-as-representatives-orchestration.service';
import {
  ClearParticipantOrchestrationService,
  FsxClearParticipantOrchestrationService,
} from '../parties/orchestration-services/clear-participant-orchestration.service';
import {
  ClearRepresentationOrchestrationService,
  FsxClearRepresentationOrchestrationService,
} from '../parties/orchestration-services/clear-representation-orchestration.service';
import {
  FsxRemoveParticipantOrchestrationService,
  RemoveParticipantOrchestrationService,
} from '../parties/orchestration-services/remove-participant-orchestration.service';
import {
  FsxRemoveRepresentationOrchestrationService,
  RemoveRepresentationOrchestrationService,
} from '../parties/orchestration-services/remove-representation-orchestration.service';
import {
  FsxUpdateParticipantOrchestrationService,
  UpdateParticipantOrchestrationService,
} from '../parties/orchestration-services/update-participant-orchestration.service';
import {
  FsxUpdateRepresentationOrchestrationService,
  UpdateRepresentationOrchestrationService,
} from '../parties/orchestration-services/update-representation-orchestration.service';
import {
  FsxSubmitFilingOrchestrationService,
  SubmitFilingOrchestrationService,
} from './services/orchestration/submit-filing-orchestration.service';
import {
  FsxValidatePartiesOrchestrationService,
  ValidatePartiesOrchestrationService,
} from './services/orchestration/validate-parties-orchestration.service';
import {
  FsxValidateDocumentsOrchestrationService,
  ValidateDocumentsOrchestrationService,
} from './services/orchestration/validate-documents-orchestration.service';
import {
  CaseReviewValidationService,
  FsxCaseReviewValidationService,
} from 'projects/libs/shared/src/lib/services/core/validation/case-review-validation.service';
import {
  AdditionalFieldsValidationService,
  FsxAdditionalFieldsValidationService,
} from 'projects/libs/shared/src/lib/services/core/validation/additional-fields-validation.service';
import {
  AddressValidationService,
  FsxAddressValidationService,
} from 'projects/libs/shared/src/lib/services/core/validation/address-validation.service';
import {
  AliasValidationService,
  FsxAliasValidationService,
} from 'projects/libs/shared/src/lib/services/core/validation/alias-validation.service';
import {
  CaseValidationService,
  FsxCaseValidationService,
} from 'projects/libs/shared/src/lib/services/core/validation/case-validation.service';
import {
  ContactValidationService,
  FsxContactValidationService,
} from 'projects/libs/shared/src/lib/services/core/validation/contact-validation.service';
import {
  CurrencyValidationService,
  FsxCurrencyValidationService,
} from 'projects/libs/shared/src/lib/services/core/validation/currency-validation.service';
import {
  DateTimeValidationService,
  FsxDateTimeValidationService,
} from 'projects/libs/shared/src/lib/services/core/validation/date-time-validation.service';
import {
  DateValidationService,
  FsxDateValidationService,
} from 'projects/libs/shared/src/lib/services/core/validation/date-validation.service';
import {
  DocumentDomainValidationService,
  FsxDocumentDomainValidationService,
} from 'projects/libs/shared/src/lib/services/core/validation/document-domain-validation.service';
import {
  DocumentFilesValidationService,
  FsxDocumentFilesValidationService,
} from 'projects/libs/shared/src/lib/services/core/validation/document-files-validation.service';
import {
  DocumentValidationService,
  FsxDocumentValidationService,
} from 'projects/libs/shared/src/lib/services/core/validation/document-validation.service';
import {
  EmailAddressValidationService,
  FsxEmailAddressValidationService,
} from 'projects/libs/shared/src/lib/services/core/validation/email-address-validation.service';
import {
  FsxIdentificationValidationService,
  IdentificationValidationService,
} from 'projects/libs/shared/src/lib/services/core/validation/identification-validation.service';
import {
  FsxNumberValidationService,
  NumberValidationService,
} from 'projects/libs/shared/src/lib/services/core/validation/number-validation.service';
import {
  FsxOrganizationValidationService,
  OrganizationValidationService,
} from 'projects/libs/shared/src/lib/services/core/validation/organization-validation.service';
import {
  FsxParticipantValidationService,
  ParticipantValidationService,
} from 'projects/libs/shared/src/lib/services/core/validation/participant-validation.service';
import {
  FsxPartyValidationService,
  PartyValidationService,
} from 'projects/libs/shared/src/lib/services/core/validation/party-validation.service';
import {
  FsxPersonNameValidationService,
  PersonNameValidationService,
} from 'projects/libs/shared/src/lib/services/core/validation/person-name-validation.service';
import {
  FsxPersonValidationService,
  PersonValidationService,
} from 'projects/libs/shared/src/lib/services/core/validation/person-validation.service';
import {
  FsxPhoneValidationService,
  PhoneValidationService,
} from 'projects/libs/shared/src/lib/services/core/validation/phone-validation.service';
import {
  FsxRepresentationValidationService,
  RepresentationValidationService,
} from 'projects/libs/shared/src/lib/services/core/validation/representation-validation.service';
import {
  FsxSearchFieldValidationService,
  SearchFieldValidationService,
} from 'projects/libs/shared/src/lib/services/core/validation/search-field-validation.service';
import {
  FsxSelectionFieldValidationService,
  SelectionFieldValidationService,
} from 'projects/libs/shared/src/lib/services/core/validation/selection-field-validation.service';
import {
  FsxSingleParticipantValidationService,
  SingleParticipantValidationService,
} from 'projects/libs/shared/src/lib/services/core/validation/single-participant-validation.service';
import {
  FsxTextFieldValidationService,
  TextFieldValidationService,
} from 'projects/libs/shared/src/lib/services/core/validation/text-field-validation.service';
import {
  FsxTimeValidationService,
  TimeValidationService,
} from 'projects/libs/shared/src/lib/services/core/validation/time-validation.service';
import {
  FsxValidationHelperFpService,
  ValidationHelperFpService,
} from 'projects/libs/shared/src/lib/services/core/validation/validation-helper-fp.service';
import {
  FsxValidationHelperService,
  ValidationHelperService,
} from 'projects/libs/shared/src/lib/services/core/validation/validation-helper.service';
import {
  FsxValidateReviewOrchestrationService,
  IValidateReviewOrchestrationService,
  ValidateReviewOrchestrationService,
} from './services/orchestration/validate-review-orchestration.service';
import {
  EditParticipantOrchestrationService,
  FsxEditParticipantOrchestrationService,
} from '../parties/orchestration-services/edit-participant-orchestration.service';
import {
  ContactsListService,
  FsxContactsListService,
} from '../contacts/contacts-list/contacts-list.service';
import {
  DocumentInfoDataService,
  FsxDocumentInfoDataService,
} from './services/document-info-data.service';
import {
  FsxLoadFilingFeesOrchestrationService,
  LoadFilingFeesOrchestrationService,
} from './services/orchestration/load-filing-fees-orchestration.service';
import {
  FilingFeesDataService,
  FsxFilingFeesDataService,
} from './services/filing-fees-data.service';
import {
  FilingChecklistService,
  FsxFilingChecklistService,
} from '../validation/filing-checklist/filing-checklist.service';
import {
  FsxPollingStateService,
  IPollingStateService,
  PollingStateService,
} from './services/polling-state.service';
import {
  FsxValidationErrorsService,
  ValidationErrorsService,
} from './services/validation-errors.service';
import {
  FsxValidationResultMappingService,
  ValidationResultMappingService,
} from './services/validation-result-mapping.service';
import {
  FsxValidationGroupErrorsService,
  ValidationGroupErrorsService,
} from './services/validation-group-errors.service';
import {
  FsxValidationIndicatorService,
  ValidationIndicatorService,
} from './services/validation-indicator.service';
import {
  FsxSelectedContactsService,
  SelectedContactsService,
} from '../contacts/contacts-list/selected-contacts.service';
import {
  FsxPanelService,
  PanelService,
} from '../shared/services/panel.service';
import {
  ContactFormService,
  FsxContactFormService,
} from '../contacts/contact-form/contact-form.service';
import {
  FilingFeesGetterService,
  FsxFilingFeesGetterService,
} from './services/filing-fees-getter.service';
import {
  FsxOpenContactFormOrchestrationService,
  OpenContactFormOrchestrationService,
} from '../shared/services/open-contact-form-orchestration.service';
import {
  FsxOpenParticipantFormOrchestrationService,
  OpenParticipantFormOrchestrationService,
} from '../shared/services/open-participant-form-orchestration.service';
import {
  FsxApplyParticipantFormUpdatesOrchestrationService,
  ApplyParticipantFormUpdatesOrchestrationService,
} from '../shared/services/apply-participant-form-updates-orchestration.service';
import {
  FsxOpenContactsListOrchestrationService,
  OpenContactsListOrchestrationService,
} from '../shared/services/open-contact-list-orchestration.service';
import {
  ContactsListTwoService,
  FsxContactsListTwoService,
} from '../contacts/contacts-list-two/contacts-list-two.service';
import { v4 as uuidv4 } from 'uuid';
import {
  FormGroupTransformService,
  FsxFormGroupTransformService,
} from '../shared/services/form-group-transform.service';
import {
  FsxPartiesGridConfigService,
  PartiesGridConfigService,
} from '../parties/parties-grid/parties-grid-config.service';
import {
  AdditionalFieldSpecLookupService,
  FsxAdditionalFieldSpecLookupService,
} from '../parties/parties-grid/additional-field-spec-lookup.service';
import {
  AdditionalFieldValueLookupService,
  FsxAdditionalFieldValueLookupService,
} from '../parties/parties-grid/additional-field-value-lookup.service';
import {
  FsxPartyDataService,
  PartyDataService,
} from './services/party-data.service';
import {
  FsxParticipantDataService,
  ParticipantDataService,
} from './services/participant-data.service';
import {
  FsxAddAssociatedPartyOrchestrationService,
  AddAssociatedPartyOrchestrationService,
} from '../documents/services/add-associated-party-orchestration.service';
import {
  FsxPartyAndParticipantDataService,
  PartyAndParticipantDataService,
} from './services/party-and-participant-data.service';
import {
  FsxAddOrUpdateContactOrchestrationService,
  AddOrUpdateContactOrchestrationService,
} from '../shared/services/add-or-update-contact-orchestration.service';
import {
  FsxUpdatePartyOrchestrationService,
  UpdatePartyOrchestrationService,
} from '../parties/orchestration-services/update-party-orchestration.service';
import {
  FsxUpdateAdditionalFieldValuesOrchestrationService,
  UpdateAdditionalFieldValuesOrchestrationService,
} from '../documents/services/update-additional-field-values-orchestration.service';

export enum PollingState {
  None,
  InProgress,
  UserError,
  ServerError,
  Succeeded,
}

@Component({
  selector: 'fsx-filing-editor',
  templateUrl: './filing-editor.component.html',
  styleUrls: ['./filing-editor.component.scss'],
  providers: [
    {
      provide: FsxUpdatePartyOrchestrationService,
      useClass: UpdatePartyOrchestrationService,
    },
    {
      provide: FsxAddOrUpdateContactOrchestrationService,
      useClass: AddOrUpdateContactOrchestrationService,
    },
    {
      provide: FsxFormGroupTransformService,
      useClass: FormGroupTransformService,
    },
    {
      provide: FsxContactsListTwoService,
      useClass: ContactsListTwoService,
    },
    {
      provide: FsxDefaultPartyService,
      useClass: DefaultPartyService,
    },
    {
      provide: FsxUpdateParticipantOrchestrationService,
      useClass: UpdateParticipantOrchestrationService,
    },
    {
      provide: FsxOpenContactFormOrchestrationService,
      useClass: OpenContactFormOrchestrationService,
    },
    {
      provide: FsxOpenParticipantFormOrchestrationService,
      useClass: OpenParticipantFormOrchestrationService,
    },
    {
      provide: FsxContactsListService,
      useClass: ContactsListService,
    },
    {
      provide: FsxPanelService,
      useClass: PanelService,
    },
    {
      provide: FsxCombinedFilingDataService,
      useClass: CombinedFilingDataService,
    },
    {
      provide: FsxCombinedFilingDataService2,
      useClass: CombinedFilingDataService,
    },
    {
      provide: FsxFilingSubTabsService,
      useClass: FilingSubTabsService,
    },
    {
      provide: FsxCaseRequestDataService,
      useClass: CaseRequestDataService,
    },
    {
      provide: FsxFilingDataService,
      useClass: FilingDataService,
    },
    {
      provide: FsxDocumentInfoDataService,
      useClass: DocumentInfoDataService,
    },
    {
      provide: FsxFilingProfileDataService,
      useClass: FilingProfileDataService,
    },
    {
      provide: FsxFilingSubTabsFactoryService,
      useClass: FilingSubTabsFactoryService,
    },
    {
      provide: FsxFilingEditorEventService,
      useClass: FilingEditorEventService,
    },
    {
      provide: FsxCaseRequestUpdateService,
      useClass: CaseRequestUpdateService,
    },
    {
      provide: FsxUploadFileService,
      useClass: UploadFileService,
    },
    {
      provide: FsxFilingEditorActionsService,
      useClass: FilingEditorActionsService,
    },
    {
      provide: FsxFirstLoadOrchestrationService,
      useClass: FirstLoadOrchestrationService,
    },
    {
      provide: FsxValidateCaseOrchestrationService,
      useClass: ValidateCaseOrchestrationService,
    },
    {
      provide: FsxValidatePartiesOrchestrationService,
      useClass: ValidatePartiesOrchestrationService,
    },
    {
      provide: FsxUpdateDocumentOrchestrationService,
      useClass: UpdateDocumentOrchestrationService,
    },
    {
      provide: FsxAddDocumentOrchestrationService,
      useClass: AddDocumentOrchestrationService,
    },
    {
      provide: FsxAddSupportingDocumentOrchestrationService,
      useClass: AddSupportingDocumentOrchestrationService,
    },
    {
      provide: FsxAddLeadDocumentOrchestrationService,
      useClass: AddLeadDocumentOrchestrationService,
    },
    {
      provide: FsxUpdateCaseRequestOrchestrationService,
      useClass: UpdateCaseRequestOrchestrationService,
    },
    {
      provide: FsxReplaceDocumentFileOrchestrationService,
      useClass: ReplaceDocumentFileOrchestrationService,
    },
    {
      provide: FsxAddDefaultParticipantOrchestrationService,
      useClass: AddDefaultParticipantOrchestrationService,
    },
    {
      provide: FsxAddContactAsParticipantOrchestrationService,
      useClass: AddContactAsParticipantOrchestrationService,
    },
    {
      provide: FsxClearParticipantOrchestrationService,
      useClass: ClearParticipantOrchestrationService,
    },
    {
      provide: FsxRemoveParticipantOrchestrationService,
      useClass: RemoveParticipantOrchestrationService,
    },
    {
      provide: FsxUploadFilesOrchestrationService,
      useClass: UploadFilesOrchestrationService,
    },
    {
      provide: FsxUploadAdditionalFieldFilesOrchestrationService,
      useClass: UploadAdditionalFieldFilesOrchestrationService,
    },
    {
      provide: FsxAddSelectedContactsAsParticipantsOrchestrationService,
      useClass: AddSelectedContactsAsParticipantsOrchestrationService,
    },
    {
      provide: FsxAddRepresentationOrchestrationService,
      useClass: AddRepresentationOrchestrationService,
    },
    {
      provide: FsxRemoveRepresentationOrchestrationService,
      useClass: RemoveRepresentationOrchestrationService,
    },
    {
      provide: FsxUpdateRepresentationOrchestrationService,
      useClass: UpdateRepresentationOrchestrationService,
    },
    {
      provide: FsxClearRepresentationOrchestrationService,
      useClass: ClearRepresentationOrchestrationService,
    },
    {
      provide: FsxApplyParticipantFormUpdatesOrchestrationService,
      useClass: ApplyParticipantFormUpdatesOrchestrationService,
    },
    {
      provide: FsxRemoveDocumentOrchestrationService,
      useClass: RemoveDocumentOrchestrationService,
    },
    {
      provide: FsxSwitchFilingOrchestrationService,
      useClass: SwitchFilingOrchestrationService,
    },
    {
      provide: FsxCloseFilingOrchestrationService,
      useClass: CloseFilingOrchestrationService,
    },
    {
      provide: FsxSubmitFilingOrchestrationService,
      useClass: SubmitFilingOrchestrationService,
    },
    {
      provide: FsxValidateDocumentsOrchestrationService,
      useClass: ValidateDocumentsOrchestrationService,
    },
    {
      provide: FsxCaseReviewValidationService,
      useClass: CaseReviewValidationService,
    },
    {
      provide: FsxAddSelectedContactsAsRepresentationOrchestrationService,
      useClass: AddSelectedContactsAsRepresentationOrchestrationService,
    },
    {
      provide: FsxCaseRequestBuilderService,
      useClass: CaseRequestBuilderService,
    },
    {
      provide: FsxValidationService,
      useClass: ValidationService,
    },
    {
      provide: FsxCaseValidationService,
      useClass: CaseValidationService,
    },
    {
      provide: FsxAddressValidationService,
      useClass: AddressValidationService,
    },
    {
      provide: FsxAdditionalFieldsValidationService,
      useClass: AdditionalFieldsValidationService,
    },
    {
      provide: FsxCurrencyValidationService,
      useClass: CurrencyValidationService,
    },
    {
      provide: FsxValidationHelperService,
      useClass: ValidationHelperService,
    },
    {
      provide: FsxValidationHelperFpService,
      useClass: ValidationHelperFpService,
    },
    {
      provide: FsxSelectionFieldValidationService,
      useClass: SelectionFieldValidationService,
    },
    {
      provide: FsxTextFieldValidationService,
      useClass: TextFieldValidationService,
    },
    {
      provide: FsxAliasValidationService,
      useClass: AliasValidationService,
    },
    {
      provide: FsxContactValidationService,
      useClass: ContactValidationService,
    },
    {
      provide: FsxDateValidationService,
      useClass: DateValidationService,
    },
    {
      provide: FsxDateTimeValidationService,
      useClass: DateTimeValidationService,
    },
    {
      provide: FsxDocumentDomainValidationService,
      useClass: DocumentDomainValidationService,
    },
    {
      provide: FsxDocumentFilesValidationService,
      useClass: DocumentFilesValidationService,
    },
    {
      provide: FsxNumberValidationService,
      useClass: NumberValidationService,
    },
    {
      provide: FsxSearchFieldValidationService,
      useClass: SearchFieldValidationService,
    },
    {
      provide: FsxPersonValidationService,
      useClass: PersonValidationService,
    },
    {
      provide: FsxEmailAddressValidationService,
      useClass: EmailAddressValidationService,
    },
    {
      provide: FsxPhoneValidationService,
      useClass: PhoneValidationService,
    },
    {
      provide: FsxIdentificationValidationService,
      useClass: IdentificationValidationService,
    },
    {
      provide: FsxPersonNameValidationService,
      useClass: PersonNameValidationService,
    },
    {
      provide: FsxOrganizationValidationService,
      useClass: OrganizationValidationService,
    },
    {
      provide: FsxTimeValidationService,
      useClass: TimeValidationService,
    },
    {
      provide: FsxDocumentValidationService,
      useClass: DocumentValidationService,
    },
    {
      provide: FsxParticipantValidationService,
      useClass: ParticipantValidationService,
    },
    {
      provide: FsxSingleParticipantValidationService,
      useClass: SingleParticipantValidationService,
    },
    {
      provide: FsxPartyValidationService,
      useClass: PartyValidationService,
    },
    {
      provide: FsxRepresentationValidationService,
      useClass: RepresentationValidationService,
    },
    {
      provide: FsxValidateReviewOrchestrationService,
      useClass: ValidateReviewOrchestrationService,
    },
    {
      provide: FsxCreateRequestDocumentService,
      useClass: CreateRequestDocumentService,
    },
    {
      provide: FsxEditParticipantOrchestrationService,
      useClass: EditParticipantOrchestrationService,
    },
    {
      provide: FsxLoadFilingFeesOrchestrationService,
      useClass: LoadFilingFeesOrchestrationService,
    },
    {
      provide: FsxFilingFeesDataService,
      useClass: FilingFeesDataService,
    },
    {
      provide: FsxFilingChecklistService,
      useClass: FilingChecklistService,
    },
    {
      provide: FsxPollingStateService,
      useClass: PollingStateService,
    },
    {
      provide: FsxValidationErrorsService,
      useClass: ValidationErrorsService,
    },
    {
      provide: FsxValidationResultMappingService,
      useClass: ValidationResultMappingService,
    },
    {
      provide: FsxValidationGroupErrorsService,
      useClass: ValidationGroupErrorsService,
    },
    {
      provide: FsxValidationIndicatorService,
      useClass: ValidationIndicatorService,
    },
    {
      provide: FsxSelectedContactsService,
      useClass: SelectedContactsService,
    },
    {
      provide: FsxContactFormService,
      useClass: ContactFormService,
    },
    {
      provide: FsxFilingFeesGetterService,
      useClass: FilingFeesGetterService,
    },
    {
      provide: FsxOpenContactsListOrchestrationService,
      useClass: OpenContactsListOrchestrationService,
    },
    {
      provide: FsxPartiesGridConfigService,
      useClass: PartiesGridConfigService,
    },
    {
      provide: FsxAdditionalFieldSpecLookupService,
      useClass: AdditionalFieldSpecLookupService,
    },
    {
      provide: FsxAdditionalFieldValueLookupService,
      useClass: AdditionalFieldValueLookupService,
    },
    {
      provide: FsxPartyDataService,
      useClass: PartyDataService,
    },
    {
      provide: FsxParticipantDataService,
      useClass: ParticipantDataService,
    },
    {
      provide: FsxAddAssociatedPartyOrchestrationService,
      useClass: AddAssociatedPartyOrchestrationService,
    },
    {
      provide: FsxPartyAndParticipantDataService,
      useClass: PartyAndParticipantDataService,
    },
    {
      provide: FsxOrganizationFormService,
      useClass: OrganizationFormService,
    },
    {
      provide: FsxUpdateAdditionalFieldValuesOrchestrationService,
      useClass: UpdateAdditionalFieldValuesOrchestrationService,
    },
  ],
})
export class FilingEditorComponent implements OnInit, OnDestroy {
  protected readonly PollingState = PollingState;
  protected readonly FilingState = FilingState;

  pollingState$: Observable<PollingState> =
    this.pollingStateService.pollingState$;

  private destroy$: Subject<void> = new Subject<void>();

  private documentsGridRows$$ = new BehaviorSubject<DocumentsGridRow[]>([]);
  documentsGridRows$ = this.documentsGridRows$$.asObservable();

  private combinedPartiesGridRows$$ = new BehaviorSubject<PartiesGridRow[]>([]);
  combinedPartiesGridRows$ = this.combinedPartiesGridRows$$.asObservable();

  filingSubTabsEnum: typeof FILING_SUB_TABS = FILING_SUB_TABS;
  validationResult$!: Observable<ProblemDetails>;
  routeFilingId$ = this.route.paramMap.pipe(
    map((paramMap: ParamMap) => {
      return paramMap.get(TRANSACTION_ID) as string;
    }),
  );
  filing!: Filing;

  summary: {
    courtName: string;
    caseClass: string;
    caseType: string;
    caseName: string | null | undefined;
  } = { courtName: '', caseClass: '', caseType: '', caseName: '' };

  classification!: DataAttribute[];

  private documentInfos$$ = new BehaviorSubject<DocumentInfo[]>([]);
  documentInfos$ = this.documentInfos$$.asObservable();
  private subTabsUpdatedTimestamp$$ = new BehaviorSubject<Moment>(moment());
  subTabsUpdatedTimestamp$ = this.subTabsUpdatedTimestamp$$.asObservable();

  public showSubmissionReceipt: boolean = false;

  combinedFilingData$ = this.combinedFilingDataService.combinedFilingData$;

  activeSubTabItem$: Observable<FilingSubTabItem> =
    this.filingSubTabsService.activeSubTabItem$;

  caseRequest$: Observable<CaseRequestViewModel> =
    this.combinedFilingData$.pipe(
      map((combinedFilingData: CombinedFilingData) => {
        return combinedFilingData.caseRequest;
      }),
    );

  filingTabs$ = this.filingTabsService.filingTabs$;

  // We get the id from a snapshot when an instance of this component is
  // first instantiated. This is used in a filter in the load stream so
  // that updates only impact the instance they are relevant for.
  // tslint:disable-next-line: no-string-literal
  filingId = this.route.snapshot.params[TRANSACTION_ID] as string;

  isMaxLeadDocumentsAllowed$ = this.combinedFilingData$.pipe(
    switchMap((combinedFilingData: CombinedFilingData) => {
      return this.filingProfileRulesService.isMaxLeadDocumentsAllowed({
        ...combinedFilingData,
      });
    }),
  );

  isMinLeadDocumentsRequired$ = this.combinedFilingData$.pipe(
    switchMap((combinedFilingData: CombinedFilingData) => {
      return this.filingProfileRulesService.isMinLeadDocumentsRequired({
        ...combinedFilingData,
      });
    }),
  );

  public resolver!: FsxReferenceResolver;
  public scheduledAt!: string;
  public caseRequest!: CaseRequestViewModel;
  public case!: RequestCaseViewModel;
  public showingFooter = false;

  public constructor(
    private readonly route: ActivatedRoute,
    @Inject(FsxFilingApiService)
    private readonly filingApiService: IFilingApiService,
    @Inject(FsxPollingStateService)
    private readonly pollingStateService: IPollingStateService,
    @Inject(FsxFilingTabsService)
    private readonly filingTabsService: IFilingTabsService,
    @Inject(FsxAddLeadDocumentOrchestrationService)
    private readonly addLeadDocumentOrchestrationService: IAddLeadDocumentOrchestrationService,
    @Inject(FsxAddDocumentOrchestrationService)
    private readonly addDocumentOrchestrationService: IAddDocumentOrchestrationService,
    private readonly filingProfileRulesService: FilingProfileRulesService,
    @Inject(FsxCaseRequestUpdateService)
    private readonly caseRequestUpdateService: ICaseRequestUpdateService,
    @Inject(FsxFilingEditorActionsService)
    readonly filingEditorActions: IFilingEditorActionsService,
    private readonly filingSubmissionReceiptService: FilingSubmissionReceiptService,
    @Inject(FsxCombinedFilingDataService)
    private readonly combinedFilingDataService: ICombinedFilingDataService,
    @Inject(FsxFilingSubTabsService)
    private readonly filingSubTabsService: IFilingSubTabsService,
    @Inject(FsxFilingDataService)
    private readonly filingDataService: IFilingDataService,
    @Inject(FsxValidateReviewOrchestrationService)
    private readonly validateReviewOrchestrationService: IValidateReviewOrchestrationService,
  ) {}

  ngOnInit(): void {
    this.filingEditorActions.loadForFilingId(this.filingId);

    this.showSubmissionReceipt = false;

    this.combinedFilingData$
      .pipe(
        take(1), // Important! This prevents nested components from reinitialising after every emission.
        filter((cfd: CombinedFilingData) => !!cfd),
        tap((cfd: CombinedFilingData) => {
          this.filing = cfd.filing;
          this.caseRequest = cfd.caseRequest;
          if (cfd.caseRequest.cases) {
            this.case = cfd.caseRequest.cases[0];
          }
          this.resolver = new FsxReferenceResolver(cfd.filingProfile, {
            filingApi: this.filingApiService, // Why not use angular's dependency injection?
            filingId: this.filingId,
            cfd: cfd,
            caseRequestUpdateService: this.caseRequestUpdateService,
          });
          this.scheduledAt = cfd.filing.scheduledAt ?? '';
          const cases: RequestCaseViewModel[] | null | undefined =
            cfd.caseRequest.cases;
          this.classification = cfd.filingProfile.classification;
          if (cases && cases.length > 0) {
            const firstCase = cases[0];
            this.summary = {
              courtName: cfd.filing.courtSummary.caption,
              caseClass: '',
              caseType: '',
              caseName: firstCase.title,
            };
          }

          this.subTabsUpdatedTimestamp$$.next(moment());
        }),
      )
      .subscribe();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  closeFilingClickedEventHandler(filingId: string) {
    this.filingEditorActions.closeFiling(filingId);
  }

  filingTabClickedEventHandler(filingId: string) {
    this.filingEditorActions.switchFiling(filingId);
  }

  filesUploadedEventHandler(uploadedFiles: IUploadedFile[]) {
    this.addLeadDocumentOrchestrationService.addLeadDocument({ uploadedFiles });
  }

  documentsGridRowsUpdatedEventHandler(documentsGridRows: DocumentsGridRow[]) {
    this.documentsGridRows$$.next(documentsGridRows);
  }

  combinedPartiesGridRowsUpdatedEventHandler(
    combinedPartiesGridRows: PartiesGridRow[],
  ): void {
    this.combinedPartiesGridRows$$.next(combinedPartiesGridRows);
  }

  addDocumentEventHandler() {
    this.addDocumentOrchestrationService.addDocument({
      uniqueIdentifiers: [uuidv4()],
    });
  }

  submitFilingEventHandler(): void {
    this.filingEditorActions.submitFiling(this.filingId);
  }

  filesLoadedEventHandler(documentFiles: DocumentInfo[]) {
    this.documentInfos$$.next(documentFiles);
  }

  authorizerSelectedEventHandler(_authorizerId: string): void {
    const courtCase = this.filing.courtCases[0];
    let filingCaseUpdate: FilingCaseUpdate = {
      title: courtCase.title,
      clientMatterKey: courtCase.clientMatterKey,
      efmKey: courtCase.efmKey,
    };
    let filingUpdate: FilingUpdate = {
      caption: this.filing.caption,
      scheduledAt: this.filing.scheduledAt,
      authorizerUserId: _authorizerId,
      cases: [filingCaseUpdate],
    };
    const filingCaseUpdate$ = this.filingApiService.updateFiling(
      this.filing.id,
      filingUpdate,
    );
    filingCaseUpdate$
      .pipe(
        take(1),
        tap((filing) => {
          this.filingDataService.setFilingData(filing);
          this.validateReviewOrchestrationService.validateReview();
        }),
      )
      .subscribe();
  }

  updateCaseRequestEventHandler(): void {
    this.caseRequest$
      .pipe(
        switchMap((caseRequest: CaseRequestViewModel) => {
          return this.filingApiService.putCaseRequest2(
            this.filingId,
            caseRequest,
          );
        }),
        take(1),
      )
      .subscribe();
  }

  submissionReceiptClicked(): void {
    this.filingSubmissionReceiptService.openFilingSubmissionReceipt(
      this.filingId,
    );
  }

  disableOverlay(): void {
    this.pollingState$
      .pipe(
        take(1),
        tap((pollingState: PollingState) => {
          if (
            pollingState === PollingState.UserError ||
            pollingState === PollingState.ServerError
          ) {
            this.pollingStateService.setPollingState(PollingState.None);
          }
        }),
      )
      .subscribe();
  }

  public participantsListNotEmptyEventHandler(isEmpty: boolean): void {
    this.showingFooter = !isEmpty;
  }
}
