import { Injectable, InjectionToken } from '@angular/core';
import { DocumentInfo } from '@fsx/fsx-shared';
import { BehaviorSubject, Observable, filter, map } from 'rxjs';

export const FsxDocumentInfoDataService =
  new InjectionToken<IDocumentInfoDataService>('FsxDocumentInfoDataService');

export interface IDocumentInfoDataService {
  documentInfos$: Observable<DocumentInfo[]>;
  setDocumentInfos(documentInfos: DocumentInfo[]): void;
}

@Injectable()
export class DocumentInfoDataService implements IDocumentInfoDataService {
  private documentInfos$$ = new BehaviorSubject<DocumentInfo[] | null>(null);
  documentInfos$: Observable<DocumentInfo[]> = this.documentInfos$$
    .asObservable()
    .pipe(
      filter((documentInfos: DocumentInfo[] | null) => documentInfos !== null),
      map(
        (documentInfos: DocumentInfo[] | null) =>
          documentInfos as DocumentInfo[],
      ),
    );

  setDocumentInfos(documentInfos: DocumentInfo[]): void {
    const newDocumentInfosArray = JSON.parse(JSON.stringify(documentInfos));
    this.documentInfos$$.next(newDocumentInfosArray);
  }
}
