import { Inject, Injectable, InjectionToken } from '@angular/core';
import {
  ContactViewModel,
  ContactProfile,
  ContactQueryResultItemGridResult,
  EnvConfig,
  NewContactViewModel,
  Query,
} from '@fsx/fsx-shared';
import { ENV_CONFIG } from '@fsx/fsx-shared';
import { Observable, catchError, of, switchMap } from 'rxjs';
import { ApiV2Service } from '../core/api/api.v2.service';

/**
 * The InjectionToken to use in the providers array to specify a concrete-implementation
 * of the IContactApiService to use at runtime.
 */
export const FsxContactApiService = new InjectionToken<IContactApiService>(
  'FsxContactApiService',
);

export interface IContactApiService {
  getContactProfile(): Observable<ContactProfile>;

  getContacts(queryConfig: Query): Observable<ContactQueryResultItemGridResult>;

  getContact(id: string): Observable<ContactViewModel>;

  /**
   * An API method to allow a new contact to be added. Retjrn the ContactViewModel
   * object if the update was successful, returns null if the API call failed for
   * any reason.
   *
   * @param contact The new contact object to pass as the payload
   */
  createContact(
    contact: NewContactViewModel,
  ): Observable<ContactViewModel | null>;

  /**
   * An API method to allow a contact to be updated. Returns the ContactViewModel
   * object if the update was successful, returns null if the API call failed for
   * any reason.
   *
   * @param id The id of the contact we want to update
   * @param contact The updated contact object to pass as the payload
   */
  updateContact(
    id: string,
    contact: NewContactViewModel,
  ): Observable<ContactViewModel | null>;
}

@Injectable()
export class ContactApiService implements IContactApiService {
  constructor(
    @Inject(ENV_CONFIG) private readonly envConfig: Observable<EnvConfig>,
    private readonly api: ApiV2Service,
  ) {}

  getContactProfile(): Observable<ContactProfile> {
    return this.envConfig.pipe(
      switchMap((envConfig: EnvConfig) => {
        const url = `${envConfig.Endpoints.contact.contactProfile()}`;
        return this.api.get<ContactProfile>(url);
      }),
    );
  }

  getContacts(
    queryConfig: Query,
  ): Observable<ContactQueryResultItemGridResult> {
    return this.envConfig.pipe(
      switchMap((envConfig: EnvConfig) => {
        const url = `${envConfig.Endpoints.contact.getContacts()}`;
        return this.api.post<Query, ContactQueryResultItemGridResult>(
          url,
          queryConfig,
        );
      }),
    );
  }

  getContact(id: string): Observable<ContactViewModel> {
    return this.envConfig.pipe(
      switchMap((envConfig: EnvConfig) => {
        const url = `${envConfig.Endpoints.contact.getContact(id)}`;
        return this.api.get<ContactViewModel>(url);
      }),
    );
  }

  /**
   * An API method to allow a new contact to be added. Retjrn the ContactViewModel
   * object if the update was successful, returns null if the API call failed for
   * any reason.
   *
   * @param contact The new contact object to pass as the payload
   */
  createContact(
    contact: NewContactViewModel,
  ): Observable<ContactViewModel | null> {
    return this.envConfig.pipe(
      switchMap((envConfig: EnvConfig) => {
        const url = `${envConfig.Endpoints.contact.createContact()}`;
        return this.api
          .post<NewContactViewModel, ContactViewModel>(url, contact)
          .pipe(
            catchError((err) => {
              console.error(`Error creating contact`, err);
              return of(null);
            }),
          );
      }),
    );
  }

  /**
   * An API method to allow a contact to be updated. Returns the ContactViewModel
   * object if the update was successful, returns null if the API call failed for
   * any reason.
   *
   * @param id The id of the contact we want to update
   * @param contact The updated contact object to pass as the payload
   */
  updateContact(
    id: string,
    contact: NewContactViewModel,
  ): Observable<ContactViewModel | null> {
    return this.envConfig.pipe(
      switchMap((envConfig: EnvConfig) => {
        const url = `${envConfig.Endpoints.contact.updateContact(id)}`;
        return this.api
          .put<NewContactViewModel, ContactViewModel>(url, contact)
          .pipe(
            catchError((err) => {
              console.error(`Error updating contact with id ${id}`, err);
              return of(null);
            }),
          );
      }),
    );
  }
}
