import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  QueryList,
  ViewChildren,
} from '@angular/core';
import { CourtSummary, CourtSummaryItemTree } from '@fsx/fsx-shared';
import { asyncScheduler } from 'rxjs';
import { FormControlWithoutModel } from '../../models';
import { DropdownOption } from '../../types';
import { FsxBasicSingleSelectionComponent } from '../../../public-api';

@Component({
  selector: 'fsx-court-selection-component',
  templateUrl: './court-selection.component.html',
  styleUrls: ['./court-selection.component.scss'],
})
export class FsxCourtSelectionComponent implements OnInit {
  @Input() courtSelectionData!: CourtSummaryItemTree;

  @Output() public browsedCourtSelected = new EventEmitter<{
    courtId: string;
    courtName: string;
    courtState?: string;
    courtPath?: string[];
  }>();

  @ViewChildren('selectionField1')
  selectionFields1!: QueryList<FsxBasicSingleSelectionComponent>;
  @ViewChildren('selectionField2')
  selectionFields2!: QueryList<FsxBasicSingleSelectionComponent>;

  public formControlsList: FormControlWithoutModel[] = [];
  public valuesArray: string[] = [];
  public labelsArray: string[] = [];
  public captionsArray: string[] = [];
  public dropdownOptionsArray: DropdownOption<void>[][] = [];
  public courtDropdownOptions: DropdownOption<void>[] = [];

  ngOnInit(): void {
    this.setDropdownData(
      this.courtSelectionData,
      this.dropdownOptionsArray.length,
    );
  }

  public setControl(control: FormControlWithoutModel) {
    this.formControlsList.push(control);
  }

  private setDropdownData(
    dropdownData: CourtSummaryItemTree,
    index: number,
  ): void {
    this.labelsArray.push(dropdownData.childLabel ?? '');

    this.dropdownOptionsArray[index] =
      dropdownData.children?.map((child: CourtSummaryItemTree) => {
        const dropdownOption: DropdownOption<void> = {
          name: child.name ?? '',
          caption: child.caption ?? '',
          selected: false,
        };
        return dropdownOption;
      }) ?? [];
  }

  public selectedValue(value: string, index: number | null) {
    if (
      index !== null &&
      index < this.valuesArray.length &&
      this.valuesArray[index] !== value
    ) {
      this.resetDropdownOptions(index);
    }

    if (index !== null) {
      const arrayValue = this.valuesArray.at(index);
      if (arrayValue) {
        if (this.valuesArray[index] === value) {
          return;
        }
        this.valuesArray[index] = value;
      } else {
        this.valuesArray.push(value);
      }
      this.searchInDataTree(index);
    } else {
      const selectedCourt = this.courtDropdownOptions.find(
        (option) => option.name === value,
      );

      this.captionsArray.push(selectedCourt?.caption ?? '');

      if (this.captionsArray.length > 2) {
        this.captionsArray = this.captionsArray.slice(
          1,
          this.captionsArray.length,
        );
      }

      this.browsedCourtSelected.emit({
        courtName: this.captionsArray[this.captionsArray.length - 1],
        courtState: this.captionsArray[0],
        courtPath:
          this.captionsArray.length > 2
            ? this.captionsArray.slice(1, this.captionsArray.length - 1)
            : [],
        courtId: value,
      });
    }
  }

  private searchInDataTree(index: number): void {
    let dropdownData: CourtSummaryItemTree = { ...this.courtSelectionData };

    this.valuesArray.forEach((value, arrayIndex) => {
      const child = dropdownData.children?.find(
        (child: CourtSummaryItemTree) => child.name === value,
      );

      if (child && child.children) {
        dropdownData = child;

        if (arrayIndex === index) {
          if (dropdownData.children?.length) {
            this.captionsArray.push(dropdownData.caption ?? '');
          }
          this.setDropdownData(dropdownData, index + 1);
        }
      }

      if (child && child.items && arrayIndex === index) {
        this.courtDropdownOptions = [];
        asyncScheduler.schedule(() => this.setCourts(child));
      } else if (child && !child.items) {
        this.courtDropdownOptions = [];
      }
    });
  }

  private setCourts(dropdownData: CourtSummaryItemTree): void {
    this.captionsArray.push(dropdownData.caption ?? '');

    this.courtDropdownOptions =
      dropdownData.items?.map((item: CourtSummary) => {
        const dropdownOption: DropdownOption<void> = {
          name: item.id ?? '',
          caption: item.caption ?? '',
          selected: false,
        };
        return dropdownOption;
      }) ?? [];
  }

  public clearDropdown(index?: number): void {
    if (index) {
      this.resetDropdownOptions(index, true);
    }
  }

  public validate(): void {
    if (this.selectionFields1) {
      this.selectionFields1.forEach((fld) => fld.validate());
    }
    if (this.selectionFields2) {
      this.selectionFields2.forEach((fld) => fld.validate());
    }
  }

  private resetDropdownOptions(index: number, clear = false): void {
    this.valuesArray = this.valuesArray.slice(0, clear ? index : index + 1);
    this.captionsArray = this.captionsArray.slice(0, index);
    this.formControlsList = this.formControlsList.slice(0, index + 1);
    this.dropdownOptionsArray = this.dropdownOptionsArray.slice(0, index + 1);
    this.labelsArray = this.labelsArray.slice(0, index + 1);
    this.courtDropdownOptions = [];
  }
}
