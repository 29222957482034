import { Injectable } from '@angular/core';
import {
  CaseRequestViewModel,
  DEFAULT_PARTICIPANT,
  DEFAULT_PARTY,
  CasePartyViewModel,
} from '@fsx/fsx-shared';
import { Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ClearParticipantService {
  public clearParticipant(
    caseRequest: CaseRequestViewModel,
    partyToClear: CasePartyViewModel,
  ): Observable<CaseRequestViewModel> {
    const caseRequestParticipants = caseRequest.participants || [];

    caseRequest.participants = caseRequestParticipants.map((p, i) => {
      return p.name === partyToClear.participantName ? DEFAULT_PARTICIPANT : p;
    });

    const caseRequestParties = caseRequest.parties || [];
    caseRequest.parties = caseRequestParties.map((p, i) => {
      return p.participantName === partyToClear.participantName
        ? DEFAULT_PARTY
        : p;
    });

    return of(caseRequest);
  }
}
