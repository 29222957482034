import { Inject, Injectable, InjectionToken } from '@angular/core';
import {
  FilingProfile,
  CaseRequestViewModel,
  FilingModeSpec,
  Filing,
} from '../../../types';
import {
  FsxTextFieldValidationService,
  ITextFieldValidationService,
} from './text-field-validation.service';
import {
  FsxValidationErrorsService,
  IValidationErrorsService,
} from 'projects/apps/fsx-ui/src/app/filing-editor/services/validation-errors.service';
import { ValidationGroupConstants } from 'projects/apps/fsx-ui/src/app/filing-editor/services/validation-group-errors.service';

export const FsxCaseReviewValidationService =
  new InjectionToken<ICaseReviewValidationService>(
    'FsxCaseReviewValidationService',
  );

export interface ICaseReviewValidationService {
  validateCaseReview(
    filing: Filing,
    caseRequest: CaseRequestViewModel,
    filingProfile: FilingProfile,
    modeSpec: FilingModeSpec,
  ): boolean;
}

@Injectable()
export class CaseReviewValidationService
  implements ICaseReviewValidationService
{
  constructor(
    @Inject(FsxValidationErrorsService)
    private readonly validationErrorsService: IValidationErrorsService,
    @Inject(FsxTextFieldValidationService)
    private readonly textFieldValidationService: ITextFieldValidationService,
  ) {}

  public validateCaseReview(
    filing: Filing,
    caseRequest: CaseRequestViewModel,
    filingProfile: FilingProfile,
    modeSpec: FilingModeSpec,
  ): boolean {
    caseRequest.isReviewValid = true;

    if (modeSpec.noteToClerk && modeSpec.noteToClerk.required) {
      // only validate if the note is inthe spec and required
      if (
        !this.textFieldValidationService.validateTextField(
          caseRequest,
          filingProfile,
          modeSpec.noteToClerk,
          caseRequest.noteToClerk,
        )
      ) {
        caseRequest.isValid = false;
        caseRequest.isReviewValid = false;
      }
    }

    const noAuthorizerErrorCode = 'noAuthorizer';
    if (!filing.authorizer) {
      caseRequest.isValid = false;
      caseRequest.isReviewValid = false;

      this.validationErrorsService.addValidationError({
        errorCode: noAuthorizerErrorCode,
        errorMessage: 'No authorizer was provided',
        group: ValidationGroupConstants.review,
      });
    } else {
      this.validationErrorsService.removeValidationError(noAuthorizerErrorCode);
    }

    return caseRequest.isReviewValid;
  }
}
