import {
  Component,
  EventEmitter,
  Inject,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import {
  AdditionalFieldSpec,
  AdditionalFieldValue,
  CombinedFilingData,
  CreateDocumentInfoService,
  DocumentInfo,
} from '@fsx/fsx-shared';
import {
  FilesUploadedEventParams,
  IUploadedFile,
  ReferenceResolver,
} from '@fsx/ui-components';
import {
  FsxCombinedFilingDataService,
  ICombinedFilingDataService,
} from '../../services/combined-filing-data.service';
import { Observable } from 'rxjs/internal/Observable';
import {
  FsxDocumentInfoDataService,
  IDocumentInfoDataService,
} from '../../services/document-info-data.service';
import {
  of,
  take,
  withLatestFrom,
  switchMap,
  combineLatest,
  tap,
  map,
} from 'rxjs';
import {
  DocumentInfoAndUploadedFile,
  FsxUploadAdditionalFieldFilesOrchestrationService,
  IUploadAdditionalFieldFilesOrchestrationService,
} from '../../../documents/services/upload-additional-field-files-orchestration.service';

@Component({
  selector: 'fsx-file-uploads-additional-field',
  templateUrl: './file-uploads-additional-field.component.html',
  styleUrls: ['./file-uploads-additional-field.component.scss'],
})
export class FileUploadsAdditionalFieldComponent implements OnInit {
  /**
   * Passed in here to forward onto the file upload component.
   */
  @Input() resolver!: ReferenceResolver;

  /**
   * A single AdditionalFieldSpec object to derive the files formArray.
   */
  @Input() additionalFieldSpec!: AdditionalFieldSpec;

  /**
   * A single AdditionalFieldValue object to derive the files formArray.
   */
  @Input() additionalFieldValue!: AdditionalFieldValue;

  /**
   * The collection of DocumentInfo objects to pass on to the FileUpload component.
   */
  documentInfos$!: Observable<DocumentInfo[]>;

  /**
   * An event to notify FsxAdditionalFieldsContainerComponent that the AdditonalFieldValue
   * has been updated and that it should update it's collection of AdditionalFieldValue objects.
   */
  @Output() additionalFieldValueEvent =
    new EventEmitter<AdditionalFieldValue>();

  constructor(
    @Inject(FsxCombinedFilingDataService)
    private readonly combinedFilingDataService: ICombinedFilingDataService,
    @Inject(FsxDocumentInfoDataService)
    private readonly documentInfoDataService: IDocumentInfoDataService,
    @Inject(FsxUploadAdditionalFieldFilesOrchestrationService)
    private readonly uploadAdditionalFieldFilesOrchestrationService: IUploadAdditionalFieldFilesOrchestrationService,
    private readonly createDocumentInfoService: CreateDocumentInfoService,
  ) {}

  ngOnInit() {
    this.documentInfos$ = this.documentInfoDataService.documentInfos$.pipe(
      map((docInfos: DocumentInfo[]) => {
        return docInfos.filter((docInfo: DocumentInfo) => {
          const fileValues: string[] =
            this.additionalFieldValue?.fileValues || [];
          const isFoundFileId: boolean = fileValues.includes(docInfo.id);
          return isFoundFileId;
        });
      }),
    );
  }

  onFilesUploadedEvent(uploadedFiles: IUploadedFile[]) {
    this.filesUploadedFromAdditionalFieldEventHandler({
      additionalFieldName: this.additionalFieldSpec.name,
      uploadedFiles,
    });
  }

  filesUploadedFromAdditionalFieldEventHandler(
    params: FilesUploadedEventParams,
  ) {
    of(params)
      .pipe(
        take(1),
        withLatestFrom(this.combinedFilingDataService.combinedFilingData$),
        switchMap(
          ([filesUploadedEventParams, combinedFilingData]: [
            FilesUploadedEventParams,
            CombinedFilingData,
          ]) => {
            const { uploadedFiles } = filesUploadedEventParams;
            const arrayOfDocumentInfoAndUploadedFile$: Observable<DocumentInfoAndUploadedFile>[] =
              this.createDocumentInfoService.getArrayOfDocumentInfoAndUploadedFile(
                uploadedFiles,
                combinedFilingData.filing.id,
              );
            return combineLatest([...arrayOfDocumentInfoAndUploadedFile$]).pipe(
              tap(
                (
                  documentInfoAndUploadedFiles: DocumentInfoAndUploadedFile[],
                ) => {
                  // Create the array of documentIds to update the filesValue array with (below)
                  const documentIds: string[] =
                    documentInfoAndUploadedFiles.map(
                      (
                        documentInfoAndUploadedFile: DocumentInfoAndUploadedFile,
                      ) => {
                        return documentInfoAndUploadedFile.documentInfo.id;
                      },
                    );

                  const existingFileValues =
                    this.additionalFieldValue?.fileValues || [];

                  // Save to additional field values.
                  const additionalFieldValue: AdditionalFieldValue = {
                    additionalFieldName: this.additionalFieldSpec.name,
                    fileValues: [...existingFileValues, ...documentIds],
                  };
                  this.additionalFieldValueEvent.emit(additionalFieldValue);

                  // Trigger the file upload
                  this.uploadAdditionalFieldFilesOrchestrationService.uploadFiles(
                    {
                      documentInfoAndUploadedFiles,
                    },
                  );
                },
              ),
            );
          },
        ),
      )
      .subscribe();
  }

  fileRemovedEventHandler(_documentInfo: DocumentInfo) {
    // How to handle removal of files here?
  }
}
