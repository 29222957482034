import { Injectable, InjectionToken } from '@angular/core';
import { FilingMode, FilingModeSpec, FilingProfile } from '../../types';

export const FsxFilingModeSpecLookupService =
  new InjectionToken<IFilingModeSpecLookupService>(
    'FsxFilingModeSpecLookupService',
  );

export interface IFilingModeSpecLookupService {
  getModeSpec(mode: FilingMode, filingProfile: FilingProfile): FilingModeSpec;
}

@Injectable()
export class FilingModeSpecLookupService
  implements IFilingModeSpecLookupService
{
  getModeSpec(mode: FilingMode, filingProfile: FilingProfile): FilingModeSpec {
    if (
      mode === FilingMode.OriginalPetition &&
      filingProfile.originalPetition
    ) {
      return filingProfile.originalPetition;
    }

    if (mode === FilingMode.Appellate && filingProfile.appellate) {
      return filingProfile.appellate;
    }

    if (mode === FilingMode.Subsequent && filingProfile.subsequent) {
      return filingProfile.subsequent;
    }

    throw new Error('Unable to determine filing mode');
  }
}
