export interface CourtSelectionEndpoints {
  getProfileSelectionTree: (courtId: string) => string;
  getCourtSelectionTree: () => string;
  getFilingProfile: (courtId: string, profileId: string) => string;
  getCourtCases: (courtId: string) => string;
  attest: (courtId: string, caseKey: string) => string;
}

export const courtSelectionEndPoints = (
  versionedUrl: string,
): CourtSelectionEndpoints => {
  const baseCourtUrl = `${versionedUrl}/courts`;
  return {
    getProfileSelectionTree: (courtId) =>
      `${baseCourtUrl}/${courtId}/profiles/selection/query`,
    getCourtSelectionTree: () => `${baseCourtUrl}/selection/query`,
    getFilingProfile: (courtId, profileId) =>
      `${baseCourtUrl}/${courtId}/profiles/${profileId}`,
    getCourtCases: (courtId) => `${baseCourtUrl}/${courtId}/cases/query`,
    attest: (courtId, caseKey) =>
      `${baseCourtUrl}/${courtId}/cases/key/${caseKey}/attest`,
  };
};
