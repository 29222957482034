import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import {
  ContactFormGroup,
  RequestContact,
  ProfileListReference,
  AddressViewModel,
  AdditionalFieldSpec,
  AdditionalFieldValue,
} from '@fsx/fsx-shared';
import { FormArrayWithModel, ReferenceResolver } from '@fsx/ui-components';
import { debounceTime, tap } from 'rxjs';

@Component({
  selector: 'fsx-contacts-additional-field',
  templateUrl: './contacts-additional-field.component.html',
  styleUrls: ['./contacts-additional-field.component.scss'],
})
export class ContactsAdditionalFieldComponent {
  /**
   * Passed in here to forward onto the contact component.
   */
  @Input() resolver!: ReferenceResolver;

  /**
   * A single AdditionalFieldSpec object to derive the contacts formArray.
   */
  @Input() additionalFieldSpec!: AdditionalFieldSpec;

  /**
   * A single AdditionalFieldValue object to derive the contacts formArray.
   */
  @Input() additionalFieldValue!: AdditionalFieldValue;

  /**
   * An event to notify FsxAdditionalFieldsContainerComponent that the AdditonalFieldValue
   * has been updated and that it should update it's collection of AdditionalFieldValue objects.
   */
  @Output() additionalFieldValueEvent =
    new EventEmitter<AdditionalFieldValue>();

  /**
   * Old way of setting the contacts formArray.
   * Here we derive a set of contactValues which can be saved in the addituionalFieldValues array.
   *
   * @param formArray The formArray emitted by the nested ContactComponent
   */
  setContactFormArray(
    formArray: FormArrayWithModel<FormGroup<ContactFormGroup>>,
  ): void {
    let contactValues: RequestContact[] = [];
    formArray.valueChanges
      .pipe(
        debounceTime(700),
        tap(() => {
          const additionalFieldValue: AdditionalFieldValue = {
            additionalFieldName: this.additionalFieldSpec.name,
            contactValues: [],
          };
          formArray.value.forEach((contact: RequestContact, index: number) => {
            // Set Category for contact addresses
            this._setContactCategories(contact);
            // Set category for phones
            this._setPhoneCategories(contact);
            // Set category for emails
            this._setEmailCategories(contact);
            // Finish Building new contact object
            let newContact: RequestContact = {
              ...contact,
            } as RequestContact;
            // Set new contact to appropriate index
            contactValues[index] = newContact;
          });

          additionalFieldValue.contactValues = contactValues;
          if (formArray.touched) {
            this.additionalFieldValueEvent.emit(additionalFieldValue);
          }
        }),
      )
      .subscribe();
  }

  /**
   * Helper method for processing the contacts formArray.
   * @param contact A contact in the previously emitted contacts formArray.
   */
  private _setContactCategories(contact: RequestContact): void {
    if (contact.addresses?.length) {
      contact.addresses.forEach((address) => {
        const listRef =
          this.additionalFieldSpec.contactFieldDefinition?.address?.category
            ?.listReference ??
          ({
            list: 'AddressCategories',
            additionalListName: '',
          } as ProfileListReference);
        let dropdownCategory = this.resolver
          .getAddressCategoryDropdownOptions(listRef)
          .find((element) => element.category?.name === address.category);
        if (dropdownCategory?.category) {
          address.category = {
            caption: dropdownCategory.category.caption,
            name: dropdownCategory.category.name,
            commonCategory: dropdownCategory.category.commonCategory,
          };
        }
        address.caption = this.setAddressCaption(address);
      });
    }
  }

  /**
   * Helper method for processing the contacts formArray.
   * @param contact A contact in the previously emitted contacts formArray.
   */
  private _setPhoneCategories(contact: RequestContact): void {
    if (contact.phones?.length) {
      contact.phones.forEach((phone) => {
        const listRef =
          this.additionalFieldSpec.contactFieldDefinition?.phone?.category
            ?.listReference ??
          ({
            list: 'PhoneCategories',
            additionalListName: '',
          } as ProfileListReference);
        let dropdownCategory = this.resolver
          .getPhoneCategoryDropdownOptions(listRef)
          .find((element) => element.category?.name === phone.category);
        if (dropdownCategory?.category) {
          phone.category = {
            caption: dropdownCategory.category.caption,
            name: dropdownCategory.category.name,
            commonCategory: dropdownCategory.category.commonCategory,
          };
        }
      });
    }
  }

  /**
   * Helper method for processing the contacts formArray.
   * @param contact A contact in the previously emitted contacts formArray.
   */
  private _setEmailCategories(contact: RequestContact): void {
    if (contact.emails?.length) {
      contact.emails.forEach((email) => {
        const listRef =
          this.additionalFieldSpec.contactFieldDefinition?.email?.category
            ?.listReference ??
          ({
            list: 'EmailCategories',
            additionalListName: '',
          } as ProfileListReference);
        let dropdownCategory = this.resolver
          .getEmailCategoryDropdownOptions(listRef)
          .find((element) => element.category?.name === email.category);
        if (dropdownCategory?.category) {
          email.category = {
            caption: dropdownCategory.category.caption,
            name: dropdownCategory.category.name,
            commonCategory: dropdownCategory.category.commonCategory,
          };
        }
      });
    }
  }

  /**
   * Helper method for processing the contacts formArray.
   */
  private setAddressCaption(newAddress: AddressViewModel): string {
    let caption = '';
    if (newAddress.addressLine1) {
      caption = `${newAddress.addressLine1}`;
    }

    if (newAddress.addressLine2) {
      caption += `, ${newAddress.addressLine2}`;
    }

    if (newAddress.locality) {
      caption += `, ${newAddress.locality}`;
    }

    if (newAddress.administrativeArea) {
      caption += `, ${newAddress.administrativeArea}`;
    }

    if (newAddress.postalCode) {
      caption += `, ${newAddress.postalCode}`;
    }

    if (newAddress.country) {
      caption += `, ${newAddress.country}`;
    }

    return caption;
  }
}
