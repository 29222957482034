import { Injectable } from '@angular/core';
import { CaseRequestViewModel, CasePartyViewModel } from '@fsx/fsx-shared';
import { Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class RemovePartyService {
  public removeParty(
    caseRequest: CaseRequestViewModel,
    participantName: string,
  ): Observable<CaseRequestViewModel> {
    const caseRequestParties: CasePartyViewModel[] = caseRequest.parties || [];
    const filteredCaseRequestParties: CasePartyViewModel[] =
      caseRequestParties.filter((p) => {
        return p.participantName !== participantName;
      });
    caseRequest.parties = filteredCaseRequestParties;
    return of(caseRequest);
  }
}
