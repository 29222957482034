import { Inject, Injectable, InjectionToken } from '@angular/core';
import {
  CaseRequestViewModel,
  CombinedFilingData,
  FILING_SUB_TABS,
  FilingSubTabItem,
} from '@fsx/fsx-shared';
import {
  Observable,
  Subject,
  filter,
  map,
  merge,
  pairwise,
  withLatestFrom,
} from 'rxjs';
import {
  FsxFilingSubTabsService,
  IFilingSubTabsService,
} from '../../filing-sub-tabs-container/filing-sub-tabs.service';
import {
  FsxCombinedFilingDataService,
  ICombinedFilingDataService,
} from '../combined-filing-data.service';
import {
  FsxCaseRequestDataService,
  ICaseRequestDataService,
} from '../case-request-data.service';
import {
  FsxCaseReviewValidationService,
  ICaseReviewValidationService,
} from 'projects/libs/shared/src/lib/services/core/validation/case-review-validation.service';

export const FsxValidateReviewOrchestrationService =
  new InjectionToken<IValidateReviewOrchestrationService>(
    'FsxValidateReviewOrchestrationService',
  );

export interface IValidateReviewOrchestrationService {
  validateReview$: Observable<CaseRequestViewModel>;
  validateReview(): void;
}

@Injectable()
export class ValidateReviewOrchestrationService
  implements IValidateReviewOrchestrationService
{
  private validateReview$$ = new Subject<void>();

  // DO NOT DELETE: may need this validation trigger later
  private reviewTabDeactivated$: Observable<void> =
    this.filingSubTabsService.activeSubTabItem$.pipe(
      pairwise(),
      filter(([previous, current]: [FilingSubTabItem, FilingSubTabItem]) => {
        return (
          previous.name === FILING_SUB_TABS.REVIEW &&
          current.name !== FILING_SUB_TABS.REVIEW
        );
      }),
      map(() => {
        return;
      }),
    );

  validateReview$: Observable<CaseRequestViewModel> = merge(
    // this.reviewTabDeactivated$, // DO NOT DELETE: may need this validation trigger later
    this.validateReview$$,
    // TODO: Add anymore review validation triggers here
  ).pipe(
    withLatestFrom(this.combinedFilingDataService.combinedFilingData$),
    map(([_, combinedFilingData]: [void, CombinedFilingData]) => {
      const { filing, caseRequest, filingProfile, modeSpec } =
        combinedFilingData;
      if (caseRequest && caseRequest && caseRequest.cases) {
        this.caseReviewValidation.validateCaseReview(
          filing,
          caseRequest,
          filingProfile,
          modeSpec,
        );
        this.caseRequestDataService.setCaseRequestData(caseRequest);
      }
      return caseRequest;
    }),
  );

  public constructor(
    @Inject(FsxCombinedFilingDataService)
    private readonly combinedFilingDataService: ICombinedFilingDataService,
    @Inject(FsxCaseReviewValidationService)
    private readonly caseReviewValidation: ICaseReviewValidationService,
    @Inject(FsxCaseRequestDataService)
    private readonly caseRequestDataService: ICaseRequestDataService,
    @Inject(FsxFilingSubTabsService)
    private readonly filingSubTabsService: IFilingSubTabsService,
  ) {}

  validateReview(): void {
    this.validateReview$$.next();
  }
}
