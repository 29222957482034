import { Inject, Injectable, InjectionToken } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import {
  AddressViewModel,
  ContactViewModel,
  ContactAliasViewModel,
  ContactAliasFormGroup,
  ContactFormGroup,
  ContactType,
  EmailAddressViewModel,
  getPersonFullName,
  NewContactViewModel,
  PersonalNameViewModel,
  PhoneViewModel,
  RequestParticipantViewModel,
  RequestParticipantAliasViewModel,
  RequestContactOrganizationViewModel,
  IContactApiService,
  ContactFormMode,
  FsxContactApiService,
  ContactPersonViewModel,
  IdentificationViewModel,
  FilingProfile,
  IdentificationCommonCategory,
  FsxAddressFormService,
  FsxAliasFormService,
  FsxEmailFormService,
  FsxPersonFormService,
  FsxPhoneFormService,
  IAddressFormService,
  IAliasFormService,
  IEmailFormService,
  IPersonFormService,
  IPhoneFormService,
  FsxOrganizationFormService,
  IOrganizationFormService,
  ContactSummary,
} from '@fsx/fsx-shared';
import {
  FormArrayWithModel,
  hasValues,
  IdentificationFormGroup,
} from '@fsx/ui-components';
import {
  FsxIdentificationsFormService,
  IIdentificationsFormService,
} from 'projects/libs/shared/src/lib/services/parties/identifications.service';
import { Observable, switchMap, take } from 'rxjs';
import {
  FsxUserDataService,
  IUserDataService,
} from '../../filing-editor/services/user-data.service';

/**
 * The InjectionToken to use in the providers array to specify a concrete-implementation
 * of the IContactFormService to use at runtime.
 */
export const FsxContactFormService = new InjectionToken<IContactFormService>(
  'FsxContactFormService',
);

export interface IContactFormService {
  submitForm(
    formGroup: FormGroup<ContactFormGroup>,
    formMode: ContactFormMode,
    contactId?: string | null,
  ): Observable<ContactViewModel | null>;

  /**
   * A method for applying FormGroup updates to a RequestParticipant object
   *
   * @param participant The RequestParticipant object to apply the FormGroup updates to.
   * @param formGroup The FormGroup with the values to apply to the RequestParticipant object.
   *
   * @returns The RequestParticipant object with the form group updates applied to it.
   */
  transformToParticipant(
    participant: RequestParticipantViewModel,
    formGroup: FormGroup<ContactFormGroup>,
    filingProfile: FilingProfile,
  ): RequestParticipantViewModel;

  validateFormGroup(
    contactFormGroup:
      | FormGroup<ContactFormGroup>
      | FormGroup<ContactAliasFormGroup>,
    force: boolean,
  ): boolean;
}

@Injectable()
export class ContactFormService implements IContactFormService {
  constructor(
    @Inject(FsxContactApiService)
    private readonly contactApiService: IContactApiService,
    @Inject(FsxAddressFormService)
    private readonly addressFormService: IAddressFormService,
    @Inject(FsxPhoneFormService)
    public readonly phoneFormService: IPhoneFormService,
    @Inject(FsxEmailFormService)
    private readonly emailFormService: IEmailFormService,
    @Inject(FsxAliasFormService)
    private readonly aliasFormService: IAliasFormService,
    @Inject(FsxIdentificationsFormService)
    private readonly identificationsFormService: IIdentificationsFormService,
    @Inject(FsxOrganizationFormService)
    private readonly organizationFormService: IOrganizationFormService,
    @Inject(FsxPersonFormService)
    private readonly personFormService: IPersonFormService,
    @Inject(FsxUserDataService)
    private readonly userDataService: IUserDataService,
  ) {}

  submitForm(
    formGroup: FormGroup<ContactFormGroup>,
    formMode: ContactFormMode,
    contactId?: string | null,
  ): Observable<ContactViewModel | null> {
    return this.userDataService.contactSummary$.pipe(
      take(1),
      switchMap((loggedInUserContactSummary: ContactSummary) => {
        const newContact = formGroup.value as NewContactViewModel;
        this._transformToNewContact(newContact, formGroup, formMode);

        formGroup.controls.aliases?.controls.forEach(
          (formGroup: FormGroup<ContactAliasFormGroup>, index) => {
            this._transformToNewContact(
              newContact.aliases[index],
              formGroup,
              formMode,
            );
            newContact.aliases[index].caption = '';
          },
        );

        if (formGroup.controls.aliases) {
          this.aliasFormService.setAliasCategories(
            newContact,
            formGroup.controls.aliases,
          );
        }

        if (
          formGroup.controls.barIdentifications ||
          formGroup.controls.otherIdentifications
        ) {
          this.identificationsFormService.setIdentificationsCategories(
            newContact,
            formGroup.controls.barIdentifications,
            formGroup.controls.otherIdentifications,
          );
        }

        if (!newContact.parentOrganization) {
          newContact.parentOrganization =
            loggedInUserContactSummary.parentOrganization;
        }

        const addOrUpdate$ = contactId
          ? this.contactApiService.updateContact(contactId, newContact)
          : this.contactApiService.createContact(newContact);

        return addOrUpdate$;
      }),
    );
  }

  /**
   * A method for applying FormGroup updates to a RequestParticipant object
   *
   * @param participant The RequestParticipant object to apply the FormGroup updates to.
   * @param formGroup The FormGroup with the values to apply to the RequestParticipant object.
   *
   * @returns The RequestParticipant object with the form group updates applied to it.
   */
  transformToParticipant(
    participant: RequestParticipantViewModel,
    formGroup: FormGroup<ContactFormGroup>,
  ): RequestParticipantViewModel {
    const newParticipant: RequestParticipantViewModel = Object.assign(
      {},
      participant,
    );

    // First thing's first, set the contact type.
    // How we configure the RequestParticipant object is slightly different for
    // Person and Organization types, so it's important that we set this early.
    newParticipant.contactType = formGroup.controls.contactType.value;

    newParticipant.addresses = [];
    formGroup.controls.addresses?.value.forEach((address: AddressViewModel) => {
      if (hasValues(address)) {
        newParticipant.addresses?.push(address);
      }
    });

    newParticipant.emails = [];
    formGroup.controls.emails?.value.forEach((email: EmailAddressViewModel) => {
      if (hasValues(email)) {
        newParticipant.emails?.push(email);
      }
    });

    newParticipant.phones = [];
    formGroup.controls.phones?.value.forEach((phone: PhoneViewModel) => {
      if (hasValues(phone)) {
        newParticipant.phones?.push(phone);
      }
    });

    newParticipant.aliases = [];
    formGroup.controls.aliases?.value.forEach(
      (alias: RequestParticipantAliasViewModel) => {
        if (hasValues(alias)) {
          if (!alias.caption) {
            alias.caption = '';
          }
          newParticipant.aliases?.push(alias);
        }
      },
    );

    this.addressFormService.setAddressCategories(newParticipant, formGroup);
    this.emailFormService.setEmailCategories(newParticipant, formGroup);
    this.phoneFormService.setPhoneCategories(newParticipant, formGroup);

    if (formGroup.controls.aliases) {
      this.aliasFormService.setAliasCategories(
        newParticipant,
        formGroup.controls.aliases,
      );

      formGroup.controls.aliases.controls.forEach((aliasFormGroup, index) => {
        if (newParticipant.aliases && newParticipant.aliases[index]) {
          this._setContactName(newParticipant.aliases[index]);
          this.addressFormService.setAddressCategories(
            newParticipant.aliases[index],
            aliasFormGroup,
          );
          this.emailFormService.setEmailCategories(
            newParticipant.aliases[index],
            aliasFormGroup,
          );
          this.phoneFormService.setPhoneCategories(
            newParticipant.aliases[index],
            aliasFormGroup,
          );
        }
      });
    }

    // Start with an empty identifications array that we can push to.
    newParticipant.identifications = [];
    if (formGroup.controls.barIdentifications) {
      // Iterate through the identification form groups pushing a new identifcation object for each form group.
      formGroup.controls.barIdentifications.controls.forEach(
        (identificationFormGroup) => {
          // ToDo: For some reason, test attorneys have their common category set to null.
          // Look up the identification category in the Filing Profile.
          // const category: IdentificationCommonCategoryDomainCategoryValue = filingProfile.identificationCategories.find(
          //   (identificationCategory: IdentificationCommonCategoryDomainCategoryValue) => {
          //     return identificationCategory.name === identificationFormGroup.controls.category.value
          //   }
          // )!;
          //
          // // Set the new Identification object.
          // const identification: IdentificationViewModel = {
          //   regionKey: identificationFormGroup.controls.regionKey.value,
          //   identificationKey: identificationFormGroup.controls.identificationKey.value,
          //   category
          // }

          // ToDo: Original fix that works but doesn't look at identification category.
          const identification: IdentificationViewModel = {
            regionKey: identificationFormGroup.controls.regionKey.value,
            identificationKey:
              identificationFormGroup.controls.identificationKey.value,
            category: {
              name: 'bar',
              caption: 'Bar Number',
              commonCategory: IdentificationCommonCategory.BarNumber,
            },
          };

          // Push the new identification object to the identifications array.
          newParticipant.identifications.push(identification);
        },
      );
    }

    if (newParticipant.contactType === ContactType.Person) {
      if (formGroup.controls.person) {
        if (formGroup.controls.person.controls.personalName.controls.fullName) {
          // The user entered a full name in a single form field, so set it into
          // the person.personalName object property.
          newParticipant.person = {
            personalName: {
              fullName:
                formGroup.controls.person.controls.personalName.controls
                  .fullName.value,
            } as PersonalNameViewModel,
          } as ContactPersonViewModel;
        } else {
          // The user entered the name in separate form fields, so set them in
          // their respective person.personalName object properties.
          newParticipant.person = {
            personalName: {
              prefix:
                formGroup.controls.person.controls.personalName.controls.prefix
                  ?.value,
              givenName:
                formGroup.controls.person.controls.personalName.controls
                  .givenName?.value,
              middleName:
                formGroup.controls.person.controls.personalName.controls
                  .middleName?.value,
              surName:
                formGroup.controls.person.controls.personalName.controls.surName
                  ?.value,
              suffix:
                formGroup.controls.person.controls.personalName.controls.suffix
                  ?.value,
            } as PersonalNameViewModel,
          } as ContactPersonViewModel;

          // Even though it was not explicitly provided, we must set the full
          // name property. Here we derive it from the other person.personalName
          // object properties.
          newParticipant.person.personalName.fullName = getPersonFullName(
            newParticipant.person.personalName,
          );
        }

        // Here we set the caption, which is the value that gets surfaced in the view.
        // For a Person, the caption should always be the full name.
        newParticipant.caption = newParticipant.person.personalName.fullName;

        // This is a Person so we only need to set the person property (above).
        // The organization property should therefore be null (below).
        newParticipant.organization = null;
      }
    }

    if (newParticipant.contactType === ContactType.Organization) {
      if (formGroup.controls.organization) {
        // The organization's title that the user entered into the form.
        const organizationTitle = formGroup.controls.organization.value.title;

        // This is an Orgnization, so we must set the organization property.
        newParticipant.organization = {
          title: organizationTitle,
          caption: organizationTitle,
        } as RequestContactOrganizationViewModel;

        // Here we set the caption, which is the value that gets surfaced in the view.
        // For Organizations, the caption should always be the orgnization's title.
        newParticipant.caption = organizationTitle;

        // This is an Organization so we only need to set the organization property (above).
        // The person property should therefore be null (below).
        newParticipant.person = null;
      }
    }

    return newParticipant;
  }

  private _transformToNewContact(
    contact: NewContactViewModel | ContactAliasViewModel,
    formGroup: FormGroup<ContactFormGroup> | FormGroup<ContactAliasFormGroup>,
    formMode: ContactFormMode,
  ): void {
    this._setContactName(contact);
    this._setFormCategories(contact, formGroup);
    this._setContactCaption(contact, formGroup);
    this._removeContactNullValues(contact);
    if (formMode === ContactFormMode.AddContact) {
      this.removePristineFormGroups(contact, formGroup);
    }
  }

  validateFormGroup(
    contactFormGroup:
      | FormGroup<ContactFormGroup>
      | FormGroup<ContactAliasFormGroup>,
    force: boolean = false,
  ): boolean {
    if (contactFormGroup.controls.contactType?.value === ContactType.Person) {
      contactFormGroup.controls.organization?.reset();
      const contactName =
        contactFormGroup.controls.person?.controls.personalName.controls;
      if (contactName) {
        this._validateFormGroupControls(
          contactName as unknown as FormGroup,
          force,
        );
      }
    }

    if (
      contactFormGroup.controls.contactType?.value === ContactType.Organization
    ) {
      contactFormGroup.controls.person?.reset();
      const organizationName = contactFormGroup.controls.organization?.controls;
      if (organizationName) {
        this._validateFormGroupControls(
          organizationName as unknown as FormGroup,
          force,
        );
      }
    }

    if (contactFormGroup.controls.addresses) {
      this._validateFormArrays(
        contactFormGroup.controls.addresses,
        true,
        force,
      );
    }
    // console.groupEnd();

    if (contactFormGroup.controls.phones) {
      this._validateFormArrays(contactFormGroup.controls.phones, true, force);
    }

    if (contactFormGroup.controls.emails) {
      this._validateFormArrays(contactFormGroup.controls.emails, true, force);
    }

    if (
      contactFormGroup.controls.barIdentifications ||
      contactFormGroup.controls.otherIdentifications
    ) {
      const idsArray = new FormArrayWithModel<
        FormGroup<IdentificationFormGroup>
      >([], {
        minRequired:
          contactFormGroup.controls.barIdentifications?.formArraySpecs
            .minRequired ?? 0,
        maxAllowed:
          contactFormGroup.controls.barIdentifications?.formArraySpecs
            .maxAllowed ?? 0,
      });

      if (contactFormGroup.controls.barIdentifications) {
        contactFormGroup.controls.barIdentifications.controls.forEach(
          (control) => idsArray.push(control),
        );
      }
      if (contactFormGroup.controls.otherIdentifications) {
        contactFormGroup.controls.otherIdentifications.controls.forEach(
          (control) => idsArray.push(control),
        );
      }

      this._validateFormArrays(idsArray, true, force);
    }

    if (contactFormGroup.controls.otherIdentifications) {
      this._validateFormArrays(
        contactFormGroup.controls.otherIdentifications,
        true,
        force,
      );
    }

    if (contactFormGroup.controls.aliases) {
      this._validateFormArrays(contactFormGroup.controls.aliases, false, force);

      contactFormGroup.controls.aliases.controls.forEach((aliasFormGroup) => {
        aliasFormGroup.controls.category?.markAsTouched();
        aliasFormGroup.controls.category?.updateValueAndValidity();
        this.validateFormGroup(aliasFormGroup, force);
      });
    }

    return contactFormGroup.valid;
  }

  private _validateFormGroupControls(
    formGroup: FormGroup,
    force: boolean = false,
  ): void {
    Object.values(formGroup).forEach((control: FormControl) => {
      if (force) {
        control.markAsDirty();
        control.markAsTouched();
        control.updateValueAndValidity();
      } else {
        if (!control.errors) {
          control.markAsTouched();
          control.updateValueAndValidity();

          if (
            (control.value && control.value.length) ||
            (!control.value && !control.dirty)
          ) {
            control.markAsUntouched();
          }
        }
      }
    });
  }

  private _validateFormArrays(
    formArray: FormArrayWithModel<FormGroup>,
    validateFormGroup: boolean = true,
    force: boolean = false,
  ): boolean {
    if (
      formArray.controls.length < formArray.formArraySpecs.minRequired ||
      formArray.controls.length > formArray.formArraySpecs.maxAllowed
    ) {
      return false;
    }

    if (validateFormGroup) {
      formArray.controls.forEach((formGroup) => {
        // console.group("pristine", formGroup.pristine);
        if (
          formArray.formArraySpecs.minRequired ||
          (!formArray.formArraySpecs.minRequired &&
            !formGroup.pristine &&
            hasValues(formGroup.value))
        ) {
          this._validateFormGroupControls(
            formGroup.controls as unknown as FormGroup,
            force,
          );
        }
        // console.groupEnd();
      });
    }

    return true;
  }

  private _setFormCategories(
    contact: NewContactViewModel | ContactAliasViewModel,
    formGroup: FormGroup<ContactFormGroup> | FormGroup<ContactAliasFormGroup>,
  ) {
    this.addressFormService.setAddressCategories(contact, formGroup);
    this.emailFormService.setEmailCategories(contact, formGroup);
    this.phoneFormService.setPhoneCategories(contact, formGroup);
    this.organizationFormService.setOrganizationValues(contact);
    this.personFormService.setPersonName(contact);
  }

  private _setContactCaption(
    contact: NewContactViewModel | ContactAliasViewModel,
    formGroup: FormGroup<ContactFormGroup> | FormGroup<ContactAliasFormGroup>,
  ) {
    this.addressFormService.setAddressCaptions(contact, formGroup);
    this.emailFormService.setEmailCaptions(contact, formGroup);
    this.phoneFormService.setPhoneCaptions(contact, formGroup);
  }

  private _removeContactNullValues(
    contact: NewContactViewModel | ContactAliasViewModel,
  ): void {
    contact.addresses = contact.addresses?.filter((address) => {
      const validValue = Object.values(address).filter((value) => !!value);
      return validValue.length ? true : false;
    });
    contact.emails = contact.emails?.filter((email) => {
      const validValue = Object.values(email).filter((value) => !!value);
      return validValue.length ? true : false;
    });
    contact.phones = contact.phones?.filter((phone) => {
      const validValue = Object.values(phone).filter((value) => !!value);
      return validValue.length ? true : false;
    });
  }

  private _setContactName(
    contact:
      | NewContactViewModel
      | ContactAliasViewModel
      | RequestParticipantAliasViewModel,
  ): void {
    if (contact.contactType === ContactType.Person) {
      if (contact.person?.personalName) {
        contact.person.personalName.fullName = getPersonFullName(
          contact.person?.personalName,
        );
      }
      contact.caption = contact.person?.personalName.fullName ?? '';
      contact.organization = null;
    } else if (contact?.organization) {
      contact.caption = contact.organization.title;
      contact.person = null;
    }
  }

  private removePristineFormGroups(
    contact: NewContactViewModel | ContactAliasViewModel,
    formGroup: FormGroup<ContactFormGroup> | FormGroup<ContactAliasFormGroup>,
  ): void {
    if (
      !!contact.addresses &&
      contact.addresses.length === 1 &&
      formGroup.controls.addresses?.pristine
    ) {
      contact.addresses.pop();
    }
    if (
      !!contact.phones &&
      contact.phones.length === 1 &&
      formGroup.controls.phones?.pristine
    ) {
      contact.phones.pop();
    }
    if (
      !!contact.emails &&
      contact.emails.length === 1 &&
      formGroup.controls.emails?.pristine
    ) {
      contact.emails.pop();
    }
  }
}
