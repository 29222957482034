import { Injectable, InjectionToken } from '@angular/core';
import {
  FilingProfile,
  IValidatable,
  TextFieldDefinition,
} from '../../../types';

export const FsxTextFieldValidationService =
  new InjectionToken<ITextFieldValidationService>(
    'FsxTextFieldValidationService',
  );

export interface ITextFieldValidationService {
  validateTextField(
    scope: IValidatable,
    filingProfile: FilingProfile,
    spec?: TextFieldDefinition | null | undefined,
    value?: string | null | undefined,
  ): boolean;
}

@Injectable()
export class TextFieldValidationService implements ITextFieldValidationService {
  public validateTextField(
    scope: IValidatable,
    filingProfile: FilingProfile,
    spec?: TextFieldDefinition | null | undefined,
    value?: string | null | undefined,
  ): boolean {
    if (!spec) {
      return true;
    }

    if (!value) {
      if (spec.required) {
        scope.isValid = false;
        return false;
      }
    }

    if (!value) {
      return true;
    }

    if (value.length > spec.maxLength) {
      scope.isValid = false;
      return false;
    }

    if (value.length > 0 && value.length < spec.minLength) {
      scope.isValid = false;
      return false;
    }

    const characterProfileName = !spec.characterProfileName
      ? filingProfile.defaultCharacterProfileName
      : spec.characterProfileName;

    const characterProfile = filingProfile.characterProfiles.find(
      (profile) => profile.name === characterProfileName,
    );
    const allowedCharacters = characterProfile?.characterSet;

    if (!allowedCharacters) {
      return true;
    }

    const fieldValueChars = [...value];
    for (let index = 0; index < fieldValueChars.length; index++) {
      const char = fieldValueChars[index];
      if (allowedCharacters?.indexOf(char) === -1) {
        scope.isValid = false;
        return false;
      }
    }

    return true;
  }
}
