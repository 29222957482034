import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import {
  FieldCategory,
  PersonalNameViewModel,
  PersonNameSpec,
  TextFieldDefinition,
} from '@fsx/fsx-shared';
import { FormControlWithModel } from '../../models';
import {
  PersonNameFormGroup,
  SelectionFieldType,
} from '../../types/form-control.types';
import { FsxTextComponent } from '../text/text.component';
import { ReferenceResolver } from '../../types';
import { FsxBaseComponent } from '../base/base.component';

@Component({
  selector: 'fsx-person-name-component',
  templateUrl: './person-name.component.html',
  styleUrls: ['./person-name.component.scss'],
})
export class FsxPersonNameComponent extends FsxBaseComponent implements OnInit {
  @Input() personNameComponentSpec!: PersonNameSpec | null;
  @Input() initialValues!: PersonalNameViewModel;
  @Input() resolver!: ReferenceResolver;
  @Input() height = '63px';

  @Output() formGroupEmitter = new EventEmitter<FormGroup<PersonNameFormGroup>>(
    true,
  );

  @ViewChild('prefixField') prefixField!: FsxTextComponent;
  @ViewChild('firstNameField') firstNameField!: FsxTextComponent;
  @ViewChild('middleNameField') middleNameField!: FsxTextComponent;
  @ViewChild('lastNameField') lastNameField!: FsxTextComponent;
  @ViewChild('suffixField') suffixField!: FsxTextComponent;
  @ViewChild('fullNameField') fullNameField!: FsxTextComponent;

  public selectionType = SelectionFieldType;
  public personNameFormGroup!: FormGroup<PersonNameFormGroup>;
  public fieldType = FieldCategory;

  public ngOnInit(): void {
    this.personNameFormGroup = new FormGroup<PersonNameFormGroup>(
      {} as PersonNameFormGroup,
    );

    this.formGroupEmitter.emit(this.personNameFormGroup);
  }

  public setControl(
    control: FormControlWithModel<TextFieldDefinition>,
    controlName: keyof PersonNameFormGroup,
  ): void {
    const formGroup = this.personNameFormGroup;
    formGroup.setControl(controlName, control);
  }

  public validate(): void {
    if (this.shouldValidateFormControl(this.personNameFormGroup)) {
      if (this.prefixField) {
        this.prefixField.validate();
      }
      if (this.firstNameField) {
        this.firstNameField.validate();
      }
      if (this.middleNameField) {
        this.middleNameField.validate();
      }
      if (this.lastNameField) {
        this.lastNameField.validate();
      }
      if (this.suffixField) {
        this.suffixField.validate();
      }
      if (this.fullNameField) {
        this.fullNameField.validate();
      }
    }
  }
}
