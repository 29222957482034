import { Component, Input } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { ValidationError } from '../../filing-editor/services/validation-errors.service';

@Component({
  selector: 'fsx-label-with-validation',
  templateUrl: './label-with-validation.component.html',
  styleUrls: ['./label-with-validation.component.scss'],
})
export class LabelWithValidationComponent {
  /**
   * The label text to display.
   */
  @Input() labelText: string = '';

  /**
   * The AbstractControl containing the errors object from which we want
   * to display error messages for.
   */
  @Input() abstractControl!: AbstractControl;

  /**
   * Previous implementation of this component before allowing the messages
   * to be displayed through Angular reactive forms. Should remove this once
   * all instances of this component pass in an abstractControl.
   */
  @Input() validationErrors: ValidationError[] = [];
}
