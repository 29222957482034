import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'fileSize',
})
export class FileSizePipe implements PipeTransform {
  transform(
    value: number,
    precision: number = 1,
    quantifier: boolean = true,
  ): string {
    let bytes: number = value || 0;
    let exp: number = (Math.log(bytes) / Math.log(1024)) | 0;
    let result: string = (bytes / Math.pow(1024, exp)).toFixed(precision);
    result = result.replace(/\.(0)+$/, '');
    if (quantifier) {
      result += ' ' + (exp == 0 ? 'B' : 'KMGTPEZY'[exp - 1] + 'B');
    }
    return result;
  }
}
