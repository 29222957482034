import { Injectable, InjectionToken } from '@angular/core';
import { FormGroup } from '@angular/forms';
import {
  ContactAliasViewModel,
  RequestParticipantViewModel,
  RequestParticipantAliasViewModel,
} from '@fsx/fsx-shared';
import {
  ContactAliasFormGroup,
  ContactFormGroup,
  EmailAddressViewModel,
  EmailAddressSpec,
  EmailCategory,
  EmailCommonCategory,
  FilingProfile,
  NewContactViewModel,
} from '@fsx/fsx-shared';

/**
 * The InjectionToken to use in the providers array to specify a concrete-implementation
 * of the IEmailFormService to use at runtime.
 */
export const FsxEmailFormService = new InjectionToken<IEmailFormService>(
  'FsxEmailFormService',
);

export interface IEmailFormService {
  setEmailCategories(
    contact:
      | NewContactViewModel
      | RequestParticipantViewModel
      | RequestParticipantAliasViewModel
      | ContactAliasViewModel,
    formGroup: FormGroup<ContactFormGroup> | FormGroup<ContactAliasFormGroup>,
  ): void;

  setEmailCaptions(
    contact: NewContactViewModel | ContactAliasViewModel,
    formGroup: FormGroup<ContactFormGroup> | FormGroup<ContactAliasFormGroup>,
  ): void;

  getEmailsFromFilingProfile(
    contactEmails: EmailAddressViewModel[] | undefined,
    emailSpec: EmailAddressSpec | undefined,
    filingProfile: FilingProfile | null,
  ): EmailAddressViewModel[];
}

@Injectable()
export class EmailFormService implements IEmailFormService {
  public setEmailCategories(
    contact:
      | NewContactViewModel
      | RequestParticipantViewModel
      | RequestParticipantAliasViewModel
      | ContactAliasViewModel,
    formGroup: FormGroup<ContactFormGroup> | FormGroup<ContactAliasFormGroup>,
  ): void {
    formGroup.controls.emails?.controls.forEach((email, index) => {
      const emailCategory = email.controls.category?.value;
      const dropdownOptions = email.controls.category?.dropdownOptions;
      const selectedOption = dropdownOptions?.find(
        (option) => option.name === emailCategory,
      );

      if (contact && contact.emails && contact.emails[index]) {
        contact.emails[index].caption = '';

        if (contact.emails[index].category && selectedOption) {
          contact.emails[index].category = {
            name: selectedOption.name,
            caption: selectedOption.caption,
            commonCategory: selectedOption.category
              ?.commonCategory as EmailCommonCategory,
          };
        }
      }
    });
  }

  public setEmailCaptions(
    contact: NewContactViewModel | ContactAliasViewModel,
    formGroup: FormGroup<ContactFormGroup> | FormGroup<ContactAliasFormGroup>,
  ): void {
    formGroup.controls.emails?.controls.forEach((_, index) => {
      if (contact.emails) {
        contact.emails[index].caption = contact.emails[index].address;
      }
    });
  }

  public getEmailsFromFilingProfile(
    contactEmails: EmailAddressViewModel[] | undefined,
    emailSpec: EmailAddressSpec | undefined,
    filingProfile: FilingProfile | null,
  ): EmailAddressViewModel[] {
    const participantEmails: EmailAddressViewModel[] = [];
    const emailsList = emailSpec?.category?.listReference;
    let selectedCategory: EmailCategory | undefined;

    contactEmails?.forEach((email) => {
      if (emailsList?.list === 'AdditionalList') {
        const list = filingProfile?.additionalLists?.find(
          (list) => list.name === emailsList.additionalListName,
        );
        const listElement = list?.items?.find(
          (item) => item.name === email.category?.name,
        );
        if (listElement && email.category) {
          selectedCategory = email.category;
        }
      } else {
        selectedCategory = filingProfile?.emailCategories?.find(
          (item) => item.commonCategory === email.category?.commonCategory,
        );
      }
      const newEmail: EmailAddressViewModel = {
        ...email,
        category: selectedCategory,
      } as EmailAddressViewModel;

      if (
        newEmail.category &&
        emailSpec &&
        participantEmails.length <= emailSpec.maxAllowed
      ) {
        participantEmails.push(newEmail);
      }
    });

    return participantEmails;
  }
}
