<div
  class="fsx-currency-component"
  [ngStyle]="{
    width: width,
    display: formControl.fieldDefinition.visible ? 'block' : 'none',
  }"
  [ngClass]="{ invalid: formControl.invalid && formControl.touched }"
>
  <ng-container *ngIf="!formControl.fieldDefinition.readOnly; else readOnly">
    <label
      >{{ caption }}
      <span class="required" *ngIf="formControl.fieldDefinition.required"
        >*</span
      >
      <mat-icon *ngIf="helpText" [matTooltip]="helpText">info_outline</mat-icon>
    </label>

    <div
      class="currency-input"
      (click)="input.focus()"
      [ngClass]="{
        invalid: formControl.invalid && formControl.touched,
        disabled: formControl.disabled,
      }"
    >
      <div class="currency-container">
        <span
          class="currency"
          *ngIf="
            formControl.value !== null ||
            (formControl.value === null && !showPlaceholder)
          "
          >$</span
        >
      </div>
      <input
        #input
        type="text"
        [placeholder]="hint"
        [ngClass]="{
          'placeholder-padding': showingPlaceholder,
          invalid: invalidInput,
        }"
        [formControl]="formControl"
        (blur)="formatValue(); togglePlaceholder(true)"
        (focus)="togglePlaceholder(false)"
      />
    </div>

    <p
      class="error-message"
      *ngIf="formControl.invalid && (initialValue || formControl.touched)"
    >
      {{ parseError || getErrorMessage() }}
    </p>
  </ng-container>

  <ng-template #readOnly>
    <p class="readonly-text">${{ formControl.formattedValue }}</p>
    <label class="readonly-label">{{ caption }}</label>
  </ng-template>
</div>
