import { Injectable } from '@angular/core';
import {
  DocumentInfo,
  DocumentRenderingInfo,
  RenderingStatus,
} from '../../types';

export enum RenderingNameEnum {
  ClerkStamped = 'clerk-stamped-pdf',
  ConvertedPdf = 'converted-pdf',
  Original = 'original',
}

export enum FileUploadState {
  Unknown = 'unknown',
  Uploading = 'uploading',
  Validating = 'validating',
  Converting = 'converting',
  Cancelled = 'cancelled',
  Failed = 'failed',
  Done = 'done',
}

@Injectable({
  providedIn: 'root',
})
export class DocumentInfoHelperService {
  getConvertedPdfRendering(
    documentInfo: DocumentInfo,
  ): DocumentRenderingInfo | null {
    return (
      documentInfo?.renderings?.find((rendering: DocumentRenderingInfo) => {
        return rendering.name === RenderingNameEnum.ConvertedPdf;
      }) || null
    );
  }

  getOriginalRendering(
    documentInfo: DocumentInfo,
  ): DocumentRenderingInfo | null {
    return (
      documentInfo?.renderings?.find((rendering: DocumentRenderingInfo) => {
        return rendering.name === RenderingNameEnum.Original;
      }) || null
    );
  }

  getConvertedPdfStatus(documentInfo: DocumentInfo): RenderingStatus {
    return (
      this.getConvertedPdfRendering(documentInfo)?.status ||
      RenderingStatus.Unknown
    );
  }

  getOriginalStatus(documentInfo: DocumentInfo): RenderingStatus {
    return (
      this.getOriginalRendering(documentInfo)?.status || RenderingStatus.Unknown
    );
  }

  getOriginalSize(documentInfo: DocumentInfo): number {
    return this.getOriginalRendering(documentInfo)?.size || 0;
  }

  hasPendingChecks(documentInfo: DocumentInfo): boolean {
    const pendingChecks = documentInfo?.pendingChecks || [];
    const hasPendingChecks = pendingChecks.length > 0;
    return hasPendingChecks;
  }

  hasFailedChecks(documentInfo: DocumentInfo): boolean {
    const failedChecks = documentInfo?.failedChecks || [];
    const hasFailedChecks = failedChecks.length > 0;
    return hasFailedChecks;
  }

  getConvertedPdfSize(documentInfo: DocumentInfo): number {
    return this.getConvertedPdfRendering(documentInfo)?.size || 0;
  }

  isConvertedPdfStatusPending(documentInfo: DocumentInfo): boolean {
    return this.getConvertedPdfStatus(documentInfo) === RenderingStatus.Pending;
  }

  isConvertedPdfStatusReady(documentInfo: DocumentInfo): boolean {
    return this.getConvertedPdfStatus(documentInfo) === RenderingStatus.Ready;
  }

  isConvertedPdfStatusError(documentInfo: DocumentInfo): boolean {
    return this.getConvertedPdfStatus(documentInfo) === RenderingStatus.Error;
  }

  getFileUploadState(documentInfo?: DocumentInfo): FileUploadState {
    const originalRenderingStatus: RenderingStatus = this.getOriginalStatus(
      documentInfo!,
    );
    const convertedPdfRenderingStatus: RenderingStatus =
      this.getConvertedPdfStatus(documentInfo!);
    const hasPendingChecks: boolean = this.hasPendingChecks(documentInfo!);
    const hasFailedChecks: boolean = this.hasFailedChecks(documentInfo!);

    return !documentInfo
      ? FileUploadState.Unknown
      : originalRenderingStatus === RenderingStatus.Pending
        ? FileUploadState.Uploading
        : hasPendingChecks && !hasFailedChecks
          ? FileUploadState.Validating
          : hasFailedChecks
            ? FileUploadState.Failed
            : convertedPdfRenderingStatus !== RenderingStatus.Ready
              ? FileUploadState.Converting
              : FileUploadState.Done;
  }
}
