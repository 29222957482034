import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  QueryList,
  ViewChildren,
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import {
  FieldCategory,
  PhoneViewModel,
  PhoneSpec,
  TextFieldDefinition,
} from '@fsx/fsx-shared';
import {
  FormArrayWithModel,
  FormControlWithModel,
} from '../../models/form-control.model';
import {
  PhoneFormGroup,
  SelectionFieldDefinition,
  SelectionFieldType,
} from '../../types/form-control.types';
import { FsxBaseComponent } from '../base/base.component';
import {
  FsxBasicSingleSelectionComponent,
  FsxTextComponent,
  ReferenceResolver,
} from '../../../public-api';

@Component({
  selector: 'fsx-phone-component',
  templateUrl: './phone.component.html',
  styleUrls: ['./phone.component.scss'],
})
export class FsxPhoneComponent extends FsxBaseComponent implements OnInit {
  @Input() phoneComponentSpec!: PhoneSpec | null;
  @Input() resolver!: ReferenceResolver;
  @Input() initialValues: PhoneViewModel[] = [];
  @Input() editMode!: boolean;

  @Output() formArrayEmitter = new EventEmitter<
    FormArrayWithModel<FormGroup<PhoneFormGroup>>
  >(true);

  @ViewChildren('categoryField')
  categoryFields!: QueryList<FsxBasicSingleSelectionComponent>;
  @ViewChildren('phoneNumberField')
  phoneNumberFields!: QueryList<FsxTextComponent>;

  public phoneFormArray!: FormArrayWithModel<FormGroup<PhoneFormGroup>>;
  public showAddNewForm = true;
  public fieldType = FieldCategory;
  public selectionType =
    SelectionFieldType.PhoneCategorySelectionFieldDefinition;

  public ngOnInit(): void {
    let phoneFormGroups: FormGroup<PhoneFormGroup>[] = [];

    if (this.initialValues.length) {
      phoneFormGroups = this.initialValues.map(
        () => new FormGroup<PhoneFormGroup>({} as PhoneFormGroup),
      );
    } else {
      const minRequired = this.phoneComponentSpec
        ? this.phoneComponentSpec.minRequired
        : 0;
      for (let i = 0; i < minRequired; i++) {
        phoneFormGroups.push(
          new FormGroup<PhoneFormGroup>({} as PhoneFormGroup),
        );
      }
    }

    this.phoneFormArray = new FormArrayWithModel<FormGroup<PhoneFormGroup>>(
      [...phoneFormGroups],
      {
        minRequired: this.phoneComponentSpec?.minRequired ?? 0,
        maxAllowed: this.phoneComponentSpec?.maxAllowed ?? 0,
      },
    );

    this.formArrayEmitter.emit(this.phoneFormArray);
  }

  public setControl(
    control:
      | FormControlWithModel<SelectionFieldDefinition>
      | FormControlWithModel<TextFieldDefinition>,
    controlName: keyof PhoneFormGroup,
    index: number,
  ): void {
    const formGroup = this.getPhoneFormGroup(index);
    formGroup.setControl(controlName, control);
  }

  public getPhoneFormGroup(index: number): FormGroup<PhoneFormGroup> {
    let formGroup = this.phoneFormArray.at(index) as FormGroup<PhoneFormGroup>;
    if (!formGroup) {
      formGroup = new FormGroup<PhoneFormGroup>({} as PhoneFormGroup);
    }
    return formGroup;
  }

  public delete(index: number): void {
    if (this.phoneFormArray.disabled) {
      return;
    }

    this.phoneFormArray.removeAt(index);
    this.initialValues.splice(index, 1);
  }

  public addNewForm(): void {
    if (this.phoneFormArray.enabled) {
      this.phoneFormArray.push(
        new FormGroup<PhoneFormGroup>({} as PhoneFormGroup),
      );
    }
  }

  public validate(): void {
    if (this.phoneFormArray.controls.length) {
      if (this.shouldValidateFormArray(this.phoneFormArray)) {
        this.categoryFields.toArray().forEach((field) => {
          field.validate();
        });
        this.phoneNumberFields.toArray().forEach((field) => {
          field.validate();
        });
      }
    }
  }
}
