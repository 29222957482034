import { Injectable, InjectionToken } from '@angular/core';
import { BehaviorSubject, Observable, map } from 'rxjs';
import { serverValidationErrorCode } from './validation-result-mapping.service';
import { ValidationGroup } from './validation-group-errors.service';

/**
 * A type to specify the possible formSectionName metadata values. These are used to
 * map validation errors back to form sections where they can be resolved in the ui.
 */
export type ParticipantFormSectionName =
  | 'Address'
  | 'Phone'
  | 'Email'
  | 'Alias';

export type RepresentationGridSectionName = 'Representation';

export type DocumentsFormSectionName = 'Document Type' | 'Associated Party';

/**
 * A union of all of the possible formSectionName metadata values.
 */
export type FormSectionNames =
  | ParticipantFormSectionName
  | RepresentationGridSectionName
  | DocumentsFormSectionName;

/**
 * The metadata properties which add more context to where a validation error
 * message came from and to which form section it relates.
 */
export interface ValidationErrorMetadata {
  /**
   * The id of the entity to which the error relates.
   * This is the id that is used to clear any erorrs for an entity being deleted.
   * (anything entity-related at all should habe this set)
   */
  entityId?: string;

  /**
   * The id of the parant entity to which the error relates.
   * This allows us to relate Address/Phone/Email validation errors back to their source.
   * Anything with a parentEntityId should not display in the checklist.
   */
  parentEntityId?: string;

  /**
   * The form section to which the error relates.
   * This is used to display validation indicator next to the section label.
   */
  formSectionName?: FormSectionNames;
}

/**
 * The data structure of the object to store in the ValidationErrorService.
 */
export interface ValidationError {
  /**
   * The code to identify the error so that it can be removed
   * on successful revalidation.
   */
  errorCode: string;

  /**
   * The message to display in the checklist.
   */
  errorMessage: string;

  /**
   * The validation group that the error resides in.
   */
  group: ValidationGroup;

  /**
   * An optional object containing additional data about where the
   * error message originated from and to what it relates.
   */
  metadata?: ValidationErrorMetadata;
}

/**
 * The InjectionToken to use in the providers array to specify a concrete-implementation
 * of the IValidationErrorsService to use at runtime.
 */
export const FsxValidationErrorsService =
  new InjectionToken<IValidationErrorsService>('FsxValidationErrorsService');

/**
 * A blueprint for a state service, which stores validation errors
 */
export interface IValidationErrorsService {
  /**
   * The validationErrors exposed as an Observable
   */
  validationErrors$: Observable<ValidationError[]>;

  /**
   * The general high-level validation errors. These are errors that are NOT specific to
   * anything within an entity (e.g. Address/Phone/Email). These are used to populate the
   * checklist.
   */
  generalValidationErrors$: Observable<ValidationError[]>;

  /**
   * A method to allow setting of the validation errors
   */
  setValidationErrors(validationErrors: ValidationError[]): void;

  /**
   * A method to allow adding of a validation error
   */
  addValidationError(validationError: ValidationError): void;

  /**
   * A method to allow removal of a validation error by error code
   * (AND parentEntityId when supplied)
   */
  removeValidationError(errorCode: string, parentEntityId?: string): void;

  /**
   * A method to allow for the quick removal of server validation errors.
   */
  removeServerValidationErrors(): void;

  /**
   * A method to allow for the quick removal of entity validation errors.
   *
   * @param entityId The id of the entity to remove validation errors for.
   *
   */
  removeEntityValidationErrors(entityId: string): void;

  /**
   * A method to allow retrieval of validation error messages by group.
   *
   * @param group The validation group to get the validation errors for.
   */
  getValidationErrorsForGroup(
    group: ValidationGroup,
  ): Observable<ValidationError[]>;

  /**
   * A method to allow retrieval of validation error messages for a given entity id.
   *
   * @param parentEntityId The id of the entity to get the errors for.
   */
  getValidationErrorsForEntity(
    parentEntityId: string,
  ): Observable<ValidationError[]>;

  /**
   * A method to allow retrieval of validation error messages for a given form section name.
   *
   * @param parentEntityId The id of the entity to get the errors for.
   * @param formSectionName The name of the form section to get the errors for.
   */
  getValidationErrorsForFormSection(
    parentEntityId: string,
    formSectionName: string,
  ): Observable<ValidationError[]>;
}

/**
 * A concrete implementation of a state service, which validation errors
 */
@Injectable()
export class ValidationErrorsService implements IValidationErrorsService {
  /**
   * The validation errors stored in a BehaviorSubject so that they can be easily
   * exposed as an Observable.
   */
  private readonly validationErrors$$ = new BehaviorSubject<ValidationError[]>(
    [],
  );

  /**
   * The validation errors exposed as an Observable
   */
  validationErrors$: Observable<ValidationError[]> =
    this.validationErrors$$.asObservable();

  /**
   * The general high-level validation errors. These are errors that are NOT specific to
   * anything within an entity (e.g. Address/Phone/Email). These are used to populate the
   * checklist.
   */
  generalValidationErrors$: Observable<ValidationError[]> =
    this.validationErrors$.pipe(
      map((validationErrors: ValidationError[]) => {
        // Filter out any entity-specific validation errors here.
        return validationErrors.filter((validationErrors: ValidationError) => {
          return validationErrors.metadata?.parentEntityId === undefined;
        });
      }),
    );

  /**
   * A method to allow setting of the validation errors
   */
  setValidationErrors(validationErrors: ValidationError[]): void {
    this.validationErrors$$.next(validationErrors);
  }

  /**
   * A method to allow adding of a validation error
   */
  addValidationError(validationError: ValidationError): void {
    const filteredValidationErrors: ValidationError[] =
      this.filterValidationErrorFromArray(
        validationError.errorCode,
        validationError.metadata?.parentEntityId,
      );
    this.validationErrors$$.next([
      ...filteredValidationErrors,
      validationError,
    ]);
  }

  /**
   * A method to allow removal of a validation error by error code
   * (AND parentEntityId when supplied)
   */
  removeValidationError(errorCode: string, parentEntityId?: string): void {
    const filteredValidationErrors: ValidationError[] =
      this.filterValidationErrorFromArray(errorCode, parentEntityId);
    this.validationErrors$$.next([...filteredValidationErrors]);
  }

  /**
   * A private helper method to filter out any related validation error(s).
   *
   * @param errorCode The error code to match on.
   * .
   * @param parentEntityId An optional parentEntityId to match on.
   *
   * @returns The validation errors not related to the provided parameters.
   */
  private filterValidationErrorFromArray(
    errorCode: string,
    parentEntityId?: string,
  ): ValidationError[] {
    return this.validationErrors$$.value.filter(
      (validationError: ValidationError) => {
        const isSameEntityId =
          validationError.metadata?.parentEntityId == parentEntityId;
        const isSameErrorCode = validationError.errorCode === errorCode;
        return parentEntityId
          ? !isSameEntityId || (isSameEntityId && !isSameErrorCode)
          : !isSameErrorCode;
      },
    );
  }

  /**
   * A method to allow for the quick removal of server validation errors.
   */
  removeServerValidationErrors(): void {
    const filteredValidationErrors: ValidationError[] =
      this.validationErrors$$.value.filter(
        (validationError: ValidationError) => {
          return !validationError.errorCode.includes(serverValidationErrorCode);
        },
      );
    this.validationErrors$$.next([...filteredValidationErrors]);
  }

  /**
   * A method to allow for the quick removal of entity validation errors.
   *
   * @param entityId The id of the entity to remove validation errors for.
   *
   */
  removeEntityValidationErrors(entityId: string): void {
    const filteredValidationErrors: ValidationError[] =
      this.validationErrors$$.value.filter(
        (validationError: ValidationError) => {
          return validationError.metadata?.entityId !== entityId;
        },
      );
    this.validationErrors$$.next([...filteredValidationErrors]);
  }

  /**
   * A method to allow retrieval of validation error messages by group.
   *
   * @param group The validation group to get the validation errors for.
   */
  getValidationErrorsForGroup(
    group: ValidationGroup,
  ): Observable<ValidationError[]> {
    return this.generalValidationErrors$.pipe(
      map((validationErrors: ValidationError[]) => {
        return validationErrors.filter((validationError) => {
          return validationError.group.groupName === group.groupName;
        });
      }),
    );
  }

  /**
   * A method to allow retrieval of validation error messages for a given entity id.
   *
   * @param parentEntityId The id of the entity to get the errors for.
   */
  getValidationErrorsForEntity(
    parentEntityId: string,
  ): Observable<ValidationError[]> {
    return this.validationErrors$.pipe(
      map((validationErrors: ValidationError[]) => {
        return validationErrors.filter((validationError) => {
          return validationError.metadata?.parentEntityId === parentEntityId;
        });
      }),
    );
  }

  /**
   * A method to allow retrieval of validation error messages for a given form section name.
   *
   * @param parentEntityId The id of the entity to get the errors for.
   * @param formSectionName The name of the form section to get the errors for.
   */
  getValidationErrorsForFormSection(
    parentEntityId: string,
    formSectionName: string,
  ): Observable<ValidationError[]> {
    return this.validationErrors$.pipe(
      map((validationErrors: ValidationError[]) => {
        return validationErrors.filter((validationError) => {
          const isSameParentEntityId =
            validationError.metadata?.parentEntityId === parentEntityId;
          const isSameFormSectionName =
            validationError.metadata?.formSectionName === formSectionName;
          return isSameParentEntityId && isSameFormSectionName;
        });
      }),
    );
  }
}
