import { Component, Input, OnChanges } from '@angular/core';
import { FormControlWithModel } from '../../models';
import { FieldDefinition } from '@fsx/ui-components';

@Component({
  selector: 'fsx-new-text',
  templateUrl: './new-text.component.html',
  styleUrls: ['./new-text.component.scss'],
})
export class FsxNewTextComponent implements OnChanges {
  /**
   * The FormControl as created upfront by the AdditionalFieldsFormService.
   */
  @Input() formControl!: FormControlWithModel<FieldDefinition>;

  /**
   * The label text to display above the control.
   */
  @Input() caption!: string;

  /**
   * The tooltip text to display on hover.
   */
  @Input() helpText!: string;

  /**
   * The placeholder text.
   */
  @Input() hint: string = '';

  /**
   * The width of the control.
   */
  @Input() width!: string;

  /**
   * The height of the control.
   */
  @Input() height: string = '63px';

  /**
   * The control's visibility.
   */
  @Input() visible: boolean = true;

  /**
   * The boolean indicating whether this is a required field.
   */
  @Input() required: boolean = false;

  /**
   * The boolean indicating whether the field is readonly.
   */
  @Input() readonly: boolean = true;

  /**
   * the boolean indicating whether the text should wrap.
   */
  @Input() overflowWrap = false;

  /**
   * The maximum allowed number of characters.
   */
  @Input() maxLength: number = 200;

  /**
   * a boolean input binding to allow consuming components to set the enabled/disabled
   * state of this text component.
   */
  @Input() disabled = false;

  /**
   * The Angular lifecycle hook where we watch for changed of the disabled property
   * to determine whether to enable/disable the control.
   */
  ngOnChanges() {
    // Note: this causes valueChanges listener to emit and set a default value on first load.
    this.enableOrDisableFormControl();
  }

  /**
   * A helper function to set the formControl's enable or disable state based
   * on the incoming [disabled] input binding.
   */
  private enableOrDisableFormControl() {
    this.disabled ? this.formControl.disable() : this.formControl.enable();
  }

  /**
   * A public method to allow the text to be cleared from container components
   */
  clearText(): void {
    if (this.formControl.disabled) {
      return;
    }

    this.formControl.setValue(null);
    this.formControl.markAsPristine();
  }

  /**
   * A public method to retrieve the first error message in the collection
   * of ValidationErrors on the FormControl.
   *
   * @returns The error message as a string.
   */
  getErrorMessage(): string {
    return Object.values({ ...this.formControl.errors })[0];
  }

  /**
   * A handler method for the input control's (focus) event, used here to
   * force the value to be formatted when the user clicks/tabs into the
   * control.
   */
  onFocus() {
    this.formatValue();
  }

  /**
   * A helper methpd to format the string vakue held in the form control,
   * in this case we're removing white space using the trim() function.
   */
  private formatValue(): void {
    const value: string = this.formControl.value;
    if (value && typeof value === 'string') {
      this.formControl.setValue(value.trim());
    }
  }

  /**
   * A public method for force validating the FormControl from parent
   * container components.
   */
  validate(): void {
    this.formControl.markAsTouched();
    this.formControl.markAsDirty();
    this.formControl.updateValueAndValidity({ emitEvent: false });
  }
}
