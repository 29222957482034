import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Inject,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  ViewChild,
} from '@angular/core';
import {
  ContactListItemViewModel,
  FsxContactApiService,
  IContactApiService,
  ParticipantSpec,
} from '@fsx/fsx-shared';
import { Sort as MaterialSort } from '@angular/material/sort';
import {
  FsxSelectedContactsService,
  ISelectedContactsService,
} from '../contacts-list/selected-contacts.service';
import {
  FsxContactsListService,
  IContactsListService,
} from '../contacts-list/contacts-list.service';
import {
  FsxContactsListTwoService,
  IContactsListTwoService,
} from './contacts-list-two.service';
import { ContactsSearchTypeEnum } from '../contacts.model';
import {
  BehaviorSubject,
  Observable,
  Subject,
  combineLatest,
  map,
  of,
  takeUntil,
  tap,
} from 'rxjs';

/**
 * The config object for the contacts list component.
 */
export interface ContactsListConfig {
  /**
   * An enum to determine whether this is a contact or attorney search.
   */
  searchType: ContactsSearchTypeEnum;

  /**
   * The ParticipantSpec objects to derive the "Party Type" / "Attorney Type" dropdown
   * options from.
   */
  participantSpecs: ParticipantSpec[];

  /**
   * The callback function to run when user clicks the add button. This is
   * specified by the calling component so that it can be handled in different
   * contexts.
   *
   * @param contactSummaries The selected contact summaries.
   */
  addCallback?: (contactSummaries: ContactListItemViewModel[]) => void;
}

/**
 * The structure of the derived view model object
 */
export interface ContactsListViewModel {
  /**
   * The list of available contact summaries.
   */
  availableContacts: ContactListItemViewModel[];

  /**
   * The list of selected contact summaries.
   */
  selectedContacts: ContactListItemViewModel[];

  /**
   * The id of the contact that has been expanded.
   */
  expandedContactId: string;
}

@Component({
  selector: 'fsx-contacts-list-two',
  templateUrl: './contacts-list-two.component.html',
  styleUrls: ['./contacts-list-two.component.scss'],
})
export class ContactsListTwoComponent
  implements AfterViewInit, OnDestroy, OnChanges
{
  /**
   * The search type, used here to toggle the display of the  Client Number / Bar Number
   * columns for Conatcts / attorneys respectively.
   */
  @Input() searchType!: ContactsSearchTypeEnum;

  /**
   * The collection of ContactSummary objects to display.
   */
  @Input() contactSummaries!: ContactListItemViewModel[];

  /**
   * The ParticipantSpec objects to derive the "Party Type" / "Attorney Type" dropdown
   * options from.
   */
  @Input() participantSpecs!: ParticipantSpec[];

  /**
   * The collection of user-selected ContactSummary objects to emit back up
   * to the parent component. This is used by the ContactsListPanel component
   * to enable/disable the "Add" button based on the number of selections.
   */
  @Output() selectedContactsChangedEvent = new EventEmitter<
    ContactListItemViewModel[]
  >();

  /**
   * An output event to trigger opening of the contact form from the parent component.
   * We use an event here so that the parent (panel component) can hook into the
   * orchestration's in progress stream to display the loading indicator.
   */
  @Output() editContactEvent = new EventEmitter<string>();

  /**
   * A reference to the outer contact list container div which we need to set the
   * scroll position when reloading the list.
   */
  @ViewChild('container') private container!: ElementRef<HTMLElement>;

  /**
   * A subject to trigger the tearing donw of the subscriptions when the component is destroyed.
   */
  private destroy$: Subject<void> = new Subject();

  /**
   * The derived view model object to pass to the view
   */
  vm$!: Observable<ContactsListViewModel>;

  /**
   * The column names for the material grid, expressed as an array of strings.
   */
  displayedColumns: string[] = [
    'select-contact',
    'caption',
    'type',
    'primary-email',
    'client-or-bar-number',
    'hover-icons',
  ];

  /**
   * The search type enum to use in the template. Used to toggle the display of the
   * Client Number / Bar Number columns for Conatcts / attorneys respectively.
   */
  searchTypeEnum = ContactsSearchTypeEnum;

  /**
   * A variable to hold the id of the selected row, used to determined the
   * expanded/collapse state of each row. Empty string when no row is selected.
   */
  expandedRowId: string = '';

  /**
   * The "select all" checkbox state variable.
   */
  isAllSelected: boolean = false;

  /**
   * The id of the expanded contact stored in a BehaviorSubject. We use this to trigger
   * the loading of the contact detail when (and only when) the row is expanded.
   */
  private expandedContactId$$ = new BehaviorSubject<string>('');

  constructor(
    @Inject(FsxContactsListService)
    readonly contactsListService: IContactsListService,
    @Inject(FsxContactsListTwoService)
    readonly contactsListTwoService: IContactsListTwoService,
    @Inject(FsxSelectedContactsService)
    readonly selectedContactsService: ISelectedContactsService,
    @Inject(FsxContactApiService)
    readonly contactApiService: IContactApiService,
  ) {}

  ngOnChanges(): void {
    this.deriveViewModel();
  }

  /**
   * A method to derive the view model object from the incoming contact summary lists.
   */
  private deriveViewModel(): void {
    this.vm$ = combineLatest([
      of(this.contactSummaries),
      this.selectedContactsService.selectedContacts$,
      this.expandedContactId$$,
    ]).pipe(
      map(([contactSummaries, selectedContacts, expandedContactId]) => {
        // Use the selected contact ids to filter out selected contacts from the available list.
        const selectedContactIds = selectedContacts.map((c) => c.id);
        const availableContacts = contactSummaries.filter((c) => {
          const isSelected = selectedContactIds.includes(c.id);
          return !isSelected;
        });

        // Return the view model object.
        return { availableContacts, selectedContacts, expandedContactId };
      }),
    );
  }

  /**
   * The lifecycle hook where we setup a scroll position listener, which we use
   * to set teh scroll position on the contact list container when the list is reloaded.
   */
  ngAfterViewInit(): void {
    this.contactsListTwoService.scrollPosition$
      .pipe(
        takeUntil(this.destroy$),
        tap((scrollPosition: number) => {
          const container = this.container.nativeElement;
          if (!container) {
            throw new Error('Scroll container was not found');
          }
          container.scroll(0, scrollPosition);
        }),
      )
      .subscribe();
  }

  /**
   * The lifecycle hook where we teardown inner subscriptions with the component.
   */
  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  /**
   * A handler function for the material table's matSortChange event.
   * Here we convert the sort.active property into a column name that the
   * API can use to sort on.
   *
   * @param sort
   */
  onSortChanged(sort: MaterialSort) {
    let column: string = 'caption';
    if (sort) {
      if (sort.active === 'caption') {
        column = 'caption';
      } else if (sort.active === 'type') {
        column = 'type';
      } else if (sort.active === 'primary-email') {
        column = 'primaryEmailAddress';
      } else if (sort.active === 'client-or-bar-number') {
        column = 'clientNameText';
      }
    }

    // Set the sort in the service to trigger the re-load.
    this.contactsListTwoService.setSort(column);
  }

  /**
   * A handler function for the contact checkbox click event.
   *
   * @param contact The ContactSummary object that was checked.
   */
  onContactCheckboxClicked(contact: ContactListItemViewModel): void {
    // Immediately toggle the contact's isSelected value.
    contact.isSelected = !contact.isSelected;

    // Use the new isSelected value to either add or remove the
    // selected contact from the selected contacts service.
    if (contact.isSelected) {
      this.selectedContactsService.addSelectedContact(contact);
    } else {
      this.selectedContactsService.removeSelectedContact(contact);
    }
  }

  /**
   * A handler function for the "select all" checkbox click event.
   */
  onSelectAllCheckboxClicked(): void {
    this.contactsListTwoService.selectAll();
  }

  /**
   * A handler function for the "unselect all" checkbox click event.
   */
  onUnselectAllCheckboxClicked(): void {
    this.contactsListTwoService.unselectAll();
  }

  /**
   * A handler method for the conatct detail component's editContactEvent.
   * When invoked we proceed by opening the Contact Form Panel.
   *
   * @param row The row which the clicked edit button resides.
   * @param rowIndex The index of the row on which the clicked edit button resides.
   */
  editContactEventHandler(row: ContactListItemViewModel, rowIndex: number) {
    this.contactsListService.queryConfig.selectedRow = rowIndex;
    this.contactsListService.setSelectedContactId(row.id);
    this.editContactEvent.emit(row.id);
  }

  /**
   * A handler function for the infinite scroll's scrolled event.
   */
  onScrollDown() {
    this.contactsListTwoService.nextPage();
  }

  /**
   * A handler function for expanding/collapsing each row.
   *
   * @param event The click event to stop propogation through the DOM
   * @param row The row that the clicked icon was on, used to set the id.
   */
  onToggleExpandRowClicked(event: Event, row: ContactListItemViewModel) {
    event.stopPropagation();
    this.expandedRowId = this.expandedRowId === row.id ? '' : row.id;
    this.expandedContactId$$.next(this.expandedRowId);
  }
}
