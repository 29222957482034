<div class="details-container">
  <div class="table-detail-section">
    <div class="table-row basic">
      <div class="caption">{{ summary.courtName }}</div>
      <div class="label">Court</div>
    </div>
    <div class="table-row basic" *ngFor="let detail of classification">
      <div class="caption">{{ detail.caption }}</div>
      <div class="label">{{ detail.label }}</div>
    </div>
  </div>

  <ng-container *ngIf="combinedFilingData$ | async as cfd">
    <ng-container
      *ngIf="cfd.filingProfile.allowsProposingCaseName; else caseNameReadOnly"
    >
      <fsx-textbox-component
        #caseNameField
        [caption]="'Case Name'"
        [width]="'668px'"
        [height]="'60px'"
        [initialValues]="[cfd.filing.courtCases[0].title ?? '']"
        [fieldDefinitions]="[caseNameFieldDefinition]"
        (textChanged)="onCaseNameTextChanged($event)"
      >
      </fsx-textbox-component>
    </ng-container>
    <ng-template #caseNameReadOnly>
      <fsx-text-component
        #caseNameField
        [caption]="'Case Name'"
        [width]="'668px'"
        [height]="'60px'"
        [initialValue]="cfd.filing.courtCases[0].title ?? '-'"
        [fieldDefinition]="caseNameFieldDefinition"
      ></fsx-text-component>
    </ng-template>
  </ng-container>

  <fsx-text-component
    #clientMatterField
    [caption]="'Client/Matter Number'"
    [width]="'256px'"
    [initialValue]="filing.courtCases[0].clientMatterKey"
    [fieldDefinition]="clientNumberFieldDefinition"
  ></fsx-text-component>

  <fsx-currency-component
    *ngIf="amountInControversyFieldDefinition"
    [caption]="'Amount In Controversy'"
    [helpText]="''"
    [hint]="''"
    [fieldType]="fieldType"
    [width]="'256px'"
    [fieldDefinition]="amountInControversyFieldDefinition"
    [initialValue]="amountInControversyInitialValue"
    (formControlEmitter)="setAmountInControversyFormControl($event)"
  ></fsx-currency-component>

  <div *ngIf="additionalFieldsVm$ | async as additionalFieldsVm">
    <!-- <pre>{{ additionalFieldsVm.additionalFieldValues | json }}</pre> -->
    <fsx-additional-fields-container
      [additionalFieldSpecs]="additionalFieldsVm.additionalFieldSpecs"
      [additionalFieldValues]="additionalFieldsVm.additionalFieldValues"
      [resolver]="resolver"
      (additionalFieldValuesEvent)="onAdditionalFieldValuesEmitted($event)"
    >
    </fsx-additional-fields-container>
  </div>
</div>
