import { formatNumber } from '@angular/common';
import { FieldCategory } from '@fsx/fsx-shared';

interface FormatOptions {
  allowDecimals?: number | boolean;
}

// https://angular.io/api/common/formatNumber
export function formatValue(
  value: string | number | null,
  type: string,
  options?: FormatOptions,
): string {
  switch (type) {
    case FieldCategory.Selection: {
      if (typeof value === 'number') {
        if (value.toString().includes('.') && options?.allowDecimals) {
          return formatNumber(value, 'en-US', '1.1-2');
        } else {
          return formatNumber(Math.floor(value), 'en-US', '1.0-0');
        }
      }
      return '0';
    }

    case FieldCategory.Currency: {
      if (typeof value === 'number') {
        if (options?.allowDecimals) {
          return formatNumber(value, 'en-US', '1.2-2');
        } else {
          return formatNumber(Math.floor(value), 'en-US', '1.0-0');
        }
      }
      return value ?? '0.00';
    }

    default:
      return `${value}`;
  }
}
