import { HttpClient } from '@angular/common/http';
import { Inject, Injectable, InjectionToken } from '@angular/core';
import {
  DocumentInfo,
  EnvConfig,
  GetUriResult,
  ImportDocumentRequest,
} from '@fsx/fsx-shared';
import { ENV_CONFIG_VALUE } from '@fsx/fsx-shared';
import { IUploadedFile } from '@fsx/ui-components';
import { Observable, catchError, of, shareReplay, timeout } from 'rxjs';

export const FsxDocumentApiService = new InjectionToken<IDocumentsApiService>(
  'FsxDocumentApiService',
);

export interface IDocumentsApiService {
  createDocumentInfo(
    filingId: string,
    uploadedFile: IUploadedFile,
  ): Observable<DocumentInfo>;

  fetchSasToken(filingId: string): Observable<GetUriResult>;

  importUploadedDocument(
    file: File,
    uploadUri: string,
    filingId: string,
    documentId: string,
  ): Observable<DocumentInfo>;

  importDocument(
    file: File,
    uploadUri: string,
    filingId: string,
  ): Observable<DocumentInfo>;

  getDocuments(filingId: string): Observable<DocumentInfo[]>;
}

@Injectable()
export class DocumentsApiService implements IDocumentsApiService {
  public constructor(
    @Inject(ENV_CONFIG_VALUE) private envConfigValue: EnvConfig,
    private readonly http: HttpClient,
  ) {}

  createDocumentInfo(
    filingId: string,
    uploadedFile: IUploadedFile,
  ): Observable<DocumentInfo> {
    const url = this.envConfigValue.Endpoints.document.createDocument(filingId);
    return this.http
      .post<DocumentInfo>(url, {
        displayName: uploadedFile.file.name.replace(/\.(?:.(?!\.))+$/gim, ''),
        originalFileName: uploadedFile.file.name,
        contentType: uploadedFile.file.type,
      })
      .pipe(shareReplay());
  }

  fetchSasToken(filingId: string): Observable<GetUriResult> {
    const url =
      this.envConfigValue.Endpoints.document.getDocumentAccessToken(filingId);
    return this.http.get<GetUriResult>(url).pipe(shareReplay());
  }

  importUploadedDocument(
    file: File,
    uploadUri: string,
    filingId: string,
    documentId: string,
  ): Observable<DocumentInfo> {
    const documentToCreate = this.getDocumentToImport(file, uploadUri);
    const url = this.envConfigValue.Endpoints.document.importUploadedDocument(
      filingId,
      documentId,
    );
    return this.http.post<DocumentInfo>(url, documentToCreate);
  }

  importDocument(
    file: File,
    uploadUri: string,
    filingId: string,
  ): Observable<DocumentInfo> {
    const documentToCreate = this.getDocumentToImport(file, uploadUri);
    const url = this.envConfigValue.Endpoints.document.importDocument(filingId);
    return this.http.post<DocumentInfo>(url, documentToCreate);
  }

  private getDocumentToImport(
    file: File,
    sourceUri: string,
  ): ImportDocumentRequest {
    return {
      displayName: file.name.replace(/\.(?:.(?!\.))+$/gim, ''),
      originalFileName: file.name,
      sourceUri: sourceUri,
      contentType: file.type,
    };
  }

  getDocuments(filingId: string): Observable<DocumentInfo[]> {
    const url =
      this.envConfigValue.Endpoints.document.getDocumentInfoCollection(
        filingId,
      );
    return this.http.get<DocumentInfo[]>(url).pipe(
      timeout(5000),
      catchError(() => {
        console.error(`Error Loading Documents (filingId: ${filingId})`);
        return of([]);
      }),
    );
  }
}
