<fsx-participant-component
  #participantField
  *ngIf="combinedFilingData$ | async as combinedFilingData"
  [initialValues]="
    additionalFieldValue && additionalFieldValue.participantValues
      ? additionalFieldValue.participantValues
      : []
  "
  [caption]="additionalFieldSpec.caption"
  [helpText]="additionalFieldSpec.helpText ?? ''"
  [hint]="additionalFieldSpec.hint ?? ''"
  [combinedFilingData]="combinedFilingData"
  [resolver]="resolver"
  [participantFieldDefinition]="additionalFieldSpec.participantFieldDefinition!"
  (selectedParticipantEvent)="setParticipantSelectedValue($event)"
>
</fsx-participant-component>
