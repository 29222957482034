import { Inject, Injectable, InjectionToken } from '@angular/core';
import {
  CasePartyViewModel,
  CaseRequestViewModel,
  ICaseRequestUpdateService,
  FsxCaseRequestUpdateService,
  CombinedFilingData,
  FsxCaseRequestBuilderService,
  ICaseRequestBuilderService,
} from '@fsx/fsx-shared';
import { Observable, Subject, switchMap, withLatestFrom } from 'rxjs';
import {
  FsxValidatePartiesOrchestrationService,
  IValidatePartiesOrchestrationService,
} from '../../filing-editor/services/orchestration/validate-parties-orchestration.service';
import {
  FsxCombinedFilingDataService,
  ICombinedFilingDataService,
} from '../../filing-editor/services/combined-filing-data.service';

export const FsxUpdatePartyOrchestrationService =
  new InjectionToken<IUpdatePartyOrchestrationService>(
    'FsxUpdatePartyOrchestrationService',
  );

export interface IUpdateCasePartyParams {
  /**
   * The original (unmodified) CaseParty object to which the updates are to be applied.
   */
  caseParty: CasePartyViewModel;

  /**
   * The subset of CaseParty properties to be updated.
   */
  partialCaseParty: Partial<CasePartyViewModel>;
}

export interface IUpdatePartyOrchestrationService {
  /**
   * The pipeline of orchestration steps needed to apply updates to a CaseParty object in the
   * caseRequest.parties array.
   */
  updatePartyOnCaseRequest$: Observable<CaseRequestViewModel>;

  /**
   * A public method to allow the orchestration to be triggered.
   */
  updateParty(params: IUpdateCasePartyParams): void;
}

@Injectable()
export class UpdatePartyOrchestrationService
  implements IUpdatePartyOrchestrationService
{
  /**
   * A subject to use as the trigger for the orchestration.
   */
  private updateParticipant$$ = new Subject<IUpdateCasePartyParams>();

  /**
   * The pipeline of orchestration steps needed to apply updates to a CaseParty object in the
   * caseRequest.parties array.
   */
  updatePartyOnCaseRequest$: Observable<CaseRequestViewModel> =
    this.updateParticipant$$.pipe(
      withLatestFrom(this.combinedFilingDataService.combinedFilingData$),
      switchMap(
        ([params, combinedFilingData]: [
          IUpdateCasePartyParams,
          CombinedFilingData,
        ]) => {
          const { filing, caseRequest } = combinedFilingData;
          const filingId: string = filing.id;
          const caseRequestBackup: CaseRequestViewModel = {
            ...caseRequest,
          } as CaseRequestViewModel;

          // Make the call to PATCH the participant type.
          return this.caseRequestBuilderService
            .updateCaseParty({
              caseRequest,
              ...params,
            })
            .pipe(
              switchMap(() => {
                /**
                 * When updating a participant, 99.9% of the time we will be doing so to clear errors.
                 *
                 * Here we restrict the validation to just the party being updated to clear any validation
                 * error against it and prevent any other parties from inadvertently being flagged as
                 * invalid when they have not been touched.
                 *
                 * If there are any edge cases where issuing an update to one participant could cause
                 * another party to become invalid then we would have to unrestrict the validation here.
                 */
                const inclusions = [params.caseParty.participantName];
                this.validatePartiesOrchestrationService.validateParties({
                  includedParticipantNames: inclusions,
                });

                // Make the PUt request to apply the update.
                return this.caseRequestUpdateService.optimisticPutOrRestore(
                  filingId,
                  caseRequest,
                  caseRequestBackup,
                  true,
                );
              }),
            );
        },
      ),
    );

  /**
   * @param combinedFilingDataService Needed to retrieve the CaseRequest object, which we subsequently update.
   *
   * @param caseRequestBuilderService Needed to build the CaseRequest object with the CaseParty updates.
   *
   * @param validatePartiesOrchestrationService Needed to initiate validation against CaseParty objects.
   *
   * @param caseRequestUpdateService Needed to PUT the updated CaseRequest object back to the server.
   */
  constructor(
    @Inject(FsxCombinedFilingDataService)
    private readonly combinedFilingDataService: ICombinedFilingDataService,
    @Inject(FsxCaseRequestBuilderService)
    private readonly caseRequestBuilderService: ICaseRequestBuilderService,
    @Inject(FsxValidatePartiesOrchestrationService)
    private readonly validatePartiesOrchestrationService: IValidatePartiesOrchestrationService,
    @Inject(FsxCaseRequestUpdateService)
    private readonly caseRequestUpdateService: ICaseRequestUpdateService,
  ) {}

  /**
   * A public method to allow the orchestration to be triggered.
   */
  updateParty(params: IUpdateCasePartyParams): void {
    this.updateParticipant$$.next(params);
  }
}
