import {
  Component,
  ElementRef,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import {
  TransactionDocument,
  TransactionInfo,
  TransactionInfoService,
} from '../transaction-activity/services/transaction-info.service';
import {
  CombinedFilingData,
  SharedModule,
  ICombinedFilingApiService,
  FilingApiService,
  CaseRequestSnapshot,
  RequestDocumentSnapshot,
  FsxCombinedFilingApiService,
} from '@fsx/fsx-shared';
import { NavigationStart, Router } from '@angular/router';
import {
  catchError,
  filter,
  ignoreElements,
  Observable,
  of,
  Subject,
  switchMap,
  take,
  takeUntil,
  tap,
} from 'rxjs';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import { NgxPrintModule } from 'ngx-print';
import {
  OpenPdfViewerParams,
  PdfViewerService,
} from '../../../../../libs/components/src/lib/components/pdf-viewer/pdf-viewer.service';

@Component({
  selector: 'fsx-filing-submission-receipt',
  standalone: true,
  templateUrl: './filing-submission-receipt.component.html',
  imports: [SharedModule, NgxPrintModule],
  styleUrls: ['./filing-submission-receipt.component.scss'],
  providers: [FilingApiService],
})
export class FilingSubmissionReceiptComponent implements OnInit, OnDestroy {
  @ViewChild('receipt') receiptEl: ElementRef | undefined;

  @Input() filingId!: string;

  private destroy$: Subject<void> = new Subject();

  transaction!: TransactionInfo;
  cfd!: CombinedFilingData;
  requestDocuments: RequestDocumentSnapshot[] = [];
  caseRequestSnapshot$!: Observable<CaseRequestSnapshot>;
  caseRequestSnapshot!: CaseRequestSnapshot;

  constructor(
    @Inject(FsxCombinedFilingApiService)
    private readonly combinedFilingApiService: ICombinedFilingApiService,
    private dialogRef: MatDialogRef<FilingSubmissionReceiptComponent>,
    private readonly router: Router,
    private readonly pdfViewerService: PdfViewerService,
    private readonly transactionInfoService: TransactionInfoService,
    private readonly filingApiService: FilingApiService,
  ) {}

  ngOnInit(): void {
    this.caseRequestSnapshot$ = this.combinedFilingApiService
      .getCombinedFilingData(this.filingId)
      .pipe(
        takeUntil(this.destroy$),
        switchMap((cfd: CombinedFilingData) => {
          this.cfd = cfd;
          return this.filingApiService.getCaseRequestSnapshot(
            cfd.filing.id,
            cfd.filing.submittedSnapshotKey as string,
          );
        }),
      );
    this.caseRequestSnapshot$
      .pipe(
        takeUntil(this.destroy$),
        tap((caseRequestSnapshot) => {
          this.caseRequestSnapshot = caseRequestSnapshot;
          this.requestDocuments = this.caseRequestSnapshot.documents ?? [];
          if (!!this.caseRequestSnapshot) {
            this.transaction =
              this.transactionInfoService.getTransactionInfoSnapshotVersion(
                caseRequestSnapshot,
              );
          } else {
            this.transaction = this.transactionInfoService.getTransactionInfo(
              this.cfd,
              this.requestDocuments,
            );
          }
        }),
        ignoreElements(),
        catchError((error: Error) => {
          console.error('Error retrieving combined filing data: ', error);
          return of(error);
        }),
      )
      .subscribe();

    // Close dialog ref on route changes
    this.router.events
      .pipe(
        takeUntil(this.destroy$),
        filter((event) => event instanceof NavigationStart),
        tap(() => this.onCloseButtonClicked()),
        take(1),
      )
      .subscribe();
  }

  public downloadReceipt(): void {
    if (!!this.receiptEl && !!this.receiptEl.nativeElement) {
      let receipt = this.receiptEl.nativeElement as HTMLElement;
      html2canvas(receipt).then((canvas) => {
        let PDF = new jsPDF('p', 'pt', 'a4', false);

        const pageWidth = 778;
        const pageHeight = 1120;
        const canvPgHThreshold = 595;
        const canvPgWThreshold = 842;

        for (var i = 0; i <= canvas.height / 980; i++) {
          //! This is all just html2canvas stuff
          let srcImg = canvas;
          let sX = 0;
          let sY = pageHeight * i; // start 980 pixels down for every new page
          let sWidth = pageWidth;
          let sHeight = pageHeight;
          let dX = 0;
          let dY = 0;
          let dWidth = pageWidth;
          let dHeight = pageHeight;

          const onePageCanvas = document.createElement('canvas');
          onePageCanvas.setAttribute('width', pageWidth.toString());
          onePageCanvas.setAttribute('height', pageHeight.toString());

          let ctx = onePageCanvas.getContext('2d');
          ctx?.drawImage(
            srcImg,
            sX,
            sY,
            sWidth,
            sHeight,
            dX,
            dY,
            dWidth,
            dHeight,
          );

          let canvasDataURL = onePageCanvas.toDataURL('image/png', 1.0);

          let width = onePageCanvas.width;
          let height = onePageCanvas.clientHeight;

          //! If we're on anything other than the first page,
          // add another page
          if (i > 0) {
            PDF.addPage([canvPgHThreshold, canvPgWThreshold]); //8.5" x 11" in pts (in*72)
          }
          //! now we declare that we're working on that page
          PDF.setPage(i + 1);
          //! now we add content to that page!
          PDF.addImage(canvasDataURL, 'PNG', 0, 0, width * 0.72, height * 0.72);
        }
        //! after the for loop is finished running, we save the pdf.
        PDF.save('Submission-Receipt.pdf');
      });
    }
  }

  public openPdfViewer(
    transactionDocument: TransactionDocument,
    index: number,
  ): void {
    const params: OpenPdfViewerParams = {
      filingId: this.cfd?.filing.id as string,
      documentId: transactionDocument.id,
      renderingName: transactionDocument.renderingName,
      fileName: transactionDocument.fileName,
      documentName: transactionDocument.documentName,
      documentCategory: transactionDocument.documentCategory,
      access: transactionDocument.access,
      associatedParty: '-',
      leadingDocument: '-',
      fileSize: transactionDocument.pdfSize,
      documentIndex: index,
    };
    this.pdfViewerService.openPdfViewer(params);
  }

  public onCloseButtonClicked(): void {
    this.dialogRef.close();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
