import {
  Component,
  ElementRef,
  HostListener,
  Input,
  ViewChild,
} from '@angular/core';
import { asyncScheduler } from 'rxjs';
import {
  SidePanelButtonsConfig,
  SidePanelButtonStatusEnum,
  SidePanelFooterConfig,
  SidePanelHeaderConfig,
} from '../../types/form-control.types';
import { FsxSidePanelFooterComponent } from './footer/footer.component';
import { FsxSidePanelHeaderComponent } from './header/header.component';

@Component({
  selector: 'fsx-side-panel-component',
  templateUrl: './side-panel.component.html',
  styleUrls: ['./side-panel.component.scss'],
})
export class FsxSidePanelComponent {
  @ViewChild('header') header!: FsxSidePanelHeaderComponent;
  @ViewChild('footer') footer!: FsxSidePanelFooterComponent;
  @Input() headerConfig!: SidePanelHeaderConfig;
  @Input() footerConfig!: SidePanelFooterConfig;

  @HostListener('document:keydown.escape', ['$event']) onKeydownHandler() {
    if (this.headerConfig?.exitAction) {
      this.headerConfig.exitAction();
    }
  }

  public setButtonStatus(
    button: SidePanelButtonsConfig,
    status: SidePanelButtonStatusEnum,
  ): void {
    switch (status) {
      case SidePanelButtonStatusEnum.Focused: {
        button.status = status;
        const buttonsRef = this.footer.buttons as unknown as ElementRef;
        const buttonsArray = (buttonsRef.nativeElement as HTMLElement).children;
        asyncScheduler.schedule(() => {
          const focusedButton = Array.from(buttonsArray).find((button) =>
            button.className.includes('focused'),
          );
          (focusedButton as HTMLElement)?.focus();
        });
        break;
      }
      default: {
        button.status = status;
      }
    }
  }
}
