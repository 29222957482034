import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import {
  ApiCallForOfflineQueue,
  EnvConfig,
  FsxApiSearchQuery,
  HttpMethod,
  HttpReqOptions,
  HttpResponseOptions,
  IApiService,
  ResultSet,
} from '@fsx/fsx-shared';
import { stringify } from 'qs';
import { BehaviorSubject, filter, Observable, of, Subject } from 'rxjs';
import { finalize, map, switchMap, take, tap } from 'rxjs/operators';
import { ENV_CONFIG } from '../app-config';

@Injectable({
  providedIn: 'root',
})
export class ApiService implements IApiService {
  private isDeviceOnline$: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);
  private loading$ = new BehaviorSubject<boolean>(false);
  private apiCallsQueue$: Subject<ApiCallForOfflineQueue[]> = new Subject();

  public get isDeviceOnline(): Observable<boolean> {
    return this.isDeviceOnline$.asObservable();
  }

  get loading(): boolean {
    return this.loading$.getValue();
  }

  set loading(loading: boolean) {
    this.loading$.next(loading);
  }

  public constructor(
    @Inject(ENV_CONFIG) private readonly envConfig: EnvConfig,
    private readonly http: HttpClient,
  ) {}

  buildHttpQueryParam(query: FsxApiSearchQuery): HttpParams {
    const qs = stringify(query, {
      skipNulls: true,
      arrayFormat: 'indices',
      allowDots: true,
    });
    return new HttpParams({ fromString: qs });
  }

  search<T>(
    url: string,
    params: FsxApiSearchQuery = { filters: [] },
  ): Observable<ResultSet<T>> {
    return this.post<unknown, ResultSet<T>>(url, params);
  }

  get<T>(url: string, options?: HttpReqOptions<'body', 'json'>): Observable<T>;
  get<T>(
    url: string,
    options?: HttpReqOptions<'response', 'arraybuffer' | 'blob'>,
  ): Observable<T>;
  get<T>(
    url: string,
    options?: HttpReqOptions<'response', 'blob'>,
  ): Observable<T>;
  get<T>(
    url: string,
    options?: HttpReqOptions<'response', 'arraybuffer'>,
  ): Observable<T>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  get<T>(url: string, options?: HttpReqOptions<any, any>): Observable<T> {
    this.loading = true;

    return this.addCallsToQueueIfOffline({
      url,
      body: null,
      httpOptions: options,
      httpReqMethod: HttpMethod.PATCH,
    }).pipe(
      filter((deviceOnlineMakeCall: boolean) => deviceOnlineMakeCall),
      switchMap(() => this.http.get<T>(url, options)),
      take(1),
      finalize(() => (this.loading = false)),
    );
  }

  post<B, T>(
    url: string,
    body: B,
    options: HttpReqOptions<'body', 'json'> = {},
  ): Observable<T> {
    this.loading = true;
    return this.addCallsToQueueIfOffline({
      url,
      body,
      httpOptions: options,
      httpReqMethod: HttpMethod.POST,
    }).pipe(
      filter((deviceOnlineMakeCall: boolean) => deviceOnlineMakeCall),
      switchMap(() => this.http.post<T>(url, body, options)),
      take(1),
      finalize(() => (this.loading = false)),
    );
  }

  putWithResponse<B>(
    url: string,
    body: B,
    options: HttpResponseOptions,
  ): Observable<HttpResponse<unknown> | ArrayBuffer> {
    this.loading = true;

    return this.http.put<HttpResponse<B>>(url, body, options).pipe(
      take(1),
      finalize(() => (this.loading = false)),
    );
  }

  put<T>(
    url: string,
    body: T,
    options: HttpReqOptions<'body', 'json'> = {},
  ): Observable<T> {
    this.loading = true;

    return this.addCallsToQueueIfOffline({
      url,
      body,
      httpOptions: options,
      httpReqMethod: HttpMethod.PUT,
    }).pipe(
      filter((deviceOnlineMakeCall: boolean) => deviceOnlineMakeCall),
      switchMap(() => this.http.put<T>(url, body, options)),
      take(1),
      finalize(() => (this.loading = false)),
    );
  }

  patch<B, R>(url: string, body: B, options = {}): Observable<R> {
    this.loading = true;
    return this.addCallsToQueueIfOffline({
      url,
      body,
      httpOptions: options,
      httpReqMethod: HttpMethod.PATCH,
    }).pipe(
      filter((deviceOnlineMakeCall: boolean) => deviceOnlineMakeCall),
      switchMap(() => this.http.patch<R>(url, body, options)),
      take(1),
      finalize(() => (this.loading = false)),
    );
  }

  delete<T>(
    url: string,
    options: HttpReqOptions<'body', 'json'> = {},
  ): Observable<T> {
    this.loading = true;
    return this.addCallsToQueueIfOffline({
      url,
      body: null,
      httpOptions: options,
      httpReqMethod: HttpMethod.DELETE,
    }).pipe(
      filter((deviceOnlineMakeCall: boolean) => deviceOnlineMakeCall),
      switchMap(() => this.http.delete<T>(url, options)),
      take(1),
      finalize(() => (this.loading = false)),
    );
  }

  buildHttpQueryParams(
    query: FsxApiSearchQuery = { filters: null },
  ): HttpParams {
    return this.buildHttpQueryParam(query);
  }

  private addCallsToQueueIfOffline(
    offlineRequest: ApiCallForOfflineQueue,
  ): Observable<boolean> {
    if (!navigator.onLine) {
      const body = offlineRequest.body;
      const addToOfflineQueue: ApiCallForOfflineQueue = {
        body,
        httpOptions: offlineRequest.httpOptions,
        httpReqMethod: offlineRequest.httpReqMethod,
        url: offlineRequest.url,
      };

      return this.apiCallsQueue$.pipe(
        tap((updatedApiCallsQueue: ApiCallForOfflineQueue[]) => {
          updatedApiCallsQueue.unshift(addToOfflineQueue);
          this.apiCallsQueue$.next(updatedApiCallsQueue);
        }),
        map(() => navigator.onLine),
      );
    }
    return of(navigator.onLine);
  }
}
