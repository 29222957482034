import { Component, Input, OnInit } from '@angular/core';
import {
  CourtSummary,
  CourtSummaryItemTree,
  FieldCategory,
  ProfileLists,
  StringSelectionFieldDefinition,
} from '@fsx/fsx-shared';
import { FSXFormControlService } from '../../../services';
import { DropdownOption } from '../../../types';
import { FsxBaseSearchComponent } from '../base-search/base-search.component';

@Component({
  selector: 'fsx-court-search-component',
  templateUrl: '../base-search/base-search.component.html',
  styleUrls: ['../base-search/base-search.component.scss'],
})
export class FsxCourtsSearchComponent
  extends FsxBaseSearchComponent
  implements OnInit
{
  @Input() courtData!: CourtSummaryItemTree;

  override fieldDefinition: StringSelectionFieldDefinition = {
    defaultValue: '',
    listReference: {
      list: ProfileLists.AdditionalList,
      additionalListName: '',
    },
    maxAllowed: 1,
    minRequired: 0,
    selectionDependentFields: [],
    readOnly: false,
    visible: true,
  };
  override caption = 'Search for Court';
  override hint = 'Type or Select Court';

  constructor(private readonly fsxFormControlService: FSXFormControlService) {
    super();
    this.inputFormControl = this.fsxFormControlService.createFormControl(
      this.fieldDefinition,
      FieldCategory.Selection,
      this.inputFormControl?.value || this.fieldDefinition.defaultValue,
    );
  }

  override ngOnInit(): void {
    this.dropdownOptions = this.traverseTree(this.courtData);
    this.selectionDisplayFn = this.selectionDisplayFn.bind(this);
    this.setDropdownOptionCaptions();
    this.setDropdownOptions();
  }

  public override createDropdownOption(
    courtItem: CourtSummary,
  ): DropdownOption<void> {
    const dropdownOption = {
      caption: courtItem.caption,
      name: courtItem.id,
      selected: false,
      keywords: [],
      itemPath: [],
      icon: 'location_city',
      rating: 0,
    };
    this.addWordToKeyWords(courtItem.caption, dropdownOption, false);
    return dropdownOption;
  }

  private setDropdownOptionCaptions(): void {
    this.dropdownOptions.forEach((option) => {
      if (option.itemPath && option.itemPath.length) {
        const regions = option.itemPath
          .slice(0, option.itemPath.length - 2)
          .join(' | ');
        option.secondaryCaption = regions;
        option.tertiaryCaption = option.itemPath[option.itemPath.length - 2];
      }
    });
  }
}
