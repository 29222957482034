import { Injectable } from '@angular/core';
import {
  CaseRequestViewModel,
  RequestParticipantViewModel,
} from '@fsx/fsx-shared';

@Injectable({
  providedIn: 'root',
})
export class AddParticipantService {
  public addParticipantsToCaseRequest(
    caseRequest: CaseRequestViewModel,
    participants: RequestParticipantViewModel[],
  ): void {
    const caseRequestParticipants = caseRequest.participants || [];
    caseRequestParticipants.push(...participants);
    caseRequest.participants = caseRequestParticipants;
  }
}
