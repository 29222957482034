import { Injectable } from '@angular/core';
import { CaseRequest, FILING_SUB_TABS, RequestDocument } from '@fsx/fsx-shared';
import { filter, Subject } from 'rxjs';

export enum ProfileRuleError {
  MaxLeadDocumentsAllowed = 'maxLeadDocumentsAllowed',
  MinLeadDocumentsRequired = 'minLeadDocumentsRequired',
  IsNotAllowedFileTyoe = 'isNotAllowedFileType',
  IsGreaterThanMaxFileSize = 'isGreaterThanMaxFileSize',
  IsGreaterThanFileNameMaxLength = 'isGreaterThanFileNameMaxLength',
}

export interface IFilingEditorEventParams {
  filingId: string;
}

export interface ICaseRequestUpdatedEventParams
  extends IFilingEditorEventParams {
  caseRequest: CaseRequest;
  forceLoad: boolean;
}

export interface IFileUploadStartedEventParams
  extends IFilingEditorEventParams {
  caseRequest: CaseRequest;
  requestDocument: RequestDocument;
}

export interface IFilingSubTabChangedEventParams
  extends IFilingEditorEventParams {
  filingSubTab: FILING_SUB_TABS;
}

export interface IRequestDocumentCreatedParams
  extends IFilingEditorEventParams {
  requestDocument: RequestDocument;
  documentIndex: number;
}

export interface IProfileRuleErrorParams extends IFilingEditorEventParams {
  profileRuleError: ProfileRuleError;
}

export interface IDocumentRuleErrorParams extends IProfileRuleErrorParams {
  requestDocument: RequestDocument;
  message: string;
}

@Injectable({
  providedIn: 'root',
})
export class FilingEditorErrorsService {
  private profileRuleError$$ = new Subject<IProfileRuleErrorParams>();
  profileRuleError$ = this.profileRuleError$$.asObservable();

  maxLeadDocumentsAllowedGuardConditionReached$ = this.profileRuleError$.pipe(
    filter((params: IProfileRuleErrorParams) => {
      return (
        params.profileRuleError === ProfileRuleError.MaxLeadDocumentsAllowed
      );
    }),
  );

  minLeadDocumentsRequiredGuardConditionReached$ = this.profileRuleError$.pipe(
    filter((params: IProfileRuleErrorParams) => {
      return (
        params.profileRuleError === ProfileRuleError.MinLeadDocumentsRequired
      );
    }),
  );

  notAllowedFileTypeGuardConditionReached$ = this.profileRuleError$.pipe(
    filter((params: IProfileRuleErrorParams) => {
      return params.profileRuleError === ProfileRuleError.IsNotAllowedFileTyoe;
    }),
  );

  raiseProfileRuleErrorEvent(params: IProfileRuleErrorParams) {
    this.profileRuleError$$.next(params);
  }
}
