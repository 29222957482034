import {
  Component,
  EventEmitter,
  Inject,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { AdditionalFieldSpec, AdditionalFieldValue } from '@fsx/fsx-shared';
import { FsxReferenceResolver } from 'projects/apps/fsx-ui/src/app/shared/resolvers/list-reference.resolver';
import {
  FsxAdditionalFieldsFormService,
  AdditionalFieldsFormService,
  IAdditionalFieldsFormService,
  AdditionalFieldSpecValueAndFormControl,
} from '../additional-fields-form.service';

@Component({
  selector: 'fsx-additional-fields-container',
  templateUrl: './additional-fields-container.component.html',
  styleUrls: ['./additional-fields-container.component.css'],
  providers: [
    {
      provide: FsxAdditionalFieldsFormService,
      useClass: AdditionalFieldsFormService,
    },
  ],
})
export class FsxAdditionalFieldsContainerComponent implements OnInit {
  /**
   * The collection of AdditionalFieldSpec objects from the FilingProfile object.
   */
  @Input() additionalFieldSpecs!: AdditionalFieldSpec[];

  /**
   * The collection of AdditonalFieldValue objects from the relavant part of the CaseRequest object.
   */
  @Input() additionalFieldValues!: AdditionalFieldValue[];

  /**
   * Shouldn't have to bring this in but we have to pass it onto the IAdditionalFieldsFormService
   * which uses it to create FormControl objects.
   */
  @Input() resolver!: FsxReferenceResolver;

  /**
   * An event to notify parent container component that AdditonalFieldValues have been updated.
   */
  @Output() additionalFieldValuesEvent = new EventEmitter<
    AdditionalFieldValue[]
  >();

  /**
   * A collection of AdditionalFieldSpec and FormControl objects wrapped in a single type.
   */
  arrayOfSpecAndFormControl!: AdditionalFieldSpecValueAndFormControl[];

  constructor(
    @Inject(FsxAdditionalFieldsFormService)
    readonly additionalFieldsFormService: IAdditionalFieldsFormService,
  ) {}

  /**
   * The angular lifecycle hook where we derive the collection of AdditonalFieldSpec and
   * AdditionalFieldValue objects.
   */
  ngOnInit(): void {
    // Here we delegate the deriving of the array of AdditionalFieldSpec and FormControl objects to the service.
    this.arrayOfSpecAndFormControl =
      this.additionalFieldsFormService.deriveArrayOfSpecAndFormControl(
        this.additionalFieldSpecs,
        this.additionalFieldValues,
        this.resolver,
      );
  }

  /**
   * A handler method for when the FsxAdditionalFieldControlsComponent emits an updated
   * AdditionalFieldValue object.
   *
   * @param additionalFieldValue The updated AdditionalFieldValue object.
   */
  additionalFieldValueEventHandler(
    additionalFieldValue: AdditionalFieldValue,
  ): void {
    // Filter out any pre-existing AdditionalFieldValue with same additionalFieldName.
    const filteredAdditionalFieldValues: AdditionalFieldValue[] =
      this.additionalFieldValues.filter(
        (addlFieldValue: AdditionalFieldValue) => {
          return (
            addlFieldValue.additionalFieldName !==
            additionalFieldValue.additionalFieldName
          );
        },
      );

    // Append the updated AdditionalFieldValue to the filtered collection of AdditionalFieldValue objects.
    const updatedAdditionalFieldValues: AdditionalFieldValue[] = [
      ...filteredAdditionalFieldValues,
      additionalFieldValue,
    ];

    // Notify parent container component that the collection of AdditionalFieldValues have been updated,
    this.additionalFieldValuesEvent.emit(updatedAdditionalFieldValues);
  }

  /**
   * A handler method for when the FsxAdditionalFieldControlsComponent emits its clearAdditionalFieldValues.
   *
   * @param additionalFieldNames The names of the additional fields to remove.
   */
  clearAdditionalFieldValuesEventHandler(additionalFieldNames: string[]): void {
    // Filter out any pre-existing AdditionalFieldValue objects with any of the emitted additionalFieldname strings
    const filteredAdditionalFieldValues: AdditionalFieldValue[] =
      this.additionalFieldValues.filter((afv: AdditionalFieldValue) => {
        const isMatchingAdditionalFieldName = additionalFieldNames.includes(
          afv.additionalFieldName,
        );
        return !isMatchingAdditionalFieldName;
      });

    // Notify parent container component that the collection of AdditionalFieldValues have been updated,
    this.additionalFieldValuesEvent.emit(filteredAdditionalFieldValues);
  }
}
