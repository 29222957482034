import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  QueryList,
  ViewChildren,
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import {
  EmailAddressViewModel,
  EmailAddressSpec,
  FieldCategory,
  TextFieldDefinition,
} from '@fsx/fsx-shared';
import {
  FormArrayWithModel,
  FormControlWithModel,
} from '../../models/form-control.model';
import {
  EmailFormGroup,
  SelectionFieldDefinition,
  SelectionFieldType,
} from '../../types/form-control.types';
import { FsxBaseComponent } from '../base/base.component';
import {
  FsxProfileSingleSelectionComponent,
  FsxTextComponent,
  ReferenceResolver,
} from '../../../public-api';

@Component({
  selector: 'fsx-email-component',
  templateUrl: './email.component.html',
  styleUrls: ['./email.component.scss'],
})
export class FsxEmailComponent extends FsxBaseComponent implements OnInit {
  @Input() emailComponentSpec!: EmailAddressSpec | null;
  @Input() resolver!: ReferenceResolver;
  @Input() initialValues: EmailAddressViewModel[] = [];
  @Input() editMode!: boolean;

  @Output() formArrayEmitter = new EventEmitter<
    FormArrayWithModel<FormGroup<EmailFormGroup>>
  >(true);

  @ViewChildren('categoryField')
  categoryFields!: QueryList<FsxProfileSingleSelectionComponent>;
  @ViewChildren('emailAddressField')
  emailAddressFields!: QueryList<FsxTextComponent>;

  public emailFormArray!: FormArrayWithModel<FormGroup<EmailFormGroup>>;
  public showAddNewForm = true;
  public fieldType = FieldCategory;
  public selectionType =
    SelectionFieldType.EmailCategorySelectionFieldDefinition;

  public ngOnInit(): void {
    let emailFormGroups: FormGroup<EmailFormGroup>[] = [];

    if (this.initialValues.length) {
      emailFormGroups = this.initialValues.map(
        () => new FormGroup<EmailFormGroup>({} as EmailFormGroup),
      );
    } else {
      const minRequired = this.emailComponentSpec
        ? this.emailComponentSpec.minRequired
        : 0;
      for (let i = 0; i < minRequired; i++) {
        emailFormGroups.push(
          new FormGroup<EmailFormGroup>({} as EmailFormGroup),
        );
      }
    }

    this.emailFormArray = new FormArrayWithModel<FormGroup<EmailFormGroup>>(
      [...emailFormGroups],
      {
        minRequired: this.emailComponentSpec?.minRequired ?? 0,
        maxAllowed: this.emailComponentSpec?.maxAllowed ?? 0,
      },
    );

    this.formArrayEmitter.emit(this.emailFormArray);
  }

  public setControl(
    control:
      | FormControlWithModel<SelectionFieldDefinition>
      | FormControlWithModel<TextFieldDefinition>,
    controlName: keyof EmailFormGroup,
    index: number,
  ): void {
    const formGroup = this.getEmailFormGroup(index);
    formGroup.setControl(controlName, control);
  }

  public getEmailFormGroup(index: number): FormGroup<EmailFormGroup> {
    let formGroup = this.emailFormArray.at(index) as FormGroup<EmailFormGroup>;
    if (!formGroup) {
      formGroup = new FormGroup<EmailFormGroup>({} as EmailFormGroup);
    }
    return formGroup;
  }

  public delete(index: number): void {
    if (this.emailFormArray.disabled) {
      return;
    }

    this.emailFormArray.removeAt(index);
    this.initialValues.splice(index, 1);
  }

  public addNewForm(): void {
    if (this.emailFormArray.enabled) {
      this.emailFormArray.push(
        new FormGroup<EmailFormGroup>({} as EmailFormGroup),
      );
    }
  }

  public validate(): void {
    if (this.shouldValidateFormArray(this.emailFormArray)) {
      if (this.categoryFields) {
        this.categoryFields.forEach((fld) => fld.validate());
      }
      if (this.emailAddressFields) {
        this.emailAddressFields.forEach((fld) => fld.validate());
      }
    }
  }
}
