import {
  Component,
  EventEmitter,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import {
  CaseQueryResultItem,
  CourtsApiService,
  CourtSummaryItemTree,
  CourtSummaryItemTreeTreeResult,
  FilingMode,
  FilingProfile,
  FilingProfileSummaryItemTree,
  NewFiling,
  NewFilingCase,
  RequestType,
} from '@fsx/fsx-shared';
import { DropdownOption, FsxCaseSearchComponent } from '@fsx/ui-components';
import { debounceTime, map, Subject, switchMap, tap } from 'rxjs';

@Component({
  selector: 'fsx-existing-filing',
  templateUrl: './existing-filing.component.html',
  styleUrls: ['./existing-filing.component.scss'],
})
export class ExistingFilingComponent implements OnInit {
  @Output() setNewFilingEvent = new EventEmitter<NewFiling | null>();
  @ViewChild('caseSearch') caseSearch!: FsxCaseSearchComponent;
  @ViewChild('subCaseSearch') subCaseSearch!: FsxCaseSearchComponent;
  public courtId!: string | null;
  public profileId!: string | null;
  public filingProfile!: FilingProfile | null;
  public efmKey!: string | null | undefined;
  public hasSubCase!: boolean | null;
  public subcaseId!: string | null;
  public courtSelectedDropdownOption!: DropdownOption<void> | null;
  public courtSelectedData!: CourtSummaryItemTree;
  public profileSelectedDropdownOption!: DropdownOption<void> | null;
  public profileSelectedData!: FilingProfileSummaryItemTree | null;
  public caseSelectedData: CaseQueryResultItem[] = [];
  public caseSelectedDropdownOption!: DropdownOption<void> | null;
  public subcaseSelectedData: CaseQueryResultItem[] = [];
  public subcaseSelectedDropdownOption!: DropdownOption<void> | null;
  public browsingCourts: boolean = false;
  public browsingProfiles: boolean = false;
  public courtPath!: string | null;
  public courtState!: string | null;
  public profilePath!: string | null;
  public profileCourt!: string | null;
  public attestationRequired!: boolean | undefined;
  public attestationAcepted = false;
  public attestationRejected = false;
  public attestationMessage!: string | undefined;
  public selectedCaseTitle!: string | undefined;
  public selectedCase!: CaseQueryResultItem | undefined;
  public isLoading = false;

  public caseSearchText$$ = new Subject<string>();

  getCourtCases$ = this.caseSearchText$$.pipe(
    debounceTime(500),
    switchMap((caseSearchText: string) => {
      return this.courtsApiService
        .getCourtCases(this.courtId!, caseSearchText)
        .pipe(
          map((cases) => {
            this.caseSelectedData = [];
            cases.data.forEach((data) => {
              this.caseSelectedData.push(data);
              return this.caseSelectedData;
            });
            this.isLoading = false;
            this.caseSearch.ngOnInit();
          }),
        );
    }),
  );

  constructor(public readonly courtsApiService: CourtsApiService) {
    this.getCourtCases$.subscribe();
  }

  ngOnInit(): void {
    this.courtsApiService
      .getCourtSelectionTree([])
      .pipe(
        tap((courtsData: CourtSummaryItemTreeTreeResult) => {
          if (courtsData.data) {
            this.courtSelectedData = courtsData.data;
          }
        }),
      )
      .subscribe();
  }

  searchTextChangedEventHandler(searchText: string) {
    if (!this.courtId) {
      return;
    }
    this.isLoading = true;
    this.caseSearchText$$.next(searchText);
  }

  public courtSelected(courtSelected: DropdownOption<void>): void {
    if (this.courtId) {
      this.setNewFilingEvent.emit(null);
    }

    this.courtId = courtSelected.name;
    this.courtSelectedDropdownOption = courtSelected;
    this.courtPath = null;
    this.courtState = null;

    if (
      this.courtSelectedDropdownOption?.itemPath &&
      this.courtSelectedDropdownOption.itemPath?.length > 2
    ) {
      this.courtPath =
        this.courtSelectedDropdownOption.itemPath
          .reverse()
          .slice(2, this.courtSelectedDropdownOption?.itemPath.length)
          .join(' | ') ?? null;
    }

    this.courtState = this.getCourtState();
  }

  public caseSelected(caseSelected: DropdownOption<void>): void {
    this.efmKey = caseSelected.efmKey;
    this.caseSelectedDropdownOption = caseSelected;

    this.selectedCase = this.caseSelectedData?.find(
      (item) => item.summary.efmKey === this.efmKey,
    );
    this.attestationRequired = this.selectedCase?.pendingAttestation;
    this.profileId = this.selectedCase?.summary.filingProfileSummary.id ?? '';
    this.courtsApiService
      .getFilingProfile(
        this.selectedCase?.summary.court.id ?? '',
        this.profileId,
      )
      .pipe(
        tap((filingProfile: FilingProfile) => {
          this.filingProfile = filingProfile;
          this.attestationMessage =
            filingProfile.attestationStatementForCaseAccess ?? '';
        }),
      )
      .subscribe();
    if (this.attestationRequired && this.attestationAcepted) {
      this.selectedCaseTitle = this.selectedCase?.summary.title;
    } else if (!this.attestationRequired) {
      this.selectedCaseTitle = this.selectedCase?.summary.title;
    }
    this.hasSubCase =
      this.selectedCase?.summary.filingProfileSummary
        .subsequentFilingsRequireSubCase ?? false;

    if (this.courtId && this.profileId && !this.hasSubCase) {
      this.setNewFiling(caseSelected, this.courtId, this.profileId, false);
    } else if (this.hasSubCase) {
      this.isLoading = true;
      if (
        this.courtId &&
        this.selectedCase?.summary.efmKey &&
        !this.selectedCase?.pendingAttestation
      ) {
        this.getSubcases(this.courtId, this.selectedCase?.summary.efmKey);
      }
    }
  }

  public getSubcases(courtId: string, efmKey: string) {
    this.courtsApiService
      .getCourtSubCases(courtId, efmKey)
      .pipe(
        tap((cases) => {
          this.subcaseSelectedData = [];
          cases.data.forEach((data) => {
            this.subcaseSelectedData.push(data);
            this.subCaseSearch.ngOnInit();
          });
          this.isLoading = false;
        }),
      )
      .subscribe();
  }

  public attestationYes() {
    if (
      this.courtId &&
      this.selectedCase?.summary.efmKey &&
      this.selectedCase.summary.filingProfileSummary
        .attestationStatementForCaseAccess
    ) {
      this.courtsApiService
        .attest(
          this.courtId,
          this.selectedCase?.summary.efmKey,
          this.selectedCase.summary.filingProfileSummary
            .attestationStatementForCaseAccess,
        )
        .pipe(
          tap(() => {
            this.attestationAcepted = true;
            this.selectedCaseTitle = this.selectedCase?.summary.title;
            this.courtsApiService
              .getCourtCases(
                this.courtId!,
                this.selectedCase?.summary.caption ?? '',
              )
              .pipe(
                tap((cases) => {
                  const updatedCasesData: CaseQueryResultItem[] = [];
                  cases.data.forEach((data) => {
                    updatedCasesData.push(data);
                  });
                  const newTitle = updatedCasesData?.find(
                    (item) => item.summary.efmKey === this.efmKey,
                  )?.summary.title;
                  if (newTitle) {
                    this.selectedCaseTitle = newTitle;
                  }
                  if (this.subcaseSelectedDropdownOption) {
                    this.setNewFiling(
                      this.subcaseSelectedDropdownOption,
                      this.courtId!,
                      this.profileId!,
                      true,
                    );
                  }
                }),
              )
              .subscribe();
          }),
        )
        .subscribe();
      this.getSubcases(this.courtId, this.selectedCase?.summary.efmKey);
    }
  }

  public attestationNo() {
    this.attestationRejected = true;
  }

  public setNewFiling(
    caseSelected: DropdownOption<void>,
    courtId: string,
    profileId: string,
    subCase: boolean,
  ) {
    this.courtsApiService
      .getFilingProfile(courtId, profileId)
      .pipe(
        tap((filingProfile: FilingProfile) => {
          this.filingProfile = filingProfile;

          let newFilingCases: NewFilingCase[];

          if (subCase) {
            newFilingCases = this.subcaseSelectedData
              .map((caseQueryItem: CaseQueryResultItem) => {
                const newFilingCase = caseQueryItem.summary as NewFilingCase;
                newFilingCase.caption = this.selectedCase?.summary.caption;
                newFilingCase.title =
                  this.selectedCaseTitle ?? this.selectedCase?.summary.title;
                return caseQueryItem.summary as NewFilingCase;
              })
              .filter(
                (caseQueryItem) => caseQueryItem.efmKey === caseSelected.efmKey,
              );
          } else {
            newFilingCases = this.caseSelectedData
              .map((caseQueryItem: CaseQueryResultItem) => {
                return caseQueryItem.summary as NewFilingCase;
              })
              .filter(
                (caseQueryItem) => caseQueryItem.efmKey === caseSelected.efmKey,
              );
          }
          const newFiling: NewFiling = {
            courtId: this.courtId ?? '',
            profileId: this.filingProfile?.id ?? '',
            mode: FilingMode.Subsequent,
            caption: caseSelected.caption ?? '',
            cases: [...newFilingCases],
            requestTypes: [RequestType.Case],
          };

          this.setNewFilingEvent.emit(newFiling);
        }),
      )
      .subscribe();
  }

  public subcaseSelected(subcaseSelected: DropdownOption<void>): void {
    this.subcaseId = subcaseSelected.name;
    this.subcaseSelectedDropdownOption = subcaseSelected;
    if (this.courtId && this.profileId) {
      this.setNewFiling(subcaseSelected, this.courtId, this.profileId, true);
    }
  }

  public toggleBrowsingCourts(): void {
    this.browsingCourts = !this.browsingCourts;
  }

  public toggleBrowsingProfiles(): void {
    this.browsingProfiles = !this.browsingProfiles;
  }

  public browsedCourtSelected(courtData: {
    courtId: string;
    courtName: string;
    courtState?: string;
    courtPath?: string[];
  }): void {
    this.courtId = courtData.courtId;
    this.courtSelectedDropdownOption = {
      caption: courtData.courtName,
      name: courtData.courtId,
      itemPath: courtData.courtPath,
      selected: false,
    };
    this.courtPath = courtData.courtPath?.join(' | ') ?? null;
    this.courtState = courtData.courtState ?? null;
    this.toggleBrowsingCourts();
  }

  public getCourtPath(): string | null {
    if (
      this.courtSelectedDropdownOption &&
      this.courtSelectedDropdownOption.itemPath
    ) {
      return this.courtSelectedDropdownOption.itemPath
        .slice(0, this.courtSelectedDropdownOption.itemPath.length - 2)
        .join(' | ');
    }
    return null;
  }

  public getCourtState(): string | null {
    if (
      this.courtSelectedDropdownOption &&
      this.courtSelectedDropdownOption.itemPath &&
      this.courtSelectedDropdownOption.itemPath.length >= 2
    ) {
      return this.courtSelectedDropdownOption.itemPath[1];
    }
    return null;
  }

  public resetCourtSelected(): void {
    this.courtId = null;
    this.courtSelectedDropdownOption = null;
    this.resetCaseSelected();
  }

  public resetCaseSelected(): void {
    this.efmKey = null;
    this.caseSelectedDropdownOption = null;
    this.attestationRequired = false;
    this.attestationAcepted = false;
    this.attestationRejected = false;
    this.hasSubCase = false;
    this.resetSubcaseSelected();
  }

  public resetSubcaseSelected(): void {
    this.subcaseId = null;
    this.subcaseSelectedDropdownOption = null;
  }
}
