import { DropdownOption, ReferenceResolver } from '@fsx/ui-components';
import { Countries } from 'projects/libs/shared/src/lib/data/countries.data';
import {
  AddressCategory,
  AliasCategory,
  CaseDetail,
  ContactProfile,
  FilingProfile,
  NamedListItem,
  ProfileListReference,
  ProfileLists,
  EmailCategory,
  IdentificationCategory,
  PhoneCategory,
  AliasSpec,
  AddressProfile,
  IdentificationCommonCategory,
  SearchResultItemGridResult,
  CaseRequestViewModel,
  CombinedFilingData,
  AdditionalFieldValue,
  AdditionalFieldSpec,
  CaseRequest,
  DocumentInfo,
  ICaseRequestUpdateService,
  IFilingApiService,
} from '@fsx/fsx-shared';
import { Observable } from 'rxjs';

export interface AdditionalResolverData {
  filingApi?: IFilingApiService;
  filingId?: string | undefined;
  caseDetail?: CaseDetail;
  cfd?: CombinedFilingData;
  caseRequestUpdateService?: ICaseRequestUpdateService;
}

export type CategoryCollections =
  | AddressCategory
  | AliasCategory
  | EmailCategory
  | IdentificationCategory
  | PhoneCategory;

/**
 * A concrete implementation of a class that allows for the looking up properties
 * on the passed in ContactProfile or FilingProfile objects.
 */
export class FsxReferenceResolver implements ReferenceResolver {
  /**
   * The profile from which to lookup properties.
   */
  private _profile!: FilingProfile | ContactProfile;

  private _caseDetail: CaseDetail | undefined;

  private _filingId: string | undefined;
  private _filingApi: IFilingApiService | undefined;
  private _cfd: CombinedFilingData | undefined;
  private _caseRequestUpdateService: ICaseRequestUpdateService | undefined;

  constructor(
    private readonly profile: FilingProfile | ContactProfile,
    private readonly addlData: AdditionalResolverData,
  ) {
    this._profile = this.profile;
    this._caseDetail = this.addlData.caseDetail;
    this._filingId = this.addlData.filingId;
    this._filingApi = this.addlData.filingApi;
    this._cfd = this.addlData.cfd;
    this._caseRequestUpdateService = this.addlData.caseRequestUpdateService;
  }

  public getCharacterProfileCharset(profileName: string | null): string {
    const _profileName =
      profileName ?? this._profile.defaultCharacterProfileName;

    return (
      this._profile.characterProfiles.find(
        (profile) => profile.name === _profileName,
      )?.characterSet ?? ''
    );
  }

  public getStringDropdownOptions(
    listReference: ProfileListReference,
  ): DropdownOption<void>[] {
    let stringDropdownOptions: NamedListItem[] | CategoryCollections[];

    switch (listReference.list) {
      case ProfileLists.AdditionalList: {
        stringDropdownOptions =
          this._profile.additionalLists.find(
            (list) => list.name === listReference.additionalListName,
          )?.items ?? [];
        break;
      }
      case ProfileLists.CaseAdditionalList: {
        stringDropdownOptions =
          this._caseDetail?.additionalLists.find(
            (list) => list.name === listReference.additionalListName,
          )?.items ?? [];
        break;
      }
      case ProfileLists.Countries: {
        return Countries as DropdownOption<void>[];
      }
      case ProfileLists.AddressCategories: {
        stringDropdownOptions = this._profile.addressCategories;
        break;
      }
      case ProfileLists.AliasCategories: {
        stringDropdownOptions = this._profile.aliasCategories;
        break;
      }
      case ProfileLists.EmailCategories: {
        stringDropdownOptions = this._profile.emailCategories;
        break;
      }
      case ProfileLists.IdentificationCategories: {
        stringDropdownOptions = this._profile.identificationCategories;
        break;
      }
      case ProfileLists.PhoneCategories: {
        stringDropdownOptions = this._profile.phoneCategories;
        break;
      }
      default:
        return [];
    }

    return this.transformListToStringDropdownOptions(stringDropdownOptions);
  }

  public getEmailCategoryDropdownOptions(
    listReference: ProfileListReference,
  ): DropdownOption<EmailCategory>[] {
    return (
      this._getCategoryDropdownOptions<EmailCategory>(
        listReference,
        this._profile.emailCategories,
      ) ?? []
    );
  }

  public getAddressCategoryDropdownOptions(
    listReference: ProfileListReference,
  ): DropdownOption<AddressCategory>[] {
    return (
      this._getCategoryDropdownOptions<AddressCategory>(
        listReference,
        this._profile.addressCategories,
      ) ?? []
    );
  }

  public getAliasCategoryDropdownOptions(
    listReference: ProfileListReference,
  ): DropdownOption<AliasCategory>[] {
    return (
      this._getCategoryDropdownOptions<AliasCategory>(
        listReference,
        this._profile.aliasCategories,
      ) ?? []
    );
  }

  public getIdentificationCategoryDropdownOptions(
    listReference: ProfileListReference,
    fullOpts = false,
  ): DropdownOption<IdentificationCategory>[] {
    if (fullOpts) {
      return (
        this._getCategoryDropdownOptions<IdentificationCategory>(
          listReference,
          this._profile.identificationCategories,
        ) ?? []
      );
    } else {
      return (
        this._getCategoryDropdownOptions<IdentificationCategory>(
          listReference,
          this._profile.identificationCategories,
        ).filter((option) => {
          return (
            option.category?.commonCategory !=
            IdentificationCommonCategory.BarNumber
          );
        }) ?? []
      );
    }
  }

  public getPhoneCategoryDropdownOptions(
    listReference: ProfileListReference,
  ): DropdownOption<PhoneCategory>[] {
    return (
      this._getCategoryDropdownOptions<PhoneCategory>(
        listReference,
        this._profile.phoneCategories,
      ) ?? []
    );
  }

  public getAliasCategoryFieldDefinitions(
    categoryName: string,
  ): AliasSpec | undefined {
    return this._profile.alias.find(
      (aliasFieldDefinition) =>
        aliasFieldDefinition.aliasCategory.name === categoryName,
    );
  }

  private _getCategoryDropdownOptions<T extends CategoryCollections>(
    listReference: ProfileListReference,
    listItems: CategoryCollections[],
  ): DropdownOption<T>[] {
    let categoryDropdownOptions!: CategoryCollections[];

    switch (listReference.list) {
      case ProfileLists.AdditionalList: {
        const additionalListItems =
          this._profile.additionalLists.find(
            (list) => list.name === listReference.additionalListName,
          )?.items ?? [];

        return this.transformNamedListToCategoryDropdownOptions(
          additionalListItems,
          listItems,
        );
      }
      case ProfileLists.CaseAdditionalList: {
        const additionalListItems =
          this._caseDetail?.additionalLists.find(
            (list) => list.name === listReference.additionalListName,
          )?.items ?? [];

        return this.transformNamedListToCategoryDropdownOptions(
          additionalListItems,
          listItems,
        );
      }
      case ProfileLists.AddressCategories:
      case ProfileLists.AliasCategories:
      case ProfileLists.EmailCategories:
      case ProfileLists.IdentificationCategories:
      case ProfileLists.PhoneCategories: {
        categoryDropdownOptions = listItems;
        break;
      }
      default:
        return [];
    }

    return this.transformListToCategoryDropdownOptions<T>(
      categoryDropdownOptions,
    );
  }

  private transformListToStringDropdownOptions(
    listItems: NamedListItem[] | CategoryCollections[],
  ): DropdownOption<void>[] {
    return (
      listItems?.map((item) => {
        const dropdownOption: DropdownOption<void> = {
          caption: item.caption,
          name: item.name,
          selected: false,
        };
        return dropdownOption;
      }) ?? []
    );
  }

  private transformListToCategoryDropdownOptions<T extends CategoryCollections>(
    listItems: CategoryCollections[],
  ): DropdownOption<T>[] {
    return (
      listItems?.map((item) => {
        const dropdownOption: DropdownOption<T> = {
          caption: item.caption,
          name: item.name,
          category: item as T,
          selected: false,
        };
        return dropdownOption;
      }) ?? []
    );
  }

  private transformNamedListToCategoryDropdownOptions<
    T extends CategoryCollections,
  >(
    listItems: NamedListItem[],
    categories: CategoryCollections[],
  ): DropdownOption<T>[] {
    return (
      categories
        ?.filter(
          (category: CategoryCollections) =>
            !!listItems?.find((item) => item.name === category?.name),
        )
        .map((item: CategoryCollections) => {
          const dropdownOption: DropdownOption<T> = {
            caption: item?.caption,
            name: item?.name,
            category: item as T,
            selected: false,
          };
          return dropdownOption;
        }) ?? []
    );
  }

  public getAddressProfile(
    profileName?: string | null,
  ): AddressProfile | undefined {
    let addressProfile: AddressProfile | undefined = undefined;
    if (profileName) {
      if (profileName) {
        addressProfile = this._profile?.addressProfiles?.find(
          (profile) => profile.name === profileName,
        );
      }
    }
    return addressProfile;
  }

  public search(
    searchValue: string,
    searchName: string,
  ): Observable<SearchResultItemGridResult> {
    if (this._filingApi && this._filingId) {
      return this._filingApi.search(
        this._filingId ?? '',
        searchValue,
        searchName,
      );
    } else {
      return {} as Observable<SearchResultItemGridResult>;
    }
  }

  public updateCaseRequest(
    path: string,
    additionalFieldsUpdate: boolean = false,
    existingAdditionalFields: AdditionalFieldValue[] | null = [],
    value: string | null = '',
  ): void {
    if (this._cfd && this._caseRequestUpdateService) {
      const caseRequest = this._cfd.caseRequest;
      const caseRequestBackup = { ...caseRequest } as CaseRequestViewModel;
      this._caseRequestUpdateService
        .optimisticPatchCaseSummaryOrRestore({
          filingId: this._cfd.filing.id,
          caseRequest: caseRequest,
          value: additionalFieldsUpdate ? existingAdditionalFields : value,
          path: path,
          backup: caseRequestBackup,
          optimistic: true,
        })
        .subscribe();
    }
  }

  public updateCaseRequestPut(
    caseRequest: CaseRequest,
    filingId: string,
  ): void {
    const caseRequestBackup = { ...caseRequest } as CaseRequestViewModel;
    this._caseRequestUpdateService?.optimisticPutOrRestore(
      filingId,
      caseRequest,
      caseRequestBackup,
      true,
    );
  }

  public updateAdditionalFieldValues(
    array: AdditionalFieldValue[],
    element: AdditionalFieldValue,
  ) {
    if (!array) {
      return;
    }

    const i = array.findIndex(
      (_element) =>
        _element.additionalFieldName === element.additionalFieldName,
    );
    if (i > -1) {
      array[i] = element;
    } else {
      array.push(element);
    }
  }

  public getAdditionalFieldInitialValue(
    spec: AdditionalFieldSpec,
    existingAdditionalFields: AdditionalFieldValue[] | null,
  ): AdditionalFieldValue | null {
    if (existingAdditionalFields) {
      const i = existingAdditionalFields.findIndex(
        (element) => element.additionalFieldName === spec.name,
      );

      if (i > -1) {
        return existingAdditionalFields[i];
      } else {
        return null;
      }
    }

    return null;
  }

  public getAdditionalFieldInitialValues(
    spec: AdditionalFieldSpec,
    existingAdditionalFields: AdditionalFieldValue[] | null,
  ): AdditionalFieldValue[] | null {
    if (existingAdditionalFields) {
      return existingAdditionalFields.filter(
        (element) => element.additionalFieldName === spec.name,
      );
    }

    return null;
  }

  public getDocumentInfos(ids: string[]): DocumentInfo[] {
    return this._cfd?.documentInfos?.filter((f) => ids.includes(f.id)) || [];
  }
}
