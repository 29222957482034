import { Injectable, InjectionToken } from '@angular/core';
import {
  CaseRequestViewModel,
  FILING_SUB_TABS,
  RequestDocumentViewModel,
  ValidationConstants,
} from '@fsx/fsx-shared';
import { filter, Observable, Subject } from 'rxjs';
import { ProfileRuleError } from './filing-editor-errors.service';

export const FsxFilingEditorEventService =
  new InjectionToken<IFilingEditorEventService>('FsxFilingEditorEventService');

export interface IFilingEditorEventParams {
  filingId: string;
}

export interface ICaseRequestUpdatedEventParams
  extends IFilingEditorEventParams {
  caseRequest: CaseRequestViewModel;
  forceLoad: boolean;
}

export interface IFileUploadStartedEventParams
  extends IFilingEditorEventParams {
  caseRequest: CaseRequestViewModel;
}

export interface IFilingSubTabChangedEventParams
  extends IFilingEditorEventParams {
  filingSubTab: FILING_SUB_TABS;
}

export interface IRequestDocumentCreatedParams
  extends IFilingEditorEventParams {
  requestDocument: RequestDocumentViewModel;
  documentIndex: number;
}

export interface IFilingEditorEventService {
  fileUploadStarted$: Observable<IFileUploadStartedEventParams>;
  filingSubTabChanged$: Observable<IFilingSubTabChangedEventParams>;
  cancelUpload$: Observable<RequestDocumentViewModel>;
  requestDocumentCreated$: Observable<IRequestDocumentCreatedParams>;
  filingSubTabChangedToDocuments$: Observable<IFilingSubTabChangedEventParams>;
  filingSubTabChangedNotDocuments$: Observable<IFilingSubTabChangedEventParams>;
  dispatchFileUploadStartedEvent(params: IFileUploadStartedEventParams): void;
  dispatchFilingSubTabChangedEvent(
    params: IFilingSubTabChangedEventParams,
  ): void;
  dispatchCancelUploadEvent(requestDocument: RequestDocumentViewModel): void;
  dispatchRequestDocumentCreatedEvent(
    params: IRequestDocumentCreatedParams,
  ): void;
}

@Injectable()
export class FilingEditorEventService implements IFilingEditorEventService {
  private fileUploadStarted$$ = new Subject<IFileUploadStartedEventParams>();
  fileUploadStarted$: Observable<IFileUploadStartedEventParams> =
    this.fileUploadStarted$$.asObservable();

  private filingSubTabChanged$$ =
    new Subject<IFilingSubTabChangedEventParams>();
  filingSubTabChanged$: Observable<IFilingSubTabChangedEventParams> =
    this.filingSubTabChanged$$.asObservable();

  private cancelUpload$$ = new Subject<RequestDocumentViewModel>();
  cancelUpload$: Observable<RequestDocumentViewModel> =
    this.cancelUpload$$.asObservable();

  private requestDocumentCreated$$ =
    new Subject<IRequestDocumentCreatedParams>();
  requestDocumentCreated$: Observable<IRequestDocumentCreatedParams> =
    this.requestDocumentCreated$$.asObservable();

  filingSubTabChangedToDocuments$: Observable<IFilingSubTabChangedEventParams> =
    this.filingSubTabChanged$.pipe(
      filter((params: IFilingSubTabChangedEventParams) => {
        return params.filingSubTab === FILING_SUB_TABS.DOCUMENTS;
      }),
    );

  filingSubTabChangedNotDocuments$: Observable<IFilingSubTabChangedEventParams> =
    this.filingSubTabChanged$.pipe(
      filter((params: IFilingSubTabChangedEventParams) => {
        return params.filingSubTab !== FILING_SUB_TABS.DOCUMENTS;
      }),
    );

  dispatchFileUploadStartedEvent(params: IFileUploadStartedEventParams): void {
    this.fileUploadStarted$$.next(params);
  }

  dispatchFilingSubTabChangedEvent(
    params: IFilingSubTabChangedEventParams,
  ): void {
    this.filingSubTabChanged$$.next(params);
  }

  dispatchCancelUploadEvent(requestDocument: RequestDocumentViewModel): void {
    this.cancelUpload$$.next(requestDocument);
  }

  dispatchRequestDocumentCreatedEvent(
    params: IRequestDocumentCreatedParams,
  ): void {
    this.requestDocumentCreated$$.next(params);
  }

  static guardConditionToValidationCode(
    profileRuleError: ProfileRuleError,
  ): ValidationConstants {
    switch (profileRuleError) {
      case ProfileRuleError.IsGreaterThanMaxFileSize:
        return ValidationConstants.FileSizeTooLarge;
      case ProfileRuleError.IsNotAllowedFileTyoe:
        return ValidationConstants.FileTypeNotAllowed;
    }

    return ValidationConstants.Unknown;
  }
}
