<fsx-additional-field-control
  *ngFor="
    let specAndFormControl of arrayOfSpecAndFormControl;
    let index = index
  "
  [additionalFieldSpec]="specAndFormControl.additionalFieldSpec"
  [additionalFieldValue]="specAndFormControl.additionalFieldValue!"
  [additionalFieldValues]="this.additionalFieldValues"
  [specAndFormControl]="specAndFormControl"
  [resolver]="resolver"
  (additionalFieldValueEvent)="additionalFieldValueEventHandler($event)"
  (clearAdditionalFieldValuesEvent)="
    clearAdditionalFieldValuesEventHandler($event)
  "
>
</fsx-additional-field-control>
