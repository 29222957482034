import { Injectable } from '@angular/core';
import { FilingSubmissionReceiptComponent } from './filing-submission-receipt.component';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';

@Injectable({
  providedIn: 'root',
})
export class FilingSubmissionReceiptService {
  public filingSubmissionReceiptDialogRef!: MatDialogRef<FilingSubmissionReceiptComponent>;

  // @Inject(MAT_DIALOG_DATA) public data: Transaction,
  constructor(private readonly dialog: MatDialog) {}

  public openFilingSubmissionReceipt(filingId: string): void {
    this.filingSubmissionReceiptDialogRef = this.dialog.open(
      FilingSubmissionReceiptComponent,
      {
        width: 'calc(100% - 60px)',
        height: '100vh',
        position: {
          top: '0px',
          right: '0px',
        },
        enterAnimationDuration: '0ms',
        exitAnimationDuration: '0ms',
        hasBackdrop: false,
        panelClass: 'filing-submission-receipt-dialog',
        closeOnNavigation: true,
      },
    );
    this.filingSubmissionReceiptDialogRef.componentInstance.filingId = filingId;
  }
}
