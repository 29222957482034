import { Inject, Injectable, InjectionToken } from '@angular/core';
import { NewFilingService, OpenFilingService } from '@fsx/fsx-shared';
import { Filing, NewFiling } from '@fsx/fsx-shared';
import { switchMap, Subject, Observable, tap, catchError, EMPTY } from 'rxjs';
import {
  FsxIsLoadingStateService,
  IIsLoadingStateService,
} from '../ui-state/is-loading-state.service';
import { HttpErrorResponse } from '@angular/common/http';

export const FsxNewFilingOrchestrationService =
  new InjectionToken<INewFilingOrchestrationService>(
    'FsxNewFilingOrchestrationService',
  );

export interface INewFilingOrchestrationService {
  createNewFiling$: Observable<Filing>;
  createNewFilingAndOpenInFilingEditor$: Observable<Filing>;
  createNewFiling(newFiling: NewFiling): void;
}

@Injectable()
export class NewFilingOrchestrationService
  implements INewFilingOrchestrationService
{
  private newFilingParams$$ = new Subject<NewFiling>();

  createNewFiling$: Observable<Filing> = this.newFilingParams$$.pipe(
    switchMap((newFiling: NewFiling) => {
      // ToDo: Start here.
      this.isLoadingStateService.isLoading(true);
      return this.newFilingService.createNewFiling(newFiling).pipe(
        tap(() => {
          // Hide indicator
          this.isLoadingStateService.isLoading(false);
        }),
      );
    }),
    catchError((error: HttpErrorResponse) => {
      console.error(error);
      this.isLoadingStateService.isLoading(false);
      return EMPTY;
    }),
  );

  createNewFilingAndOpenInFilingEditor$: Observable<Filing> =
    this.createNewFiling$.pipe(
      switchMap((filing: Filing) => {
        return this.openFilingService.openFiling(filing);
      }),
    );

  constructor(
    private readonly newFilingService: NewFilingService,
    private readonly openFilingService: OpenFilingService,
    @Inject(FsxIsLoadingStateService)
    private readonly isLoadingStateService: IIsLoadingStateService,
  ) {}

  createNewFiling(newFiling: NewFiling): void {
    // ToDo: Remove this once a fix has been implemented by the BE (#151322)
    // Temp fix to allow for new subf filings to be created. Currently failing because the BE is returning 'null'
    // for captions in the filing and in each case.
    // newFiling.caption = newFiling.caption ?? 'New Filing Title' ?? ''; // ToDo: Breaking change, setting to hard coded value for now
    this.newFilingParams$$.next(newFiling);
  }
}
