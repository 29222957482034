<div class="fsx-search-component">
  <label
    >{{ caption }}
    <span class="required" *ngIf="fieldDefinition.minRequired">*</span>
    <mat-icon *ngIf="helpText" [matTooltip]="helpText">info_outline</mat-icon>
  </label>

  <mat-progress-bar
    *ngIf="isLoading"
    class="progress-bar"
    mode="indeterminate"
  ></mat-progress-bar>

  <div class="input-container">
    <input
      #input
      [formControl]="selectionInput"
      [placeholder]="hint"
      [ngClass]="{
        invalid: inputFormControl.invalid && selectionInput.touched,
      }"
      [matAutocomplete]="auto"
      (keydown.tab)="selectSingleOption()"
      (keydown.ArrowUp)="setFocusedElement()"
      (keydown.ArrowDown)="setFocusedElement()"
      (keydown.enter)="selectSingleOption()"
      (keydown.backspace)="clearSelection()"
      [title]="selectionInput.value"
      (keyup)="onSearchTextChanged()"
    />
    <div class="arrow-container">
      <span
        class="material-icons arrow-down"
        [ngClass]="{ disabled: selectionInput.disabled }"
        (click)="input.focus()"
      >
        expand_more
      </span>
    </div>
  </div>

  <p
    class="error-message"
    *ngIf="inputFormControl.invalid && selectionInput.touched"
  >
    {{ getErrorMessage() }}
  </p>

  <mat-autocomplete
    #auto="matAutocomplete"
    [displayWith]="selectionDisplayFn"
    (closed)="onSingleSelectDropdownClose()"
  >
    <mat-option
      class="search-option"
      *ngFor="let option of filteredOptions | async"
      [value]="option.name"
      (click)="selectSingleOption(option)"
      [title]="option.caption"
    >
      <div class="search-option-container">
        <div class="captions-container">
          <p class="caption primary-caption">{{ option.caption }}</p>
          <p *ngIf="option.secondaryCaption" class="caption secondary-caption">
            {{ option.secondaryCaption }}
          </p>
          <p *ngIf="option.tertiaryCaption" class="caption tertiary-caption">
            {{ option.tertiaryCaption }}
          </p>
        </div>
      </div>
    </mat-option>
  </mat-autocomplete>
</div>
