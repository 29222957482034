import {
  Component,
  HostListener,
  Input,
  OnDestroy,
  AfterViewInit,
  ViewChild,
  ViewChildren,
  QueryList,
  Inject,
  Output,
  EventEmitter,
  OnInit,
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import {
  AddressViewModel,
  AliasFieldDefinition,
  CombinedFilingData,
  ContactAliasFormGroup,
  ContactAliasViewModel,
  ParticipantFormGroup,
  ContactOrganizationFormGroup,
  ContactOrganizationViewModel,
  ContactPersonFormGroup,
  ContactProfile,
  ContactType,
  ContactViewModel,
  EmailAddressSpec,
  EmailAddressViewModel,
  FilingProfile,
  FsxFilingApiService,
  IdentificationCommonCategory,
  IdentificationSpec,
  IdentificationViewModel,
  IFilingApiService,
  OrganizationSpec,
  ParticipantCategory,
  ParticipantFormMode,
  ParticipantSpec,
  PersonalNameViewModel,
  PersonNameSpec,
  PhoneSpec,
  PhoneViewModel,
  RequestContactOrganizationViewModel,
  RequestParticipantAliasViewModel,
  RequestParticipantRepresentationViewModel,
  RequestParticipantViewModel,
  TextFieldDefinition,
} from '@fsx/fsx-shared';
import {
  AddressComponentFieldDefinition,
  AddressFormGroup,
  DropdownOption,
  DropdownOptionsTypes,
  EmailFormGroup,
  FormArrayWithModel,
  FormControlWithModel,
  FsxAddressComponent,
  FsxAliasComponent,
  FsxEmailComponent,
  FsxIdentificationComponent,
  FsxPersonNameComponent,
  FsxPhoneComponent,
  FsxTextComponent,
  IdentificationFormGroup,
  PersonNameFormGroup,
  PhoneFormGroup,
  SelectionFieldDefinition,
  SidePanelFooterConfig,
  SidePanelHeaderConfig,
  invalidOptionValidator,
} from '@fsx/ui-components';
import {
  debounceTime,
  distinctUntilChanged,
  Subject,
  takeUntil,
  tap,
} from 'rxjs';
import {
  FsxEditParticipantOrchestrationService,
  IEditParticipantOrchestrationService,
} from '../../parties/orchestration-services/edit-participant-orchestration.service';
import { FsxReferenceResolver } from '../../shared/resolvers/list-reference.resolver';
import {
  ContactFormService,
  FsxContactFormService,
  IContactFormService,
} from '../contact-form/contact-form.service';
import {
  FsxValidatePartiesOrchestrationService,
  IValidatePartiesOrchestrationService,
} from '../../filing-editor/services/orchestration/validate-parties-orchestration.service';
import {
  FsxPanelService,
  IPanelService,
} from '../../shared/services/panel.service';
import {
  FsxContactsListService,
  IContactsListService,
} from '../contacts-list/contacts-list.service';

/**
 * The config object for the participant form component.
 */
export interface ParticipantFormConfig {
  /**
   * The mode that the form should operate in. Can be Add/Edit for either
   * a Participant/Representation.
   */
  formMode: ParticipantFormMode;

  /**
   * The participant category of the participant being edited. Used to look
   * up the participant spec when in edit mode.
   */
  participantCategory: ParticipantCategory;

  /**
   * The flag to pass to the participant form so that the form can be used to
   * add/edit representation participants.
   */
  isRepresentation: boolean;

  /**
   * The participant to edit in the participant form.
   */
  participant: RequestParticipantViewModel;
}

const ShowAllOptions = {
  YES: true,
  NO: false,
};
@Component({
  selector: 'fsx-participant-form',
  templateUrl: 'participant-form.component.html',
  styleUrls: ['./participant-form.component.scss'],
  providers: [
    {
      provide: FsxContactFormService,
      useClass: ContactFormService,
    },
  ],
})
export class ParticipantFormComponent
  implements OnInit, OnDestroy, AfterViewInit
{
  /**
   * The config object for the participant form component.
   */
  @Input() config!: ParticipantFormConfig;

  /**
   * The Angular FormGroup to track the value and validity of the nested
   * FormControl, FormGroup and FormArray instances. Pre-initialised in
   * the ParticipantFormPanelComponent and appended to in handler functions
   * from within this class when nested components emit the FormGroup,
   * FormArray and FormControl instances that they themselves create.
   */
  @Input() participantFormGroup!: FormGroup<ParticipantFormGroup>;

  @Input() combinedFilingData!: CombinedFilingData;
  @Input() representation!: RequestParticipantRepresentationViewModel;

  /**
   * An output vent to notify the parent ParticipantFormPanelCompoent of the
   * overall form validity, which it uses to enable/disable the Save bitton
   * in the footer componnet.
   */
  @Output() isFormValidEvent = new EventEmitter<boolean>();

  @ViewChild('personNameField') personNameField!: FsxPersonNameComponent;
  @ViewChild('organizationField') organizationField!: FsxTextComponent;
  @ViewChildren('barNumberField')
  barNumberFields!: QueryList<FsxIdentificationComponent>;
  @ViewChildren('addressField') addressFields!: QueryList<FsxAddressComponent>;
  @ViewChildren('phoneField') phoneFields!: QueryList<FsxPhoneComponent>;
  @ViewChildren('emailField') emailFields!: QueryList<FsxEmailComponent>;
  @ViewChildren('identificationField')
  identificationFields!: QueryList<FsxIdentificationComponent>;
  @ViewChildren('aliasField') aliasFields!: QueryList<FsxAliasComponent>;

  public headerConfig!: SidePanelHeaderConfig;
  public footerConfig!: SidePanelFooterConfig;
  public ContactType = ContactType;
  public contactType: ContactType = ContactType.Person;
  public participantFormMode = ParticipantFormMode;
  public contactProfile!: ContactProfile;
  public showProgressBar = false;

  /**
   * The ParticipantSpec object containing the rules for configuring
   * all parts of this participant form.
   */
  public participantSpec!: ParticipantSpec | undefined;

  public resolver!: FsxReferenceResolver;

  public personNameFieldDefinition!: PersonNameSpec;
  public personNameInitialValues!: PersonalNameViewModel;
  public organizationFieldDefinition!: OrganizationSpec;
  public organizationInitialValues!:
    | ContactOrganizationViewModel
    | RequestContactOrganizationViewModel;
  public addressFieldDefinition!: AddressComponentFieldDefinition;
  public addressInitialValues: AddressViewModel[] = [];
  public phoneFieldDefinition!: PhoneSpec;
  public phoneInitialValues: PhoneViewModel[] = [];
  public barIdentificationInitialValues: IdentificationViewModel[] = [];
  public otherIdentificationInitialValues: IdentificationViewModel[] = [];

  /**
   * The IdentificationSpec object as taken from the ParticipantSpec object
   * containing the rules for configuring the "Identifiers" and "Bar Number"
   * form sections.
   */
  public identificationSpec!: IdentificationSpec;

  public identificationFormArray!: FormArrayWithModel<
    FormGroup<IdentificationFormGroup>
  >;

  public showBarIdentificationComponent = false;
  public barFormArray!: FormArrayWithModel<FormGroup<IdentificationFormGroup>>;
  public showOtherIdentificationComponent = false;
  public otherIdentificationFormArray!: FormArrayWithModel<
    FormGroup<IdentificationFormGroup>
  >;
  public emailFieldDefinition!: EmailAddressSpec;
  public emailInitialValues: EmailAddressViewModel[] = [];
  public aliasFieldDefinition!: AliasFieldDefinition;
  public aliasInitialValues:
    | RequestParticipantAliasViewModel[]
    | ContactAliasViewModel[] = [];
  public contact!: ContactViewModel | null;
  public idLength!: number;

  private destroy$: Subject<unknown> = new Subject();

  constructor(
    @Inject(FsxFilingApiService)
    private readonly filingApiService: IFilingApiService,
    @Inject(FsxEditParticipantOrchestrationService)
    readonly editParticipantOrchestrationService: IEditParticipantOrchestrationService,
    @Inject(FsxContactFormService)
    readonly contactFormService: IContactFormService,
    @Inject(FsxPanelService) public readonly panelService: IPanelService,
    @Inject(FsxContactsListService)
    readonly contactsListService: IContactsListService,
    @Inject(FsxValidatePartiesOrchestrationService)
    readonly validatePartiesOrchestrationService: IValidatePartiesOrchestrationService,
  ) {}

  @HostListener('document:keydown.escape', ['$event']) onKeydownHandler() {
    if (!this.headerConfig && this.panelService.dialogsArray.length === 1) {
      this.closeFormDialog();
    }
  }

  @HostListener('document:keydown.enter', ['$event']) onEnterKeyHandler(
    $event: KeyboardEvent,
  ) {
    // don't action the return key if the form isn't valid
    if ($event.keyCode === 13 && this.participantFormGroup.invalid) {
      return;
    }
    this.footerConfig.buttons[1].action();
  }

  ngOnInit(): void {
    if (this.combinedFilingData) {
      if (
        this.config.formMode === ParticipantFormMode.EditParticipant ||
        this.config.formMode === ParticipantFormMode.AddParticipant
      ) {
        this.setParticipantForm();
      } else if (
        this.config.formMode === ParticipantFormMode.EditRepresentation
      ) {
        this.setRepresentationForm();
      }
      this.setFormValidityListener();
    }
  }

  ngAfterViewInit(): void {
    if (
      this.config.formMode === ParticipantFormMode.EditParticipant ||
      this.config.formMode === ParticipantFormMode.EditRepresentation
    ) {
      // ToDo: Disabling this for now as it is causing issues with the intended behavior of the form. The side effect here
      // ToDo: is that invalid parties with pre-filled data with invalid fields will not have their errors surfaced until the user
      // ToDo: touches them. It's also important to note that concierge team will not be concerned about filling out the form,
      // ToDo: which is why this should be disabled for now until a solution can be found, since leaving it enabled will be an annoyance for them.
      this.validate();
    }
  }

  public ngOnDestroy(): void {
    this.destroy$.next(null);
    this.destroy$.complete();
  }

  public setActiveForm(contactType: ContactType): void {
    this.contactType = contactType;
    this.participantFormGroup.controls.contactType.setValue(this.contactType);
  }

  /**
   * A private method to setup a stream which listens to the ParticipantFormGroup changes
   * to trigger validation of the overall FormGroup and nested FormControl, FormGroups
   * and FormArrays.
   */
  private setFormValidityListener(): void {
    this.participantFormGroup.valueChanges
      .pipe(
        takeUntil(this.destroy$),
        debounceTime(500),
        distinctUntilChanged((curr, prev) => {
          // No need to continue to re-validate the PartcipantFormGroup if the value hasn't changed.
          return JSON.stringify(prev) === JSON.stringify(curr);
        }),
        tap(() => {
          // Set forceValidation to true only when in one of the edit modes.
          const editModes = [
            ParticipantFormMode.EditParticipant,
            ParticipantFormMode.EditRepresentation,
          ];
          const forceValidation = editModes.includes(this.config.formMode);

          // Make the call to the service to validate the FormGroup, FormArrays and FromControls.
          const validForm = this.contactFormService.validateFormGroup(
            this.participantFormGroup,
            forceValidation,
          );

          const isValid = validForm && this.participantFormGroup.valid;

          // Notify parent ParticipantFormPanelComponent that the validity has changed.
          this.isFormValidEvent.emit(isValid);
        }),
      )
      .subscribe();
  }

  public closeFormDialog(): void {
    this.panelService.closeCurrentDialog();
  }

  private setParticipantForm(): void {
    if (
      this.combinedFilingData.filingProfile &&
      this.config.participantCategory
    ) {
      this.participantSpec = this.combinedFilingData.modeSpec?.participant.find(
        (spec) => {
          return (
            spec.participantCategory.name ===
            this.config.participantCategory.name
          );
        },
      );

      this.resolver = new FsxReferenceResolver(
        this.combinedFilingData.filingProfile,
        {
          filingApi: this.filingApiService,
          filingId: this.combinedFilingData.filingProfile.id,
        },
      );

      this._setComponentsData(this.config.participant);
      this._setParticipantContactType(this.config.participant);
      this._setComponentsSpecs(
        this.participantSpec,
        this.combinedFilingData.filingProfile,
      );
    }
  }

  // Customize here
  private setRepresentationForm(): void {
    if (
      this.combinedFilingData.filingProfile &&
      this.config.participant &&
      this.config.participantCategory
    ) {
      this.config.formMode = ParticipantFormMode.EditRepresentation;
      this.contactType = ContactType.Person;
      this.participantSpec = this.combinedFilingData.modeSpec?.participant.find(
        (spec) => {
          return (
            spec.participantCategory.name ===
            this.config.participantCategory.name
          );
        },
      );
      this.resolver = new FsxReferenceResolver(
        this.combinedFilingData.filingProfile,
        {
          filingApi: this.filingApiService,
          filingId: this.combinedFilingData.filingProfile.id,
        },
      );

      this._setComponentsData(this.config.participant);
      this._setParticipantContactType(this.config.participant);
      this._setComponentsSpecs(
        this.participantSpec,
        this.combinedFilingData.filingProfile,
      );
    }
  }

  public validate(): void {
    // TODO - this needs to be cleverer, taking into account the specs for
    // each child-component. For example, if emails has a minRequired of 0, it's OK
    // to have an empty email (event though the email address field itself is required)

    if (this.personNameField) {
      this.personNameField.validate();
    }

    if (this.organizationField) {
      this.organizationField.validate();
    }

    if (this.barNumberFields && this.barNumberFields.length > 0) {
      this.barNumberFields.forEach((fld: FsxIdentificationComponent) => {
        return fld.validate();
      });
    }

    if (this.addressFields && this.addressFields.length > 0) {
      this.addressFields.forEach((fld: FsxAddressComponent) => {
        fld.validate();
      });
    }

    if (this.phoneFields && this.phoneFields.length > 0) {
      this.phoneFields.forEach((fld: FsxPhoneComponent) => {
        fld.validate();
      });
    }

    if (this.emailFields && this.emailFields.length > 0) {
      this.emailFields.forEach((fld: FsxEmailComponent) => {
        fld.validate();
      });
    }

    if (this.identificationFields && this.identificationFields.length > 0) {
      this.identificationFields.forEach((fld: FsxIdentificationComponent) => {
        fld.validate();
      });
    }

    if (this.aliasFields && this.aliasFields.length > 0) {
      this.aliasFields.forEach((fld: FsxAliasComponent) => {
        fld.validate();
      });
    }
  }

  private getIdentificationsCount(
    barFormArray: FormArrayWithModel<FormGroup<IdentificationFormGroup>>,
    otherIdentificationFormArray: FormArrayWithModel<
      FormGroup<IdentificationFormGroup>
    >,
  ) {
    const barControlsCount = barFormArray?.controls?.length ?? 0;
    const otherControlsCount =
      otherIdentificationFormArray?.controls?.length ?? 0;

    return barControlsCount + otherControlsCount;
  }

  private _setComponentsSpecs(
    spec: ParticipantSpec | undefined,
    profile: ContactProfile | FilingProfile,
  ): void {
    if (spec?.person?.personalName) {
      this.personNameFieldDefinition = spec.person.personalName;
    }

    if (spec?.organization) {
      this.organizationFieldDefinition = spec.organization;
    }

    if (spec?.address) {
      const profileName =
        spec.address.addressProfileName ?? profile.defaultAddressProfileName;

      const addressCategoriesDefinition = profile.addressProfiles.find(
        (prof) => prof.name === profileName,
      );

      if (addressCategoriesDefinition) {
        this.addressFieldDefinition = {
          ...spec.address,
          ...addressCategoriesDefinition.spec,
        };
      }
    }

    if (spec?.phone) {
      this.phoneFieldDefinition = spec.phone;
    }

    if (spec?.email) {
      this.emailFieldDefinition = spec.email;
    }

    if (spec?.identification) {
      this.identificationSpec = spec.identification;
      if (spec.identification.category?.listReference) {
        const idCategories =
          this.resolver.getIdentificationCategoryDropdownOptions(
            spec.identification.category?.listReference,
            ShowAllOptions.YES,
          );
        if (
          idCategories.filter((option) => {
            return (
              option.category?.commonCategory !=
              IdentificationCommonCategory.BarNumber
            );
          }).length > 0
        ) {
          this.showOtherIdentificationComponent = true;
        }
        if (
          idCategories.filter((option) => {
            return option.category?.commonCategory == 'BarNumber';
          }).length > 0
        ) {
          this.showBarIdentificationComponent = true;
        }
      }
    }

    if (spec?.alias) {
      this.aliasFieldDefinition = spec.alias;
    }
  }

  private _setParticipantContactType(
    participant: RequestParticipantViewModel | null,
  ): void {
    // if (participant?.contactType) {
    //   this.contactType = participant?.contactType;
    //   this.participantFormGroup.controls.contactType.setValue(this.contactType);
    // }

    if (participant) {
      if (participant.contactType === ContactType.Unknown) {
        this.contactType = ContactType.Person;
      } else {
        this.contactType = participant.contactType;
      }
      this.participantFormGroup.controls.contactType.setValue(this.contactType);
    }
  }

  private _setComponentsData(
    participant: RequestParticipantViewModel | null,
  ): void {
    if (participant?.person?.personalName) {
      this.personNameInitialValues = participant?.person?.personalName;
    }

    if (participant?.organization) {
      this.organizationInitialValues = participant.organization;
    }

    if (participant?.addresses) {
      this.addressInitialValues = participant.addresses;
    }

    if (participant?.emails) {
      this.emailInitialValues = participant.emails;
    }

    if (participant?.phones) {
      this.phoneInitialValues = participant.phones;
    }

    if (participant?.identifications) {
      this.barIdentificationInitialValues = participant.identifications.filter(
        (identification) => {
          return (
            identification.category.commonCategory ===
            IdentificationCommonCategory.BarNumber
          );
        },
      );
      this.otherIdentificationInitialValues =
        participant.identifications.filter((identification) => {
          return (
            identification.category.commonCategory !==
            IdentificationCommonCategory.BarNumber
          );
        });
    }

    if (participant?.aliases) {
      this.aliasInitialValues = participant.aliases;
    }
  }

  /**
   * A handler method for setting the 'person' FormGroup in the ParticipantFormGroup.
   *
   * @param fgPersonalName The emitted FormGroup from the PersonNameComponent.
   */
  setPersonNameFormGroup(fgPersonalName: FormGroup<PersonNameFormGroup>): void {
    // The person FormGroup containing the personalName FormGroup.
    const fgPerson = new FormGroup<ContactPersonFormGroup>({
      personalName: fgPersonalName,
    });
    this.participantFormGroup.setControl('person', fgPerson);
  }

  /**
   * A handler method for setting the 'organization' FormGroup in the ParticipantFormGroup.
   *
   * @param fcTitle The emitted FormControl from the organisation instance of the TextComponent.
   */
  setOrganizationFormControl(
    fcTitle: FormControlWithModel<TextFieldDefinition>,
  ): void {
    // The organization FormGroup containing the title FormControl
    const fgOrganization = new FormGroup<ContactOrganizationFormGroup>({
      title: fcTitle,
    });
    this.participantFormGroup.setControl('organization', fgOrganization);
  }

  /**
   * A handler method for setting the 'addresses' FormArray in the ParticipantFormGroup.
   *
   * @param faAddresses The emitted FormArray from the AddressComponent.
   */
  setAddressFromArray(
    faAddresses: FormArrayWithModel<FormGroup<AddressFormGroup>>,
  ): void {
    this.participantFormGroup.setControl('addresses', faAddresses);

    // Iterate over each instance of the address FormGroups adding the custom invalid
    // option validator to each of the nested dropdown FormControls.
    this.participantFormGroup.controls.addresses?.controls.forEach(
      (addressFormGroup: FormGroup<AddressFormGroup>) => {
        this.addInvalidOptionValidator(
          addressFormGroup.controls.administrativeArea,
        );
        this.addInvalidOptionValidator(addressFormGroup.controls.country);
        this.addInvalidOptionValidator(addressFormGroup.controls.category);
      },
    );
  }

  /**
   * A helper method for adding a custom invalid option validator to a given FormControl
   *
   * @param formControl TYhe FormControl to add the custom invalid option validator to.
   */
  private addInvalidOptionValidator(
    formControl: FormControlWithModel<SelectionFieldDefinition>,
  ): void {
    if (formControl) {
      const dropdownOptions: DropdownOption<DropdownOptionsTypes>[] =
        formControl.dropdownOptions || [];
      const dropdownOptionStrings: string[] = dropdownOptions.map(
        (ddo: DropdownOption<DropdownOptionsTypes>) => ddo.name,
      );
      formControl.addValidators(invalidOptionValidator(dropdownOptionStrings));
    }
  }

  /**
   * A handler method for setting the 'phones' FormArray in the ParticipantFormGroup.
   *
   * @param faPhones The emitted FormArray from the PhoneComponent.
   */
  setPhoneFormArray(
    faPhones: FormArrayWithModel<FormGroup<PhoneFormGroup>>,
  ): void {
    this.participantFormGroup.setControl('phones', faPhones);
  }

  /**
   * A handler method for setting the 'emails' FormArray in the ParticipantFormGroup.
   *
   * @param faEmails The emitted FormArray from the EmailComponent.
   */
  setEmailFormArray(
    faEmails: FormArrayWithModel<FormGroup<EmailFormGroup>>,
  ): void {
    this.participantFormGroup.setControl('emails', faEmails);
  }

  /**
   * A handler method for setting the 'barIdentifications' FormArray in the ParticipantFormGroup.
   *
   * @param faBarIdentifications The emitted FormArray from the "bar Number" instance of the IdentificationComponent.
   */
  setBarFormArray(
    faBarIdentifications: FormArrayWithModel<
      FormGroup<IdentificationFormGroup>
    >,
  ): void {
    this.participantFormGroup.setControl(
      'barIdentifications',
      faBarIdentifications,
    );

    this.barFormArray = faBarIdentifications;
    this.barFormArray.valueChanges
      .pipe(
        debounceTime(500),
        tap(() => {
          this.idLength = this.getIdentificationsCount(
            this.barFormArray,
            this.otherIdentificationFormArray,
          );
        }),
      )
      .subscribe();
  }

  /**
   * A handler method for setting the 'otherIdentifications' FormArray in the ParticipantFormGroup.
   *
   * @param faOtherIdentifications The emitted FormArray from the "Identifiers" instance of the IdentificationComponent.
   */
  setOtherIdFormArray(
    faOtherIdentifications: FormArrayWithModel<
      FormGroup<IdentificationFormGroup>
    >,
  ): void {
    this.participantFormGroup.setControl(
      'otherIdentifications',
      faOtherIdentifications,
    );
    this.otherIdentificationFormArray = faOtherIdentifications;
    this.otherIdentificationFormArray.valueChanges
      .pipe(
        debounceTime(500),
        tap(() => {
          this.idLength = this.getIdentificationsCount(
            this.barFormArray,
            this.otherIdentificationFormArray,
          );
        }),
      )
      .subscribe();
  }

  /**
   * A handler method for setting the 'aliases' FormArray in the ParticipantFormGroup.
   *
   * @param faAliases The emitted FormArray from the AliasComponent.
   */
  setAliasFromArray(
    faAliases: FormArrayWithModel<FormGroup<ContactAliasFormGroup>>,
  ): void {
    this.participantFormGroup.setControl('aliases', faAliases);
  }
}
