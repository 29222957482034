<div
  class="filings-container prevent-select"
  fxLayout="row"
  *ngIf="vm$ | async as vm"
>
  <div fxLayout="column" fxFill>
    <div class="filter-row-container">
      <div fxLayout="row" fxLayoutAlign="space-between center">
        <div fxLayout="row" fxLayoutAlign="start center">
          <div class="filter-control">
            <fsx-text-component
              #searchText
              [caption]="'Search'"
              [hint]="'Search'"
              [width]="'256px'"
              [initialValue]="searchTextValue"
              [fieldDefinition]="defaultFieldDefinition"
              (textChanged)="onSearchTextChanged($event)"
            >
            </fsx-text-component>
          </div>
          <div
            class="filter-control"
            fxLayout="column"
            fxLayoutAlign="center start"
          >
            <label>Date</label>
            <mat-form-field>
              <mat-date-range-input [formGroup]="range" [rangePicker]="picker">
                <input
                  matStartDate
                  formControlName="start"
                  placeholder="Start date"
                />
                <input
                  matEndDate
                  formControlName="end"
                  placeholder="End date"
                  (dateChange)="onDateRangeChanged()"
                />
              </mat-date-range-input>
              <div class="picker-container">
                <mat-datepicker-toggle
                  matIconSuffix
                  [for]="picker"
                ></mat-datepicker-toggle>
                <mat-date-range-picker #picker></mat-date-range-picker>
              </div>
            </mat-form-field>
          </div>
          <div class="filter-control">
            <fsx-basic-single-selection
              #statusSingleSelection
              [caption]="'Status'"
              [hint]="'Select'"
              [width]="'190px'"
              [initialValue]="statusFilterValue"
              [inputDropdownOptions]="statusList"
              [clearable]="true"
              [required]="false"
              (selectedValue)="onStatusFilterSelected($event)"
              (clearValue)="onStatusFilterCleared()"
            >
            </fsx-basic-single-selection>
          </div>
          <div class="filter-control">
            <fsx-basic-single-selection
              #filingsSingleSelection
              [caption]="'Filings'"
              [hint]="'Select'"
              [width]="'190px'"
              [inputDropdownOptions]="filingsList"
              [clearable]="false"
              [required]="false"
              (selectedValue)="onFilingsFilterChanged($event)"
            >
            </fsx-basic-single-selection>
          </div>
          <div class="filter-control">
            <button
              id="apply-button"
              class="primary"
              (click)="onApplyClicked()"
            >
              Apply
            </button>
          </div>
          <div class="filter-control">
            <button
              id="apply-button"
              class="secondary"
              (click)="onResetFilterClicked()"
            >
              Reset
            </button>
          </div>
        </div>
        <div>
          <button class="primary" [matMenuTriggerFor]="menu">
            <div fxLayout="row" fxLayoutAlign="center center">
              <span>Start Filing</span>
              <mat-icon>arrow_drop_down</mat-icon>
            </div>
          </button>
          <mat-menu #menu="matMenu">
            <button mat-menu-item (click)="onNewCaseButtonClicked()">
              <span>New Case Filing</span>
            </button>
            <button mat-menu-item (click)="onExistingCaseButtonClicked()">
              <span>Existing Filing</span>
            </button>
          </mat-menu>
        </div>
      </div>
      <div class="status-filters" fxLayout="row" fxLayoutAlign="space-between">
        <div fxLayout="row" fxLayoutGap="5px">
          <button
            class="accepted-bg"
            (click)="onQuickStatusFilterButtonClicked(filingState.Accepted)"
            [disabled]="isAcceptedButtonDisabled"
            [ngClass]="{ disabled: isAcceptedButtonDisabled }"
          >
            Accepted
          </button>
          <button
            class="rejected-bg"
            (click)="onQuickStatusFilterButtonClicked(filingState.Rejected)"
            [disabled]="isRejectedButtonDisabled"
            [ngClass]="{ disabled: isRejectedButtonDisabled }"
          >
            Rejected
          </button>
          <button
            class="submitted-bg"
            (click)="onQuickStatusFilterButtonClicked(filingState.Submitted)"
            [disabled]="isSubmittedButtonDisabled"
            [ngClass]="{ disabled: isSubmittedButtonDisabled }"
          >
            Submitted
          </button>
        </div>
        <div *ngIf="vm.transactionsData.pagingData as pagingData">
          <span *ngIf="pagingData.total"
            >{{ pagingData.total }} Total Transactions</span
          >
        </div>
      </div>
    </div>

    <!-- START: Filings Grid Container -->
    <div class="filings-grids-container" fxLayout="column">
      <!-- START: Filings Grid Header Columns  -->
      <div
        fxFlex="none"
        fxLayout="row"
        fxLayoutAlign="start center"
        fxLayoutGap="0px"
        class="title-row"
      >
        <div class="status-col header" fxFlex="nogrow" style="width: 6px"></div>
        <div
          class="bookmark-col"
          fxLayout="column"
          fxLayoutAlign="center center"
          fxFlex="nogrow"
        >
          <div fxFlex="none" class="icon header-icon bookmark-border"></div>
        </div>
        <div
          class="column header"
          fxFlex="150px"
          fxLayout="row"
          fxLayoutAlign="start center"
        >
          <span>TID</span>
          <!-- <mat-icon class="sort-icon" svgIcon="arrows-both"></mat-icon> -->
        </div>
        <div
          class="column header"
          fxFlex="200px"
          fxLayout="row"
          fxLayoutAlign="start center"
        >
          <span>Case No. / Name</span>
          <!-- <mat-icon class="sort-icon" svgIcon="arrows-both"></mat-icon> -->
        </div>
        <div
          class="column header"
          fxFlex="300px"
          fxLayout="row"
          fxLayoutAlign="start center"
        >
          <span>Court</span>
          <!-- <mat-icon class="sort-icon" svgIcon="arrows-both"></mat-icon> -->
        </div>
        <div
          class="column header"
          fxFlex="150px"
          fxLayout="row"
          fxLayoutAlign="start center"
        >
          <span>Filed By</span>
          <!-- <mat-icon class="sort-icon" svgIcon="arrows-both"></mat-icon> -->
        </div>
        <div
          class="column header"
          fxFlex="130px"
          fxLayout="row"
          fxLayoutAlign="start center"
        >
          <span>Submitted</span>
          <mat-icon
            class="sort-icon"
            svgIcon="arrows-both"
            (click)="onSortIconClicked(filterColumnEnum.CreatedAt)"
          ></mat-icon>
        </div>
        <div
          class="column header"
          fxFlex="120px"
          fxLayout="row"
          fxLayoutAlign="start center"
        >
          <span>Status</span>
          <mat-icon
            class="sort-icon"
            svgIcon="arrows-both"
            (click)="onSortIconClicked(filterColumnEnum.Status)"
          ></mat-icon>
        </div>
        <div class="column header" fxFlex="auto">Actions</div>
        <!-- <div class="column header" fxFlex="auto">Profile</div>
        <div class="column header" fxFlex="75px">Type</div> -->
      </div>
      <!-- END: Filings Grid Header Columns  -->
      <!-- ToDo: The flex values are hard coded. This is just a temp solution since eventually there will be a
           ToDo: submission module. Should be values set globally in a scss file... -->
      <!-- START: Filings Grid Rows  -->

      <div
        id="transactions-container"
        infinite-scroll
        [infiniteScrollDistance]="4"
        [infiniteScrollThrottle]="300"
        [infiniteScrollContainer]="'#transactions-container'"
        [fromRoot]="true"
        (scrolled)="onScrollDown()"
      >
        <div
          *ngFor="let filing of vm.transactionsData.transactions"
          fxLayout="row"
          fxLayoutGap="0px"
          class="data-row"
          (click)="onTableRowClicked(filing)"
        >
          <div
            class="status-col"
            fxLayout="column"
            fxFlex="nogrow"
            [ngClass]="{
              'accepted-bg': filing.status === 'Accepted',
              'cancelled-bg': filing.status === 'Cancelled',
              'draft-bg': filing.status === 'Draft',
              'rejected-bg':
                filing.status === 'Rejected' ||
                filing.status === 'RejectedByEfm',
              'received-bg': filing.status === 'PartiallyAccepted',
              'submitted-bg': filing.status === 'Submitted',
            }"
          >
            <span id="status">&nbsp;</span>
          </div>
          <div
            class="bookmark-col"
            fxLayout="column"
            fxLayoutAlign="start center"
            fxFlex="nogrow"
          >
            <div
              fxFlex="none"
              class="icon"
              [ngClass]="
                filing.bookmarked
                  ? 'bookmark-fill'
                  : 'hover-icon bookmark-border'
              "
              (click)="onBookmarkedClicked($event, filing)"
            ></div>
          </div>
          <div class="column row" fxLayout="column" fxFlex="150px">
            <span
              id="transaction-id"
              (click)="onTransactionIdClicked($event, filing)"
              >{{
                filing | filingCaptionTitle: FilingCaptionTitleEnum.TID
              }}</span
            >
          </div>
          <div class="column row" fxLayout="column" fxFlex="200px">
            <span id="case-number">{{
              filing | filingCaptionTitle: FilingCaptionTitleEnum.Caption
            }}</span>
            <span id="case-name">{{
              filing | filingCaptionTitle: FilingCaptionTitleEnum.Title
            }}</span>
          </div>
          <div class="column row" fxLayout="column" fxFlex="300px">
            <span *ngIf="filing.courtSummary">{{
              filing.courtSummary.caption
            }}</span>
          </div>
          <div class="column row" fxLayout="column" fxFlex="150px">
            {{ filing.filer.caption }}
          </div>
          <div class="column row" fxLayout="column" fxFlex="130px">
            <span>{{ filing.createdAt | date: "MM/dd/yyyy" }}</span>
            <span>{{ filing.createdAt | date: "H:mm" }}</span>
          </div>
          <div
            class="column row status {{ filing.status | lowercase }}"
            fxLayout="column"
            fxFlex="120px"
          >
            {{
              filing.status === FilingState.RejectedByEfm
                ? "Failed"
                : filing.status
            }}
          </div>
          <div class="column row" fxFlex="auto" fxLayoutAlign="start center">
            <mat-icon
              matTooltip="Edit"
              [matTooltipShowDelay]="200"
              [matTooltipHideDelay]="0"
              [matTooltipPosition]="'above'"
              [matTooltipClass]="'fsx-tooltip'"
              [matTooltipDisabled]="filing.status !== filingState.Draft"
              class="hover-icon edit-icon"
              [ngClass]="{ disabled: filing.status !== filingState.Draft }"
              (click)="onEditIconClicked($event, filing)"
            >
            </mat-icon>
            <mat-icon
              matTooltip="Return Corrections"
              [matTooltipShowDelay]="200"
              [matTooltipHideDelay]="0"
              [matTooltipPosition]="'above'"
              [matTooltipClass]="'fsx-tooltip'"
              [matTooltipDisabled]="true"
              class="hover-icon return-corrections-icon disabled"
              (click)="onReturnCorrectionsClicked($event, filing)"
            >
            </mat-icon>
            <mat-icon
              matTooltip="Receipt"
              [matTooltipShowDelay]="200"
              [matTooltipHideDelay]="0"
              [matTooltipPosition]="'above'"
              [matTooltipClass]="'fsx-tooltip'"
              [matTooltipDisabled]="filing.status === filingState.Draft"
              [ngClass]="{ disabled: filing.status === filingState.Draft }"
              class="hover-icon receipt-icon"
              (click)="onReceiptIconClicked($event, filing)"
            >
            </mat-icon>
            <mat-icon
              matTooltip="Start SubF"
              [matTooltipShowDelay]="200"
              [matTooltipHideDelay]="0"
              [matTooltipPosition]="'above'"
              [matTooltipClass]="'fsx-tooltip'"
              [matTooltipDisabled]="true"
              class="hover-icon start-subf-icon disabled"
              (click)="onStartSubFClicked($event, filing)"
            >
            </mat-icon>
            <mat-icon
              matTooltip="Service Contact Report"
              [matTooltipShowDelay]="200"
              [matTooltipHideDelay]="0"
              [matTooltipPosition]="'above'"
              [matTooltipClass]="'fsx-tooltip'"
              [matTooltipDisabled]="true"
              class="hover-icon service-contact-report-icon disabled"
              (click)="onServiceContactReportClicked($event, filing)"
            >
            </mat-icon>
            <mat-icon
              matTooltip="Manage Service Contacts"
              [matTooltipShowDelay]="200"
              [matTooltipHideDelay]="0"
              [matTooltipPosition]="'above'"
              [matTooltipClass]="'fsx-tooltip'"
              [matTooltipDisabled]="true"
              class="hover-icon manage-service-contacts-icon disabled"
              (click)="onManageServiceContactsClicked($event, filing)"
            >
            </mat-icon>
            <mat-icon
              matTooltip="Delete"
              [matTooltipShowDelay]="200"
              [matTooltipHideDelay]="0"
              [matTooltipPosition]="'above'"
              [matTooltipClass]="'fsx-tooltip'"
              [matTooltipDisabled]="filing.status !== filingState.Draft"
              class="hover-icon cancel-icon"
              [ngClass]="{ disabled: filing.status !== filingState.Draft }"
              (click)="onRmoveTransactionClicked($event, filing)"
            >
            </mat-icon>
          </div>
          <!-- <div class="column row" fxLayout="column" fxFlex="auto" >{{ filing.profileSummary?.caption }}</div>
          <div class="column row" fxLayout="column" fxFlex="75px" >{{ filing.mode | filingModeDisplayName }}</div> -->
        </div>
        <div
          *ngIf="vm.transactionsData.transactions.length === 0"
          id="no-transactions-row"
        >
          <div id="loading-transactions" *ngIf="vm.transactionsData.isLoading">
            <mat-progress-bar
              class="transactions-loading-indicator"
              mode="indeterminate"
            ></mat-progress-bar>
            Loading Transactions...
          </div>
          <div
            id="no-transactions-found"
            *ngIf="!vm.transactionsData.isLoading"
          >
            No Transactions Found
          </div>
        </div>
        <div
          *ngIf="
            vm.transactionsData.transactions.length === 0 &&
            vm.transactionsData.isLoading
          "
          class="no-transactions-row"
        ></div>
      </div>
      <!-- END: Filings Grid Rows  -->
    </div>
    <!-- END: Filings Grid Container -->
  </div>
</div>
