import { InjectionToken } from '@angular/core';
import { FormGroup } from '@angular/forms';
import {
  AddressCategory,
  AddressViewModel,
  ContactFormGroup,
  ContactProfile,
  FilingProfile,
  NewContactViewModel,
  PhoneCategory,
  PhoneViewModel,
  getPersonFullName,
} from '@fsx/fsx-shared';
import { AddressFormGroup, PhoneFormGroup } from '@fsx/ui-components';

/**
 * The InjectionToken to use in the providers array to specify a concrete-implementation
 * of the IFormGroupTransformService to use at runtime.
 */
export const FsxFormGroupTransformService =
  new InjectionToken<IFormGroupTransformService>(
    'FsxFormGroupTransformService',
  );

/**
 * A blueprint for a service with methods for transforming a form group into
 * a contact, participant or party.
 */
export interface IFormGroupTransformService {
  /**
   * A method for transforming a form group into a contact.
   *
   * @param formGroup The form group to transform into a contact.
   */
  toNewContact(
    formGroup: FormGroup<ContactFormGroup>,
    contactProfile: ContactProfile,
    filingProfile: FilingProfile,
  ): NewContactViewModel;
}

/**
 * A concrete implementation of a service with methods for transforming
 * a form group into a contact, participant or party.
 */
export class FormGroupTransformService implements IFormGroupTransformService {
  /**
   * A method for transforming a form group into a contact.
   *
   * @param formGroup The form group to transform into a contact.
   */
  toNewContact(
    formGroup: FormGroup<ContactFormGroup>,
    contactProfile: ContactProfile,
    filingProfile: FilingProfile,
  ): NewContactViewModel {
    const newContact: NewContactViewModel = {
      ...(formGroup.value as NewContactViewModel),
      addresses: [],
      phones: [],
      emails: [],
      identifications: [],
      aliases: [],
    };

    newContact.person!.personalName.fullName = getPersonFullName(
      newContact!.person!.personalName,
    );

    newContact.caption = newContact.person?.personalName.fullName!;

    formGroup.controls.phones?.controls.forEach(
      (phoneFormGroup: FormGroup<PhoneFormGroup>) => {
        const phoneCatgeoryName: string = phoneFormGroup.controls.category
          .value as string;

        const originalPhoneCategory: PhoneCategory | undefined =
          filingProfile.phoneCategories.find((phoneCatgeory: PhoneCategory) => {
            return phoneCatgeory.name === phoneCatgeoryName;
          });

        const matchingPhoneCategory: PhoneCategory | undefined =
          contactProfile.phoneCategories.find(
            (phoneCatgeory: PhoneCategory) => {
              return (
                phoneCatgeory.commonCategory ===
                originalPhoneCategory?.commonCategory
              );
            },
          );

        const phoneViewModel: PhoneViewModel = {
          category: matchingPhoneCategory,
          caption: '',
          fullNumber: phoneFormGroup.controls.fullNumber.value,
        };

        newContact.phones.push(phoneViewModel);
      },
    );

    formGroup.controls.addresses?.controls.forEach(
      (addressFormGroup: FormGroup<AddressFormGroup>) => {
        const addressCatgeoryName: string = addressFormGroup.controls.category
          .value as string;

        const originalAddressCategory: AddressCategory | undefined =
          filingProfile.addressCategories.find(
            (addressCatgeory: AddressCategory) => {
              return addressCatgeory.name === addressCatgeoryName;
            },
          );

        const matchingAddressCategory: AddressCategory | undefined =
          contactProfile.addressCategories.find(
            (addressCatgeory: AddressCategory) => {
              return (
                addressCatgeory.commonCategory ===
                originalAddressCategory?.commonCategory
              );
            },
          );

        const address: AddressViewModel = {
          category: matchingAddressCategory,
          caption: matchingAddressCategory?.caption!,
          addressLine1: addressFormGroup.controls.addressLine1.value,
          addressLine2: addressFormGroup.controls.addressLine2.value,
          locality: addressFormGroup.controls.locality.value,
          administrativeArea:
            addressFormGroup.controls.administrativeArea.value,
          postalCode: addressFormGroup.controls.postalCode.value,
          country: addressFormGroup.controls.country.value,
        };

        newContact.addresses.push(address);
      },
    );

    return newContact;
  }
}
