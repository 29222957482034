import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export function minRequiredArrayLengthValidator(
  minRequired: number,
): ValidatorFn {
  return (formArray: AbstractControl): ValidationErrors | null => {
    const isValid = formArray.value.length >= minRequired;
    return isValid
      ? null
      : {
          minRequiredArrayLength: `Must provide at least ${minRequired}`,
        };
  };
}
