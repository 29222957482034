import { InjectionToken, Injectable, Injector } from '@angular/core';
import { MatDialogRef, MatDialog } from '@angular/material/dialog';
import { ContactFormPanelComponent } from '../../contacts/contact-form-panel/contact-form-panel.component';
import { ContactsListPanelComponent } from '../../contacts/contacts-list-panel/contacts-list-panel.component';
import { ParticipantFormPanelComponent } from '../../contacts/participant-form-panel/participant-form-panel.component';
import { ContactFormConfig } from '../../contacts/contact-form/contact-form.component';
import { ParticipantFormConfig } from '../../contacts/participant-form/participant-form.component';
import { ContactsListConfig } from '../../contacts/contacts-list-two/contacts-list-two.component';

/**
 * A marker interface implemented by the various panel components that can be
 * opened/closed dynamically by the ContactsService
 */
export interface FsxPanel {}

export interface PanelParams {
  /**
   * The injector of the calling component. This allows the modal to use
   * the same dependencies as its opener.
   */
  injector?: Injector | null;
}

export interface OpenContactsListPanelParams extends PanelParams {
  /**
   * The config object to pass on to the contacts list component.
   */
  contactsListConfig: ContactsListConfig;
}

export interface OpenContactsFormPanelParams extends PanelParams {
  /**
   * The config object to pass on to the contacts form component.
   */
  contactsFormConfig: ContactFormConfig;
}

export interface OpenParticipantFormPanelParams extends PanelParams {
  /**
   * The config object to pass on to the participant form component.
   */
  participantFormConfig: ParticipantFormConfig;
}

/**
 * The InjectionToken to use in the providers array to specify a concrete-implementation
 * of the IPanelService to use at runtime.
 */
export const FsxPanelService = new InjectionToken<IPanelService>(
  'FsxPanelService',
);

/**
 * A blueprint of a utility service for opening/closing of dialogs.
 */
export interface IPanelService {
  /**
   * An array of FsxPanel references, that we can push to when opened
   * and dynamically pop (if required) to close.
   */
  dialogsArray: MatDialogRef<FsxPanel>[];

  /**
   * A method to initiate the opening of the Participant Form. This form is used
   * to Add/Edit both participants and representation (which are also participants).
   *
   * @param params A config object defining how the form should be displayed.
   */
  openParticipantFormPanel(params: OpenParticipantFormPanelParams): void;

  openContactsFormPanel(params: OpenContactsFormPanelParams): void;

  openContactsListPanel(params: OpenContactsListPanelParams): void;

  closeContactsList(): void;

  closeCurrentDialog(): void;
}

/**
 * A concrete implementation of a utility service for opening/closing of dialogs.
 */
@Injectable()
export class PanelService implements IPanelService {
  /**
   * An array of FsxPanel references, that we can push to when opened
   * and dynamically pop (if required) to close.
   */
  public dialogsArray: MatDialogRef<FsxPanel>[] = [];

  /**
   *
   * @param dialog The Angular Material dialog service used to open dialogs.
   */
  constructor(public dialog: MatDialog) {}

  /**
   * A method to initiate the opening of the Participant Form. This form is used
   * to Add/Edit both participants and representation (which are also participants).
   *
   * @param params A config object defining how the form should be displayed.
   */
  public openParticipantFormPanel(
    params: OpenParticipantFormPanelParams,
  ): void {
    const dialogRef = this.dialog.open(ParticipantFormPanelComponent, {
      data: { ...params.participantFormConfig },
      width: '768px',
      height: '100vh',
      position: {
        top: '0px',
        right: '0px',
      },
      enterAnimationDuration: '0ms',
      exitAnimationDuration: '0ms',
      disableClose: true,
      injector: params.injector!,
    });
    this.dialogsArray.push(dialogRef);
  }

  public openContactsFormPanel(params: OpenContactsFormPanelParams): void {
    const dialogRef = this.dialog.open(ContactFormPanelComponent, {
      data: { ...params.contactsFormConfig },
      width: '768px',
      height: '100vh',
      position: {
        top: '0px',
        right: '0px',
      },
      enterAnimationDuration: '0ms',
      exitAnimationDuration: '0ms',
      disableClose: true,
      injector: params.injector!,
    });
    this.dialogsArray.push(dialogRef);
  }

  public openContactsListPanel(params: OpenContactsListPanelParams): void {
    const dialogRef = this.dialog.open(ContactsListPanelComponent, {
      data: { ...params.contactsListConfig },
      width: '768px',
      height: '100vh',
      position: {
        top: '0px',
        right: '0px',
      },
      enterAnimationDuration: '0ms',
      exitAnimationDuration: '0ms',
      disableClose: true,
      injector: params.injector!,
    });
    this.dialogsArray.push(dialogRef);
  }

  public closeContactsList(): void {
    this.dialog.closeAll();
  }

  public closeCurrentDialog(): void {
    const dialogRef = this.dialogsArray.pop();
    dialogRef?.close();
  }
}
