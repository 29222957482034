import { Injectable, InjectionToken } from '@angular/core';
import { Router } from '@angular/router';
import { RECENT_TRANSACTION_ID, TRANSACTIONS_EDITOR } from '@fsx/fsx-shared';
import { Observable, Subject, tap } from 'rxjs';

export const FsxSwitchFilingOrchestrationService =
  new InjectionToken<ISwitchFilingOrchestrationService>(
    'FsxSwitchFilingOrchestrationService',
  );

export interface ISwitchFilingOrchestrationService {
  switchFilingInFilingEditor$: Observable<string>;
  switchFiling(filingId: string): void;
}

@Injectable()
export class SwitchFilingOrchestrationService
  implements ISwitchFilingOrchestrationService
{
  private switchFilingParams$$ = new Subject<string>();

  switchFilingInFilingEditor$: Observable<string> =
    this.switchFilingParams$$.pipe(
      tap((filingId: string) => {
        localStorage.setItem(RECENT_TRANSACTION_ID, filingId);
        this.router.navigate([TRANSACTIONS_EDITOR, filingId]);
      }),
    );

  constructor(private readonly router: Router) {}

  switchFiling(filingId: string): void {
    this.switchFilingParams$$.next(filingId);
  }
}
