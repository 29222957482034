import {
  CasePartyViewModel,
  ParticipantSpec,
  RequestParticipantViewModel,
} from '@fsx/fsx-shared';
import { RepresentationGridRow } from '../../../../../../libs/components/src/lib';

/**
 * a view model for a row in the parties grid.
 */
export class PartiesGridRow {
  /**
   * The index of the row in the grid.
   */
  rowIndex: number;

  /**
   * The CaseParty object for the row.
   */
  party: CasePartyViewModel;

  /**
   * The RequestParticipant object for the row.
   */
  participant: RequestParticipantViewModel;

  /**
   * The ParticipantSpec object relating to the selected "Party Type" (party.participantCategory) on the row.
   */
  participantSpec: ParticipantSpec;

  /**
   * The array of representation related to the party on the row.
   */
  representationGridRows: RepresentationGridRow[];

  /**
   * The index of the party in the caseRequest.parties array/
   * (Often the same as rowIndex when displayed in that order but that's not guarenteed)
   */
  partyIndex: number;

  /**
   * The AdditionalFieldSpec captions of the additional fields that the party has been
   * selected for,
   */
  additionalFieldCaptions: string[];

  /**
   * A derived value to indicate whether the party has been used in any additional fields.
   */
  hasAdditionalFieldNames: boolean;

  constructor(
    rowIndex: number,
    party: CasePartyViewModel,
    participant: RequestParticipantViewModel,
    participantSpec: ParticipantSpec,
    representationGridRows: RepresentationGridRow[],
    partyIndex: number,
    additionalFieldCaptions: string[],
  ) {
    this.rowIndex = rowIndex;
    this.party = party;
    this.participant = participant;
    this.participantSpec = participantSpec;
    this.representationGridRows = representationGridRows;
    this.partyIndex = partyIndex;
    this.additionalFieldCaptions = additionalFieldCaptions;
    this.hasAdditionalFieldNames = additionalFieldCaptions.length > 0;
  }
}
