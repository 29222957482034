import { Component, Input, OnChanges, OnDestroy } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { Subject, takeUntil, tap } from 'rxjs';

@Component({
  selector: 'fsx-validation-errors',
  templateUrl: './validation-errors.component.html',
  styleUrls: ['./validation-errors.component.scss'],
})
export class ValidationErrorsComponent implements OnChanges, OnDestroy {
  /**
   * The AbstractControl containing the errors object from which we want
   * to display error messages for.
   */
  @Input() abstractControl!: AbstractControl;

  /**
   * A subject to trigger the tearing down of the subscriptions when the component is destroyed.
   */
  private destroy$: Subject<void> = new Subject();

  /**
   * An array of error message strings to display in the template
   */
  errorMessages: string[] = [];

  /**
   * The angular lifecycle hook where we wait for the AbstractControl to
   * be initialised before attempting to retrieve messages.
   */
  ngOnChanges(): void {
    if (this.abstractControl) {
      this.setupAbstractControlValidityListener();
    }
  }

  /**
   * The angular lifecycle hook where we teardown inner subscriptions
   * with the component.
   */
  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  private setupAbstractControlValidityListener(): void {
    this.abstractControl.valueChanges
      .pipe(
        takeUntil(this.destroy$),
        tap(() => {
          this.errorMessages = this.getErrorMessages();
        }),
      )
      .subscribe();
  }

  /**
   * A helper method to retrieve all of the error message strings from the
   * passed in AbstractControl's errors object.
   *
   * @returns An array of error message strings.
   */
  private getErrorMessages(): string[] {
    return Object.values({ ...this.abstractControl.errors });
  }
}
