import {
  Component,
  EventEmitter,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import {
  CombinedFilingData,
  Filing,
  FilingApiService,
  FilingMode,
  FsxFilingApiService,
  IFilingApiService,
  RequestType,
  TRANSACTIONS_ACTIVITY,
} from '@fsx/fsx-shared';
import { Observable, Subject, switchMap, tap } from 'rxjs';
import { Router } from '@angular/router';
import { RequestTypesJoined } from '../../transaction-activity/status/status.component';

export interface SubmissionReceiptDetail {
  caseDetails: {
    courtName: string;
    caseClass: string;
    caseType: string;
    caseName: string;
    clientMatterNumber: string;
  };
  filingDetails: {
    id: string;
    filingType: string;
    transactionId: string;
    submittedBy: string;
    submittedOn: string;
    transactionMode: string;
    authorizer: string;
    sendToEmail: string;
  };
}

@Component({
  selector: 'fsx-filing-submission-confirmation',
  templateUrl: './filing-submission-confirmation.component.html',
  styleUrls: ['./filing-submission-confirmation.component.scss'],
  providers: [
    {
      provide: FsxFilingApiService,
      useClass: FilingApiService,
    },
  ],
})
export class FilingSubmissionConfirmationComponent
  implements OnInit, OnDestroy
{
  @Input() combinedFilingData$!: Observable<CombinedFilingData>;
  @Output() submissionReceiptClickedEvent = new EventEmitter<void>();

  public submissionReceiptDetail!: SubmissionReceiptDetail;
  public showReceipt = false;

  private filing$!: Observable<Filing>;
  private destroy$: Subject<void> = new Subject<void>();

  constructor(
    private readonly router: Router,
    @Inject(FsxFilingApiService)
    private readonly filingApiService: IFilingApiService,
  ) {}

  ngOnInit(): void {
    this.filing$ = this.combinedFilingData$?.pipe(
      tap((cfd) => {
        this.submissionReceiptDetail = <SubmissionReceiptDetail>{
          filingDetails: {
            id: '',
            sendToEmail: '-',
            filingType: '-',
            transactionId: '-',
            submittedBy: '-',
            submittedOn: '-',
            transactionMode: '-',
            authorizer: '-',
          },
          caseDetails: {
            courtName: cfd.filing?.courtSummary?.caption,
            caseClass: '-',
            caseType: '-',
            caseName: cfd.caseRequest.cases![0].title ?? '-',
            clientMatterNumber: cfd.filing.courtCases[0].clientMatterKey, // ToDo: Does not support multiple cases
          },
        };

        const classification = cfd.filingProfile.classification;
        if (!!classification && classification.length) {
          this.submissionReceiptDetail.caseDetails.caseClass = classification
            .map((c) => c.caption)
            .join(' - ');
          const caseType = classification.find((c) => c.label === 'Case Type');
          if (!!caseType) {
            this.submissionReceiptDetail.caseDetails.caseType =
              caseType.caption;
          }
        }
      }),
      switchMap((cfd) => {
        return this.filingApiService.getFiling(cfd.filing.id);
      }),
    );
    this.filing$
      .pipe(
        tap((filing) => {
          this.submissionReceiptDetail.filingDetails = {
            id: filing.id,
            sendToEmail: filing?.filer.primaryEmailAddress ?? '-',
            filingType: this.getFilingType(filing.requestTypes),
            transactionId: filing.courtCases[0].transactionEfmKey ?? '-',
            submittedBy: filing?.filer.caption ?? '-',
            submittedOn: filing?.submittedAt ?? '-',
            transactionMode: this.getTransactionMode(filing.mode),
            authorizer: '-',
          };
          this.submissionReceiptDetail.caseDetails.caseName =
            filing.courtCases[0].title ?? '-';
          this.showReceipt = true;
        }),
      )
      .subscribe();
  }

  private getFilingType(requestTypes: RequestType[]): string {
    if (
      requestTypes.includes(RequestType.Case) &&
      requestTypes.includes(RequestType.Service)
    ) {
      return RequestTypesJoined.FileAndServe;
    } else if (
      requestTypes.includes(RequestType.Case) &&
      !requestTypes.includes(RequestType.Service)
    ) {
      return RequestTypesJoined.FileOnly;
    } else if (
      !requestTypes.includes(RequestType.Case) &&
      requestTypes.includes(RequestType.Service)
    ) {
      return RequestTypesJoined.Service;
    } else {
      return RequestTypesJoined.None;
    }
  }

  private getTransactionMode(mode: FilingMode): string {
    switch (mode) {
      case FilingMode.OriginalPetition:
        return 'New Filing';
      case FilingMode.Subsequent:
        return 'Existing Filing';
      case FilingMode.None:
        return 'None';
      case FilingMode.Appellate:
        return 'Appellate';
    }
  }

  public getTimeZoneAbbreviation(date: string): string {
    if (date !== '') {
      // Get Worded Timezone
      const timeZone = String(String(new Date(date)).split('(')[1]).split(
        ')',
      )[0];
      // Extract the initials and join them together. Return result as the timezone abbreviation.
      return timeZone
        .split(' ')
        .map((word) => word.charAt(0))
        .join('');
    }
    return '-';
  }

  submissionReceiptBtnClicked(): void {
    this.submissionReceiptClickedEvent.emit();
  }

  public routeToActivity(): void {
    this.router.navigate([
      TRANSACTIONS_ACTIVITY,
      this.submissionReceiptDetail.filingDetails.id,
    ]);
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
