import { Injectable } from '@angular/core';
import { IUploadedFile } from '@fsx/ui-components';
import {
  FilingEditorErrorsService,
  IDocumentRuleErrorParams,
  ProfileRuleError,
} from 'projects/apps/fsx-ui/src/app/filing-editor/services/filing-editor-errors.service';
import { Observable, tap } from 'rxjs';
import { RequestDocument } from '../../types';
import { FilingProfileRulesService } from './filing-profile-rules.service';
import { CombinedFilingData } from './combined-filing-api.service';

export interface ProfileRuleCheckResult {
  passed: boolean;
  expected: string;
  actual: string;
}

@Injectable({
  providedIn: 'root',
})
export class FilingProfileRuleCheckService {
  constructor(
    private readonly filingProfileRulesService: FilingProfileRulesService,
    private readonly filingEditorErrorsService: FilingEditorErrorsService,
  ) {}

  isAllowedFileType(params: {
    filingId: string;
    combinedFilingData: CombinedFilingData;
    uploadedFile: IUploadedFile;
    requestDocument: RequestDocument;
  }): Observable<ProfileRuleCheckResult> {
    const { filingId, combinedFilingData, uploadedFile, requestDocument } =
      params;
    return this.filingProfileRulesService
      .isAllowedFileType({
        combinedFilingData,
        uploadedFile,
      })
      .pipe(
        tap((isAllowedFileTypeResult: ProfileRuleCheckResult) => {
          const { passed, expected, actual } = isAllowedFileTypeResult;
          if (!passed) {
            const profileRuleErrorParams: IDocumentRuleErrorParams = {
              filingId,
              profileRuleError: ProfileRuleError.IsNotAllowedFileTyoe,
              message: `${uploadedFile.file.name} has a file type of ${actual} which is not an allowed file type. Allowed file types are: ${expected}`,
              requestDocument,
            };
            this.filingEditorErrorsService.raiseProfileRuleErrorEvent(
              profileRuleErrorParams,
            );
          }
        }),
      );
  }

  isSmallerThanMaxFileSize(params: {
    filingId: string;
    combinedFilingData: CombinedFilingData;
    uploadedFile: IUploadedFile;
    requestDocument: RequestDocument;
  }): Observable<ProfileRuleCheckResult> {
    const { filingId, combinedFilingData, uploadedFile, requestDocument } =
      params;
    return this.filingProfileRulesService
      .isSmallerThanMaxFileSize({
        combinedFilingData,
        uploadedFile,
      })
      .pipe(
        tap((ruleCheckResult: ProfileRuleCheckResult) => {
          const { passed, expected, actual } = ruleCheckResult;
          if (!passed) {
            const profileRuleErrorParams: IDocumentRuleErrorParams = {
              filingId,
              profileRuleError: ProfileRuleError.IsGreaterThanMaxFileSize,
              message: `${uploadedFile.file.name} has a file size of ${actual} which is above the permitted max file size. Max file size is: ${expected}`,
              requestDocument,
            };
            this.filingEditorErrorsService.raiseProfileRuleErrorEvent(
              profileRuleErrorParams,
            );
          }
        }),
      );
  }

  isLessThanMaxFileNameLength(params: {
    filingId: string;
    combinedFilingData: CombinedFilingData;
    uploadedFile: IUploadedFile;
    requestDocument: RequestDocument;
  }): Observable<ProfileRuleCheckResult> {
    const { filingId, combinedFilingData, uploadedFile, requestDocument } =
      params;
    return this.filingProfileRulesService
      .isLessThanMaxFileNameLength({
        combinedFilingData,
        uploadedFile,
      })
      .pipe(
        tap((ruleCheckResult: ProfileRuleCheckResult) => {
          const { passed, expected, actual } = ruleCheckResult;
          if (!passed) {
            const profileRuleErrorParams: IDocumentRuleErrorParams = {
              filingId,
              profileRuleError: ProfileRuleError.IsGreaterThanFileNameMaxLength,
              message: `${uploadedFile.file.name} has a file name length of ${actual} which is above the permitted max file name length. Max file name length is: ${expected}`,
              requestDocument,
            };
            this.filingEditorErrorsService.raiseProfileRuleErrorEvent(
              profileRuleErrorParams,
            );
          }
        }),
      );
  }
}
