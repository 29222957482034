<ng-container *ngIf="additionalFieldSpec">
  <div
    *ngIf="additionalFieldSpec.addressFieldDefinition as addressFieldDefinition"
  >
    <!-- <pre>{{ additionalFieldSpec | json }}</pre> -->
    <fsx-addresses-additional-field
      [resolver]="resolver"
      [additionalFieldSpec]="additionalFieldSpec"
      [additionalFieldValue]="additionalFieldValue"
      (additionalFieldValueEvent)="additionalFieldValueEventHandler($event)"
    >
    </fsx-addresses-additional-field>
  </div>

  <div
    *ngIf="additionalFieldSpec.instructionDefinition as instructionDefinition"
  >
    <!-- <pre>{{ instructionDefinition | json }}</pre> -->
    <fsx-instruction-component
      [caption]="additionalFieldSpec.caption"
      [width]="'100%'"
      [height]="'auto'"
      [fieldDefinition]="instructionDefinition"
    ></fsx-instruction-component>
  </div>

  <div *ngIf="additionalFieldSpec.textFieldDefinition as textFieldDefinition">
    <!-- <pre>{{ additionalFieldSpec | json }}</pre> -->
    <fsx-new-text
      [formControl]="specAndFormControl.formControl!"
      [caption]="additionalFieldSpec.caption"
      [helpText]="additionalFieldSpec.helpText ?? ''"
      [hint]="additionalFieldSpec.hint ?? ''"
      [width]="'200px'"
      [required]="textFieldDefinition.required"
      [maxLength]="textFieldDefinition.maxLength"
      [readonly]="false"
    ></fsx-new-text>
  </div>

  <div
    *ngIf="additionalFieldSpec.numberFieldDefinition as numberFieldDefinition"
  >
    <!-- <pre>{{ additionalFieldSpec | json }}</pre> -->
    <fsx-number-component
      #numberField
      [caption]="additionalFieldSpec.caption"
      [helpText]="additionalFieldSpec.helpText ?? ''"
      [hint]="additionalFieldSpec.hint ?? ''"
      [fieldType]="additionalFieldSpec.fieldType"
      [fieldDefinition]="additionalFieldSpec.numberFieldDefinition"
      [initialValue]="
        specAndFormControl.formControl &&
        (specAndFormControl.formControl.value ?? null)
      "
      [width]="'100%'"
      (formControlEmitter)="setNumberFormControl($event)"
    ></fsx-number-component>
  </div>

  <div *ngIf="additionalFieldSpec.dateFieldDefinition as dateFieldDefinition">
    <!-- <pre>{{ additionalFieldSpec | json }}</pre> -->
    <fsx-date-component
      #dateField
      [caption]="additionalFieldSpec.caption"
      [helpText]="additionalFieldSpec.helpText ?? ''"
      [hint]="additionalFieldSpec.hint ?? ''"
      [fieldType]="additionalFieldSpec.fieldType"
      [width]="'100%'"
      [fieldDefinition]="additionalFieldSpec.dateFieldDefinition"
      [initialValue]="
        specAndFormControl.formControl &&
        (specAndFormControl.formControl.value ?? null)
      "
      (formControlEmitter)="setDateFormControl($event)"
    ></fsx-date-component>
  </div>

  <div
    *ngIf="
      additionalFieldSpec.currencyFieldDefinition as currencyFieldDefinition
    "
  >
    <!-- <pre>{{ additionalFieldSpec | json }}</pre> -->
    <fsx-currency-component
      #currencyField
      [caption]="additionalFieldSpec.caption"
      [helpText]="additionalFieldSpec.helpText ?? ''"
      [hint]="additionalFieldSpec.hint ?? ''"
      [fieldType]="additionalFieldSpec.fieldType"
      [width]="'100%'"
      [fieldDefinition]="additionalFieldSpec.currencyFieldDefinition"
      [initialValue]="
        specAndFormControl.formControl &&
        specAndFormControl.formControl.value &&
        (specAndFormControl.formControl.value.amount ?? null)
      "
      (formControlEmitter)="setCurrencyFormControl($event)"
    ></fsx-currency-component>
  </div>

  <div
    *ngIf="additionalFieldSpec.booleanFieldDefinition as booleanFieldDefinition"
  >
    <!-- <pre>{{ additionalFieldSpec | json }}</pre> -->
    <fsx-new-boolean
      #booleanField
      [formControl]="specAndFormControl.formControl!"
      [caption]="additionalFieldSpec.caption"
      [helpText]="additionalFieldSpec.helpText ?? ''"
      [width]="'100%'"
      [height]="'inherit'"
    ></fsx-new-boolean>
  </div>

  <div
    *ngIf="
      additionalFieldSpec.selectionFieldDefinition as selectionFieldDefinition
    "
  >
    <!-- <pre>{{ additionalFieldSpec | json }}</pre> -->
    <fsx-profile-single-selection
      #singleSelectionField
      [caption]="additionalFieldSpec.caption"
      [helpText]="additionalFieldSpec.helpText ?? ''"
      [hint]="additionalFieldSpec.hint ?? ''"
      [fieldType]="additionalFieldSpec.fieldType"
      [width]="'200px'"
      [height]="'auto'"
      [initialValue]="
        specAndFormControl.formControl && specAndFormControl.formControl.value
          ? specAndFormControl.formControl.value[0]
          : ''
      "
      [selectionType]="selectionType.StringSelectionFieldDefinition"
      [fieldDefinition]="additionalFieldSpec.selectionFieldDefinition"
      [resolver]="resolver"
      (formControlEmitter)="setFormControl($event)"
    >
    </fsx-profile-single-selection>
  </div>

  <div
    *ngIf="additionalFieldSpec.searchFieldDefinition as searchFieldDefinition"
  >
    <!-- <pre>{{ additionalFieldSpec | json }}</pre> -->
    <fsx-efm-search-component
      #searchField
      [fieldDefinition]="additionalFieldSpec.searchFieldDefinition"
      [caption]="additionalFieldSpec.caption"
      [resolver]="resolver"
      [initialValue]="
        specAndFormControl.formControl &&
        (specAndFormControl.formControl.value ?? null)
      "
      (optionSelected)="setSearchFormControl($event)"
    >
    </fsx-efm-search-component>
  </div>

  <div *ngIf="additionalFieldSpec.fileFieldDefinition as fileFieldDefinition">
    <!-- <pre>{{ additionalFieldSpec | json }}</pre> -->
    <fsx-file-uploads-additional-field
      [resolver]="resolver"
      [additionalFieldSpec]="additionalFieldSpec"
      [additionalFieldValue]="additionalFieldValue"
      (additionalFieldValueEvent)="additionalFieldValueEventHandler($event)"
    >
    </fsx-file-uploads-additional-field>
  </div>

  <div
    *ngIf="additionalFieldSpec.contactFieldDefinition as contactFieldDefinition"
  >
    <!-- <pre>{{ additionalFieldSpec | json }}</pre> -->
    <fsx-contacts-additional-field
      [resolver]="resolver"
      [additionalFieldSpec]="additionalFieldSpec"
      [additionalFieldValue]="additionalFieldValue"
      (additionalFieldValueEvent)="additionalFieldValueEventHandler($event)"
    ></fsx-contacts-additional-field>
  </div>

  <div
    *ngIf="
      additionalFieldSpec.participantFieldDefinition as participantFieldDefinition
    "
  >
    <!-- <pre>{{ additionalFieldSpec | json }}</pre> -->
    <fsx-participants-additional-field
      [resolver]="resolver"
      [additionalFieldSpec]="additionalFieldSpec"
      [additionalFieldValue]="additionalFieldValue"
      (additionalFieldValueEvent)="additionalFieldValueEventHandler($event)"
    >
    </fsx-participants-additional-field>
  </div>

  <div *ngIf="displayNestedAdditionalFields">
    <!-- <pre>{{ additionalFieldValues | json }}</pre> -->
    <fsx-additional-fields-container
      *ngIf="nestedAdditionalFieldSpecs.length > 0"
      [additionalFieldSpecs]="nestedAdditionalFieldSpecs"
      [additionalFieldValues]="additionalFieldValues"
      [resolver]="resolver"
      (additionalFieldValuesEvent)="
        onNestedAdditionalFieldValuesEmitted($event)
      "
    >
    </fsx-additional-fields-container>
  </div>
</ng-container>
