import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { MatCheckbox } from '@angular/material/checkbox';
import {
  BooleanFieldDefinition,
  CombinedFilingData,
  FieldCategory,
  StringSelectionFieldDefinition,
} from '@fsx/fsx-shared';
import { FormControlWithModel } from '../../models/form-control.model';
import { DropdownOption, ReferenceResolver } from '../../types';

@Component({
  selector: 'fsx-subcategory-component',
  templateUrl: './subcategory.component.html',
  styleUrls: ['./subcategory.component.scss'],
})
export class FsxSubcategoryComponent implements OnInit {
  @ViewChild('checkbox') checkbox!: MatCheckbox;
  @Input() fieldDefinition!: StringSelectionFieldDefinition;
  @Input() initialValues!: string[];
  @Input() width!: string;
  @Input() resolver!: ReferenceResolver;
  @Input() combinedFilingData!: CombinedFilingData | null;

  @Output() formControlEmitter = new EventEmitter<
    FormControlWithModel<StringSelectionFieldDefinition>[]
  >();
  @Output() setValues = new EventEmitter<string[]>();

  public showPlaceholder = true;
  public caption = 'Party SubCategory';
  public options!: DropdownOption<void>[];
  public formControl: FormControlWithModel<BooleanFieldDefinition>[] = [];
  public fieldType = FieldCategory.Boolean;
  public booleanFieldDefinition: BooleanFieldDefinition = {
    readOnly: false,
    visible: true,
    additionalFields: [],
  };
  public subcategoriesSelected: string[] = [];
  public isValid = true;
  public validationMessage: string = '';

  ngOnInit(): void {
    if (this.initialValues) {
      this.subcategoriesSelected = this.initialValues;
    }
    this.options = this.resolver.getStringDropdownOptions(
      this.fieldDefinition.listReference,
    );
  }

  public setFormControl(
    formControl: FormControlWithModel<BooleanFieldDefinition>,
    index: number,
  ) {
    if (this.subcategoriesSelected.includes(this.options[index].name)) {
      formControl.setValue(true);
    }
    this.formControl[index] = formControl;
    formControl.valueChanges.subscribe(() => {
      this._mapOptionsToStringArray();
    });
  }

  _mapOptionsToStringArray() {
    this.options.forEach((option, index) => {
      if (this.formControl[index].value === true) {
        if (!this.subcategoriesSelected.includes(option.name)) {
          this.subcategoriesSelected.push(option.name);
        }
      } else {
        if (this.subcategoriesSelected.includes(option.name)) {
          const index = this.subcategoriesSelected.indexOf(option.name);
          this.subcategoriesSelected.splice(index, 1);
        }
      }
    });
    this.setValues.emit(this.subcategoriesSelected);
  }

  public validate(): void {
    if (this.subcategoriesSelected.length < this.fieldDefinition.minRequired) {
      this.validationMessage = `at least ${this.fieldDefinition.minRequired}`;
      this.isValid = false;
      return;
    }

    if (this.subcategoriesSelected.length > this.fieldDefinition.maxAllowed) {
      this.validationMessage = `at most ${this.fieldDefinition.maxAllowed}`;
      this.isValid = false;
      return;
    }

    this.validationMessage = '';
    this.isValid = true;
  }
}
