import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import {
  AdditionalFieldSpec,
  AdditionalFieldValue,
  AddressFieldDefinition,
  AddressViewModel,
  ProfileListReference,
} from '@fsx/fsx-shared';
import {
  AddressComponentFieldDefinition,
  AddressFormGroup,
  FormArrayWithModel,
  ReferenceResolver,
} from '@fsx/ui-components';
import { debounceTime, skip, tap } from 'rxjs';

@Component({
  selector: 'fsx-addresses-additional-field',
  templateUrl: './addresses-additional-field.component.html',
  styleUrls: ['./addresses-additional-field.component.scss'],
})
export class AddressesAdditionalFieldComponent {
  /**
   * Passed in here to forward onto the contact component.
   */
  @Input() resolver!: ReferenceResolver;

  /**
   * A single AdditionalFieldSpec object to derive the participants formArray.
   */
  @Input() additionalFieldSpec!: AdditionalFieldSpec;

  /**
   * A single AdditionalFieldValue object to derive the participants formArray.
   */
  @Input() additionalFieldValue!: AdditionalFieldValue;

  /**
   * An event to notify FsxAdditionalFieldsContainerComponent that the AdditonalFieldValue
   * has been updated and that it should update it's collection of AdditionalFieldValue objects.
   */
  @Output() additionalFieldValueEvent =
    new EventEmitter<AdditionalFieldValue>();

  getAddressFieldDefinition(
    addrFieldDefinition: AddressFieldDefinition,
  ): AddressComponentFieldDefinition | null {
    let addressFieldDefinition: AddressComponentFieldDefinition;
    const addressCategoriesDefinition = this.resolver.getAddressProfile(
      addrFieldDefinition.addressProfileName,
    );
    if (addressCategoriesDefinition) {
      addressFieldDefinition = {
        minRequired: addrFieldDefinition.minRequired,
        maxAllowed: addrFieldDefinition.maxAllowed,
        addressProfileName: addrFieldDefinition.addressProfileName,
        country: addrFieldDefinition.country,
        category: addrFieldDefinition.category,
        description: addrFieldDefinition.description,
        additionalFields: addrFieldDefinition.additionalFields,
        ...addressCategoriesDefinition.spec,
      };
    } else {
      return null;
    }

    return addressFieldDefinition;
  }

  setAddressFormArray(
    formArray: FormArrayWithModel<FormGroup<AddressFormGroup>>,
  ): void {
    let addressValues: AddressViewModel[] = [];
    formArray.valueChanges
      .pipe(
        debounceTime(700),
        skip(1),
        tap(() => {
          const additionalFieldValue: AdditionalFieldValue = {
            additionalFieldName: this.additionalFieldSpec.name,
            addressValues: [],
          };

          formArray.value.forEach(
            (address: AddressViewModel, index: number) => {
              const listReference =
                this.additionalFieldSpec.addressFieldDefinition?.category
                  ?.listReference ??
                ({
                  list: 'AddressCategories',
                  additionalListName: '',
                } as ProfileListReference);
              let dropdownCategory = this.resolver
                .getAddressCategoryDropdownOptions(listReference)
                .find((element) => element.category?.name === address.category);
              if (dropdownCategory?.category) {
                address.category = {
                  caption: dropdownCategory.category.caption,
                  name: dropdownCategory.category.name,
                  commonCategory: dropdownCategory.category.commonCategory,
                };
              }
              let newAddress: AddressViewModel = {
                ...address,
                caption: '',
              } as AddressViewModel;
              newAddress.caption = this.setAddressCaption(newAddress);
              addressValues[index] = newAddress;
            },
          );

          additionalFieldValue.addressValues = addressValues;

          // Issue the update
          // this.additionalFieldValue.addressValues = addressValues;
          this.additionalFieldValueEvent.emit(additionalFieldValue);
        }),
      )
      .subscribe();
  }

  setAddressCaption(newAddress: AddressViewModel): string {
    let caption = '';
    if (newAddress.addressLine1) {
      caption = `${newAddress.addressLine1}`;
    }

    if (newAddress.addressLine2) {
      caption += `, ${newAddress.addressLine2}`;
    }

    if (newAddress.locality) {
      caption += `, ${newAddress.locality}`;
    }

    if (newAddress.administrativeArea) {
      caption += `, ${newAddress.administrativeArea}`;
    }

    if (newAddress.postalCode) {
      caption += `, ${newAddress.postalCode}`;
    }

    if (newAddress.country) {
      caption += `, ${newAddress.country}`;
    }

    return caption;
  }
}
