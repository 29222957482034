import { InjectionToken } from '@angular/core';
import { ValidationDetail, ValidationResult } from '@fsx/fsx-shared';
import { ValidationError } from './validation-errors.service';
import { ValidationGroupConstants } from './validation-group-errors.service';

/**
 * The InjectionToken to use in the providers array to specify a concrete-implementation
 * of the IValidationResultMappingService to use at runtime.
 */
export const FsxValidationResultMappingService =
  new InjectionToken<IValidationResultMappingService>(
    'FsxValidationResultMappingService',
  );

/**
 * A blueprint for a mapping service, which maps ValidationResult object returned from
 * the server to ValidationError array to display in the filing checklist.
 */
export interface IValidationResultMappingService {
  /**
   * A mapping function to map from a ValidatonResult object to a ValidationErrors array.
   *
   * @param validationResult The validationResult object as returned from the server.
   */
  map(validationResult: ValidationResult): ValidationError[];
}

/**
 * A constant to provide a consistent error code string pattern for
 * server validation errors.
 */
export const serverValidationErrorCode: string = 'server-validation-error';

/**
 * A concrete implementation of a mapping service, which maps ValidationResult object
 * returned from the server to ValidationError array to display in the filing checklist.
 */
export class ValidationResultMappingService
  implements IValidationResultMappingService
{
  /**
   * A mapping function to map from a ValidatonResult object to a ValidationErrors array.
   *
   * @param validationResult The validationResult object as returned from the server.
   */
  map(validationResult: ValidationResult): ValidationError[] {
    let validationErrors: ValidationError[] = [];
    let count: number = 0;
    if (validationResult?.validations) {
      for (const key in validationResult.validations) {
        const validationDetails: ValidationDetail[] =
          validationResult.validations[key];
        validationDetails.forEach((validationDetail: ValidationDetail) => {
          count++;

          let group = ValidationGroupConstants.unknown;

          if (validationDetail.field === 'Documents') {
            group = ValidationGroupConstants.documents;
          }

          if (validationDetail.field === 'Parties') {
            group = ValidationGroupConstants.parties;
          }

          if (key.indexOf('case.Cases') > -1) {
            group = ValidationGroupConstants.detail;
          }

          if (key.indexOf('case.Documents') > -1) {
            group = ValidationGroupConstants.documents;
          }

          if (key.indexOf('case.Parties') > -1) {
            group = ValidationGroupConstants.parties;
          }

          if (key.indexOf('case.Participants') > -1) {
            group = ValidationGroupConstants.parties;
          }

          const validationError: ValidationError = {
            errorCode: `${serverValidationErrorCode}-${count}`,
            errorMessage: validationDetail.caption,
            group,
          };
          validationErrors.push(validationError);
        });
      }
    }
    return validationErrors;
  }
}
