import { Inject, Injectable, InjectionToken } from '@angular/core';
import {
  CurrencyFieldDefinition,
  CurrencyValue,
  IValidatable,
} from '../../../types';
import {
  FsxValidationHelperService,
  IValidationHelperService,
} from './validation-helper.service';

export const FsxCurrencyValidationService =
  new InjectionToken<ICurrencyValidationService>(
    'FsxCurrencyValidationService',
  );

export interface ICurrencyValidationService {
  validateCurrency(
    currency: CurrencyValue | null | undefined,
    spec: CurrencyFieldDefinition | null | undefined,
    scope: IValidatable,
  ): boolean;
}

@Injectable()
export class CurrencyValidationService implements ICurrencyValidationService {
  constructor(
    @Inject(FsxValidationHelperService)
    private readonly validationHelperService: IValidationHelperService,
  ) {}

  public validateCurrency(
    currency: CurrencyValue | null | undefined,
    spec: CurrencyFieldDefinition | null | undefined,
    scope: IValidatable,
  ): boolean {
    if (!spec) {
      return true;
    }

    if (!currency) {
      if (spec.required) {
        scope.isValid = false;
        return false;
      }
      return true;
    }

    const decimalCount = this.validationHelperService.countDecimals(
      currency.amount,
    );
    if (!spec.allowDecimals && decimalCount > 0) {
      scope.isValid = false;
      return false;
    }

    if (spec.allowDecimals && decimalCount > 2) {
      scope.isValid = false;
      return false;
    }

    if (spec.minValue && currency.amount < spec.minValue) {
      scope.isValid = false;
      return false;
    }

    if (spec.maxValue && currency.amount > spec.maxValue) {
      scope.isValid = false;
      return false;
    }

    if (!currency.currencyKey) {
      scope.isValid = false;
      return false;
    }

    return true;
  }
}
