import { Inject, Injectable, InjectionToken } from '@angular/core';
import {
  CaseRequestViewModel,
  RequestParticipantViewModel,
} from '@fsx/fsx-shared';
import { BehaviorSubject, Observable, combineLatest, filter, map } from 'rxjs';
import {
  FsxCaseRequestDataService,
  ICaseRequestDataService,
} from './case-request-data.service';

/**
 * The InjectionToken to use in the providers array to specify a concrete-implementation
 * of the IParticipantDataService to use at runtime.
 */
export const FsxParticipantDataService =
  new InjectionToken<IParticipantDataService>('FsxParticipantDataService');

/**
 * A blueprint for a state service for storing the RequestParticipant objects.
 */
export interface IParticipantDataService {
  /**
   * A public member, which combines "Case Participants" with "Case Request Participants"
   * and exposes the result as an Observable of RequestParticipant objects.
   */
  participants$: Observable<RequestParticipantViewModel[]>;

  /**
   * A public method, which sets the array of RequestParticipant objects
   */
  setParticipants(participants: RequestParticipantViewModel[]): void;
}

/**
 * A concrete implementation of a state service for storing the RequestParticipant objects.
 */
@Injectable()
export class ParticipantDataService implements IParticipantDataService {
  /**
   * A private member, which stores the "Case Participants" as an array of RequestParticipant
   * objects in a BehaviorSubject.
   */
  private caseParticipants$$ = new BehaviorSubject<
    RequestParticipantViewModel[] | null
  >(null);

  /**
   * A private member, which exposes the "Case Participants" as an observable.
   */
  private caseParticipants$ = this.caseParticipants$$.asObservable().pipe(
    filter((participants) => participants !== null),
    map((participants) => participants as RequestParticipantViewModel[]),
  );

  /**
   * A private member, which exposes the "Case Request Participants" as an observable.
   */
  private caseRequestParticipants$ =
    this.caseRequestDataService.caseRequest$.pipe(
      map((caseRequest: CaseRequestViewModel) => {
        const caseRequestParticipants = caseRequest.participants || [];
        return caseRequestParticipants;
      }),
    );

  /**
   * A public member, which combines "Case Participants" with "Case Request Participants"
   * and exposes the result as an Observable of RequestParticipant objects.
   */
  participants$: Observable<RequestParticipantViewModel[]> = combineLatest([
    this.caseParticipants$,
    this.caseRequestParticipants$,
  ]).pipe(
    map(
      ([caseParticipants, caseRequestParticipants]: [
        RequestParticipantViewModel[],
        RequestParticipantViewModel[],
      ]) => {
        // Combine the "Case Participants" CaseParty objects with the "Case Request Participants" objects here...

        // Derive a list of ParticipantNames from the "Case Request Participants" that exist on the CaseRequest.
        // - We will use these to remove any duplicate CaseParty objects next.
        const caseRequestPartyNames = caseRequestParticipants.map(
          (p) => p.name,
        );

        // Exclude any "Case Participants" that are already "Case Request Participants"
        const filteredCaseParticipants = caseParticipants.filter((p) => {
          const exists = caseRequestPartyNames.indexOf(p.name) > -1;
          return !exists;
        });

        // Combine the "Case Request Participants" with the filtered "Case Participants" into a single array.
        const participants = [
          ...caseRequestParticipants,
          ...filteredCaseParticipants,
        ];

        return participants;
      },
    ),
  );

  /**
   * @param caseRequestDataService Where we get the "Case Request Parties" from.
   */
  constructor(
    @Inject(FsxCaseRequestDataService)
    private readonly caseRequestDataService: ICaseRequestDataService,
  ) {}

  /**
   * A public method, which sets the array of RequestParticipant objects
   *
   * @param participants The array of RequestParticipant objects to store
   */
  setParticipants(participants: RequestParticipantViewModel[]): void {
    const mewParticipantsObj = JSON.parse(JSON.stringify(participants));
    this.caseParticipants$$.next(mewParticipantsObj);
  }
}
