<div
  class="fsx-boolean-component"
  [ngStyle]="{
    width: width,
    height: height,
    display: visible ? 'block' : 'none',
  }"
>
  <div class="checkbox-container">
    <mat-checkbox
      #checkbox
      color="primary"
      [ngClass]="{
        readonly: readonly,
      }"
      [formControl]="formControl"
      [checked]="formControl.value"
    ></mat-checkbox>
    <label
      [ngClass]="{
        disabled: formControl.disabled || readonly,
      }"
      (click)="onLabelClicked()"
      >{{ caption }}
    </label>

    <label class="tooltip-container">
      <mat-icon *ngIf="helpText" [matTooltip]="helpText">info_outline</mat-icon>
    </label>
  </div>

  <p class="error-message" *ngIf="formControl.invalid && formControl.touched">
    {{ getErrorMessage() }}
  </p>
</div>
