import { Inject, Injectable, InjectionToken } from '@angular/core';
import {
  PhoneViewModel,
  PhoneSpec,
  IValidatable,
  FilingProfile,
} from '../../../types';
import {
  FsxTextFieldValidationService,
  ITextFieldValidationService,
} from './text-field-validation.service';
import {
  FsxValidationErrorsService,
  IValidationErrorsService,
} from 'projects/apps/fsx-ui/src/app/filing-editor/services/validation-errors.service';
import { ValidationGroupConstants } from 'projects/apps/fsx-ui/src/app/filing-editor/services/validation-group-errors.service';

export const FsxPhoneValidationService =
  new InjectionToken<IPhoneValidationService>('FsxPhoneValidationService');

export interface IPhoneValidationService {
  validatePhones(
    phones: PhoneViewModel[],
    spec: PhoneSpec | null | undefined,
    scope: IValidatable,
    filingProfile: FilingProfile,
    parentEntityId?: string,
  ): boolean;

  validatePhone(
    phone: PhoneViewModel,
    spec: PhoneSpec | null | undefined,
    scope: IValidatable,
    filingProfile: FilingProfile,
    parentEntityId?: string,
  ): boolean;
}

@Injectable()
export class PhoneValidationService implements IPhoneValidationService {
  constructor(
    @Inject(FsxTextFieldValidationService)
    private readonly textFieldValidationService: ITextFieldValidationService,
    @Inject(FsxValidationErrorsService)
    private readonly validationErrorsService: IValidationErrorsService,
  ) {}

  public validatePhones(
    phones: PhoneViewModel[],
    spec: PhoneSpec | null | undefined,
    scope: IValidatable,
    filingProfile: FilingProfile,
    parentEntityId?: string,
  ): boolean {
    let result = true;

    if (!spec) {
      return true;
    }

    if (!phones) {
      phones = [];
    }

    const minPhonesErrorCode = 'minPhonesErrorCode';
    if (phones.length < spec.minRequired) {
      scope.isValid = false;
      result = false;
      this.validationErrorsService.addValidationError({
        errorCode: minPhonesErrorCode,
        errorMessage: `Must provide at least ${spec.minRequired}`,
        group: ValidationGroupConstants.parties,
        metadata: {
          entityId: parentEntityId,
          parentEntityId: parentEntityId,
          formSectionName: 'Phone',
        },
      });
    } else {
      this.validationErrorsService.removeValidationError(
        minPhonesErrorCode,
        parentEntityId,
      );
    }

    const maxPhonesErrorCode = 'maxPhonesErrorCode';
    if (phones.length > spec.maxAllowed) {
      scope.isValid = false;
      result = false;
      this.validationErrorsService.addValidationError({
        errorCode: maxPhonesErrorCode,
        errorMessage: `Must not provide more than ${spec.maxAllowed}`,
        group: ValidationGroupConstants.parties,
        metadata: {
          entityId: parentEntityId,
          parentEntityId: parentEntityId,
          formSectionName: 'Phone',
        },
      });
    } else {
      this.validationErrorsService.removeValidationError(
        maxPhonesErrorCode,
        parentEntityId,
      );
    }

    for (let index = 0; index < phones.length; index++) {
      const phone = phones[index];
      if (!this.validatePhone(phone, spec, scope, filingProfile)) {
        scope.isValid = false;
        result = false;
      }
    }

    return result;
  }

  public validatePhone(
    phone: PhoneViewModel,
    spec: PhoneSpec | null | undefined,
    scope: IValidatable,
    filingProfile: FilingProfile,
    parentEntityId?: string,
  ): boolean {
    if (!spec) {
      return true;
    }

    phone.isValid = true;

    return this.textFieldValidationService.validateTextField(
      scope,
      filingProfile,
      spec.fullNumber,
      phone.fullNumber,
    );
  }
}
