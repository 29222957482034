import {
  Component,
  EventEmitter,
  Inject,
  Injector,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { PartiesGridRow } from '../parties-grid/parties-grid.model';
import {
  EditParticipantEventParams,
  EditRepresentationEventParams,
  ParticipantViewMinMaxValues,
  PartiesGridConfig,
  UpdateParticipantEventParams,
} from '../parties-grid/parties-grid.component';
import {
  AdditionalFieldValue,
  CasePartyViewModel,
  CombinedFilingData,
  ContactSummary,
  ContactSummaryViewModel,
  ContactViewModel,
  CreateDocumentInfoService,
  DocumentInfo,
  FieldCategory,
  ParticipantCategory,
  ParticipantCommonCategory,
  ParticipantFormMode,
  ParticipantSpec,
  RequestParticipantRepresentationViewModel,
  RequestParticipantViewModel,
} from '@fsx/fsx-shared';
import {
  DropdownOption,
  FilesUploadedEventParams,
  FormControlWithoutModel,
  SelectionFieldType,
} from '@fsx/ui-components';
import { ContactsSearchTypeEnum } from '../../contacts/contacts.model';
import { FsxReferenceResolver } from '../../shared/resolvers/list-reference.resolver';
import {
  AttorneySelectedEventParams,
  ContactSummariesSelectedEventParams,
  ParticipantSelectedEventParams,
} from '../representation-grid/representation-grid.component';
import {
  FsxCombinedFilingDataService,
  ICombinedFilingDataService,
} from '../../filing-editor/services/combined-filing-data.service';
import {
  Observable,
  combineLatest,
  of,
  switchMap,
  take,
  tap,
  withLatestFrom,
} from 'rxjs';
import {
  FsxUpdateParticipantOrchestrationService,
  IUpdateParticipantOrchestrationService,
  IUpdateParticipantParams,
} from '../orchestration-services/update-participant-orchestration.service';
import {
  FsxAddContactAsParticipantOrchestrationService,
  IAddContactAsParticipantOrchestrationService,
  IAddContactAsParticipantParams,
} from '../orchestration-services/add-contact-as-participant-orchestration.service';
import {
  FsxClearParticipantOrchestrationService,
  IClearParticipantEventParams,
  IClearParticipantOrchestrationService,
} from '../orchestration-services/clear-participant-orchestration.service';
import {
  FsxAddSelectedContactsAsParticipantsOrchestrationService,
  IAddSelectedContactsAsParticipantsOrchestrationService,
} from '../orchestration-services/add-selected-contacts-as-participants-orchestration.service';
import {
  FsxClearRepresentationOrchestrationService,
  IClearRepresentationOrchestrationService,
  IClearRepresentationParams,
} from '../orchestration-services/clear-representation-orchestration.service';
import {
  FsxRemoveRepresentationOrchestrationService,
  IRemoveRepresentationOrchestrationService,
  IRemoveRepresentationEventParams,
} from '../orchestration-services/remove-representation-orchestration.service';
import {
  FsxUpdateRepresentationOrchestrationService,
  IUpdateRepresentationOrchestrationService,
  IUpdateRepresentationParams,
} from '../orchestration-services/update-representation-orchestration.service';
import {
  FsxOpenParticipantFormOrchestrationService,
  IOpenParticipantFormOrchestrationService,
} from '../../shared/services/open-participant-form-orchestration.service';
import {
  FsxAddRepresentationOrchestrationService,
  IAddRepresentationOrchestrationService,
  IAddRepresentationParams,
} from '../orchestration-services/add-representation-orchestration.service';
import {
  FsxAddSelectedContactsAsRepresentationOrchestrationService,
  IAddSelectedContactsAsRepresentationOrchestrationService,
  IAddSelectedContactsAsRepresentativesParams,
} from '../orchestration-services/add-selected-contacts-as-representatives-orchestration.service';
import {
  DocumentInfoAndUploadedFile,
  FsxUploadAdditionalFieldFilesOrchestrationService,
  IUploadAdditionalFieldFilesOrchestrationService,
} from '../../documents/services/upload-additional-field-files-orchestration.service';
import {
  FsxAddDefaultParticipantOrchestrationService,
  IAddDefaultParticipantOrchestrationService,
} from '../orchestration-services/add-default-participant-orchestration.service';
import {
  FsxUpdatePartyOrchestrationService,
  IUpdatePartyOrchestrationService,
} from '../orchestration-services/update-party-orchestration.service';
import {
  FsxOpenContactsListOrchestrationService,
  IOpenContactsListOrchestrationService,
} from '../../shared/services/open-contact-list-orchestration.service';

@Component({
  selector: 'fsx-parties-grid-detail',
  templateUrl: './parties-grid-detail.component.html',
  styleUrls: ['./parties-grid-detail.component.scss'],
})
export class PartiesGridDetailComponent implements OnInit {
  @Input() row!: PartiesGridRow;

  @Input() partiesMap = new Map<string, ParticipantViewMinMaxValues>();

  @Input() existingPartiesContactIds!: string[] | undefined;

  @Input() isInitiating!: boolean;

  @Input() resolver!: FsxReferenceResolver;

  @Input() partiesGridConfig!: PartiesGridConfig;

  @Output() editParticipantEvent =
    new EventEmitter<EditParticipantEventParams>();

  @Output() selectedContactSummariesEvent = new EventEmitter<
    ContactSummary[]
  >();

  @Output() partyTypeSelectedEvent = new EventEmitter<{
    value: string;
    caseParty: CasePartyViewModel;
    partyIndex: number;
  }>();

  @Output() filesUploadedFromPartiesAdditionalFieldEvent = new EventEmitter<{
    params: FilesUploadedEventParams;
    row: PartiesGridRow;
  }>();

  @Output() attorneySelectedEvent =
    new EventEmitter<AttorneySelectedEventParams>();

  @Output() clearRepresentationEvent = new EventEmitter<CasePartyViewModel>();

  @Output() contactSummariesSelectedEvent =
    new EventEmitter<ContactSummariesSelectedEventParams>();

  @Output() addRepresentationEvent =
    new EventEmitter<ParticipantSelectedEventParams>();

  /**
   * An output event to allow for the triggering of the top-down party-level validation.
   */
  @Output() validateParentPartyEvent = new EventEmitter<PartiesGridRow>();

  @Output() setAdditionalFieldValuesEvent = new EventEmitter<{
    value: AdditionalFieldValue;
    partyIndex: number;
  }>();

  fieldType = FieldCategory;

  contactsSearchType = ContactsSearchTypeEnum;

  selectionType = SelectionFieldType.StringSelectionFieldDefinition;

  /**
   * The collection of DocumentInfo objects to pass to the AdditionalFieldsComponent
   */
  partyFileUploadDocumentInfos: DocumentInfo[] = [];

  combinedFilingData$ = this.combinedFilingDataService.combinedFilingData$;

  participantCategory!: ParticipantCategory;

  /**
   * The array of dropdown options to populate the "Party Type" dropdown list.
   * (derived from passed in array of ParticipantSpecs)
   */
  partyTypeDropdownOptions: DropdownOption<void>[] = [];

  public participantsListFormControl!: FormControlWithoutModel;

  /**
   * The participant spec as determined by the "Party Type" dropdown in the view.
   */
  currentParticipantSpec: ParticipantSpec | undefined;

  /**
   * The ParticipantCommonCategory enum which we use in the template to set the
   * ParticipantCommonCategory.Attorney value in the ContactSearchComponent.
   */
  participantCommonCategoryEnum: typeof ParticipantCommonCategory =
    ParticipantCommonCategory;

  /**
   *
   */
  constructor(
    readonly injector: Injector,
    @Inject(FsxOpenContactsListOrchestrationService)
    private readonly openContactsListOrchestrationService: IOpenContactsListOrchestrationService,
    @Inject(FsxUpdatePartyOrchestrationService)
    private readonly updatePartyOrchestrationService: IUpdatePartyOrchestrationService,
    @Inject(FsxUploadAdditionalFieldFilesOrchestrationService)
    private readonly uploadAdditionalFieldFilesOrchestrationService: IUploadAdditionalFieldFilesOrchestrationService,
    @Inject(FsxAddRepresentationOrchestrationService)
    private readonly addRepresentationOrchestrationService: IAddRepresentationOrchestrationService,
    @Inject(FsxAddSelectedContactsAsRepresentationOrchestrationService)
    private readonly addSelectedContactsAsRepresentationOrchestrationService: IAddSelectedContactsAsRepresentationOrchestrationService,
    @Inject(FsxOpenParticipantFormOrchestrationService)
    private readonly openParticipantFormOrchestrationService: IOpenParticipantFormOrchestrationService,
    @Inject(FsxRemoveRepresentationOrchestrationService)
    private readonly removeRepresentationOrchestrationService: IRemoveRepresentationOrchestrationService,
    @Inject(FsxUpdateRepresentationOrchestrationService)
    private readonly updateRepresentationOrchestrationService: IUpdateRepresentationOrchestrationService,
    @Inject(FsxClearRepresentationOrchestrationService)
    private readonly clearRepresentationOrchestrationService: IClearRepresentationOrchestrationService,
    @Inject(FsxAddSelectedContactsAsParticipantsOrchestrationService)
    private readonly addSelectedContactsAsParticipantsOrchestrationService: IAddSelectedContactsAsParticipantsOrchestrationService,
    @Inject(FsxClearParticipantOrchestrationService)
    private readonly clearParticipantOrchestrationService: IClearParticipantOrchestrationService,
    @Inject(FsxAddContactAsParticipantOrchestrationService)
    private readonly addContactAsParticipantOrchestrationService: IAddContactAsParticipantOrchestrationService,
    @Inject(FsxCombinedFilingDataService)
    private readonly combinedFilingDataService: ICombinedFilingDataService,
    @Inject(FsxUpdateParticipantOrchestrationService)
    private readonly updateParticipantOrchestrationService: IUpdateParticipantOrchestrationService,
    @Inject(FsxAddDefaultParticipantOrchestrationService)
    private readonly addDefaultParticipantOrchestrationService: IAddDefaultParticipantOrchestrationService,
    private readonly createDocumentInfoService: CreateDocumentInfoService,
  ) {}

  ngOnInit() {
    this.setPartyTypeDropdownOptions(this.partiesGridConfig.participantSpecs);
    this.createParticipantsListDropdown();
    this.setPartyFileUploadDocumentInfos();
  }

  private setPartyFileUploadDocumentInfos() {
    this.combinedFilingDataService.combinedFilingData$
      .pipe(
        take(1),
        tap((combinedFilingData: CombinedFilingData) => {
          if (combinedFilingData) {
            const documentInfos = combinedFilingData.documentInfos || [];
            const caseRequestParties =
              combinedFilingData.caseRequest.parties || [];
            if (caseRequestParties.length > 0) {
              const partyAdditionalFieldValues =
                caseRequestParties[0].additionalFieldValues || [];
              const partyFileValues: string[] =
                partyAdditionalFieldValues.flatMap(
                  (addlFieldValue: AdditionalFieldValue) => {
                    return addlFieldValue.fileValues || [];
                  },
                );

              // Should refresh the documentInfos for this party.
              // Does not work, no file size displayed after file uploads.
              // This probably won't work until polling is moved, or extended to work across all tabs.
              this.partyFileUploadDocumentInfos = documentInfos.filter(
                (docInfo: DocumentInfo) => {
                  return partyFileValues.includes(docInfo.id);
                },
              );
            }
          }
        }),
      )
      .subscribe();
  }

  /**
   * A handler method for the FsxContactSearchCompont's selectedContactSummariesEvent. This is the
   * handler function that gets triggered after selecting contacts through the "Manage Contacts" link
   *
   * @param participantName  The participantName of the default party that is to be removed and
   * replaced with the new party/parties derived from the selected contacts.
   *
   * @param contactSummaries The selected ContactSummary objects to derive new parties from.
   */
  selectedContactSummariesEventHandler(
    participantName: string,
    contactSummaries: ContactSummary[],
  ) {
    this.addSelectedContactsAsParticipants({
      participantCategory: this.participantCategory,
      participantSpec: this.currentParticipantSpec!,
      participantName,
      selectedContactSummaries: contactSummaries,
    });
  }

  /**
   * A handler function for the "Party Type" dropdown's selectedValue
   * event. We hook into it here to trigger a PATCH request to apply
   * the update on the server.
   *
   * @param params The params object needed to initiate orchestration.
   */
  onPartyTypeSelected(params: {
    /**
     * The selected ParticipantCategory name (id). Needed here to
     * lookup the ParticipantCategory that we want to set.
     */
    value: string;

    /**
     * The CaseParty object to set the ParticipantCategory on.
     */
    caseParty: CasePartyViewModel;

    /**
     * The index of the CaseParty object in the CaseRequest.parties
     * array. This is needed for the subsequent PATCH request.
     */
    partyIndex: number;
  }): void {
    // Guard clause to prevent infinite loop
    const isSameValue =
      params.caseParty.participantCategory?.name === params.value;
    if (isSameValue) {
      return;
    }

    // Guard clause to prevent looking up a value that doesn't exist
    if (!params.value) {
      return;
    }

    // Lookup the ParticipantSpec using the selected ParticipantCategory name.
    const participantSpec: ParticipantSpec =
      this.partiesGridConfig.participantSpecs.find((pSpec: ParticipantSpec) => {
        return pSpec.participantCategory.name === params.value;
      })!;

    this.currentParticipantSpec = participantSpec;

    this.updateParticipant({
      participantCategory: participantSpec.participantCategory,
      caseParty: params.caseParty,
      partyIndex: params.partyIndex,
    });
  }

  public setValues(values: string[], partyIndex: number) {
    this.combinedFilingDataService.combinedFilingData$
      .pipe(
        take(1),
        tap((combinedFilingData: CombinedFilingData) => {
          if (
            combinedFilingData?.caseRequest &&
            combinedFilingData.caseRequest.parties
          ) {
            combinedFilingData.caseRequest.parties[
              partyIndex
            ].participantSubCategoryNames = values;
            this.resolver.updateCaseRequestPut(
              combinedFilingData.caseRequest,
              combinedFilingData.filing.id,
            );
          }
        }),
      )
      .subscribe();
  }

  addRepresentationEventHandler(params: ParticipantSelectedEventParams): void {
    this.addRepresentationEvent.emit(params);
  }

  validateParticipant(row: PartiesGridRow): void {
    this.validateParentPartyEvent.emit(row);
  }

  /**
   * A helper function for updating the participant on the CaseRequest object. This
   * is called when the user selects a new option from the "Party Type" dropdown.
   */
  private updateParticipant(params: UpdateParticipantEventParams) {
    this.combinedFilingDataService.combinedFilingData$
      .pipe(
        take(1),
        tap((combinedFilingData: CombinedFilingData) => {
          // We cannot continue without a caseRequestPartyIndex.
          // This is needed to be able to make the subsequent PATCH request.
          if (params.caseParty.caseRequestPartyIndex === undefined) {
            console.error(
              'Attempt made to update a CaseParty without a caseRequestPartyIndex',
              params.caseParty,
            );
            return;
          }

          const updateParticipantParams: IUpdateParticipantParams = {
            filingId: combinedFilingData.filing.id,
            caseRequest: combinedFilingData.caseRequest,
            caseRequestPartyIndex: params.caseParty.caseRequestPartyIndex,
            participantCategory: params.participantCategory,
          };
          this.updateParticipantOrchestrationService.updateParticipant(
            updateParticipantParams,
          );
        }),
      )
      .subscribe();
  }

  private createParticipantsListDropdown(): void {
    const firstOrDefault: string =
      this.partyTypeDropdownOptions.length > 0
        ? this.partyTypeDropdownOptions[0].name
        : '';
    const selectedParticipantCategoryName =
      this.row.party.participantCategory?.name || firstOrDefault;
    this.setCurrentParticipantSpec(selectedParticipantCategoryName);
    this.setParticipantCategory(selectedParticipantCategoryName);
  }

  private setCurrentParticipantSpec(value: string) {
    this.currentParticipantSpec = this.partiesGridConfig.participantSpecs.find(
      (pSpec: ParticipantSpec) => {
        return pSpec.participantCategory.name === value;
      },
    );
  }

  private setParticipantCategory(value: string) {
    const selectedOption = this.partyTypeDropdownOptions.find(
      (option) => option.name === value,
    );

    if (selectedOption) {
      this.participantCategory = {
        name: selectedOption.name,
        caption: selectedOption.caption,
        commonCategory:
          selectedOption.commonCategory as ParticipantCommonCategory,
      };
    }
  }

  /**
   * The handler function for the (selectedContactEvent) event from the FsxContactSearchComponent.
   * This event is raised when the user selects a contact in the contact search dropdown.
   *
   * @param params The ParticipantCategory, ParticipantSpec, Contact and ParticipantName
   * (Guid) from the FsxPartiesGridComponent instance from which the event was raised.
   */
  selectedContactEventHandler(
    contact: ContactViewModel,
    participantName: string,
  ) {
    if (!this.currentParticipantSpec) {
      console.error('Cannot continue without a particpant spec');
      return;
    }

    // Get the recentlyUsedContactIds array from session storage (if it exists),
    // otherwise create a new empty array to push to.
    const localStorageRecentlyUsedContactIds: string | null =
      localStorage.getItem('recentlyUsedContactIds');
    const recentlyUsedContactIds: string[] = localStorageRecentlyUsedContactIds
      ? JSON.parse(localStorageRecentlyUsedContactIds)
      : [];

    // The maximum number of recentlyUsedContactIds that we can store until we
    // have to start removing entries
    const maxRecentlyUsedContacts: number = 3;

    // Here we check if the contactId already exists.
    // - If it does exists it will return the index in the array
    // - if it does not exist it will return -1 (handled next)
    const existingContactIdIndex: number = recentlyUsedContactIds.findIndex(
      (contactId) => {
        return contactId === contact.id;
      },
    );

    // Here we set the index of the element that we will remove
    // (only used if maxRecentlyUsedContacts size is reached)
    const removeContactdFromIndex: number =
      existingContactIdIndex !== -1 ? existingContactIdIndex : 0;

    // We always push the most recently used contact id to the end of the array
    // so that the most recently used contactIds can be brought back in the
    // correct order, Last in First out (LIFO);
    recentlyUsedContactIds.push(contact.id);

    // Conditionoally remove a contactId entry from the array
    // (if the last push exceeded maxRecentlyUsedContacts size)
    if (recentlyUsedContactIds.length > maxRecentlyUsedContacts) {
      recentlyUsedContactIds.splice(removeContactdFromIndex, 1);
    }

    // Stringify and set the array back into localStorage
    localStorage.setItem(
      'recentlyUsedContactIds',
      JSON.stringify(recentlyUsedContactIds),
    );

    // this.contactSelectedEvent.next({
    //   contact,
    //   participantName,
    //   participantSpec: this.currentParticipantSpec,
    //   participantCategory: this.participantCategory,
    // });

    this.combinedFilingData$
      .pipe(
        take(1),
        tap((combinedFilingData: CombinedFilingData) => {
          const addContactAsParticipantParams: IAddContactAsParticipantParams =
            {
              contact,
              participantName,
              participantSpec: this.currentParticipantSpec!,
              participantCategory: this.participantCategory!,
              filingId: combinedFilingData.filing.id,
              caseRequest: combinedFilingData.caseRequest,
              filingProfile: combinedFilingData.filingProfile,
            };

          this.addContactAsParticipantOrchestrationService.addContactAsParticipant(
            addContactAsParticipantParams,
          );
        }),
      )
      .subscribe();
  }

  /**
   * A function to derive the "Party Type" dropdown options from a given array of ParticipantSpec objects.
   *
   * @param participantSpecs The Particpant Specs from which to derive the dropdown options.
   */
  private setPartyTypeDropdownOptions(
    participantSpecs: ParticipantSpec[],
  ): void {
    // Derive the dropdown options from the array of ParticipantSpec objects.
    this.partyTypeDropdownOptions = participantSpecs.map(
      (pSpec: ParticipantSpec) => {
        return { ...pSpec.participantCategory, selected: false };
      },
    );
  }

  /**
   * The handler function for the (clearParticipantEvent) event from the participant
   * table. This event is raised internally when the user clicks the trash can icon
   * on the nested participant table to clear the selected participant from the case
   * and reset the row to the default participant.
   *
   * @param partyToClear The CasePary object to clear and reset.
   */
  clearParticipantEventHandler(partyToClear: CasePartyViewModel): void {
    this.combinedFilingData$
      .pipe(
        take(1),
        tap((combinedFilingData: CombinedFilingData) => {
          const clearParticipantParams: IClearParticipantEventParams = {
            partyToClear,
            caseRequest: combinedFilingData.caseRequest,
            filingId: combinedFilingData.filing.id,
          };

          this.clearParticipantOrchestrationService.clearParticipant(
            clearParticipantParams,
          );
        }),
      )
      .subscribe();
  }

  /**
   * A handler function for the contact icon's click event.
   *
   * @param participantName The unique identifier for the participant row.
   */
  onOpenContactsIconClicked(participantName: string): void {
    if (!this.currentParticipantSpec) {
      console.error('Cannot continue without a particpant spec');
      return;
    }

    this.openContactsListOrchestrationService.openContactsList({
      searchType: ContactsSearchTypeEnum.contacts,
      commonCategory: this.partiesGridConfig.participantCommonCategory,
      addCallback: (contactSummaries: ContactSummaryViewModel[]) => {
        this.addSelectedContactsAsParticipants({
          participantCategory: this.participantCategory,
          participantSpec: this.currentParticipantSpec!,
          participantName,
          selectedContactSummaries: contactSummaries,
        });
      },
    });
  }

  /**
   * The callback function passed into the ContactsListPanelComponent, which gets invoked
   * when the user clicks the "Add" button from within the panel.
   */
  private addSelectedContactsAsParticipants(params: {
    selectedContactSummaries: ContactSummaryViewModel[];
    participantCategory: ParticipantCategory;
    participantSpec: ParticipantSpec;
    participantName: string;
  }) {
    this.combinedFilingData$
      .pipe(
        take(1),
        tap((combinedFilingData) => {
          this.addSelectedContactsAsParticipantsOrchestrationService.addSelectedContactsAsParticipants(
            {
              filingId: combinedFilingData.filing.id,
              caseRequest: combinedFilingData.caseRequest,
              contactSummaries: params.selectedContactSummaries,
              participantCategory: params.participantCategory,
              participantSpec: params.participantSpec,
              filingProfile: combinedFilingData.filingProfile,
              participantName: params.participantName,
            },
          );
        }),
      )
      .subscribe();
  }

  /**
   * The handler function for the (clearRepresentationEvent) event from each the
   * RepresentationGridComponent. This event is raised internally when the user clicks
   * the "Self Represented" checkbox to clear all representation from the case party.
   *
   * @param caseParty The Case Party object from which all representation is to be removed.
   */
  clearRepresentationEventHandler(caseParty: CasePartyViewModel) {
    this.combinedFilingData$
      .pipe(
        take(1),
        tap((combinedFilingData: CombinedFilingData) => {
          const clearRepresentationParams: IClearRepresentationParams = {
            filingId: combinedFilingData.filing.id,
            caseRequest: combinedFilingData.caseRequest,
            caseParty: caseParty,
          };
          this.clearRepresentationOrchestrationService.clearRepresentation(
            clearRepresentationParams,
          );
        }),
      )
      .subscribe();
  }

  /**
   * The handler function for the (removeRepresentationEvent) event from each instance
   * of the FsxPartiesGridComponent. This event is raised internally when the user clicks
   * on the trash can icon on a representation row in the representation grid within the
   * parties grid.
   *
   * @param params The RequestParticipantRepresentation object to remove along with the
   * CaseParty object to remove it from.
   */
  removeRepresentationEventHandler(params: {
    representationToRemove: RequestParticipantRepresentationViewModel;
    partyToRemoveFrom: CasePartyViewModel;
  }): void {
    this.combinedFilingData$
      .pipe(
        take(1),
        tap((combinedFilingData: CombinedFilingData) => {
          const removeParticipantParams: IRemoveRepresentationEventParams = {
            filingId: combinedFilingData.filing.id,
            caseRequest: combinedFilingData.caseRequest,
            representationToRemove: params.representationToRemove,
            partyToRemoveFrom: params.partyToRemoveFrom,
          };
          this.removeRepresentationOrchestrationService.removeRepresentation(
            removeParticipantParams,
          );
        }),
      )
      .subscribe();
  }

  /**
   * The handler function for the (updateRepresentationEvent) event from each instance
   * of the FsxPartiesGridComponent. This event is raised internally when the user selects
   * a new attorney type (role) from the dropdown on the representation row.
   */
  updateRepresentationEventHandler(params: {
    attorneyParticipantSpec: ParticipantSpec | null;
    attorneyParticipantCategory: ParticipantCategory | null;
    participantName: string;
    representation: RequestParticipantRepresentationViewModel;
    additionalFields: AdditionalFieldValue[] | null;
  }): void {
    this.combinedFilingData$
      .pipe(
        take(1),
        tap((combinedFilingData: CombinedFilingData) => {
          const caseRequestParties =
            combinedFilingData.caseRequest.parties || [];
          const caseParty: CasePartyViewModel = caseRequestParties.find(
            (party: CasePartyViewModel) => {
              return party.participantName === params.participantName;
            },
          )!;

          const updateRepresentationParams: IUpdateRepresentationParams = {
            ...params,
            filingId: combinedFilingData.filing.id,
            caseRequest: combinedFilingData.caseRequest,
            caseParty: caseParty,
            representation: params.representation,
            participantCategory: params.attorneyParticipantCategory,
            additionalFields: params.additionalFields,
          };
          this.updateRepresentationOrchestrationService.updateRepresentation(
            updateRepresentationParams,
          );
        }),
      )
      .subscribe();
  }

  /**
   * A handler function for the "Add Party" button's click event.
   * Here we open the participant form in the participant form panel
   * to allow the user to enter the new participant's details.
   *
   * @param participant The default RequestParticipant object to open
   * the panel with. This will be an bare-bones RequestParticipant with
   * only the name (id) property set. From the user perspective they are
   * adding a new record, but in code we are editing the existing object.
   */
  onAddPartyButtonClicked(participant: RequestParticipantViewModel) {
    this.combinedFilingData$
      .pipe(
        take(1),
        tap((combinedFilingData: CombinedFilingData) => {
          // Get the selected ParticipantCategory name from the "Party Type" dropdown.
          const participantCategoryName =
            this.participantsListFormControl.value;

          // Use the selected ParticpantCategory name to lookup the ParticipantSpec
          // in the FilingProfile.
          const participantSpec = combinedFilingData.modeSpec?.participant.find(
            (spec) => {
              return spec.participantCategory.name === participantCategoryName;
            },
          )!;

          // Open the ParticipantForm using the ParticipantSpec's ParticipantCategory
          this.openParticipantFormOrchestrationService.openParticipantForm({
            formMode: ParticipantFormMode.AddParticipant,
            participant: participant,
            isRepresentation: false,
            participantCategory: participantSpec.participantCategory,
          });
        }),
      )
      .subscribe();
  }

  public setBasicPartyFormControl(controls: FormControlWithoutModel) {
    this.participantsListFormControl = controls;
  }

  /**
   * The handler function for the (editRepresentationEvent) event from each instance
   * of the FsxPartiesGridComponent. This event is raised internally when the user
   * clicks the edit icon on the participant table in the representation section.
   *
   * @param params The RequestParticipantRepresentation, ParticpantCatgeory and
   * RequestParticipant objects.
   */
  editRepresentationEventHandler(params: EditRepresentationEventParams): void {
    this.openParticipantFormOrchestrationService.openParticipantForm({
      formMode: ParticipantFormMode.EditRepresentation,
      participant: params.participant,
      participantCategory: params.participantCategory!,
      isRepresentation: true,
    });
  }

  /**
   * The handler function for the (editParticipantEvent) event from each instance
   * of the FsxPartiesGridComponent. This event is raised internally when the user
   * clicks the edit icon on the nested participant table to start editing the
   * participant record.
   *
   * @param params The RequestParticipant and ParticipantCategory objects of the
   * participant that the user wants to edit.
   */
  editParticipantEventHandler(participant: RequestParticipantViewModel): void {
    this.openParticipantFormOrchestrationService.openParticipantForm({
      formMode: ParticipantFormMode.EditParticipant,
      participant,
      participantCategory: this.participantCategory,
      isRepresentation: false,
    });
  }

  /**
   * The handler function for the (attorneySelectedEvent) event from each instance
   * of the FsxPartiesGridComponent. This event is raised internally when the user
   * selects a contact from the contact search dropdown in the Representation section.
   *
   * @param params The Contact, ParticipantSpec and CaseParty objects from the
   * FsxPartiesGridComponent instance from which the event was raised.
   */
  attorneySelectedEventHandler(params: AttorneySelectedEventParams): void {
    this.combinedFilingData$
      .pipe(
        take(1),
        tap((combinedFilingData: CombinedFilingData) => {
          const addRepresentationParams: IAddRepresentationParams = {
            ...params,
            filingId: combinedFilingData.filing.id,
            caseRequest: combinedFilingData.caseRequest,
            participantSpec: params.participantSpec,
            filingProfile: combinedFilingData.filingProfile,
          };
          this.addRepresentationOrchestrationService.addRepresentation(
            addRepresentationParams,
          );
        }),
      )
      .subscribe();
  }

  /**
   * The handler function for the (contactSummariesSelectedEvent) event from each
   * instance of the FsxPartiesGridComponent. This event is raised internally when
   * the user opens the contacts list and selects contacts to add as participants
   * to the case.
   *
   * @param params The ParticipantSpec and CaseParty objects along with the array
   * of ContactSummaries objects from the FsxPartiesGridComponent instance from
   * which the event was raised.
   */
  contactSummariesSelectedEventHandler(
    params: ContactSummariesSelectedEventParams,
  ): void {
    this.combinedFilingData$
      .pipe(
        take(1),
        tap((combinedFilingData: CombinedFilingData) => {
          const addContactsAsRepresentativesParams: IAddSelectedContactsAsRepresentativesParams =
            {
              ...params,
              participantSpec: params.participantSpec,
              participantCategory: params.participantSpec.participantCategory,
              caseRequest: combinedFilingData.caseRequest,
              filingId: combinedFilingData.filing.id,
              filingProfile: combinedFilingData.filingProfile,
            };
          this.addSelectedContactsAsRepresentationOrchestrationService.addSelectedContactsAsRepresentatives(
            addContactsAsRepresentativesParams,
          );
        }),
      )
      .subscribe();
  }

  filesUploadedFromPartiesAdditionalFieldEventHandler(
    params: FilesUploadedEventParams,
    row: PartiesGridRow,
  ) {
    of(params)
      .pipe(
        take(1),
        withLatestFrom(this.combinedFilingData$),
        switchMap(
          ([filesUploadedEventParams, combinedFilingData]: [
            FilesUploadedEventParams,
            CombinedFilingData,
          ]) => {
            const { uploadedFiles } = filesUploadedEventParams;
            const arrayOfDocumentInfoAndUploadedFile$: Observable<DocumentInfoAndUploadedFile>[] =
              this.createDocumentInfoService.getArrayOfDocumentInfoAndUploadedFile(
                uploadedFiles,
                combinedFilingData.filing.id,
              );
            return combineLatest([...arrayOfDocumentInfoAndUploadedFile$]).pipe(
              tap(
                (
                  documentInfoAndUploadedFiles: DocumentInfoAndUploadedFile[],
                ) => {
                  // Create the array of documentIds to update the filesValue array with (below)
                  const documentIds: string[] =
                    documentInfoAndUploadedFiles.map(
                      (
                        documentInfoAndUploadedFile: DocumentInfoAndUploadedFile,
                      ) => {
                        return documentInfoAndUploadedFile.documentInfo.id;
                      },
                    );

                  // Get the existing additionalFieldValues or an empty array
                  // - This is what we update and pass to the updateParticipantEvent
                  const existingAdditionalFieldValues =
                    row.party.additionalFieldValues || [];

                  // Try to find an AdditionalFieldValue object for the "participant-file-selection" spec
                  let specAdditionalFieldValue:
                    | AdditionalFieldValue
                    | undefined = existingAdditionalFieldValues.find(
                    (f) => f.additionalFieldName === params.additionalFieldName,
                  );

                  if (specAdditionalFieldValue) {
                    // If found, push to the existing fileValues array
                    documentIds.forEach((id: string) => {
                      specAdditionalFieldValue!.fileValues?.push(id);
                    });
                  } else {
                    // If not found then create it and populate fileValues array
                    specAdditionalFieldValue = {
                      additionalFieldName: params.additionalFieldName,
                      fileValues: [...documentIds],
                    };

                    // Push the new AdditionalFieldValue object to the list of existing AdditionalFieldValue objects
                    existingAdditionalFieldValues.push(
                      specAdditionalFieldValue,
                    );
                  }

                  // const participantCategory: ParticipantCategory = row.party.participantCategory!;

                  // this.updateParticipant({
                  //   participantCategory,
                  //   caseParty: row.party,
                  //   partyIndex: row.partyIndex,
                  //   additionalFieldValues: existingAdditionalFieldValues
                  // });

                  this.uploadAdditionalFieldFilesOrchestrationService.uploadFiles(
                    {
                      documentInfoAndUploadedFiles,
                    },
                  );
                },
              ),
            );
          },
        ),
      )
      .subscribe();
  }

  /**
   * A method to handle the setting of AdditionalFieldValue objects for parties.
   *
   * @param additionalFieldValues The updated additionalFieldValues collection.
   * @param row The PartiesGridRow of the party to which the additionalFieldValue is being set.
   */
  onAdditionalFieldValuesEmitted(
    additionalFieldValues: AdditionalFieldValue[],
    row: PartiesGridRow,
  ) {
    // Create the partial CaseParty object
    const partialCaseParty: Partial<CasePartyViewModel> = {
      additionalFieldValues: [...additionalFieldValues],
    };

    // Apply the update through the orchestration service.
    this.updatePartyOrchestrationService.updateParty({
      caseParty: row.party,
      partialCaseParty,
    });
  }
}
