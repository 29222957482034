import { Inject, Injectable, InjectionToken } from '@angular/core';
import { Observable, combineLatest, map } from 'rxjs';
import {
  FsxCaseRequestDataService,
  ICaseRequestDataService,
} from './case-request-data.service';
import {
  CaseRequestViewModel,
  RequestDocumentViewModel,
} from '@fsx/fsx-shared';
import {
  FsxValidationErrorsService,
  IValidationErrorsService,
  ValidationError,
} from './validation-errors.service';

/**
 * The data structure of a validation group to assist with the
 * displaying of error messages in the checklist component.
 */
export interface ValidationGroup {
  groupName: string;
  singular: string;
  plural: string;
}

/**
 * An abstract class in which we declare some static constants to
 * use in quick assignments in the ValidationGroupErrorsService
 * and spec file.
 */
export abstract class ValidationGroupConstants {
  static detail: ValidationGroup = {
    groupName: 'Case Detail',
    singular: 'field',
    plural: 'fields',
  };

  static parties: ValidationGroup = {
    groupName: 'Parties',
    singular: 'party',
    plural: 'parties',
  };

  static documents: ValidationGroup = {
    groupName: 'Documents',
    singular: 'document',
    plural: 'documents',
  };

  static review: ValidationGroup = {
    groupName: 'Review & Submit',
    singular: 'field',
    plural: 'fields',
  };

  static unknown: ValidationGroup = {
    groupName: 'Additional',
    singular: 'unknown',
    plural: 'unknowns',
  };
}

/**
 * The data structure of the object to store in the ValidationGroupErrorsService.
 */
export interface ValidationGroupError {
  /**
   * The validation group to assist with display in the checklist
   */
  group: ValidationGroup;

  /**
   * The count of entity errors in the validation group (n parties, n documents etc).
   * UI and server-side filing profile rule errors should not be included in this count.
   */
  errorCount: number;

  /**
   * Any filing profile rule errors from either the UI or the server.
   */
  errorMessages: ValidationError[];
}

/**
 * The InjectionToken to use in the providers array to specify a concrete-implementation
 * of the IValidationGroupErrorsService to use at runtime.
 */
export const FsxValidationGroupErrorsService =
  new InjectionToken<IValidationGroupErrorsService>(
    'FsxValidationGroupErrorsService',
  );

/**
 * A blueprint for a UI service, which transforms the caseRequestViewModel entity validation
 * errors into validation groups with error counts.
 */
export interface IValidationGroupErrorsService {
  /**
   * The validation group errors exposed as an Observable.
   */
  validationGroupErrros$: Observable<ValidationGroupError[]>;
}

/**
 * A concrete implementation of a UI service, which transforms the caseRequestViewModel
 * entity validation errors into validation groups with error counts.
 */
@Injectable()
export class ValidationGroupErrorsService
  implements IValidationGroupErrorsService
{
  /**
   * The validation group errors exposed as an Observable.
   */
  validationGroupErrros$: Observable<ValidationGroupError[]> = combineLatest([
    this.caseRequestDataService.caseRequest$,
    this.validationErrorsService.getValidationErrorsForGroup(
      ValidationGroupConstants.detail,
    ),
    this.validationErrorsService.getValidationErrorsForGroup(
      ValidationGroupConstants.parties,
    ),
    this.validationErrorsService.getValidationErrorsForGroup(
      ValidationGroupConstants.documents,
    ),
    this.validationErrorsService.getValidationErrorsForGroup(
      ValidationGroupConstants.review,
    ),
    this.validationErrorsService.getValidationErrorsForGroup(
      ValidationGroupConstants.unknown,
    ),
  ]).pipe(
    map(
      ([
        caseRequest,
        detailValidationErrors,
        partiesValidationErrors,
        documentsValidationErrors,
        reviewValidationErrors,
        unknownValidationErrors,
      ]: [
        CaseRequestViewModel,
        ValidationError[],
        ValidationError[],
        ValidationError[],
        ValidationError[],
        ValidationError[],
      ]) => {
        let validationGroupErrors: ValidationGroupError[] = [];

        const countOfInvalidCaseDetailFields = 0;
        validationGroupErrors.push({
          errorCount: countOfInvalidCaseDetailFields,
          group: ValidationGroupConstants.detail,
          errorMessages: [...detailValidationErrors],
        });

        const countOfInvalidParties =
          this.getCountOfInvalidParties(caseRequest);
        validationGroupErrors.push({
          errorCount: countOfInvalidParties,
          group: ValidationGroupConstants.parties,
          errorMessages: [...partiesValidationErrors],
        });

        const countOfInvalidDocuments =
          this.getCountOfInvalidDocuments(caseRequest);
        validationGroupErrors.push({
          errorCount: countOfInvalidDocuments,
          group: ValidationGroupConstants.documents,
          errorMessages: [...documentsValidationErrors],
        });

        const countOfInvalidReviewFields = 0;
        validationGroupErrors.push({
          errorCount: countOfInvalidReviewFields,
          group: ValidationGroupConstants.review,
          errorMessages: [...reviewValidationErrors],
        });

        validationGroupErrors.push({
          errorCount: 0,
          group: ValidationGroupConstants.unknown,
          errorMessages: [...unknownValidationErrors],
        });

        return validationGroupErrors;
      },
    ),
  );

  /**
   * A private method to combine the count of invalid participant objects with the
   * count of invalid parties objects into a single count.
   *
   * @param caseRequest The case request object, which holds the participant and
   * parties objects from which we dervice the Parties validation error count.
   *
   * @returns The number of Parties with errors on either the particpant or party object
   */
  private getCountOfInvalidParties(caseRequest: CaseRequestViewModel): number {
    // A set to ensure that any participantName can only exist once
    let invalidParticipantIds: Set<string> = new Set<string>();

    caseRequest.participants?.forEach((p) => {
      if (p.isValid === false) {
        invalidParticipantIds.add(p.name);
      }
    });

    caseRequest.parties?.forEach((p) => {
      if (p.isValid === false) {
        invalidParticipantIds.add(p.participantName);
      }
    });

    return invalidParticipantIds.size;
  }

  /**
   * A private method to count the number of invalid documents
   *
   * @param caseRequest The case request object, which holds the documents from which we
   * dervice the Documents validation error count.
   *
   * @returns The number of Documents with errors
   */
  private getCountOfInvalidDocuments(
    caseRequest: CaseRequestViewModel,
  ): number {
    const countOfInvalidDocuments =
      caseRequest.documents?.filter((d: RequestDocumentViewModel) => {
        return d.isValid == false;
      }).length || 0;

    return countOfInvalidDocuments;
  }

  /**
   *
   * @param caseRequestDataService The single source of truth for case request data.
   */
  constructor(
    @Inject(FsxCaseRequestDataService)
    private readonly caseRequestDataService: ICaseRequestDataService,
    @Inject(FsxValidationErrorsService)
    private readonly validationErrorsService: IValidationErrorsService,
  ) {}
}
