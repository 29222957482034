import { Component, Input } from '@angular/core';
import { FormArray, FormControl, FormGroup } from '@angular/forms';

@Component({
  template: '',
})
export class FsxBaseComponent {
  @Input() inputWidth!: string;

  public preventEvent(event: Event): void {
    event.stopImmediatePropagation();
  }

  public shouldValidateFormArray(formArray: FormArray): boolean {
    let validate = true;
    formArray.controls.forEach((fg) => {
      if (validate) {
        const obj = Object(fg.value);
        validate = !Object.keys(obj)
          .map(function (k) {
            return obj[k];
          })
          .reduce((a, b) => a && b === '', true);
      } else {
        return;
      }
    });
    return validate;
  }

  public shouldValidateFormControl(
    formControl: FormControl | FormGroup,
  ): boolean {
    let validate = true;
    if (validate) {
      const obj = Object(formControl.value);
      validate = !Object.keys(obj)
        .map(function (k) {
          return obj[k];
        })
        .reduce((a, b) => a && b === '', true);
    }
    return validate;
  }
}
