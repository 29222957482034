import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import {
  DocumentInfo,
  DocumentInfoHelperService,
  DocumentRenderingInfo,
  FileUploadState,
  RenderingStatus,
  ValidationConstants,
  ValidationDetail,
} from '@fsx/fsx-shared';
import { RowError } from '../documents-grid/documents-grid.model';

@Component({
  selector: 'fsx-file-upload-status',
  templateUrl: './file-upload-status.component.html',
  styleUrls: ['./file-upload-status.component.scss'],
})
export class FileUploadStatusComponent implements OnChanges {
  @Input() documentInfo!: DocumentInfo;
  @Input() errors?: RowError[];
  @Output() cancelUploadEvent = new EventEmitter<void>();

  renderingStatus: typeof RenderingStatus = RenderingStatus;

  isCancelled = false;
  isValid: boolean | null = null;

  fileUploadState!: FileUploadState;

  fileUploadStateEnum: typeof FileUploadState = FileUploadState;
  convertedPdf!: DocumentRenderingInfo | null;
  error?: ValidationDetail | null;

  constructor(
    private readonly documentInfoHelperService: DocumentInfoHelperService,
  ) {}

  ngOnChanges(_changes: SimpleChanges): void {
    this.fileUploadState = this.documentInfoHelperService.getFileUploadState(
      this.documentInfo,
    );
    this.convertedPdf = this.documentInfoHelperService.getConvertedPdfRendering(
      this.documentInfo,
    );

    if (this.errors) {
      // see if we've a file too large error
      // at th emoment, this is the only one we need to surface separately
      const fileTooLargeError = this.errors.find(
        (e) => e.message.code === ValidationConstants.FileSizeTooLarge,
      );

      if (fileTooLargeError) {
        this.error = { ...fileTooLargeError.message };
        this.error.description = fileTooLargeError.message.caption.toString();
        this.error.caption = 'File too large';
        this.isValid = false;
      } else {
        this.error = null;
      }
    } else {
      this.error = null;
    }
  }

  onCancelUploadClicked(event: Event) {
    event.stopPropagation();
    this.isCancelled = true;
    this.cancelUploadEvent.emit();
  }

  validate() {
    this.isValid = !(this.fileUploadState === FileUploadState.Unknown);
  }
}
